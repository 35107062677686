import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {BiSearch, BiXCircle} from 'react-icons/bi'
import {Icons} from '../TopNavBar'
import {palette} from '../../styled/colors'
import {media} from 'styled-bootstrap-grid'
import {useDispatch} from 'react-redux'
import {saveSearchQuery} from '../../actions/authActions'
import {mockUsers} from '../../utils'
import {Flexed, Text} from '../../styled/shared'
import useRouter from '../../hooks/router'
import {api} from '../../helpers/auth-axios'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

const Container = styled.div`
	position: relative;
	${media.xs`
    width: 100%;
`};
	${media.sm`
    width: 100%;
`};
	${media.md`
    width: 100%;
`};
	${media.lg`
    width: 300px;
`};
`

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`

const Input = styled.input<any>`
	width: 100%;
	padding: 10px 30px 10px 40px;
	border: none;
	border-radius: 5px;
	outline: none;

	display: block;
	border-style: ${({isContact, error}) => (isContact ? 'none' : 'solid !important')};
	border-color: ${({error}) => (error ? `#eb5353` : 'rgba(223, 226, 230)')};
	border-width: 0.5px !important;
	min-width: 0;
	font-size: 0.9rem;
	font-weight: 400;
	color: ${palette.text_color};
	line-height: 24px;
	&::placeholder {
		font-weight: 300;
		color: ${palette.input_text_color};
	}
	&:focus {
		outline: 0;
	}
`

const SearchIcon = styled(BiSearch)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	color: #aaa;
	cursor: pointer;
`

const ClearIcon = styled.img`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 2%;
	color: #aaa;
	cursor: pointer;
`

const SuggestionCard = styled.div`
	position: absolute;
	top: calc(100% + 10px); /* Position suggestion card below the input field */
	left: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 10px 20px;
	z-index: 1; /* Ensure suggestion card is above other elements */
`

const SuggestionsList = styled.ul<any>`
	list-style-type: none;
	padding: 0;
	margin: 0;
	height: ${({users}) => (users ? '20rem' : '')};
	overflow-y: scroll;
`

const SuggestedUser = styled.li`
	padding: 8px 0;
	/* border-top: 1px solid #ccc; */
	&:first-child {
		border-top: none;
	}
`

const Suggestions = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const [searchText, setSearchText] = useState('')
	const [openCard, setOpenCard] = useState(false)
	const [users, setUsers] = useState<any[]>([])
	const {t} = useTranslation()

	const suggestionRef: any = useRef(null)

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
				setOpenCard(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleInputChange = (event: any) => {
		setSearchText(event.target.value)
		searchUsers(event.target.value)
		// dispatch(saveSearchQuery(event.target.value))
		setOpenCard(event.target.value?.trim()?.length ? true : false)
	}

	const handleClearInput = () => {
		setSearchText('')
		dispatch(saveSearchQuery(''))
	}

	// Filter users based on search text
	const filteredUsers = mockUsers.filter(
		(user) => user?.name?.toLowerCase().includes(searchText.toLowerCase()) || user?.membership?.toLowerCase().includes(searchText.toLowerCase()) || user?.designation?.toLowerCase().includes(searchText.toLowerCase())
	)

	const renderSuggestedUsers = () => {
		if (!searchText) {
			return null // Hide suggestions if no text entered
		}

		// Map filtered users to JSX
		const usersJSX = users?.map((user, index) => {
			// Function to highlight matched text
			const highlightMatch = (text: any) => {
				const index = text.toLowerCase().indexOf(searchText.toLowerCase())
				if (index !== -1) {
					return (
						<>
							{text.substring(0, index)}
							<strong>{text.substring(index, index + searchText.length)}</strong>
							{text.substring(index + searchText.length)}
						</>
					)
				}
				return text
			}

			// Render user name with matched text highlighted
			return (
				<SuggestedUser
					key={index}
					onClick={() => {
						router.navigate(`/searched-users?query=${user?.full_name?.trimEnd()}`)
						dispatch(saveSearchQuery(user?.full_name))
						setSearchText(user?.full_name)
					}}>
					<Text fontSize={'0.9'} lineHeight={'1.09'} fontWeight={400} color={'text_color'} pointer>
						{highlightMatch(user?.full_name)}
					</Text>
				</SuggestedUser>
			)
		})

		return <>{usersJSX}</>
	}

	const seeAllResultsMessage = `${t('See all results for')} "${searchText}"`

	const searchUsers = async (query?: any) => {
		await api
			.post('/user/search_by_name', {name: query?.trimEnd()})
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data) {
						if (query == res?.data?.data?.query) {
							setUsers(res?.data?.data?.users)
						}
					}
				} else {
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {})
	}

	return (
		<Container ref={suggestionRef}>
			<InputWrapper>
				<SearchIcon />
				<Input type="text" placeholder="Search" value={searchText} onChange={handleInputChange} /> {searchText && <ClearIcon onClick={handleClearInput} src={'/images/icon_close.svg'} />}
			</InputWrapper>
			{openCard && (
				<SuggestionCard>
					<SuggestionsList users={filteredUsers?.length && filteredUsers?.length >= 9 ? true : false}>{renderSuggestedUsers()}</SuggestionsList>
					<Flexed
						padding={'0.8rem 0'}
						onClick={() => {
							dispatch(saveSearchQuery(searchText))
							setSearchText(searchText)
							router.navigate(`/searched-users?query=${searchText?.trimEnd()}`)
						}}>
						<Text fontSize={'0.8'} lineHeight={'0.952'} fontWeight={600} color={'blue_500'} pointer>
							{seeAllResultsMessage}
						</Text>
					</Flexed>
				</SuggestionCard>
			)}
		</Container>
	)
}

export default Suggestions
