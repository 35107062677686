import React, {useEffect, useState} from 'react'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Text, Flexed, Spacer, Paragraph} from '../styled/shared'
import {FaArrowLeft} from 'react-icons/fa6'
import {palette} from '../styled/colors'
import useRouter from '../hooks/router'
import {Card} from './Dashboard'
import {Icons} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {api} from '../helpers/auth-axios'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../actions/authActions'
import {Elements} from '@stripe/react-stripe-js'
import WithdrawForm from '../components/modals/WithdrawModal'
import {loadStripe} from '@stripe/stripe-js'
import FadeLoader from 'react-spinners/FadeLoader'
import Loader from '../components/common/Loader'
import {useTranslation} from 'react-i18next'
import WithdrawPolicies from '../components/modals/WithdrawPolicies'
import Footer from '../components/Footer'
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js'
import {useSelector} from 'react-redux'
import {AppConstants} from '../AppConstants'
import EmailModel from '../components/modals/EmailModel'
const stripePromise = AppConstants.stripe_public_key ? loadStripe(AppConstants.stripe_public_key) : null

const WithdrawSuccess = () => {
	const router = useRouter()
	const [isContinue, setIsContinue] = useState(false)
	const [loading, setLoading] = useState(false)
	const [amount, setAmount] = useState<number>(0)
	const [coins, setCoins] = useState<number>(0)
	const [deduction, setDeduction] = useState<number>(0)
	const [withdrawForm, setWithdrawForm] = useState<Boolean>(false)
	const {t} = useTranslation()
	const [finalAmount, setFinalAmount] = useState<number>(0)
	const [modal, setModal] = useState(false)
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const dispatch = useDispatch()
	const [openModel, setOpenModel] = useState(false)

	useEffect(() => {
		if (router.query.coins) {
			const coinsQuery = router.query.coins
			const coins: any = Array.isArray(coinsQuery) ? coinsQuery[0] : coinsQuery
			const parsedCoins = parseInt(coins, 10)

			if (!isNaN(parsedCoins) && parsedCoins > 0) {
				const calcAmount = parsedCoins / 5
				const calcDeduction = calcAmount * 0.1
				const calcFinalAmount = calcAmount - calcDeduction

				setAmount(calcAmount)
				setCoins(parsedCoins)
				setDeduction(calcDeduction)
				setFinalAmount(calcFinalAmount)
			}
		}
	}, [router.query.coins])
	useEffect(() => {
		if (router?.query?.accountId) {
			transfercoins()
		}
	}, [router.query?.accountId])

	const transfercoins = async () => {
		setLoading(true)
		await api
			.get(`/user/transfer_coins?accountId=${router?.query?.accountId}&amount=${router?.query?.amount}&coins=${router?.query?.coins}`)
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					toast.success(res.data.message)
					setIsContinue(true)
					dispatch(callAutoLogin(true))
					router.setParams('')
				} else {
					setLoading(false)
					router.setParams(`?coins=${router?.query?.coins}`)
					toast.error(res.data.message)
				}
			})
			.catch((err) => {
				setLoading(false)
			})
	}

	const handleWithdrawl = async (cardNumber?: any) => {
		setLoading(true)
		const response = await api.post('/user/withdraw_coins', {coins: coins, amount: amount, cardNumber})

		if (response.data.status) {
			if (response.data?.data?.url) {
				window.location.href = response.data?.data?.url
				setTimeout(() => {
					setLoading(false)
				}, 3000)
			} else {
				toast.success(response.data.message)
				dispatch(callAutoLogin(true))
				setLoading(false)
				setIsContinue(true)
			}
		} else {
			setLoading(false)
			toast.error(response.data.message)
		}
	}

	function PayPalButton({amount, onSuccess}: any) {
		return (
			<PayPalButtons
				createOrder={(data, actions: any) => {
					return actions.order.create({
						purchase_units: [
							{
								amount: {
									value: amount
								}
							}
						]
					})
				}}
				onApprove={(data, actions: any) => {
					return actions.order.capture().then((details: any) => {
						onSuccess(details)
					})
				}}
			/>
		)
	}

	const handleCashout = async () => {
		setLoading(true)
		router.setParams(`?coins=${router?.query?.coins}`)
		try {
			await api
				.post('/user/transfer_coins_paypal', {coins: coins, amount})
				.then((res: any) => {
					if (res.data?.status) {
						if (res?.data?.data?.url) {
							setLoading(false)
							dispatch(callAutoLogin(true))
							window.location.href = res?.data?.data?.url
						} else {
							setLoading(false)
							dispatch(callAutoLogin(true))
							toast.success(res.data?.message)
							setIsContinue(true)
						}
					}
				})
				.catch((err) => {
					toast.error('Error during cashout: ' + err.response?.data?.message)
					setLoading(false)
				})
		} catch (error: any) {
			setLoading(false)
			console.log('====eeee', error)
			toast.error('Error during cashout: ' + error.response?.data?.message)
		}
	}

	return (
		<Section>
			{/* <Spacer height={4} /> */}
			<CenteredContainer fluid>
				{loading ? (
					<Loader />
				) : (
					<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
						<Row>
							<Col>
								<>
									<Flexed direction="column" align={'left'} gap={'0.6'}>
										<Flexed direction="column" align={''} gap={'0'}>
											<Flexed direction="row" align={'center'} gap={'0.6'}>
												<Icons
													src={'/images/icon_view.svg'}
													onClick={() => {
														router.navigate('/withdraw-coins')
													}}
													pointer={'pointer'}
												/>
												{isContinue ? (
													''
												) : (
													<Text fontSize={'1.12'} lineHeight={'1.3'} fontWeight={'700'} color={'dark_blue'}>
														{' '}
														{t('Withdraw Coins')}
													</Text>
												)}
											</Flexed>
										</Flexed>
									</Flexed>
								</>

								{isContinue ? (
									<Flexed align="center" text-align="center" direction="column" gap={1}>
										<SuccessText fontSize={1.5} lineHeight={'2.12'} fontWeight={600} fontStyle={'italic'}>
											{t('Thank You')}
										</SuccessText>

										<SuccessImg src="/images/thankyou.png" alt="Success" />
										<DimondText>{t('For your purchase')}</DimondText>

										<Card marginLeft={'0'} removeShadow radius={'0.6rem'} background={palette.purpole_gradient} width={'16.62rem'} minHeight={'auto'} height={'6.25rem'}>
											<Flexed justify={'center'} align={'center'} direction="row" height={'100%'}>
												<Text fontSize={'2'} lineHeight={'2.54'} fontWeight={'800'} color={'dark_blue'} isCentered>
													$ {amount.toFixed(2)}
												</Text>
											</Flexed>
										</Card>
										<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'500'} color={'dark_blue'}>
											{t('Your coins have been redeemed to your Stripe')}
										</Text>

										<Button
											small
											hasBorder
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													<FaArrowLeft />
													&nbsp; {t('BACK TO BMT')}
												</>
											}
											background={palette.white}
											color={palette.blue_500}
											lineHeight={'0.89rem'}
											ifClicked={() => router.navigate('/buy-my-time')}
										/>
									</Flexed>
								) : (
									<Flexed align="center" text-align="center" direction="column" gap={1}>
										<PaypalFlex align="center" direction="column" justify={'space-around'} gap={1} padding={'2rem'}>
											<Flexed align="center" direction="column" justify={'space-between'} gap={1}>
												<Flexed direction="row" align={'center'} justify={'center'}>
													<Icons src={'/images/paypal.svg'} />
													<Icons width={'50%'} src={'/images/Stripe wordmark - white (small).png'} />
												</Flexed>

												<Text fontSize={'1.5'} lineHeight={'1.84'} fontWeight={'600'} color={'white'} isCentered>
													{t('Redeem your points for cash')}
												</Text>
												<Flexed width={'80%'}>
													<Paragraph fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={'white'} isCentered>
														{t('Redeem your balance for cash to your account with Stripe/Paypal')}
													</Paragraph>
												</Flexed>

												<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'700'} color={'white'} isCentered>
													{t('REWARD UP TO')}
												</Text>

												<Card marginLeft={'0'} removeShadow radius={'0.6rem'} background={palette.paypal_card} width={'15.62rem'} minHeight={'auto'} height={'6.25rem'}>
													<Flexed justify={'center'} align={'center'} direction="row" height={'100%'}>
														<Text fontSize={'2'} lineHeight={'2.54'} fontWeight={'800'} color={'dark_blue'} isCentered>
															$ {amount.toFixed(2)}
														</Text>
													</Flexed>
												</Card>

												{/* <Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'500'} color={'white'} isCentered>
													Amount: ${amount.toFixed(2)} - (10%)Deduction: ${deduction.toFixed(2)} = Final: ${finalAmount.toFixed(2)}
												</Text> */}
											</Flexed>
											<Spacer height={3} />
											<Flexed align="start" direction="column" justify={'space-between'} gap={0.5}>
												<Button
													small
													// hasBorder
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={
														<>
															<Icons src={'/images/icon_log-out.svg'} /> &nbsp;{t('CONTINUE TO WITHDRAW COINS USING STRIPE')}
														</>
													}
													background={palette.white}
													color={palette.dark_blue}
													lineHeight={'0.90rem'}
													ifClicked={() => handleWithdrawl()}
												/>
												<Button
													small
													// hasBorder
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={
														<>
															<Icons src={'/images/icon_log-out.svg'} /> &nbsp;{t('CONTINUE TO WITHDRAW COINS USING PAYPAL')}
														</>
													}
													background={palette.white}
													color={palette.dark_blue}
													lineHeight={'0.90rem'}
													ifClicked={() => setOpenModel(true)}
												/>
											</Flexed>
											<Text textDecoration="underline" onClick={() => setModal(true)} isCentered pointer fontSize={'0.72'} lineHeight={'1.25'} fontWeight={'400'} color={'white'}>
												{t('Withdrawl Policies')}
											</Text>
										</PaypalFlex>
									</Flexed>
								)}
							</Col>
						</Row>

						<Spacer height={1} />
					</Card>
				)}
			</CenteredContainer>
			{withdrawForm && (
				<Elements stripe={stripePromise}>
					<WithdrawForm coins={coins} amount={amount} setIsContinue={setIsContinue} closeModal={() => setWithdrawForm(false)} />
				</Elements>
			)}
			{openModel && (
				<EmailModel
					onClose={() => {
						setOpenModel(false)
					}}
					onSubmit={() => {
						setIsContinue(true)
						setOpenModel(false)
					}}
					coins={coins}
					amount={amount}
				/>
			)}
			{modal && <WithdrawPolicies onClose={() => setModal(false)} />}
			{!loading ? <Footer /> : ''}
		</Section>
	)
}

const Section = styled.div`
	background-image: url('/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const PaypalFlex = styled(Flexed)`
	background-image: url('/images/withdraw_coins_bg.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 26.87rem;
	height: 40.18rem;
	padding: 1rem;
`

const CenteredContainer = styled(Container)`
	margin-top: 6rem;
	display: flex;
	justify-content: center;
`
const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const DimondText = styled(Text)`
	color: ${palette.dark_blue};
	font-weight: 700;
	font-size: 1.125;
	line-height: 1.361rem;
`
const ButtonMain = styled(Flexed)`
	width: 12.938rem;
	height: 2rem;
	font-size: 0.75rem;
	border: 1px solid;
	border-radius: 1rem;
	padding: 0.5rem, 1rem, 0.5rem, 1rem;
	color: #006fee;
`
const ButtonMainText = styled(Text)`
	color: #006fee;
	font-size: 0.75rem;
`
const Cardimg = styled.img`
	background: linear-gradient();
`
export default WithdrawSuccess
