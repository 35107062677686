import React from 'react'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Spacer} from '../styled/shared'
import ProBMTMemberShip from '../components/bmt/ProBMTMemberShip '
import Footer from '../components/Footer'

const UpgradeBMTMembership = () => {
	return (
		<MainContainer>
			<Spacer height={6} />
			<Container fluid>
				<ProBMTMemberShip />
				<Footer />
			</Container>
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 0;
`

export default UpgradeBMTMembership
