import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../../styled/shared'
import {Card} from '../../pages/Dashboard'
import {palette} from '../../styled/colors'
import moment from 'moment-timezone'
import useRouter from '../../hooks/router'
import {Icons, ProfileImg} from '../TopNavBar'
import {mockUsers} from '../../utils/index'
import {CustomCards} from '../../pages/BMT'
import {FaArrowRight} from 'react-icons/fa'
import Button from '../common/Button'
import Dimond from '../../pages/Dimond'
import {api} from '../../helpers/auth-axios'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {callAutoLogin, saveUser} from '../../actions/authActions'
import Loader from '../common/Loader'
import {useTranslation} from 'react-i18next'

const FreeBMTMemberShip = ({bmtDetails, onClose, isSignup}: {bmtDetails?: any; onClose?: any; isSignup?: any}) => {
	const [isProceed, setIsProceed] = useState<any>(false)
	const router: any = useRouter()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation()
	const [inviteFrom, setInviteFrom] = useState<any>({email: '', userId: ''})

	useEffect(() => {
		// Extract encoded query parameter from the URL
		let url: any = localStorage.getItem('invited-details')
		const urlParams = new URLSearchParams(url)
		if (urlParams) {
			const encodeduserId = urlParams.get('data')
			const encodedemail = urlParams.get('email')

			// Decode the data
			if (encodeduserId && encodedemail) {
				const decodedData = atob(encodeduserId)
				const decodedEmail = atob(encodedemail)
				setInviteFrom({email: decodedEmail, userId: decodedData})
			} else {
				console.error('Encoded data is null or undefined')
			}
		}
	}, [])

	const updateUserMembership = async (membership?: any) => {
		setLoading(true)
		await api
			.post('/user/update_user_membership', {membership: membership, invite_email: inviteFrom?.email, invite_user_id: inviteFrom.userId})
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					setIsProceed(true)
					dispatch(callAutoLogin(true))
					if (inviteFrom?.email) {
						localStorage.removeItem('invited-details')
					}
					const redirect = sessionStorage.getItem('redirect_url')
					if (redirect) {
						// Navigate to the stored path
						router.navigate(redirect)
					}
					toast.success(res?.data?.message)
				}
				if (!res?.data?.status) {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((er: any) => {
				setLoading(false)
			})
	}

	const getDetails = (type: string) => {
		const membershipDetails: {[key: string]: string} = {
			diamond: `"${t('Exclusive for C-Level executives, Managing Partners, Chairmen, Presidents, and entrepreneurs who lead organizations and drive strategic vision.')}"`,
			gold: `"${t('Designed for senior executives, including directors, vice presidents, and managing directors.')}"`,
			silver: `"${t('Ideal for middle management professionals such as managers, senior managers, and heads of departments.')}"`,
			bronze: `"${t('Perfect for entry-level professionals, freelancers, specialists, individual contributors, and junior managers looking to grow.')}"`
		}

		return membershipDetails[type]
	}

	console.log('======inviteFrom', inviteFrom)
	return (
		<Card background={palette.white} padding={'1rem 1.2rem'} style={{overflowY: 'scroll'}}>
			{loading && <Loader />}
			<Row alignItems="center">
				<Col lg={12} md={12} sm={12}>
					{isProceed ? (
						<>
							{router?.pathname == '/buy-membership' ? (
								<Spacer height={'0.5'} />
							) : (
								<Flexed direction="column" align={''} gap={'0'} padding={'0.5rem 1.5rem'}>
									<Flexed direction="row" align={'left'} gap={'0.6'}>
										<Icons
											src={'/images/icon_view.svg'}
											onClick={() => {
												setIsProceed(false)
											}}
											pointer={'pointer'}
										/>
									</Flexed>
								</Flexed>
							)}
							<Dimond bmtDetails={bmtDetails} onBack={onClose} />
						</>
					) : (
						<Card background={'transparent'} padding={''} removeShadow overflow={'visible'}>
							<Flexed direction="column" align={''} gap={'0'} padding={'0rem 0.5rem'}>
								<Flexed direction="row" align={'center'} gap={'0.6'}>
									<Icons
										src={'/images/icon_view.svg'}
										onClick={() => {
											onClose && onClose()
										}}
										pointer={'pointer'}
									/>
									<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
										{t('BMT Free Membership')}
									</Text>
								</Flexed>
							</Flexed>
							<Spacer height={'3'} />
							<Flexed direction="column" height={'100%'} align={'center'}>
								<HandleFlex direction={'column'} align={'center'} flexFlow={'wrap'} gap={'0.5'} flexWrap={'wrap'}>
									<Flexed gap={'0.6'} height={'7.75rem'}>
										{bmtDetails[0]?.type?.toLowerCase().includes('diamond') ? (
											<Icons isBookNetworking src={'/images/daimond_member_card.svg'} height={'100%'} />
										) : bmtDetails[0]?.type?.toLowerCase().includes('gold') ? (
											<Icons isBookNetworking src={'/images/gold_member_card.svg'} height={'100%'} />
										) : bmtDetails[0]?.type?.toLowerCase().includes('silver') ? (
											<Icons isBookNetworking src={'/images/silver_member_card.svg'} height={'100%'} />
										) : bmtDetails[0]?.type?.toLowerCase().includes('bronze') ? (
											<Icons isBookNetworking src={'/images/bronze_member_card.svg'} height={'100%'} />
										) : (
											''
										)}
									</Flexed>
									<Flexed gap={'0.6'} width={'35rem'}>
										<Text fontSize="0.9" isCentered>
											{getDetails(bmtDetails[0]?.type)}
										</Text>
									</Flexed>

									<Spacer height={'0.5'} />
									<Card width={'43rem'} removeShadow borderColor={palette.zink_300} padding={'0rem'} background="transparent">
										<ManageCardComponent gap={'0.2'} direction={'column'}>
											<Flexed bmtDetails padding="1.64rem 1.64rem 0 1.64rem" justify="space-between" align="center" direction="row" gap="0.7">
												<Icons src={'/images/time.png'} height={'100%'} />
												<Text lineHeight={'1.21'} xw fontWeight={'500'} color={'text_color'}>
													{t('As a')} {bmtDetails[0]?.type?.toUpperCase()} {t('Member, you’ll earn these coins that value your time and can be withdrawn as cash')}.{' '}
												</Text>
											</Flexed>
											<HR />
											<Flexed bmtDetails gap={1} direction="row" justify="center" align="center">
												{bmtDetails?.map((card: any, i: any) => (
													<CustomCards padding={'1rem'} width="19.25rem" removeShadow radius={'0.6rem'} marginLeft={'0'} background={'transparent'} borderColor={palette.default_200} minHeight={'auto'} height={'8.125rem'}>
														<Flexed align={'center'} padding={'1'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
															<Text fontSize={'1.25'} lineHeight={'0.95'} fontWeight={'700'} color={'text_color'}>
																{card?.name}
															</Text>
														</Flexed>
														<Spacer height={0.4} />

														<Flexed align={'center'} padding={'1'} justify={'space-between'} direction={'row'}>
															<Flexed align={'start'} gap={'0.4'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																<Flexed align={'center'} justify={'center'} gap={'0.2'} direction={'row'}>
																	<Text fontSize={'1'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																		<Icons marginRight={'8px'} src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
																		&nbsp;{parseInt(card?.coins)}
																	</Text>
																	<Text lineHeight={'0.81'} fontSize={0.8} fontWeight={'500'} color={'blue_500'}>
																		{t('coins')}
																	</Text>
																</Flexed>

																<Flexed justify={'center'} align={'center'} gap={'0.2'} direction={'row'}>
																	<Text display="flex" fontSize={'1'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																		<Icons marginRight={'8px'} src={'/images/schedule.svg'} style={{height: '0.9rem'}} />
																		&nbsp;{parseInt(card?.time)}
																	</Text>
																	<Text lineHeight={'0.81'} fontSize={0.8} fontWeight={'500'} color={'blue_500'}>
																		{t('minutes')}
																	</Text>
																</Flexed>
															</Flexed>

															<Flexed align={'center'} gap={'0.2'} justify={'center'} direction={'row'} style={{background: palette.input_bg, borderRadius: '10px'}} width={'6.75rem'} height={'5.125rem'}>
																<Text fontSize={'2'} lineHeight={'1.53'} fontWeight={'700'} color={'text_color'}>
																	${card?.price}
																</Text>
															</Flexed>
														</Flexed>
													</CustomCards>
												))}
											</Flexed>
										</ManageCardComponent>
										<Spacer height={'1'} />

										<Text align="center" fontSize={'1'} lineHeight={'1.21'} fontWeight={'500'} color={'text_color'}>
											{t('Upon confirmation you are unable to change this setting until 2 weeks')}
										</Text>
										<Spacer height={'1'} />
									</Card>

									<Spacer height={'1'} />
									<Button
										small
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'8px 16px 8px 16px'}
										label={
											<>
												{t('PROCEED')} &nbsp; <FaArrowRight />
											</>
										}
										background={palette.blue_500}
										color={palette.white}
										lineHeight={'0.89rem'}
										ifClicked={() => {
											updateUserMembership(bmtDetails?.map((e: any) => e?.id))
										}}
									/>
								</HandleFlex>
							</Flexed>
						</Card>
					)}
				</Col>
			</Row>
			<Spacer height={'1'} />
		</Card>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
`

export const CustomSmallScreenSpacer = styled(Spacer)<any>`
	${media.xs`
    display: flex;
    `}
	${media.sm`
    display: flex;

    `}
    ${media.md`

    display: none;
    `}
	${media.lg`
   display: none;
    `}
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (max-width: 768px) {
		flex-flow: wrap;
	}
`

const ManageCardComponent = styled(Flexed)<any>`
	height: ${({height}) => height};
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
	text-align: center;
	align-items: center;
	height: 100%;
	 `};
`

const HR = styled.hr`
	border: 0.1px solid gainsboro;
`
export default FreeBMTMemberShip
