import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Text, Flexed, Spacer} from '../styled/shared'
import {FaArrowLeft} from 'react-icons/fa6'
import {palette} from '../styled/colors'
import {Icons} from '../components/TopNavBar'
import Button from '../components/common/Button'
import useRouter from '../hooks/router'
import {useTranslation} from 'react-i18next'

const Dimond = ({bmtDetails, onBack}: {bmtDetails?: any; onBack?: any}) => {
	const router = useRouter()
	const {t} = useTranslation()

	const userAgent = navigator.userAgent.toLowerCase()

	const isMobile = /iphone|ipad|ipod|android/.test(userAgent)
	const isTablet = /ipad/.test(userAgent)
	const isDesktop = !isMobile && !isTablet

	return (
		<Section justify="center">
			<Container fluid>
				<Row>
					<Col>
						<Flexed align="center" text-align="center" direction="column" gap={1}>
							<SuccessText fontSize={1.5} fontWeight={600}>
								{t('Congratulations')}
							</SuccessText>

							<SuccessImg src="/images/dimond.png" alt="Success" />

							<DimondText>{t('You are now a')} </DimondText>

							<Flexed gap={'0.6'} pointer height={'7.75rem'} direction={'row'}>
								{bmtDetails[0]?.type?.toLowerCase().includes('diamond') ? (
									<Icons src={'/images/daimond_member_card.svg'} height={isMobile ? 'auto' : '100%'} />
								) : bmtDetails[0]?.type?.toLowerCase().includes('gold') ? (
									<Icons src={'/images/gold_member_card.svg'} height={isMobile ? 'auto' : '100%'} />
								) : bmtDetails[0]?.type?.toLowerCase().includes('silver') ? (
									<Icons src={'/images/silver_member_card.svg'} height={isMobile ? 'auto' : '100%'} />
								) : bmtDetails[0]?.type?.toLowerCase().includes('bronze') ? (
									<Icons src={'/images/bronze_member_card.svg'} height={isMobile ? 'auto' : '100%'} />
								) : (
									''
								)}
							</Flexed>
							<Spacer height={'1'} />

							<Button
								small
								borderRadius={'1rem'}
								hasBorder
								fontSize={'0.8rem'}
								padding={'8px 16px 8px 16px'}
								label={
									<>
										<FaArrowLeft />
										&nbsp; {router?.pathname == '/buy-membership' ? 'GO TO DASHBOARD' : 'BACK TO BMT'}
									</>
								}
								background={palette.white}
								color={palette.blue_500}
								lineHeight={'0.89rem'}
								ifClicked={() => (router?.pathname == '/buy-membership' ? router.navigate('/dashboard') : onBack && onBack())}
							/>
						</Flexed>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}
const Section = styled(Flexed)`
	align-items: center;
`
const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 15.466rem;
	height: 15.125rem;
`
const DimondText = styled(Text)`
	color: ${palette.dark_blue};
	font-weight: 700;
	font-size: 1.125;
	line-height: 1.361rem;
`
const ButtonMain = styled(Flexed)`
	width: 12.938rem;
	height: 2rem;
	font-size: 0.75rem;
	border: 1px solid;
	border-radius: 1rem;
	padding: 0.5rem, 1rem, 0.5rem, 1rem;
	color: #006fee;
`
const ButtonMainText = styled(Text)`
	color: #006fee;
	font-size: 0.75rem;
`
export default Dimond
