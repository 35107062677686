import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {FacebookShareButton, LinkedinShareButton, WhatsappShareButton, TwitterShareButton} from 'react-share'
import {Icons} from '../TopNavBar'
import {media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import copy from 'copy-to-clipboard'
import {toast} from 'react-toastify'
import DynamicMetaTags from '../DynamicMetadata'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const Header = styled(Text)`
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

interface ShareModalProps {
	header?: any
	body?: any
	user?: any
	onReportAndBlock?: (reason: any) => void
	onCancel?: () => void
	onReport?: (reason: any) => void
	onClose?: () => void
	buttonTitle?: string
}

const ShareModal = ({header, onClose, user, body}: ShareModalProps) => {
	const {t} = useTranslation()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const title = `Join ${user?.full_name ? user?.full_name : user?.first_name} on the JustNetwork platform and turn your valuable time into profit! Connect, collaborate, and profit from your time.`
	let linkedinTitle = `Join me on JustNetwork Platform. A revolutionary prospecting platform that forster connectivity among business professionals. The Magic ” BMT feature stands for Buy My Time, users can Sign up free and take a free membership that align with your professional roles, therefore you will access bmt link to share with whoever must value your time.`
	return (
		<Overlay>
			{/* <DynamicMetaTags
				title={user ? `Join ${user?.full_name} on the JustNetwork platform and turn your valuable time into profit! Connect, collaborate, and profit from your time.` : ''}
				imageUrl={user?.image_Url ? user?.image_Url : ''}
				url={`${window.location.origin}/${user?.user_name}`}
				description={user?.specifications ? user?.specifications : ''}></DynamicMetaTags> */}
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>

				<Text>{t(body)}</Text>
				<Spacer height={2} />

				{/* Social Media Share Options */}
				<CustomFlexed direction="column" width={'100%'} justify={'space-between'} align={'center'}>
					<Flexed icons direction="row" width={'100%'} justify={'space-between'} isIcons align={'center'}>
						<WhatsappShareButton title={title} url={`${window.location.origin}/${user?.user_name}`}>
							<Icons isSocialIcon pointer={'pointer'} src={'/images/whatsApp.svg'} />
						</WhatsappShareButton>

						<FacebookShareButton title={title} url={`${window.location.origin}/${user?.user_name}`}>
							<Icons
								isSocialIcon
								onClick={() => {
									toast.success('Content coppied to clipboard')
									copy(`${title}`)
								}}
								pointer={'pointer'}
								src={'/images/facebook.svg'}
							/>
						</FacebookShareButton>
						<TwitterShareButton title={title} url={`${window.location.origin}/${user?.user_name}`}>
							<Icons
								width="42px"
								onClick={() => {
									toast.success('Content coppied to clipboard')
									copy(`${title}`)
								}}
								isSocialIcon
								pointer={'pointer'}
								src={'/images/x.png'}
							/>
						</TwitterShareButton>

						<LinkedinShareButton source="JustNetwork" summary={linkedinTitle} title={title} url={`${window.location.origin}/${user?.user_name}`}>
							<Icons
								isSocialIcon
								pointer={'pointer'}
								onClick={() => {
									toast.success('Content coppied to clipboard')
									copy(`${linkedinTitle}` + '\n' + `${window.location.origin}/${user?.user_name}`)
								}}
								src={'/images/linkedin_1.svg'}
							/>
						</LinkedinShareButton>
					</Flexed>
				</CustomFlexed>
			</Modal>
		</Overlay>
	)
}

const CustomFlexed = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	flex-direction: column;
	 `};
`
export default ShareModal
