import React from 'react'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {Icons} from './TopNavBar'
import {Col, Row, media} from 'styled-bootstrap-grid'
import Button from './common/Button'
import useRouter from '../hooks/router'
import {useTranslation} from 'react-i18next'
import {Card} from '../pages/BMT'

const PaidMembership: React.FC<any> = ({clickUpgrade}) => {
	const router = useRouter()
	const {t} = useTranslation()
	return (
		<Card width={'100%'} background={palette.default_theme} height={8} padding={'0.5rem'} style={{alignItems: 'center', display: 'flex', justifyContent: 'space-between'}}>
			<Row justifyContent="between" alignItems="center" style={{width: '100%'}}>
				<Col sm={12} md={6} lg={9}>
					<HandleFlex direction={'row'} align={'center'} gap={'0.7'}>
						<ProBadgeWrapper>
							<ProBadgeCircle></ProBadgeCircle>
							<Icons height={'100%'} width={'100%'} src={'/images/pro_badge.svg'} />
						</ProBadgeWrapper>
						<Spacer height={1} />
						<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
							<Text fontSize={'1.12'} lineHeight={'1.2'} fontWeight={'700'} color={'text_color'}>
								{t('Paid Memberships')}
							</Text>
							<Spacer height={0.5} />
							<Flexed direction={'row'} gap={'0.4'} align={'center'} flexWrap={'wrap'}>
								<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
									{t('Become a')}
								</Text>

								<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'}>
									{t('Pro Member')}
								</Text>
							</Flexed>
							<Spacer height={0.5} />
							<Text banner fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'white'} fontStyle={'italic'}>
								{t('Tailored for top entrepreneurs, VCs, influencers, celebrities, Public Figures and similar high-profile individuals, whose time is exceptionally valuable.')}
							</Text>
						</HandleFlex>
					</HandleFlex>
				</Col>
				<Col lg={3} md={6} sm={12} alignSelf="end">
					<HandleFlex direction="column" justify={'center'} align={'center'} gap={'1'} padding={'1rem'}>
						<Flexed direction="row" justify={'space-between'} align={'center'} gap={'0.8'} width={'16.87rem'} height={'3rem'} padding={'0 1rem'} background={`rgba(228, 228, 231, 0.1)`} radius={'8px'}>
							<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
								{t('Monthly Subscription')}
							</Text>
							<Text fontSize={'1.8'} lineHeight={'2.5'} fontWeight={'700'} color={'white'}>
								$8
							</Text>
						</Flexed>
						<Button
							small
							borderRadius={'1rem'}
							fontSize={'0.7rem'}
							fontWeight={600}
							padding={'0 0.5rem'}
							label={<>{t('UPGRADE NOW')}</>}
							background={palette.white}
							color={palette.text_color}
							lineHeight={'0.8rem'}
							ifClicked={() => {
								router.navigate('/upgrade-bmt-membership?signup=true')
							}}
						/>
					</HandleFlex>
				</Col>
			</Row>
		</Card>
	)
}

const PaidMembershipContainer = styled(Flexed)`
	${media.xs`
      flex-direction: column;
      align-items: center;
      text-align: center;
   `}
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 70%;
	}
	${media.xs`
	display: content;
	width: 100%
	 `};
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 94px;
	height: 94px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`
const Dot = styled.div<{bg: string}>`
	width: 5px;
	height: 5px;
	background: ${({bg}) => bg};
	border-radius: 50%;
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
      text-align: center;
      align-items: center;
      justify-content: center;
	  flex-direction: column;
      width: 100%;
   `};
`

export default PaidMembership
