import {useEffect, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import styled from 'styled-components'
import TopNavBar from './components/TopNavBar'
import Home from './pages/Home'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import SignUp from './pages/SignUp'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {palette} from './styled/colors'
import OtpVerificationPage from './pages/OtpVerification'
import Dashboard from './pages/Dashboard'
import Network from './pages/Network'
import BottomChatCardPopup from './components/BottomChatCardPopup'
import BookNetwrokUserMeeting from './pages/BookNetwrokUserMeeting'
import Inbox from './pages/Inbox'
import RescheduleRequest from './components/netwrokComponents/RescheduleRequest'
import RescheduleRequestSuccessful from './components/netwrokComponents/RescheduleRequestSuccessful'
import AcceptRescheduleRequestSuccessful from './components/netwrokComponents/AcceptRescheduleRequestSuccessful'
import DeclineRequest from './components/netwrokComponents/DeclineRequest'
import BMT from './pages/BMT'
import BMTSuccess from './pages/BMTSuccess'
import Dimond from './pages/Dimond'
import Withdraw from './pages/Withdraw'
import WithdrawSuccess from './pages/WithdrawSuccess'
import Logout from './pages/Logout'
import UpgradeBMTMembership from './pages/UpgradeBMTMembership'
import Notifications from './pages/Notifications'
import Calendar from './pages/Calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import SearchedUsers from './pages/SearchedUsers'
import BMTPaymentSuccessful from './components/bmt/BMTPaymentSuccessful'
import ProMemberCongrats from './components/bmt/ProMemberCongrats'
import BuyCoins from './pages/BuyCoins'
import BuyCoinsPayment from './components/bmt/BuyCoinsPayment'
import SendCoins from './pages/SendCoins'
import {api} from './helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {callAutoLogin, chatAllUsers, chatUnreadMessages, chatUsers, networkUsers, saveUser} from './actions/authActions'
import {toast} from 'react-toastify'
import useRouter from './hooks/router'
import UpdateUserPassword from './pages/UpdateUserPassword'
import StartVideoMeeting from './pages/InitializeVideoCall'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import {UIKitSettingsBuilder} from '@cometchat/uikit-shared'
import {metaInfo} from './metaInfo'
import {AppConstants} from './AppConstants'
import UserProfileAfterSignup from './pages/UserProfileAfterSignup'
import VideoCallPage from './pages/VideoCallAppointment'
import moment from 'moment-timezone'
import SuccessPaymentMessage from './components/SuccessPaymentMessage'
import ProMembershipSuccessfull from './components/bmt/ProMembershipSuccessfull'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import PageNotFound from './pages/PageNotFound'
import ContactUsForm from './components/ContactUsForm'
import Footer from './components/Footer'
import DynamicMetaTags from './components/DynamicMetadata'
import {PrivateRoute} from './routes/PrivateRoute'
import LazyLoader from './components/common/LazyLoader'
import {useTranslation} from 'react-i18next'
import i18n from './i18n/i18n'
import {Helmet, HelmetProvider} from 'react-helmet-async'

interface IProps {
	active: boolean
	isDarkTheme: boolean
}

const GlobalLoader = () => <div>{/* <LazyLoader /> */}</div>

const App = () => {
	const {pathname} = useLocation()
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const verifyAuthenticated = localStorage.getItem('authenticated')
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)
	const token = localStorage.getItem('authorization')
	const dispatch: any = useDispatch()
	const router: any = useRouter()
	const [loginuserInChat, setLoginUserInChat] = useState(false)
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const {t} = useTranslation()
	let checkPathname = router.pathname?.includes('.bmt')

	useEffect(() => {
		moment.tz.setDefault(userDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)

		const updateTimezone = async () => {
			if (userDetails?.id && !userDetails?.timezone) {
				await api.post('/user/update', {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
			}
		}
		updateTimezone()
	}, [userDetails])

	useEffect(() => {
		if (!userDetails?.id && token && pathname == '/initialize-video') {
			autoLogin()
		}
	}, [])

	useEffect(() => {
		if (_isDarkTheme) {
			document.body.style.backgroundColor = '#141414'
		} else {
			document.body.style.backgroundColor = '#ffffff'
		}
	}, [_isDarkTheme, token])

	useEffect(() => {
		if (router?.query?.token) {
			if (router?.query?.status) {
				toast.success(t(router?.query?.status))
				loginUser()
			}
			if (router?.query?.error) {
				toast.error(t(router?.query?.error))
				loginUser()
			}
			if ((router.pathname == '/calendar' && router?.query?.token) || (router.pathname == '/buy-membership' && router?.query?.token)) {
				localStorage.setItem('authorization', router?.query?.token)
				dispatch(callAutoLogin(true))
				router.navigate(router.pathname == '/buy-membership' ? `/buy-membership?id=${router?.query?.id}` : '/calendar')
			} else {
				localStorage.setItem('authorization', router?.query?.token)
				dispatch(callAutoLogin(true))
				router.navigate(router.pathname == '/buy-membership' ? `/buy-membership?id=${router?.query?.id}` : '/dashboard')
			}
		} else if (token && token != 'undefined' && router.pathname == '/') {
			if (router.pathname == '/calendar' && router?.query?.token) {
				router.navigate('/calendar')
			} else {
				router.navigate('/dashboard')
			}
		} else if (
			!token &&
			router.pathname != '/' &&
			router.pathname != '/privacy-policy' &&
			router.pathname != '/terms-conditions' &&
			pathname != '/forgot-password' &&
			pathname != '/verify-otp' &&
			pathname != '/signup' &&
			pathname != '/auth/google/callback' &&
			!router.pathname?.includes('.bmt')
		) {
			router.navigate('/login')
		}
	}, [token])

	useEffect(() => {
		initializeChat()
	})

	useEffect(() => {
		if (loginuserInChat) {
			loginUser()
		}
	}, [loginuserInChat, userDetails?.chat_id, myNetworkUsers?.length])

	const initializeChat = async () => {
		try {
			const uiKitSettings = new UIKitSettingsBuilder()
				.setAdminHost(`${AppConstants.COMET_CHAT_APP_ID}.api-${AppConstants.COMET_CHAT_REGION}.cc-cluster-2.io/v3.0`)
				.setClientHost(`${AppConstants.COMET_CHAT_APP_ID}.apiclient-${AppConstants.COMET_CHAT_REGION}.cc-cluster-2.io/v3.0`)
				.setAppId(AppConstants.COMET_CHAT_APP_ID)
				.setRegion(AppConstants.COMET_CHAT_REGION)
				.setAuthKey(AppConstants.COMET_CHAT_AUTH_KEY)
				.subscribePresenceForAllUsers()
				.build()
			try {
				await CometChatUIKit.init(uiKitSettings)?.then(async (res: any) => {
					new CometChat.AppSettingsBuilder()
						.overrideAdminHost(`https://${AppConstants.COMET_CHAT_APP_ID}.api-${AppConstants.COMET_CHAT_REGION}.cc-cluster-2.io/v3.0`)
						.overrideClientHost(`https://${AppConstants.COMET_CHAT_APP_ID}.apiclient-${AppConstants.COMET_CHAT_REGION}.cc-cluster-2.io/v3.0`)
					if (userDetails && userDetails?.chat_id && !loginuserInChat) {
						setLoginUserInChat(true)
					}
				})
			} catch (error) {
				console.error('Error fetching users:', error)
			}
		} catch (error) {
			console.error('Error fetching users:', error)
		}
	}

	const loginUser = async () => {
		try {
			if (userDetails && userDetails?.chat_id) {
				let getloggedInUser: any = await CometChatUIKit.getLoggedinUser().then((res) => {
					return res
				})
				console.log('=====getloggedInUser', getloggedInUser)
				if (!getloggedInUser?.uid) {
					let user = await CometChatUIKit.login(userDetails?.chat_id)
						?.then(async (loggedInUser) => {
							const result: any = await CometChat.getUnreadMessageCount()
							dispatch(chatUnreadMessages(Object.keys(result?.users)?.length))
							return loggedInUser
						})
						.catch((err) => {
							console.log('=====err at app', err)
							return null
						})
					if (user && userDetails?.is_social_login) {
						const newUser = new CometChat.User(userDetails?.chat_id)
						if (newUser) {
							newUser.setName(userDetails?.full_name)
							newUser.setMetadata(userDetails)
							newUser.setAvatar(userDetails?.image_Url)
						}
						await CometChat.updateCurrentUserDetails(newUser).then(
							(user) => {
								console.log('updated user', user)
							},
							(error) => {
								console.log('error', error)
							}
						)
					}
					await CometChat.setDemoMetaInfo(metaInfo)
					await getUsers()
				} else if (getloggedInUser?.uid) {
					if (userDetails?.is_social_login) {
						const newUser = new CometChat.User(userDetails?.chat_id)
						if (newUser) {
							newUser.setName(userDetails?.full_name)
							newUser.setMetadata(userDetails)
							newUser.setAvatar(userDetails?.image_Url)
						}
						await CometChat.updateCurrentUserDetails(newUser).then(
							(user) => {
								console.log('updated user', user)
							},
							(error) => {
								console.log('error', error)
							}
						)
					}
					await CometChat.setDemoMetaInfo(metaInfo)
					await getUsers()
				}
			}
		} catch (err) {
			console.error('Error fetching users:', err)
		}
	}

	useEffect(() => {
		if (page > 1) {
			getUsers(30 * page, page)
		}
	}, [page])

	const getUsers = async (limit: number = 30, page: number = 1) => {
		try {
			const usersRequest = new CometChat.UsersRequestBuilder().hideBlockedUsers(true).setUIDs(myNetworkUsers).setLimit(limit).build()
			let conversationsRequest = new CometChat.ConversationsRequestBuilder().setLimit(50).withUserAndGroupTags(true).build()

			const result: any = await CometChat.getUnreadMessageCount()
			dispatch(chatUnreadMessages(Object.keys(result?.users)?.length))
			conversationsRequest.fetchNext().then(
				(conversationList) => {
					let conversation = conversationList.filter((res: any) => !res?.conversationWith?.deactivatedAt)
					dispatch(chatUsers(conversation))

					let totalUnread = conversationList.filter((e: any) => e.unreadMessageCount)
					// dispatch(chatUnreadMessages(totalUnread?.length))
					if (conversationList.length === limit) {
						setPage(page + 1)
					}
				},
				(error) => {
					console.log('Conversations list fetching failed with error:', error)
				}
			)
			try {
				const userList = await usersRequest.fetchNext()
				dispatch(chatAllUsers(userList))
				if (userList.length === limit) {
					setPage(page + 1)
					// await getUsers(limit, page + 1)
				}
			} catch (error) {
				console.error('Error fetching users:', error)
			}
		} catch (error) {
			console.error('Error fetching users:', error)
		}
	}

	useEffect(() => {
		const loader: any = document.getElementById('initial-loader')
		if (loader) {
			loader.style.display = 'none' // Hide the initial loader
			setLoading(false)
		}
	}, [])

	const autoLogin = async () => {
		await api
			.post('/user/auto_login')
			.then(async (res: any) => {
				if (res?.data?.status) {
					if ((res?.data?.data?.user?.is_email_verified || res?.data?.data?.user?.google_id || res?.data?.data?.user?.linkedin_id) && (res?.data?.data?.user?.userMembership?.length || res?.data?.data?.user?.proMembership?.length)) {
						dispatch(networkUsers(res?.data?.data?.user_network))
						dispatch(saveUser(res?.data?.data?.user))
						dispatch(callAutoLogin(false))
						localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
						localStorage.setItem('user_lang', res?.data?.data?.user?.language)
					} else {
						dispatch(networkUsers(res?.data?.data?.user_network))
						dispatch(callAutoLogin(false))
						if (pathname !== '/subscription_success' && res?.data?.data?.user?.is_email_verified) {
							router.navigate(`/buy-membership?id=${res?.data?.data?.user?.id}`)
						}
					}
					i18n.changeLanguage(res?.data?.data?.user?.language)
					localStorage.setItem('user_lang', res?.data?.data?.user?.language)
				} else {
					await initializeChat()
					sessionStorage.removeItem('sessionId')
					if (router.pathname != '/privacy-policy' && router.pathname != '/terms-conditions' && !checkPathname && router.pathname != '/') {
						router.navigate('/login')
					}
				}
			})
			.catch(async (error: any) => {
				await initializeChat()
				sessionStorage.removeItem('sessionId')

				if (router.pathname != '/privacy-policy' && router.pathname != '/terms-conditions' && !checkPathname && router.pathname != '/') {
					router.navigate('/login')
				}
			})
	}

	if (loading) return <GlobalLoader />

	return (
		<div>
			<HelmetProvider>
				{pathname == '/initialize-video' ? '' : <TopNavBar />}
				<Main active={pathname !== '/'} isDarkTheme={_isDarkTheme}>
					{!router.pathname?.includes('.bmt') ? <DynamicMetaTags title={'JustNetwork'} imageUrl={''} url={``} description={''}></DynamicMetaTags> : ''}
					<Routes>
						<Route path="/" element={<Home />}></Route>
						<Route path="/login" element={<Login />}></Route>
						<Route path="/signUp" element={<SignUp />}></Route>
						<Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
						<Route path="/terms-conditions" element={<TermsAndConditions />}></Route>
						<Route path="/forgot-password" element={<ForgotPassword />}></Route>
						<Route path="/verify-otp" element={<OtpVerificationPage />}></Route>
						<Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />}></Route>
						<Route path="/network" element={<PrivateRoute Component={Network} />}></Route>
						<Route path="/:username" element={<BookNetwrokUserMeeting />}></Route>
						<Route path="/inbox" element={<PrivateRoute Component={Inbox} />}></Route>
						<Route path="/request-reschedule" element={<PrivateRoute Component={RescheduleRequest} />}></Route>
						<Route path="/reschedule-successful" element={<PrivateRoute Component={RescheduleRequestSuccessful} />}></Route>
						<Route path="/accept-reschedule-successful" element={<PrivateRoute Component={AcceptRescheduleRequestSuccessful} />}></Route>
						<Route path="/decline-request" element={<PrivateRoute Component={DeclineRequest} />}></Route>
						<Route path="/buy-my-time" element={<PrivateRoute Component={BMT} />}></Route>
						<Route path="/upgrade-bmt-membership" element={<PrivateRoute Component={UpgradeBMTMembership} />}></Route>
						<Route path="/notifications" element={<PrivateRoute Component={Notifications} />}></Route>
						<Route path="/calendar" element={<PrivateRoute Component={Calendar} />}></Route>
						<Route path="/searched-users" element={<PrivateRoute Component={SearchedUsers} />}></Route>
						<Route path="/bmt-membership-payment" element={<PrivateRoute Component={BMTPaymentSuccessful} />}></Route>
						<Route path="/BMTSuccess" element={<PrivateRoute Component={BMTSuccess} />}></Route>
						<Route path="/logout" element={<PrivateRoute Component={Logout} />}></Route>
						<Route path="/diamond" element={<PrivateRoute Component={Dimond} />}></Route>
						<Route path="/withdraw-paypal" element={<PrivateRoute Component={WithdrawSuccess} />}></Route>
						<Route path="/withdraw-coins" element={<PrivateRoute Component={Withdraw} />}></Route>
						<Route path="/bmt-membership-success" element={<PrivateRoute Component={ProMemberCongrats} />}></Route>
						<Route path="/buy-coins" element={<PrivateRoute Component={BuyCoins} />}></Route>
						<Route path="/buy-coins-payment" element={<PrivateRoute Component={BuyCoinsPayment} />}></Route>
						<Route path="/send-coins" element={<PrivateRoute Component={SendCoins} />}></Route>
						<Route path="/update-password" element={<PrivateRoute Component={UpdateUserPassword} />}></Route>
						<Route path="/initialize-video" element={<PrivateRoute Component={VideoCallPage} />}></Route>
						<Route path="/buy-membership" element={<PrivateRoute Component={UserProfileAfterSignup} />}></Route>

						<Route path="/coins_screens" element={<PrivateRoute Component={BMTSuccess} />}></Route>
						<Route path="/buy_coins_payment" element={<PrivateRoute Component={SuccessPaymentMessage} />}></Route>
						<Route path="/subscription_success" element={<PrivateRoute Component={ProMembershipSuccessfull} />}></Route>
						<Route path="/contact-us" element={<ContactUsForm />}></Route>

						{/* <Route path="/coins_screens" element={<Logout />}></Route> */}
						{/* <Route path="/coins_screens" element={<Dimond />}></Route> */}
						{/* <Route path="/coins_screens" element={<Withdraw />}></Route> */}
						{/* <Route path="/coins_screens" element={<WithdrawSuccess />}></Route> */}
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</Main>
				{/* {pathname !== '/' && <Footer />} */}

				{userDetails?.id && pathname != '/' && userDetails?.is_email_verified && pathname != '/initialize-video' && pathname != '/privacy-policy' ? <BottomChatCardPopup /> : ''}
			</HelmetProvider>
		</div>
	)
}

const Main = styled.div<IProps>`
	/* padding-top: ${({active}) => (active ? '4.375rem' : `0`)};x */
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.white)};
`

export default App
