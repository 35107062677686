import React from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import FeedbackForm from '../FeedbackForm'
import {Logo} from '../TopNavBar'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 650px;
	position: relative;
`

const Header = styled(Text)`
	margin: 0 0 10px;
`

const Body = styled.p`
	margin: 0 0 20px;
	overflow-y: scroll;
	height: 70vh;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`

interface ConfirmationPopupProps {
	header?: any
	body?: any
	onCancel?: () => void
	onConfirm?: () => void
	onClose?: () => void
	buttonTitle?: any
	eventDetails?: any
}
const MeetingFeedbackModel = ({header, body, onConfirm, onClose, buttonTitle, eventDetails}: ConfirmationPopupProps) => {
	const {t} = useTranslation()
	return (
		<Overlay>
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t('Help Us Improve Your Experience')}
					</Header>{' '}
					&nbsp;&nbsp;&nbsp;
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>
				<Flexed justify={'center'} direction="row" padding={'0.5rem'}>
					<Logo src="/images/logo.svg" style={{height: '3.8rem', width: '3.8rem'}} alt="logo" />
				</Flexed>
				{/* <Spacer height={1} /> */}
				<FeedbackForm onClose={() => onClose && onClose()} eventDetails={eventDetails} />
			</Modal>
		</Overlay>
	)
}

export default MeetingFeedbackModel
