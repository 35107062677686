import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../styled/shared'
import {Card} from './Dashboard'
import {palette} from '../styled/colors'
import moment from 'moment-timezone'
import useRouter from '../hooks/router'
import {mockUsers} from '../utils/index'
import {Icons, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {InnerDiv, OuterDiv, TabsText} from './Network'
import {useSelector} from 'react-redux'
import {api} from '../helpers/auth-axios'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../components/common/Loader'
import Footer from '../components/Footer'
import {BsClock} from 'react-icons/bs'

const SearchedUsers = () => {
	const [users, setUsers] = useState<any>('')
	const [activeTab, setActiveTab] = useState<any>(1)
	const router: any = useRouter()
	const _searchQuery: any = useSelector<any>((state: any) => state.auth.searchQuery)
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState<any>(1)
	const [hasMore, setHasMore] = useState(true)

	const {t} = useTranslation()

	useEffect(() => {
		if (_searchQuery) {
			setPage(1)
			searchUsers(_searchQuery, 1)
		}
	}, [_searchQuery, page])

	const searchUsers = async (query?: any, page = 1) => {
		setLoading(true)
		await api
			.post('/user/search_by_name', {name: query?.trimEnd(), page})
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data) {
						if (query == res?.data?.data?.query) {
							if (page > 1) {
								setUsers([...users, ...res?.data?.data?.users])
							} else {
								setUsers(res?.data?.data?.users)
							}
						}
					}
					setLoading(false)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {})
	}

	const fetchMoreData = () => {
		setPage((prevPage: any) => prevPage + 1)
	}

	return (
		<MainContainer display={activeTab == 2} direction={activeTab == 2}>
			<Spacer height={6} />
			{loading && <Loader />}
			<Container fluid style={activeTab == 2 ? {flex: activeTab == 2 ? 1 : ''} : {}}>
				<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<Card background={'transparent'} padding={''} removeShadow overflow={'visible'}>
								<Row>
									<Col lg={3} md={12} sm={12}>
										<>
											<Flexed direction="column" align={''} gap={'0'}>
												<Flexed direction="row" align={'center'} gap={'0.6'}>
													<Icons
														src={'/images/icon_view.svg'}
														onClick={() => {
															router.navigate('/dashboard')
														}}
														pointer={'pointer'}
													/>
													<Text fontSize={'1.15'} lineHeight={'1.36'} fontWeight={'700'} color={'text_color'}>
														{' '}
														{t('Search results')} ({_searchQuery ? (users?.length && users?.length < 10 ? '0' + users?.length : users?.length) : 0})
													</Text>
												</Flexed>
											</Flexed>
											<Spacer height={'3'} />
										</>
									</Col>
									<Col lg={6} md={12} sm={12}>
										<Spacer height={1} />
										<Card background={'transparent'} padding={'0'} borderColor={'#0000001F'} removeShadow overflow={'visible'}>
											{/* <Flexed direction="row" align={'center'}>
												<Flexed
													direction="column"
													align={'center'}
													justify={'space-between'}
													width={'50%'}
													gap={'1'}
													margin={'auto'}
													padding={'1.2rem 0 0'}
													height="4rem"
													pointer
													onClick={() => setActiveTab(1)}
													style={{borderBottom: `1px solid ${palette.zink_300}`}}>
													<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={activeTab == 1 ? '600' : 400} color={activeTab == 1 ? 'blue_500' : 'foreground_600'} pointer>
														{t('Network')} ({_searchQuery ? (users?.length && users?.length < 10 ? '0' + users?.length : users?.length) : 0})
													</TabsText>
													{activeTab == 1 ? (
														<OuterDiv style={{width: '100%', borderRadius: '0'}}>
															<InnerDiv style={{borderWidth: '2px'}} />
														</OuterDiv>
													) : (
														<div></div>
													)}
												</Flexed>
												<Flexed
													direction="column"
													align={'center'}
													width={'50%'}
													justify={'space-between'}
													// gap={'1'}
													height="4rem"
													margin={'auto'}
													padding={'1.2rem 0 0'}
													pointer
													onClick={() => setActiveTab(2)}
													style={{borderBottom: `1px solid ${palette.zink_300}`}}>
													<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={activeTab == 2 ? '600' : 400} color={activeTab == 2 ? 'blue_500' : 'foreground_600'} pointer>
														{t('General')} ({2})
													</TabsText>
													{activeTab == 2 ? (
														<OuterDiv style={{width: '100%', borderRadius: '0'}}>
															<InnerDiv style={{borderWidth: '2px'}} />
														</OuterDiv>
													) : (
														<div></div>
													)}
												</Flexed>
											</Flexed> */}
											<>
												{activeTab == 1 ? (
													<InfiniteScroll scrollableTarget="scrollableDiv" dataLength={users?.length} next={fetchMoreData} hasMore={hasMore} scrollThreshold={0.9} loader={<></>} endMessage={<></>}>
														<Flexed direction="column" align={''} gap={'0.5'} padding={'1rem'} height={'33rem'} overflow={'scroll'}>
															{users?.length && _searchQuery
																? users?.map((user: any, i: any) => (
																		<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0.3rem 1rem'} border={'1px solid ' + palette?.zink_300} radius={'0.7rem'}>
																			<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
																				<Flexed direction="row" align={'center'}>
																					<ProfileImg
																						height={'2.8rem'}
																						width={'2.8rem'}
																						onClick={() => {
																							router.navigate(`/${user?.user_name}`, {
																								state: {route: `/searched-users?query=${_searchQuery}`}
																							})
																						}}>
																						<Icons pointer={'pointer'} src={user?.image_Url ? user?.image_Url : `/images/user_default.png`} alt="userDefault" />
																					</ProfileImg>

																					<Flexed
																						direction="column"
																						justify={'space-between'}
																						align={'start'}
																						padding={'0.5rem '}
																						pointer
																						onClick={() => {
																							router.navigate(`/${user?.user_name}`, {
																								state: {route: `/searched-users?query=${_searchQuery}`}
																							})
																						}}>
																						<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
																							<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
																								{user?.full_name}
																							</Text>
																							{user?.linkedin_profile_verified || user?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
																						</Flexed>
																						<Spacer height={0.2} />
																						<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
																							{user?.designation}
																						</Text>
																					</Flexed>
																				</Flexed>
																				<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
																					<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
																						{user?.isInNetwork ? (
																							<Button
																								small
																								borderRadius={'1rem'}
																								fontSize={'0.8rem'}
																								// padding={'0'}
																								label={
																									<>
																										<BsClock /> &nbsp; {t('Buy My Time')}
																									</>
																								}
																								background={palette.default_theme}
																								color={palette.white}
																								// minWidth={'10rem'}
																								padding={'8px 16px 8px 16px'}
																								lineHeight={'0.89rem'}
																								ifClicked={() => {
																									router.navigate(`/${user?.user_name}`, {state: {route: '/network'}})
																								}}
																							/>
																						) : (
																							<Button
																								small
																								borderRadius={'1rem'}
																								fontSize={'0.8rem'}
																								hasBorder
																								padding={'0'}
																								label={<>{t('ADD NETWORK')}</>}
																								background={palette.white}
																								color={palette.blue_500}
																								minWidth={'8rem'}
																								lineHeight={'0.89rem'}
																								ifClicked={() => {
																									router.navigate(`/${user?.user_name}`, {
																										state: {route: `/searched-users?query=${_searchQuery}`}
																									})
																								}}
																							/>
																						)}
																					</Flexed>
																				</Flexed>
																			</CustomFlexedForWrap>
																		</Flexed>
																  ))
																: !loading && (
																		<Flexed direction="column" align={'center'}>
																			<Spacer height={2} />
																			<Icons src={'/images/not_result_found.svg'} />
																			<Spacer height={1} />
																			<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
																				{t('No results found')}
																			</Text>
																			<Spacer height={0.2} />
																			<Text fontSize={'0.9'} lineHeight={'1.09'} fontWeight={'400'} color={'black'}>
																				{t('We couldn’t find any results for')} “{_searchQuery}”
																			</Text>
																		</Flexed>
																  )}
														</Flexed>
													</InfiniteScroll>
												) : (
													''
												)}
												{activeTab == 2 ? (
													<Flexed direction="column" align={''} gap={'0.5'}>
														<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0.3rem 1rem'} style={{borderBottom: '1px solid ' + palette?.zink_300}}>
															<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
																<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
																	<Flexed direction="column" align={'left'} gap={'0.3'}>
																		<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
																			{t('Join Pro Membership')}
																		</Text>
																		<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
																			{t('Become a Pro Member now')}
																		</Text>
																	</Flexed>
																</Flexed>
																<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'} onClick={() => router.navigate('/upgrade-bmt-membership')} pointer>
																	<Icons src={'/images/icon_view_left.svg'} pointer />
																</Flexed>
															</CustomFlexedForWrap>
														</Flexed>
														<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0.3rem 1rem'} style={{borderBottom: '1px solid ' + palette?.zink_300}}>
															<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
																<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
																	<Flexed direction="column" align={'left'} gap={'0.3'}>
																		<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
																			{t('BMT - Pro Membership')}
																		</Text>
																		<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
																			{t('View your active membership')}
																		</Text>
																	</Flexed>
																</Flexed>
																<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'} onClick={() => router.navigate('/buy-my-time')} pointer>
																	<Icons src={'/images/icon_view_left.svg'} pointer />
																</Flexed>
															</CustomFlexedForWrap>
														</Flexed>
													</Flexed>
												) : (
													''
												)}
											</>
										</Card>
									</Col>
									<Col lg={3}></Col>
								</Row>
							</Card>
						</Col>
					</Row>
					<Spacer height={'3'} />
				</Card>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div<any>`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100vh; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
	display: ${({display}) => (display ? 'flex' : '')};
	flex-direction: ${({direction}) => (direction ? 'column' : '')}; ;
`

export const CustomSmallScreenSpacer = styled(Spacer)<any>`
	${media.xs`
    display: flex;
    `}
	${media.sm`
    display: flex;

    `}
    ${media.md`

    display: none;
    `}
	${media.lg`
   display: none;
    `}
`

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

export default SearchedUsers
