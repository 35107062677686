export const mockUsers = [
	{
		image: 'netwrok_1.svg',
		name: 'Will smith',
		designation: 'CEO at Apple',
		status: 'online',
		requestStatus: '',
		coins: '120',
		time: '40',
		price: '50',
		inNetwork: true,
		membership: 'pro',
		bronze: {
			coins: 100,
			time: 60,
			price: 10
		},
		bronzePlus: {
			coins: 120,
			time: 80,
			price: 20
		},
		silver: {
			coins: 40,
			time: 40,
			price: 15
		},
		silverPlus: {
			coins: 60,
			time: 90,
			price: 30
		},
		gold: {
			coins: 200,
			time: 120,
			price: 20
		},
		goldPlus: {
			coins: 300,
			time: 150,
			price: 50
		},
		pro: {
			coins: 300,
			time: 180,
			price: 30
		},
		proPlus: {
			coins: 400,
			time: 210,
			price: 50
		},
		diamond: {
			coins: 120,
			time: 20,
			price: 40
		},
		diamondPlus: {
			coins: 150,
			time: 50,
			price: 50
		}
	},
	{
		image: 'netwrok_3.svg',
		name: 'Alena Baptista',
		designation: 'Entrepreneur in B2B industry',
		status: 'offline',
		requestStatus: '',
		coins: '120',
		time: '40',
		price: '50',
		inNetwork: false,
		membership: 'diamond',
		bronze: {
			coins: 100,
			time: 60,
			price: 10
		},
		bronzePlus: {
			coins: 120,
			time: 80,
			price: 20
		},
		silver: {
			coins: 40,
			time: 40,
			price: 15
		},
		silverPlus: {
			coins: 60,
			time: 90,
			price: 30
		},
		gold: {
			coins: 200,
			time: 120,
			price: 20
		},
		goldPlus: {
			coins: 300,
			time: 150,
			price: 50
		},
		pro: {
			coins: 300,
			time: 180,
			price: 30
		},
		proPlus: {
			coins: 400,
			time: 210,
			price: 50
		},
		diamond: {
			coins: 120,
			time: 20,
			price: 40
		},
		diamondPlus: {
			coins: 150,
			time: 50,
			price: 50
		}
	},
	{
		image: 'netwrok_2.svg',
		name: 'James George',
		designation: 'UI/UX Design Expert',
		status: 'offline',
		requestStatus: '',
		coins: '120',
		time: '40',
		price: '50',
		inNetwork: false,
		membership: 'silver',
		bronze: {
			coins: 100,
			time: 60,
			price: 10
		},
		bronzePlus: {
			coins: 120,
			time: 80,
			price: 20
		},
		silver: {
			coins: 40,
			time: 40,
			price: 15
		},
		silverPlus: {
			coins: 60,
			time: 90,
			price: 30
		},
		gold: {
			coins: 200,
			time: 120,
			price: 20
		},
		goldPlus: {
			coins: 300,
			time: 150,
			price: 50
		},
		pro: {
			coins: 300,
			time: 180,
			price: 30
		},
		proPlus: {
			coins: 400,
			time: 210,
			price: 50
		},
		diamond: {
			coins: 120,
			time: 20,
			price: 40
		},
		diamondPlus: {
			coins: 150,
			time: 50,
			price: 50
		}
	},
	{
		image: 'netwrok_1.svg',
		name: 'Will smith',
		designation: 'CEO at Apple',
		status: 'online',
		requestStatus: '',
		coins: '120',
		time: '40',
		price: '50',
		membership: 'pro',
		inNetwork: true,
		bronze: {
			coins: 100,
			time: 60,
			price: 10
		},
		bronzePlus: {
			coins: 120,
			time: 80,
			price: 20
		},
		silver: {
			coins: 40,
			time: 40,
			price: 15
		},
		silverPlus: {
			coins: 60,
			time: 90,
			price: 30
		},
		gold: {
			coins: 200,
			time: 120,
			price: 20
		},
		goldPlus: {
			coins: 300,
			time: 150,
			price: 50
		},
		pro: {
			coins: 300,
			time: 180,
			price: 30
		},
		proPlus: {
			coins: 400,
			time: 210,
			price: 50
		},
		diamond: {
			coins: 120,
			time: 20,
			price: 40
		},
		diamondPlus: {
			coins: 150,
			time: 50,
			price: 50
		}
	},
	{
		image: 'netwrok_3.svg',
		name: 'Alena Baptista',
		designation: 'Entrepreneur in B2B industry',
		status: 'red',
		requestStatus: '',
		coins: '120',
		time: '40',
		price: '50',
		membership: 'pro',
		inNetwork: true,
		bronze: {
			coins: 100,
			time: 60,
			price: 10
		},
		bronzePlus: {
			coins: 120,
			time: 80,
			price: 20
		},
		silver: {
			coins: 40,
			time: 40,
			price: 15
		},
		silverPlus: {
			coins: 60,
			time: 90,
			price: 30
		},
		gold: {
			coins: 200,
			time: 120,
			price: 20
		},
		goldPlus: {
			coins: 300,
			time: 150,
			price: 50
		},
		pro: {
			coins: 300,
			time: 180,
			price: 30
		},
		proPlus: {
			coins: 400,
			time: 210,
			price: 50
		},
		diamond: {
			coins: 120,
			time: 20,
			price: 40
		},
		diamondPlus: {
			coins: 150,
			time: 50,
			price: 50
		}
	},
	{image: 'netwrok_2.svg', name: 'James George', designation: 'UI/UX Design Expert', status: 'yellow', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: true, membership: 'pro'},
	{image: 'netwrok_1.svg', name: 'Will smith', designation: 'CEO at Apple', status: 'online', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: false},
	{image: 'netwrok_3.svg', name: 'Alena Baptista', designation: 'Entrepreneur in B2B industry', status: 'offline', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: true, membership: 'pro'},
	{image: 'netwrok_2.svg', name: 'James George', designation: 'UI/UX Design Expert', status: 'offline', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: false},
	{image: 'netwrok_1.svg', name: 'Will smith', designation: 'CEO at Apple', status: 'online', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: true, membership: 'pro'},
	{image: 'netwrok_3.svg', name: 'Alena Baptista', designation: 'Entrepreneur in B2B industry', status: 'red', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: false},
	{image: 'netwrok_2.svg', name: 'James George', designation: 'UI/UX Design Expert', status: 'yellow', requestStatus: '', coins: '120', time: '40', price: '50', inNetwork: true, membership: 'pro'}
]

export const getFirstWords = (text: any, numWords: any) => {
	const words = text.split(' ')
	return words.length > numWords ? words.slice(0, numWords).join(' ') + '...' : text
}

// utils/linkedinValidator.js
export function isValidLinkedInURL(url: any) {
	const linkedInProfileRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/
	return linkedInProfileRegex.test(url)
}

export async function checkLinkedInProfile(url: any) {
	if (!isValidLinkedInURL(url)) {
		return false
	}

	try {
		const response = await fetch(url, {method: 'HEAD'})
		return response.status === 200
	} catch (error) {
		console.error('Error fetching URL:', error)
		return false
	}
}
