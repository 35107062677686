import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../../styled/colors'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export interface InputPhoneNoProps {
	label?: string
	setFileValue?: any
	required?: boolean
	placeholder?: string
	disabled?: boolean
	error?: boolean
	errorMsg?: string
	isSignup?: boolean
	isContact?: boolean
	onChange?: any
	name?: string
	type?: string
	id?: string
	value?: string | number
	className?: string
	readOnly?: boolean
	min?: any
	autoComplete?: string
	maxLength?: any
	allowNumber?: boolean
	slotLeft?: React.ReactNode
	onBlur?(): void
	onFocus?(): void
	step?: any
	pattern?: any
	onInput?: any
	allowPhoneNumberOnly?: boolean
	inputMode?: any
	allowText?: any
	allowAddess?: any
	allowOnlyNumbers?: any
	allowOnlyNumbersAndDecimal?: boolean
}

const InputPhoneNo = ({label, setFileValue, required, placeholder, disabled, error, errorMsg, isSignup, isContact, onChange, value, name}: InputPhoneNoProps) => {
	const [isActive, setActive] = useState<boolean>(false)
	const [isFocused, setFocused] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<any>('')
	const [userCountry, setUserCountry] = useState<any>('')

	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if ((inputRef.current && inputRef.current.value?.length > 0) || (inputRef.current && inputRef.current.value?.length > 1)) setActive(true)

		fetch('https://ipinfo.io/json')
			.then((response) => response.json())
			.then((data) => setUserCountry(data.country.toLowerCase()))
	}, [])

	useEffect(() => {
		setInputValue(value ?? '')
		// setActive(!!value)
	}, [value])

	useEffect(() => {
		if (inputValue) setActive(true)
	}, [inputValue])

	const focusInput = () => {
		setActive(true)
		setFocused(true)
		if (inputRef.current) inputRef.current.focus()
	}

	const handleFocus = () => {
		setFocused(true)
	}

	const unfocusInput = () => {
		setFocused(false)
		if ((inputRef.current && inputRef.current.value?.length === 0) || (inputRef.current && inputRef.current.value?.length === 1)) setActive(false)
	}

	const handleChange = (event: any) => {
		setInputValue(event)
		if (event) {
			onChange({name: name, value: event})
		}
	}

	return (
		<InputContainer onClick={() => focusInput()}>
			<Wrapper error={error} isContact={isContact} isSignup={isSignup}>
				<div>
					<InputField
						country={value ? undefined : userCountry}
						// enableAreaCodes={true}
						value={inputValue}
						enableAreaCodeStretch
						countryCodeEditable
						inputProps={{
							ref: inputRef,
							name: name,
							required: true,
							placeholder: !isActive ? label : ''
						}}
						onChange={(e: any) => handleChange(e)}
						onFocus={handleFocus}
						onBlur={unfocusInput}
						disabled={disabled}
					/>
					<LabelWrapper value={inputValue} error={error} isContact={isContact} isSignup={isSignup}>
						<label>{label}</label>
					</LabelWrapper>
				</div>
			</Wrapper>
			{error && <Message>{error}</Message>}
		</InputContainer>
	)
}

const InputContainer = styled.div<any>`
	width: 100%;
	position: relative;
	display: block;
`

const Wrapper = styled.div<InputPhoneNoProps>`
	padding: 0.45rem 1.15rem;
	align-items: stretch;
	border-radius: 0.325rem;
	border-style: ${({isContact, error}) => (isContact ? 'none' : 'solid !important')};
	border-width: 0.5px !important;

	background-color: ${({isContact, isSignup}) => (isSignup ? '#F4F4F5' : '#fff')};
	transition: border-color 0.15s ease-in-out;
	border-color: ${({error}) => (error ? `#eb5353` : 'rgba(223, 226, 230)')};
`

const LabelWrapper = styled.label<any>`
	position: absolute;
	max-width: calc(100% - 24px);
	top: ${({isActive, isFocused}) => (isActive || isFocused ? '-10px' : '24%')};
	left: ${({isActive, isFocused}) => (isActive || isFocused ? '10px' : '4%')};
	top: -1px;
	label {
		color: ${({error}) => (error ? `#eb5353` : '#8C8B88')};
		font-size: 10px;
		display: block;
		padding: 0 6px;
		margin-left: -6px;
		font-weight: 300;
		text-transform: capitalize;
		line-height: 1;
		opacity: ${({value, type}) => (value || type == 'date' ? `1` : '0')};
		transform: ${({value, type}) => (value || type == 'date' ? `translateY(-50%)` : 'translateY(50%)')};
		transition: ${({value}) => (value ? `transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;` : 'all 0.15s ease-in-out')};
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		background-color: ${({isContact, error, isSignup}) => (isSignup ? 'transparent' : isContact ? 'transparent' : 'white')};
		&::before,
		&::after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 50%;
			z-index: -1;
			left: 0;
		}
		&::before {
			top: 50%;
		}
		&::after {
			top: 0;
			background-color: transparent;
		}
	}
`

const InputField = styled(PhoneInput)<InputPhoneNoProps>`
	display: block;
	background-color: transparent;
	width: 100%;
	border: none;
	padding-left: 0.5rem;
	font-size: 0.9rem;
	color: ${palette.text_color};

	&::placeholder {
		font-weight: 300;
		color: ${palette.input_text_color};
	}
	&:focus {
		outline: 0;
	}
	&:focus + ${LabelWrapper} {
		label {
			transform: translateY(-50%) !important;
			opacity: 1 !important;
			transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;
		}
	}
	> input {
		border: none !important;
	}
`

const Message = styled.div`
	color: #eb5353;
	background: white;
	border: 1px solid #eb5353;
	border-radius: 0px 0px 0.325rem 0.325rem;
	padding: 2px 10px;
	margin-top: -3px;
	font-size: 0.75rem;
	font-weight: 300;
`

export default InputPhoneNo
