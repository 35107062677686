import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import '../src/styled/cart.css'
import './index.css'
import App from './App'
import {Provider} from 'react-redux'
import rootReducer from './reducers/index'
import {createStore} from 'redux'
import {BrowserRouter} from 'react-router-dom'
import {GridThemeProvider} from 'styled-bootstrap-grid'
import {ThemeProvider} from 'styled-components'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './i18n/i18n'
import * as Sentry from '@sentry/react'
import '@cometchat/uikit-elements'

const store = createStore(rootReducer)

const gridTheme = {
	gridColumns: 12,
	breakpoints: {
		xxl: 1440,
		xl: 1200,
		lg: 992,
		md: 768,
		sm: 576,
		xs: 575
	},
	row: {
		padding: 15
	},
	col: {
		padding: 15
	},
	container: {
		padding: 15,
		maxWidth: {
			xxl: 1400,
			xl: 1200,
			lg: 960,
			md: 720,
			sm: 540,
			xs: 540
		}
	}
}

const styledTheme = {
	mainColor: 'purple'
}

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_URL,
	integrations: [Sentry.browserTracingIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/justnetwork.tech\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider theme={styledTheme}>
				<GridThemeProvider gridTheme={gridTheme}>
					<App />
				</GridThemeProvider>
				<ToastContainer position="top-right" />
			</ThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)
