import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import ReactPlayer from 'react-player/lazy'
import 'plyr/dist/plyr.css'
import {BsPlayBtn} from 'react-icons/bs'
import {Flexed} from '../styled/shared'
import ContactForm from './ContactUsForm'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import useRouter from '../hooks/router'
import {AppGalleryButton, AppStoreButton, ButtonsContainer, GooglePlayButton} from 'react-mobile-app-button'
import {Icons} from './TopNavBar'
import {CustomSmallScreenSpacer} from './netwrokComponents/RescheduleRequest'
import BuyTimeNowForm from './BuyTimeNowForm'
interface IProps {
	isDarkTheme: boolean
}

const APKUrl = ''
const IOSUrl = ''
const AboutUsPage = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const {t} = useTranslation()
	const UserGuidOptions = [
		{title: `${t('Discover BMT')} - “${t('Buy My Time')}"`, description: t('A section that highlights the unique BMT feature and how it sets JustNetwork apart from other networking platforms.'), icon: '/images/icon_network_lp.svg'},
		{title: t(`Monetize Your Network`), description: t('Join Thousands of Professionals Monetizing Their Networking on JustNetwork'), icon: '/images/icon_dollar_lp.svg'},
		{title: t(`Maximize Your Networking`), description: 'Explore how JustNetwork Pays You for Your Time', icon: '/images/icon_time_lp.svg'},
		{
			title: t(`Secure Transactions`),
			description: 'Enjoy peace of mind with our secure and transparent transaction system by Stripe and PayPal, and ensures that you get paid for your time and expertise.',
			icon: '/images/icon_secure.svg'
		}
	]

	const handlePhoneClick = (phone?: any) => {
		window.location.href = `tel:${phone}`
	}

	// Function to handle email click
	const handleEmailClick = (email?: any) => {
		window.location.href = `mailto:${email}`
	}

	// Function to handle address click
	const handleAddressClick = (address?: any) => {
		const latitude = 123.456
		const longitude = 78.91
		window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, '_blank')
	}

	const router = useRouter()
	const contactFormRef: any = useRef(null)

	useEffect(() => {
		if (router.location.state == 'contact' && contactFormRef?.current) {
			contactFormRef?.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [router.location.state])
	return (
		<Container fluid>
			<Spacer height="2" />
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card isDarkTheme={_isDarkTheme}>
						<Row alignItems="center" style={{padding: '1.5rem'}}>
							<Col xl={6} lg={6} md={12} sm={12} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
								<Customflexed width={'100%'} padding={'0rem 3rem 0rem 0rem'} smallPadding={'0rem 1rem 0rem 0rem'}>
									<Flexed width={'100%'} hight={'100%'}>
										<GradientText fontSize={'4'} fontWeight={'600'} whiteSpace="normal" align="start" lineHeight="4.5" width="fit-content">
											Connect & <br />
											Prospect Now
										</GradientText>
									</Flexed>
									<Spacer height={'0.5'} />
									<CustomParagrapht fontSize={'2'} fontWeight={'bold'} lineHeight={'2.5'} color={'input_text_color'} smallFontSize={'1.5rem'}>
										Quick Connect & Unlock the Power of Personalized Prospecting
									</CustomParagrapht>
									<Spacer height={'0.8'} />
									<CustomIcon width={'21.43rem'} smallWidth={'15.16rem'} src={'/images/Marketing-Marketing-target-01.svg'} />
									<CustomSmallScreenSpacer height={1} />
								</Customflexed>
								{/* <Col lg={6}> */}
								<Customflexed padding={'1rem 2rem'} smallPadding={'1rem'} width={'100%'}>
									<Flexed>
										<Paragraph fontSize={'1.25'} lineHeight={'1.5'} color={'input_text_color'}>
											Send an invite to your prospect with an offer to buy their time. We will notify you when they join & set their availability.
										</Paragraph>
										<Spacer height={'0.5'} />
										<Text fontSize={'1'} color={'input_text_color'} lineHeight="1.2" width="fit-content">
											10 mails left today − <span style={{textDecoration: 'underline', cursor: 'pointer'}}>learn more</span>
										</Text>
										<Spacer height={'0.5'} />
									</Flexed>
									<Flexed width={'100%'}>
										<BuyTimeNowForm setRemainingEmails={() => {}} redirect={true} />
									</Flexed>
								</Customflexed>
							</Col>
							{/* </Col> */}

							<Col xl={6} lg={6} md={12} sm={12} xs={12}>
								<div style={{borderRadius: '1rem', overflow: 'hidden'}}>
									<CustomPlayer
										url="/images/3052883-uhd_3840_2160_30fps.mp4"
										controls
										width="100%"
										height="100%"
										playIcon={<BsPlayBtn />}
										style={{borderRadius: '1rem'}}
										config={{
											file: {
												attributes: {
													controlsList: 'nodownload' // Remove download button
												}
											}
										}}
									/>
								</div>
								<Text isCentered fontSize={'1.2'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
									{t('Learn How JustNetwork Works?')}
								</Text>
								<Spacer height={'0.8'} />
								<Flexed direction={'column'} align={'center'} justifyContent="center">
									<AboutUs fontSize={'1.5'} lineHeight={'1.81'} fontWeight={'700'} color={'blue_500'}>
										{t('ABOUT US')}
									</AboutUs>

									<Paragraph fontSize={'1.1'} lineHeight={'1.79'} fontWeight={'400'} color={'text_color'}>
										{t(
											`In today’s fast-paced world, gaining access to industry leaders and decision-makers has never been
											more challenging. Our platform empowers you to connect with the people who matter most—ensuring
											that every minute you spend is time well-invested`
										)}
									</Paragraph>
								</Flexed>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Spacer height="2" />

			<StyledRow>
				{UserGuidOptions?.map((options: any) => (
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<StyledCard isDarkTheme={_isDarkTheme}>
							<CustomFlexed direction={'column'} align={'start'}>
								<Img src={options.icon} />
								<Spacer height="1" />
								<AboutUs fontSize={'1.25'} lineHeight={'1.5'} fontWeight={'700'} color={'text_color'}>
									{t(options?.title)}
								</AboutUs>
								<Spacer height="1" />
								<Paragraph fontSize={'1.12'} lineHeight={'21.78px'} fontWeight={'400'} color={'text_color'}>
									{t(options?.description)}
									{options?.title == 'Maximize Your Networking' ? <Spacer height="1.8" /> : ''}
								</Paragraph>
							</CustomFlexed>
						</StyledCard>
					</Col>
				))}
			</StyledRow>
			<StyledCard isDarkTheme={_isDarkTheme}>
				<Row>
					<Col xl={7} lg={7} md={7} sm={12} xs={12}>
						<CustomFlexed direction={'column'} align={'start'} justify={'center'}>
							<Spacer height="1" />
							<AboutUs fontSize={'1.5'} lineHeight={'1.81'} fontWeight={'700'} color={'blue_500'}>
								{t('CONTACT')}
							</AboutUs>
							<Text fontSize={'2.25'} lineHeight={'2.72'} fontWeight={'700'} color={'text_color'}>
								{t('Let’s Work Together')}
							</Text>
							<Spacer height="1" />
							<Img src={'/images/undraw_agreement_re_d4dv 2.svg'} />
							<Spacer height="1" />

							<Col xl={12} lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col xl={4} lg={4} md={4} sm={12} xs={12}>
										<CustomFlexed direction={'column'} align={'start'} gap={'0.5'}>
											<Img src={'/images/icon_mobile.svg'} />
											<Text fontSize={'0.8'} lineHeight={'1.02'} fontWeight={'400'} color={'zink_400'}>
												{t('PHONE')}
											</Text>

											<Text pointer={true} fontSize={'1.12'} lineHeight={'1.36'} fontWeight={'400'} color={'black'} title={'+1 (740) 272-4669'} onClick={() => handlePhoneClick('+1 (740) 272-4669')}>
												+1 (740) 272-4669
											</Text>
										</CustomFlexed>
									</Col>
									<Col xl={4} lg={4} md={4} sm={12} xs={12}>
										<CustomFlexed direction={'column'} align={'start'} gap={'0.5'}>
											<Img src={'/images/icon_email.svg'} />
											<Text fontSize={'0.8'} lineHeight={'1.02'} fontWeight={'400'} color={'zink_400'}>
												{t('EMAIL')}
											</Text>
											<Text pointer={true} fontSize={'1.12'} lineHeight={'1.36'} fontWeight={'400'} title={'info@justnetwork.tech'} color={'black'} onClick={() => handleEmailClick('info@justnetwork.tech')}>
												info@justnetwork.tech
											</Text>
										</CustomFlexed>
									</Col>
									<Col xl={4} lg={4} md={4} sm={12} xs={12}>
										<CustomFlexed direction={'column'} align={'start'} gap={'0.5'}>
											<Img src={'/images/icon_location.svg'} />
											<Text fontSize={'0.8'} lineHeight={'1.02'} fontWeight={'400'} color={'zink_400'}>
												{t('ADDRESS')}
											</Text>
											<Headings
												// pointer={true}
												fontSize={'1.12'}
												lineHeight={'1.36'}
												fontWeight={'400'}
												color={'black'}
												title={'131 Continental Dr, Suite 305, in the city of Newark'}
												// onClick={() => handleAddressClick('131 Continental Dr, Suite 305, in the city of Newark, zip code 19713, and county of New Castle, Delaware, USA')}
											>
												131 Continental Dr, Suite 305, in the city of Newark
											</Headings>
										</CustomFlexed>
									</Col>
								</Row>
							</Col>
							<Col xl={12} lg={12} md={12} sm={12} xs={12}>
								<Spacer height={1} />
								<Text fontSize={'1'} lineHeight={'1.02'} fontWeight={'500'} color={'text_color'}>
									{t('Coming Soon')}
								</Text>
								<Spacer height={0.5} />
								<CustomButtonsFlexed direction={'row'} align={'start'} gap={'0.5'} flexWrap={'wrap'} width={'100%'}>
									<GooglePlayButton url={APKUrl} theme={'light'} className={'custom-style'} />

									<AppStoreButton url={IOSUrl} theme={'light'} className={'custom-style'} />
								</CustomButtonsFlexed>
							</Col>
						</CustomFlexed>
					</Col>

					<Col xl={5} lg={5} md={5} sm={12} xs={12} ref={contactFormRef}>
						<CustomFlexed padding={'3rem 2rem'} direction={'column'} align={'start'} gap={'0.5'}>
							<Text fontSize={'1.5'} lineHeight={'1.81'} fontWeight={'700'} color={'text_color'}>
								{t('Send message')}
							</Text>
							<Headings fontSize={'1'} lineHeight={'1.21'} fontWeight={'400'} color={'black'}>
								{t('Feel free to contact us anytime. We will get back to you as soon as we can.')}
							</Headings>
							<Spacer height="1" />

							<ContactForm />
						</CustomFlexed>
					</Col>
				</Row>
			</StyledCard>
			{/* <Spacer height="1" /> */}
		</Container>
	)
}

const Img = styled.img``

const AboutUs = styled(Text)<any>`
	${media.xs`
	margin-top:0.4rem;
    text-align: center !important;
    `}
	${media.sm`
	margin-top:0.4rem;
	text-align:center !important;
    `}
    ${media.md`
	margin-top:0rem;
	text-align: ${({isCentered}: any) => (isCentered ? 'center' : '')};
    `}
	${media.lg`
	margin-top:0rem;
	text-align: ${({isCentered}: any) => (isCentered ? 'center' : '')};
    `}
`

const Headings = styled(Text)<any>`
	text-align: ${({isCentered}: any) => (isCentered ? 'center' : 'left')};
	${media.xs`

    text-align: ${({isCentered}: any) => (isCentered ? 'center' : 'center')};
    `}
	${media.sm`
	text-align:${({isCentered}: any) => (isCentered ? 'center' : 'center')};
    `}
    ${media.md`
	margin-top:0rem;
	text-align: ${({isCentered}: any) => (isCentered ? 'center' : 'left')};
    `}
	${media.lg`
	margin-top:0rem;
	text-align: ${({isCentered}: any) => (isCentered ? 'center' : 'left')};
    `}
`

const CustomFlexed = styled(Flexed)<any>`
	${media.xs`

    align-items: center ;
	>p{
		text-align:center ;
	}
    `}
	${media.sm`
	align-items:center ;
	>p{
		text-align:center ;
	}
    `}
    ${media.md`
	align-items: ${({align}: any) => (align ? align : '')};
	>p{
		text-align:${({align}: any) => (align ? align : 'start')};
	}
    `}
	${media.lg`
	align-items: ${({align}: any) => (align ? align : '')};
	>p{
		text-align:${({align}: any) => (align ? align : 'start')};
	}
    `}
`

const CustomButtonsFlexed = styled(Flexed)<any>`
	${media.xs`

    align-items: center ;
	justify-content: center;
    `}
	${media.sm`
	align-items:center ;
	justify-content: center;

    `}
    ${media.md`
	align-items: ${({align}: any) => (align ? align : '')};
	justify-content: center;

    `}
	${media.lg`
	align-items: ${({align}: any) => (align ? align : '')};
	justify-content: start;
    `}
`

export const ButtonFlex = styled(Flexed)<any>`
	${media.xs`

    align-items: center ;
	justify-content:center;
    `}
	${media.sm`
	align-items:center ;
	justify-content:center;

    `}
    ${media.md`
	align-items: ${({align}: any) => (align ? align : '')};
	justify-content:start;

    `}
	${media.lg`
	align-items: ${({align}: any) => (align ? align : '')};
	justify-content:start;

    `}
`

const Card = styled.div<IProps>`
	position: relative;
	overflow: hidden;
	text-align: center;
	padding: 1rem;
	border-radius: 15px;
	/* box-shadow: ${palette.shadow}; */
	min-height: 13rem;
	cursor: default;
	margin-bottom: 1.875rem;
	background: #ffffff99;

	border: 2px solid;

	border-image-source: linear-gradient(112.31deg, #ffffff 6.93%, rgba(255, 255, 255, 0) 98.02%);
	/* background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.cards_bg)}; */
	${media.xs` min-height: 12rem;`}
`

const StyledRow = styled(Row)`
	display: flex;
	flex-wrap: wrap;
`

const StyledCard = styled(Card)`
	flex: 1 0 50%;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	cursor: default;
`

const CustomPlayer = styled(ReactPlayer)`
	border-radius: 1rem !important;
	> video {
		border-radius: 1rem !important;
	}
`

const GradientText = styled(Text)`
	color: transparent; /* Set the actual text color to transparent */
	background-image: linear-gradient(to bottom, rgba(136, 0, 200, 1) 0%, rgba(0, 68, 144, 1) 100%);
	-webkit-background-clip: text; /* For Safari and Chrome */
	-webkit-text-fill-color: transparent; /* For Safari and Chrome */
	background-clip: text;
	text-fill-color: transparent;
	font-size: ${({fontSize}) => fontSize};
	${media.xs`
	font-size: 2rem;
	line-height: 2.5rem;
	 `};
	${media.md`
	font-size: 3rem;
	line-height: 3.5rem;
	 `};
`

const CustomParagrapht = styled(Paragraph)<any>`
	font-size: ${({fontSize}) => fontSize};
	${media.xs`
	font-size: 1rem;
	line-height: 1.5rem;
	 `};
	${media.md`
	font-size: ${({smallFontSize}: any) => smallFontSize};
	line-height: auto;
	 `};
`
const CustomIcon = styled(Icons)<any>`
	width: ${({width}) => width};
	${media.xs`
	width: ${({smallWidth}: any) => smallWidth};
	 `};
`

const Customflexed = styled(Flexed)<any>`
	padding: ${({padding}) => padding};
	justify-content: start;
	display: flex;
	${media.xs`
	justify-content:center;
	align-items:center;
	display:flex;
padding: ${({smallPadding}: any) => smallPadding};
	 `};
`
export default AboutUsPage
