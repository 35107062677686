import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Text} from '../../styled/shared'
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {Icons} from '../TopNavBar'
import {useTranslation} from 'react-i18next'

const InputField = ({label, value, required, type, placeholder, onChange, disabled, error, allowOnlyNumbers, errorMsg, bgTransparent, name, isEmailVerified, isPhoneVerified, background, padding, isContact}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)

	const [visible, setVisible] = useState(false)
	const [keyValue, setKeyValue] = useState<any>('')
	const [inputValue, setInputValue] = useState(value ?? '')
	const {t} = useTranslation()
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (allowOnlyNumbers) {
			const re = /^\d+$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				setInputValue(event.target.value)
				onChange({
					value: event.target.value,
					name: event.target.name
				})
			} else {
				if (re.test(val) || val?.length == 0) {
					setInputValue(event.target.value.replace(/[\s]/g, ''))
					if (onChange)
						onChange({
							value: event.target.value.replace(/[\s]/g, ''),
							name: event.target.name
						})
				}
			}
		} else {
			setInputValue(event.target.value)
			if (onChange) onChange({value: event.target.value, name: event.target.name})
		}
	}

	const onKeyDown = (e: any) => {
		e.preventDefault()
	}

	return (
		<>
			{label && (
				<Flexed direction={'row'} justify={'space-between'}>
					<Label type="normal" margin="0rem 0rem 0.4rem 0rem" isDarkTheme={_isDarkTheme} color={'black'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'600'}>
						{label} {required ? <Mandatory>*</Mandatory> : ''}
					</Label>
					{type == 'email' && isEmailVerified ? (
						<Text color={'success'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'}>
							<Icons src={'/images/icon_tick.svg'} />
							{t('Verified')}
						</Text>
					) : type == 'email' ? (
						<Text color={'danger_1'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'}>
							<Icons src={'/images/not_verified.svg'} /> {t('Not Verified')}
						</Text>
					) : (
						''
					)}
				</Flexed>
			)}
			{type !== 'textarea' ? (
				<>
					<InputWrapper>
						<TextInput
							value={inputValue ?? value}
							type={`${type && !visible ? (type === 'search' ? 'text' : type) : 'text'}`}
							disabled={disabled}
							placeholder={placeholder ? placeholder : label}
							error={error}
							onChange={(event: any) => handleChange(event)}
							bgTransparent={bgTransparent}
							isDarkTheme={_isDarkTheme}
							background={background}
							padding={padding}
							isContact={isContact}
							onKeyDown={(e: any) => {
								if (type == 'date') {
									type == 'date' && onKeyDown(e)
								} else {
									setKeyValue(e.key)
								}
							}}
						/>
						{type === 'password' && (
							<Icon direction="row" align="center" justify="center" isDarkTheme={_isDarkTheme}>
								{visible ? (
									<Eye
										onClick={() => {
											setVisible(false)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								) : (
									<CloseEye
										onClick={() => {
											setVisible(true)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								)}
							</Icon>
						)}
						{type === 'search' && (
							<Icon direction="row" align="center" justify="center">
								<Search src="/images/icons/search.svg" />
							</Icon>
						)}
					</InputWrapper>
					{error && (
						<Text fontSize={0.625} type="small" color="danger">
							{t(error?.message)}
						</Text>
					)}
				</>
			) : (
				<TextArea isDarkTheme={_isDarkTheme} bgTransparent={bgTransparent} row={4} value={value} placeholder={placeholder} onChange={(event: any) => onChange({value: event.target.value, name: name})} />
			)}
		</>
	)
}

export const Label = styled(Text)`
	font-weight: 500;
	text-transform: ${({textTransform}) => (textTransform ? 'capitalize' : ' uppercase')};
`

export const Mandatory = styled.span`
	color: ${palette.danger};
`

const InputWrapper = styled.div`
	position: relative;
`

const TextInput = styled.input<any>`
	font-family: 'Roboto';
	width: 100%;
	line-height: 2rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 12px;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem 0.8rem`)};
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({disabled, isDarkTheme, isContact}) => (isContact && disabled ? `${palette.text_color}` : disabled || isDarkTheme ? `${palette.silver}` : `${palette.text_color}`)};
	width: 100%;
	background: ${({disabled, bgTransparent, isDarkTheme, background, isContact}) =>
		background ? background : bgTransparent ? 'transparent' : isContact && disabled ? `${palette.light_gray}` : disabled ? `${palette.silver}` : isDarkTheme ? `${palette.black}` : `${palette.white}`};
	/* &:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.blue}`)};
	} */
	&::placeholder {
		color: ${({disabled, isDarkTheme, isContact}) => (isContact && disabled ? `${palette.text_color}` : disabled || isDarkTheme ? `${palette.silver}` : `${palette.input_text_color}`)};
		font-size: 0.9rem !important;
		line-height: 1.2rem !important;
		font-weight: 400 !important;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled, isDarkTheme, isContact}) => (isContact && disabled ? `${palette.text_color}` : disabled || isDarkTheme ? `${palette.silver}` : `${palette.input_text_color}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		// color: ${palette.black};
	}
`

const Icon = styled(Flexed)<any>`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	right: 0.063rem;
	width: 2.5rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border-bottom-right-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
`

const Eye = styled(BsFillEyeFill)<any>`
	font-size: 1.25rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	opacity: 0.5;
	cursor: pointer;
`
const CloseEye = styled(BsFillEyeSlashFill)<any>`
	font-size: 1.25rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	opacity: 0.5;
	cursor: pointer;
`

const TextArea = styled.textarea<any>`
	width: 100%;
	line-height: 1.2rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	height: 9.2rem;
	border-radius: 12px;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.text_color}`)};
	background: ${({disabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : disabled ? `${palette.silver}` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};

	&::placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.input_text_color}`)};
		font-size: 0.9rem !important;
		line-height: 1.2rem !important;
		font-weight: 500 !important;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.input_text_color}`)};
		font-size: 0.9rem !important;
		line-height: 1.2rem !important;
		font-weight: 500 !important;
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		// color: ${palette.black};
	}
`

const Search = styled.img`
	width: 1rem;
`

export default InputField
