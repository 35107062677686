import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Flexed, Heading, Paragraph, Spacer, Text} from '../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import SignUpForm from '../components/SignupForm'
import {Icons} from '../components/TopNavBar'
import {useTranslation} from 'react-i18next'
import Footer from '../components/Footer'
import useRouter from '../hooks/router'

interface IProps {
	isDarkTheme?: boolean
}

const SignUp = () => {
	const [inviteFrom, setInviteFrom] = useState({})

	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const {t} = useTranslation()
	const router = useRouter()
	useEffect(() => {
		// Extract encoded query parameter from the URL
		const urlParams = new URLSearchParams(window.location.search)
		window.location.search && localStorage.setItem('invited-details', window.location.search)
		const encodeduserId = urlParams.get('data')
		const encodedemail = urlParams.get('email')

		// Decode the data
		if (encodeduserId && encodedemail) {
			const decodedData = atob(encodeduserId)
			const decodedEmail = atob(encodedemail)
			// setInviteFrom({email: decodedEmail, userId: decodedData})
		} else {
			console.error('Encoded data is null or undefined')
		}
	}, [])

	const contactFormRef: any = useRef(null)

	useEffect(() => {
		if (router.location.state == 'top' && contactFormRef?.current) {
			contactFormRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [router.location.state])
	return (
		<>
			<MainContainer ref={contactFormRef}>
				<Container fluid>
					<Row justifyContent="center">
						<Col xl={6} lg={6} md={12} sm={12} xs={12}>
							<Spacer height={2} />
							<SignUpForm inviteFrom={inviteFrom} />
						</Col>

						<Col xl={6} lg={6} md={6} sm={12} xs={12}>
							<Flexed justify={'center'} direction={'column'} align={'center'}>
								<Spacer height={2} />
								<CustomSpacer height={4} />
								<Icons width={'100%'} src="/images/signup-sideCover.svg" />
								<Spacer height={2} />
								<CustomSpacer height={3} />
								<Col xl={9.5} lg={9.5} md={9.5} sm={12} xs={12}>
									<CustomFlexed direction={'row'} justify={'space-between'} align={'baseline'}>
										<CustomText fontSize={'2'} lineHeight={'2.4'} fontWeight={'700'} color={'black'}>
											{t('CONNECT')}
										</CustomText>
										<Dot />
										<CustomText fontSize={'2'} lineHeight={'2.4'} fontWeight={'700'} color={'black'}>
											{t('NETWORK')}
										</CustomText>
										<Dot />
										<CustomText fontSize={'2'} lineHeight={'2.4'} fontWeight={'700'} color={'black'}>
											{t('EARN')}
										</CustomText>
									</CustomFlexed>
								</Col>
								<Spacer height={2} />
								<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} fontWeight={'700'}>
									{t("Transform your professional networking into a profitable venture with JustNetwork's unique BMT feature.")}
								</Paragraph>
							</Flexed>
							<Spacer height={2} />
						</Col>
					</Row>
				</Container>
			</MainContainer>
			<Footer />
		</>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`
const CustomFlexed = styled(Flexed)`
	${media.xs`
    gap:0.5rem;
flex-direction: column;
justify-content: center;
align-items: center;
    `}
	${media.sm`
    gap:0.5rem;
    justify-content: center;
	flex-direction: column;
    align-items: center;

    `}
    ${media.md`
    gap:0rem;
	flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    `}
	${media.lg`
    gap:0rem;
    justify-content: space-between;
	flex-direction: row;
    align-items: baseline;

    `}
`

export const Card = styled.div<IProps>`
	position: relative;
	overflow: hidden;
	text-align: center;
	padding: 1.5rem;
	border-radius: 15px;
	min-height: 13rem;
	cursor: default;
	margin-bottom: 1.875rem;
	border: 2px solid;
	background: #ffffff;

	border-image-source: linear-gradient(112.31deg, #ffffff 6.93%, rgba(255, 255, 255, 0) 98.02%);
	box-shadow: 0px 0px 20px 0px #0000000d;

	${media.xs` min-height: 12rem;`};
`
const CustomText = styled(Text)<any>`
	background: ${palette.default_theme};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

export const Dot = styled.div<any>`
	height: ${({height}) => (height ? height : '6px')};
	width: ${({height}) => (height ? height : '6px')};
	border-radius: 50%;
	background: ${({bg}) => (bg ? bg : palette.default_theme)};
	border: ${({border}) => (border ? border : 'none')};
`

export const CustomSpacer = styled(Spacer)<any>`
	${media.xs`
    display: none;
    `}
	${media.sm`
    display: none;

    `}
    ${media.md`

    display: flex;
    `}
	${media.lg`
   display: flex;
    `}
`
export default SignUp
