import React, {useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Flexed, Heading, Paragraph, Spacer, Text} from '../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {CustomSpacer} from './SignUp'
import LoginForm from '../components/LoginForm'
import {CustomSmallScreenSpacer} from '../components/netwrokComponents/RescheduleRequest'
import {useTranslation} from 'react-i18next'
import Footer from '../components/Footer'

const LogIn = () => {
	useEffect(() => {
		// alert(33)
		localStorage.clear()
	}, [])
	const {t} = useTranslation()
	return (
		<MainContainer>
			<Container fluid>
				<Row justifyContent="center">
					<Col xl={6} lg={6} md={12} sm={12} xs={12}>
						<Spacer height={2} />
						<CustomSmallScreenSpacer height={1} />
						<CustomSpacer height={2} />
						<LoginForm />
					</Col>

					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<Flexed justify={'center'} direction={'column'} align={'center'} height={'auto'}>
							<Spacer height={2} />
							<CustomSpacer height={4} />
							<img width={'80%'} src="/images/signup-sideCover.svg" />
							<Spacer height={2} />
							{/* <CustomSpacer height={3} /> */}

							<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
								{t('How is the process of earning on JustNetwork?')}
							</Paragraph>
							<Spacer height={1} />
							<OuterDiv>
								<InnerDiv />
							</OuterDiv>
							<Spacer height={0.5} />
							<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
								"{t('Network, earn coins, and exchange them for money - as simple as that!')}"
							</Paragraph>
						</Flexed>
						{/* <Spacer height={8} /> */}
					</Col>
				</Row>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`

const OuterDiv = styled.div`
	width: 100px;
	border-radius: 1rem;
	overflow: hidden;
`

const InnerDiv = styled.div`
	width: 100%;
	height: 100%;
	border-width: 4px;
	border-style: solid;
	border-image: ${palette.default_theme} 1;
	background: ${palette.default_theme};
`

export default LogIn
