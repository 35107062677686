import axios from 'axios'
import { toast } from 'react-toastify'

//api call
export const api = axios.create({
	baseURL: `https://api.justnetwork.tech/just-network`,
	headers: {
		'Content-Type': 'application/json'
	}
})

api.interceptors.request.use(
	(request) => {
		let token = localStorage.getItem('authorization')
		const isLoggedIn = token && token

		if (isLoggedIn) {
			request.headers.common.Authorization = `Bearer ${token}`
		}

		return request
	},
	(error) => {
		return Promise.reject(error)
	}
)

// api.interceptors.response.use(
// 	(response) => {
// 		return response
// 	},
// 	function (error) {
// 		return Promise.reject(error)
// 	}
// )

api.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			// Redirect to login page
			window.location.href = '/login'
			console.log('======error.response', error.response)
			if (error.response?.data?.message)
				toast.error(error.response?.data?.message)
		} else {
			return Promise.reject(error)
		}

	}
)
