import Pusher from 'pusher-js'
import {AppConstants} from '../AppConstants'

const pusherConfig = {
	key: AppConstants.PUSHER_APP_KEY,
	cluster: AppConstants.PUSHER_CLUSTER,
	encrypted: true
}

const pusher = new Pusher(pusherConfig.key, pusherConfig)

export const subscribeToChannel = (channelName, eventName, callback) => {
	const channel = pusher.subscribe(channelName)
	channel.bind(eventName, callback)

	return () => {
		channel.unbind(eventName, callback)
		if (channel.subscriptionCount === 0) {
			pusher.unsubscribe(channelName)
		}
	}
}
