import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Card} from '../../pages/Dashboard'
import {palette} from '../../styled/colors'
import useRouter from '../../hooks/router'
import {Icons, ProfileImg} from '../TopNavBar'
import {loadStripe} from '@stripe/stripe-js'
import SubscriptionCheckoutPage from './checkout/SubscriptionCheckoutPage'
import {useSelector} from 'react-redux'
import {AppConstants} from '../../AppConstants'
import {useTranslation} from 'react-i18next'

const ProBMTMemberShip = () => {
	const [monthlyMemberShip, setMonthlyMemberShip] = useState<any>(true)
	const router: any = useRouter()
	const {t} = useTranslation()

	const proMembershipFeatures = [t('Pro Badge'), t('Setup your own BMT Value'), t('Early access to new features'), t('Premium Visibility'), t('Send & Receive unlimited coins')]
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	useEffect(() => {
		localStorage.setItem('pro_duration', monthlyMemberShip ? 'monthly' : 'annually')
	}, [monthlyMemberShip])

	const contactFormRef: any = useRef(null)

	useEffect(() => {
		if (contactFormRef?.current) {
			contactFormRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [router.location.state])

	return (
		<Card background={palette.white} overflow={'visible'} padding={'0.6rem 0.6rem'} ref={contactFormRef}>
			<Row alignItems="center">
				<Col lg={12} md={12} sm={12}>
					<Card background={'transparent'} padding={''} removeShadow overflow={'visible'}>
						<Flexed direction="column" align={''} gap={'0'} padding={'0rem 0.5rem'}>
							<Flexed direction="row" align={'center'} gap={'0.6'}>
								<Icons
									src={'/images/icon_view.svg'}
									onClick={() => {
										let check = localStorage.getItem('upgrade_to_pro')
										if (router.query.signup) {
											router.navigate(-1)
										} else {
											router.navigate(userDetails?.proMembership?.length || userDetails?.userMembership?.length ? '/buy-my-time' : check ? check : '/buy-my-time')
										}
									}}
									pointer={'pointer'}
								/>
								<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
									{t('Join Pro Membership')}
								</Text>
							</Flexed>
						</Flexed>
						<Spacer height={'3'} />
						<Row alignItems="center" style={{padding: '1rem'}}>
							<Col lg={6} md={12} sm={12} alignSelf="start">
								<Flexed direction="column" height={'100%'} align={'left'}>
									<HandleFlex direction={'column'} align={'left'} gap={'0.5'} flexWrap={'wrap'}>
										<Card background={palette.default_theme} minHeight={'6.56rem'} padding={'1rem'} style={{alignItems: 'center', display: 'flex'}}>
											<Flexed direction={'row'} align={'center'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
												<HandleFlex direction={'row'} align={'center'} gap={'0.5'} flexWrap={'wrap'}>
													<ProBadgeWrapper>
														<ProBadgeCircle></ProBadgeCircle>
														<Icons height={'80%'} width={'80%'} src={'/images/pro_badge.svg'} />
													</ProBadgeWrapper>
													<Spacer height={2} />
													<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
														<Flexed direction={'column'} gap={'0.5'} align={'start'}>
															<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
																{t('Become a')}
															</Text>

															<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
																{t('Pro Member')}
															</Text>
														</Flexed>
													</HandleFlex>
												</HandleFlex>

												<HandleFlex direction="column" justify={'center'} align={'center'} gap={'1'} padding={'1rem'}>
													<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
														{t('Subscription')}
													</Text>

													<Flexed direction="row" align={'center'} width={'8.12rem'} height={'3.31rem'} gap={0.3} padding={'0.3rem'} background={`#FAFAFA`} radius={'8px'}>
														<Flexed
															align={'center'}
															gap={'0.8'}
															width={'48%'}
															height={'100%'}
															padding={'1rem'}
															pointer
															radius={'8px'}
															style={monthlyMemberShip ? {boxShadow: '0px 1px 4px 0px #00000040'} : {}}
															onClick={() => setMonthlyMemberShip(true)}>
															<Text fontSize={'0.7'} lineHeight={'0.83'} fontWeight={'600'} color={!monthlyMemberShip ? 'foreground_600' : 'blue_500'}>
																{t('Monthly')}
															</Text>
														</Flexed>

														<Flexed
															align={'center'}
															gap={'0.8'}
															width={'48%'}
															height={'100%'}
															padding={'1rem'}
															pointer
															radius={'8px'}
															style={monthlyMemberShip == false ? {boxShadow: '0px 1px 4px 0px #00000040'} : {}}
															onClick={() => setMonthlyMemberShip(false)}>
															<Text fontSize={'0.7'} lineHeight={'0.83'} fontWeight={'600'} color={!monthlyMemberShip == false ? 'foreground_600' : 'blue_500'}>
																{t('Annually')}
															</Text>
														</Flexed>
													</Flexed>
												</HandleFlex>
											</Flexed>
										</Card>
										<Text banner fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'text_color'} fontStyle={'italic'}>
											{t('Tailored for top entrepreneurs, VCs, influencers, celebrities, Public Figures and similar high-profile individuals, whose time is exceptionally valuable.')}
										</Text>
										<Spacer height={'0.1'} />

										<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'500'} color={'black'} opacity={'50%'}>
											{t('Pay JustNetwork')}
										</Text>
										<Flexed direction={'row'} align={'center'}>
											<Text fontSize={'2'} lineHeight={'2.52'} fontWeight={'600'} color={'text_color'}>
												US {monthlyMemberShip ? '$8.00' : '$86.00 '}{' '}
											</Text>
											{monthlyMemberShip ? (
												''
											) : (
												<Text fontSize={'1.2'} lineHeight={'1.2'} fontWeight={'500'} color={'text_color'}>
													(10% Promo)
												</Text>
											)}
										</Flexed>

										<Flexed direction={'column'} padding={'0 0.4rem'}>
											<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'text_color'}>
												{t('Pro Membership Plan Advantages')}
											</Text>
											<Spacer height={'0.8'} />
											<Flexed direction={'column'} gap={'0.9'}>
												{proMembershipFeatures?.map((benifits) => (
													<Flexed direction={'row'} padding={'0 0.2rem'} align={'center'} gap={'0.3'}>
														<Icons src={'/images/icon_tick.svg'} />
														<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={'default_foreground'}>
															{benifits}
														</Text>
													</Flexed>
												))}
											</Flexed>
										</Flexed>
									</HandleFlex>
								</Flexed>
							</Col>
							<Col lg={6} md={12} sm={12}>
								<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
									{!monthlyMemberShip && <SubscriptionCheckoutPage priceId={AppConstants.ANNUALLY_SUBSCRIPTION_ID} />}
									{monthlyMemberShip && <SubscriptionCheckoutPage priceId={AppConstants.MONTHLY_SUBSCRIPTION_ID} />}
								</Card>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			{/* <Spacer height={'3'} /> */}
		</Card>
	)
}

export const CustomSmallScreenSpacer = styled(Spacer)<any>`
	${media.xs`
    display: flex;
    `}
	${media.sm`
    display: flex;

    `}
    ${media.md`

    display: none;
    `}
	${media.lg`
   display: none;
    `}
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
	text-align: center;
	align-items: center;
	justify-content:center;
	width: 100%;
	 `};
`

const ManageCardComponent = styled(Flexed)<any>`
	height: ${({height}) => height};
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
	text-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	 `};
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 50px;
		width: 37px;
	}
	${media.xs`
	display: content;
	width: 100%
	 `};
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

export default ProBMTMemberShip
