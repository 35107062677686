import React from 'react'
import {Navigate} from 'react-router-dom'

interface Props {
	Component: React.FunctionComponent
	redirectTo?: string
}

export const PrivateRoute: React.FC<Props> = ({Component, redirectTo = '/login', ...props}) => {
	const isAuthenticated = localStorage.getItem('authorization') ? true : false

	if (!isAuthenticated) return <Navigate to={redirectTo} />

	const component = <Component {...props} />
	const Layout = component.type?.Layout ?? React.Fragment

	return <Layout>{component}</Layout>
}
