import React from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Spacer, Text} from '../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {CustomSpacer} from './SignUp'
import SideCover from '../components/SideCover'
import OtpVerificationForm from '../components/OtpVerificationForm'
import useRouter from '../hooks/router'
import OtpVerification from '../components/OtpVerification'
import Footer from '../components/Footer'

const OtpVerificationPage = () => {
	const router: any = useRouter()
	return (
		<MainContainer>
			<Container fluid>
				<Row justifyContent="center">
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<Spacer height={2} />
						<CustomSpacer height={4} />
						{router?.query?.isloginVerify ? <OtpVerification /> : <OtpVerificationForm />}
					</Col>
					<SideCover />
				</Row>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`
const BuyMyTime = styled(Text)`
	background-clip: text;
	background-image: ${palette.default_theme};
	color: transparent;
`

export default OtpVerificationPage
