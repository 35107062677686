import React from 'react'
import {Flexed, Text} from '../../styled/shared'
import {Dot} from '../../pages/SignUp'
import {palette} from '../../styled/colors'
import useRouter from '../../hooks/router'
import {useTranslation} from 'react-i18next'

const AuthTerms = () => {
	const router = useRouter()
	const {t} = useTranslation()
	const handleNavigateNewTab = (url: any) => {
		window.open(url, '_blank') // Opens in a new tab
	}
	return (
		<Flexed direction={'row'} align={'center'} gap={'0.5'} flexWrap={'wrap'}>
			<Text fontSize={'0.72'} pointer lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'} onClick={() => router.navigate('/')}>
				©JustNetwork
			</Text>
			<Dot bg={palette.input_text_color} width={'4px'} height={'4px'} />
			<Text fontSize={'0.72'} pointer lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'} onClick={() => router.navigate('/', {state: 'contact'})}>
				{t('Contact us')}
			</Text>
			<Dot bg={palette.input_text_color} width={'4px'} height={'4px'} />
			<Text fontSize={'0.72'} pointer lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'} onClick={() => handleNavigateNewTab('/terms-conditions')}>
				{t('Terms & Conditions')}
			</Text>
			<Dot bg={palette.input_text_color} width={'4px'} height={'4px'} />
			<Text fontSize={'0.72'} pointer lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'} onClick={() => handleNavigateNewTab('/privacy-policy')}>
				{t('Privacy Policy')}
			</Text>
		</Flexed>
	)
}

export default AuthTerms
