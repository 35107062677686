import React from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {CustomSpacer} from './SignUp'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import {useTranslation} from 'react-i18next'
import Footer from '../components/Footer'

interface IProps {
	isDarkTheme?: boolean
}

const ForgotPassword = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const {t} = useTranslation()
	let _navigate = useNavigate()

	return (
		<MainContainer>
			<Container fluid>
				<Row justifyContent="center">
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<Spacer height={2} />
						<CustomSpacer height={2} />
						<ForgotPasswordForm />
					</Col>

					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<Flexed justify={'center'} direction={'column'} align={'center'}>
							<Spacer height={2} />
							<CustomSpacer height={4} />
							<img width={'80%'} src="/images/signup-sideCover.svg" />
							<Spacer height={2} />
							<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
								{t("Monetize Your Networking Time with JustNetwork's Revolutionary")}
							</Paragraph>
							<Spacer height={1} />
							<BuyMyTime direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
								{t('Buy My Time')}
							</BuyMyTime>
							<Spacer height={0.5} />
							<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
								{t('Feature')}
							</Paragraph>
						</Flexed>
						{/* <Spacer height={8.5} /> */}
					</Col>
				</Row>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`
export const BuyMyTime = styled(Text)`
	background-clip: text;
	background-image: ${palette.default_theme};
	color: transparent;
`

export default ForgotPassword
