import React, { Component, useEffect, useState } from "react";
import {
    Provider,
    Chat,
    ConversationList,
    useClient,
    rootStore,
    ConversationItem,
    Button,
    TextMessage,
    Avatar,
    MessageList,
    MessageEditor,
    AudioMessage,
    VideoMessage,
    ImageMessage, FileMessage
} from "chatuim2";
import "chatuim2/style.css";
import { Flexed, Text } from "../../styled/shared";
import { Icons } from "../TopNavBar";
import { palette } from "../../styled/colors";
import { useTranslation } from "react-i18next";
import { Card } from "../../pages/Dashboard";
import { AppConstants } from "../../AppConstants";
import { BiChat, BiChevronUp } from "react-icons/bi";
import { Chatroom } from "agora-chat-uikit";
import { useSelector } from "react-redux";
import Loader from "../common/Loader";

const ChatApp = ({ meetingDetails, token, userId, chatWith, chatUser, openModel, setOpenModel, tokenData }) => {

    const conversation = {
        chatType: "singleChat",
        conversationId: `${chatWith?.toLowerCase()}`,
        name: chatUser?.full_name,
        lastMessage: {},
    };
    const [openChatModel, setOpenChatModel] = useState(openModel)
    const { t } = useTranslation()
    const client = useClient();
    const [loading, setLoading] = useState(false)
    const userDetails = useSelector((state) => state.auth.userDetails)
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        setOpenChatModel(openModel)

    }, [openModel])

    useEffect(() => {
        const loginChat = () => {
            try {
                setLoading(true)
                client &&
                    client
                        .open({
                            user: `${userId}`,
                            agoraToken: token,
                        })
                        .then((res) => {

                            rootStore.conversationStore.addConversation(conversation);
                            // rootStore.conversationStore.setCurrentCvs(conversation)
                            // rootStore.conversationStore.setConversation([conversation])


                            setTimeout(() => {
                                rootStore.conversationStore.setCurrentCvs(conversation)
                                setLoading(false)
                            }, 2000)


                        }).catch((err) => {
                            setLoading(false)
                            console.log('=====err', err)

                        });
            } catch (error) {
                setLoading(false)

            }
        }
        if (token && chatWith && userId) {
            loginChat()
        }

    }, [client, token]);

    const renderTxtMsg = (msg) => {
        return (
            <TextMessage
                bubbleStyle={{
                    background: msg?.from == chatWith ? palette.reply_bg : palette.blue_500,
                    // padding: msg?.status ? '10px 15px 10px 20px' : '10px 15px 10px 20px',
                    borderRadius: msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? '20px 20px 20px 0px' : '20px 20px 0px 20px', color: msg?.from == chatWith ? 'black' : 'white',
                    // whiteSpace: 'wrap',
                    width: 'auto',
                    maxWidth: '80%'
                }}
                select={false}
                style={{ width: '100%' }}
                showTranslation={false}
                shape="square"
                nickName={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name : userDetails?.full_name}
                status={msg?.status}
                avatar={<Avatar style={{ background: palette?.light_gray }} src={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.image_Url ? chatUser?.image_Url : null : userDetails?.image_Url ? userDetails?.image_Url : null}> {msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name?.charAt(0)?.toUpperCase() : userDetails?.full_name?.charAt(0)?.toUpperCase()} </Avatar>}
                textMessage={msg}
            ></TextMessage>
        );
    };



    const renderMessage = (msg) => {
        if (msg?.type === 'txt') {
            return renderTxtMsg(msg);
        } else if (msg?.type == 'audio') {
            return <AudioMessage style={{ maxWidth: '90%' }} select={false} nickName={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name : userDetails?.full_name} avatar={<Avatar style={{ background: palette?.light_gray }} src={msg?.from == chatWith ? chatUser?.image_Url ? chatUser?.image_Url : null : userDetails?.image_Url ? userDetails?.image_Url : null}> {msg?.from == chatWith ? chatUser?.full_name?.charAt(0)?.toUpperCase() : userDetails?.full_name?.charAt(0)?.toUpperCase()} </Avatar>} audioMessage={msg} />
        } else if (msg?.type == 'video') {
            return <VideoMessage style={{ maxWidth: '80%' }} select={false} nickName={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name : userDetails?.full_name} avatar={<Avatar style={{ background: palette?.light_gray }} src={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.image_Url ? chatUser?.image_Url : null : userDetails?.image_Url ? userDetails?.image_Url : null}> {msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name?.charAt(0)?.toUpperCase() : userDetails?.full_name?.charAt(0)?.toUpperCase()} </Avatar>} videoMessage={msg} />
        } else if (msg?.type == 'file') {
            return <FileMessage style={{ maxWidth: '80%' }} select={false} nickName={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name : userDetails?.full_name} avatar={<Avatar style={{ background: palette?.light_gray }} src={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.image_Url ? chatUser?.image_Url : null : userDetails?.image_Url ? userDetails?.image_Url : null}> {msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name?.charAt(0)?.toUpperCase() : userDetails?.full_name?.charAt(0)?.toUpperCase()} </Avatar>} fileMessage={msg} />
        } else if (msg?.type == 'img') {
            return <ImageMessage style={{ maxWidth: '90%' }} select={false} nickName={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.full_name : userDetails?.full_name} avatar={<Avatar style={{ background: palette?.light_gray }} src={msg?.from?.toLowerCase() == chatWith?.toLowerCase() ? chatUser?.image_Url ? chatUser?.image_Url : null : userDetails?.image_Url ? userDetails?.image_Url : null}> {msg?.from == chatWith ? chatUser?.full_name?.charAt(0)?.toUpperCase() : userDetails?.full_name?.charAt(0)?.toUpperCase()} </Avatar>} imageMessage={msg} />
        }
    };

    return (
        <Card
            radius={openChatModel ? 0 : '1.25rem 1.25rem  0 0'}
            minHeight={openChatModel ? '100%' : '4rem'}
            background={openChatModel ? palette.white : 'transparent'}
            padding={'0'}
            width={openChatModel ? '28rem' : '13rem'}
            style={{
                position: openChatModel ? 'relative' : 'fixed',
                top: openChatModel ? '' : '91%',
                right: openChatModel ? '0rem' : '28%',
                zIndex: 9999,
                transition: 'bottom 0.3s ease-in-out',
                boxShadow: `0px 0px 10px 0px #0000001A`,
                overflow: 'hidden'
            }}>
            <Flexed direction="row" margin={'0'} justify={'space-between'} padding={'1rem'} style={{ borderBottom: `1px solid ${palette.zink_300}` }}>
                <Flexed direction="column" align={'center'} gap={'0'} onClick={() => {
                    setOpenChatModel(!openChatModel)
                    setOpenModel(!openModel)
                }}>
                    <BiChat color="white" fontSize={'1.5rem'} />

                </Flexed>
                <BiChevronUp fontSize={'1.2rem'} color="white" onClick={() => {
                    setOpenChatModel(!openChatModel)
                    setOpenModel(!openModel)
                }} />
                {openChatModel ? <>
                    <Flexed direction="column" align={'center'} gap={'0'} >


                        <Text fontSize={'0.7'} lineHeight={'1.26'} fontWeight={'400'} color={'white'}>
                            {t('Chat')}
                        </Text>
                    </Flexed>
                    <Flexed direction="row" align={'center'} gap={'0.5'}>

                        <Icons
                            src={openChatModel ? '/images/icon_close.svg' : '/images/icon_view_top.svg'}
                            alt="i"
                            onClick={() => {
                                setOpenChatModel(!openChatModel)
                                setOpenModel(!openModel)
                            }}
                            pointer={'pointer'}
                        />
                    </Flexed>
                </>
                    : ""}
            </Flexed>
            {/* <div style={{ height: '10rem', overflow: 'scroll' }}>
                <ConversationList renderHeader={() => <></>} renderSearch={() => <></>} />
            </div> */}

            <div>

                {rootStore?.conversationStore?.byId && !loading ? <Chat renderEmpty={() => <></>} renderHeader={() => <></>} renderMessageList={() => <MessageList renderMessage={renderMessage} />} style={{ height: '34rem', overflowY: 'scroll' }} >
                    <MessageEditor />
                </Chat> :
                    <Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>

                        <Loader isComponent={true} />
                    </Flexed>}

            </div>
        </Card>
    );
};

const AgoraChat = ({ token, user, meeting, userId, chatWith, chatUser, openModel, setOpenModel, tokenData }) => {
    const appKey = AppConstants.agora_chat_app_key;
    return (
        <Provider
            initConfig={{
                appKey,
                userId: userId,
                token: token,
            }}
        >
            <ChatApp tokenData={tokenData} token={token} userId={userId} chatWith={chatWith} openModel={openModel} setOpenModel={setOpenModel} chatUser={chatUser} />
        </Provider>
    );
}

export default AgoraChat;