import React, {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import styled from 'styled-components'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import LinkedInPage from './common/LoginWithLinkedin'
import {Card} from './AuthPage'
import LogInGoogle from './common/LogInGoogle'
import InputPhoneNo from './common/input/InputPhone'
import Checkbox from './common/Checkbox'
import {FaArrowRight} from 'react-icons/fa'
import useRouter from '../hooks/router'
import {Dot} from '../pages/SignUp'
import {api} from '../helpers/auth-axios'
import MultiSelect from './common/MultiSelect'
import InputSelect from './common/input/InputSelect'
import Loader from './common/Loader'
import {useTranslation} from 'react-i18next'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {AppConstants} from '../AppConstants'
import AuthTerms from './common/AuthTerms'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'

const initialValues = {
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	password: '',
	confirm_password: '',
	accept_terms: false,
	gender: {value: 'male', label: 'Male'}
}

const SignUpForm = ({inviteFrom}: {inviteFrom?: any}) => {
	const router = useRouter()
	const [loading, setLoading] = useState(false)
	const [readTerm, setReadTerm] = useState(false)
	const {t} = useTranslation()
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required(t('First Name is required')),
		accept_terms: Yup.string().required(t('Please read our terms and conditions')),
		last_name: Yup.string().required(t('Last Name is required')),
		email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
		password: Yup.string().min(8).required(t('Password is required')),
		phone: Yup.string().min(8).required(t('Phone is required')),
		gender: Yup.object().required(t('Gender is required')),
		confirm_password: Yup.string()
			.oneOf([Yup.ref('password') ?? undefined, ''], t('Passwords must match'))
			.required(t('Confirm Password is required'))
	})

	useEffect(() => {
		initializeChat()
	}, [])
	const initializeChat = async () => {
		try {
			await CometChatUIKit.logout()
		} catch (err) {
			console.log('Some Error Occured', err)
		}
	}

	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/register', {...values, timezone: userTimeZone, gender: values?.gender?.value, invite_email: inviteFrom?.email, invite_user_id: inviteFrom.userId})
			.then(async (res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					router.navigate(`/verify-otp?email=${values?.email}&isloginVerify=${true}`)
					actions.setSubmitting(false)
					actions.resetForm()
					toast.success(res?.data?.message)
					//add user in cometchat

					try {
						const newUser = new CometChat.User(res?.data?.data?.user?.chat_id, {setMetadata: res?.data?.data?.user})
						newUser.setName(res?.data?.data?.user?.full_name)
						newUser.setMetadata(res?.data?.data?.user)
						newUser.setAvatar(res?.data?.data?.user?.image_Url)
						const createdUser = await CometChat.createUser(newUser, AppConstants.COMET_CHAT_AUTH_KEY)
					} catch (error) {
						console.log(' error:', error)
					}

					localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
					localStorage.setItem('authenticated', 'true')
					localStorage.setItem('isNewUser', 'true')
				} else {
					setLoading(false)
					toast.error(t(res?.data?.message))
				}
			})
			.catch((error: any) => {
				if (error?.message) {
					setLoading(false)
					toast.error(error?.message)
				}
			})
	}

	const genderOptions = [
		{value: 'male', label: t('Male')},
		{value: 'female', label: t('Female')},
		{value: 'other', label: t('Other')}
	]
	return (
		<Row justifyContent="center">
			<Col xl={8} lg={8} md={8} sm={12} xs={10}>
				{loading && <Loader />}
				<Spacer height={2.5} />
				<Card>
					<Flexed justify="center">
						<Flexed justify="center">
							<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
								{t('Create Your Account for Free')}
							</Text>
							<Spacer height={0.4} />
							<Paragraph fontSize={'0.8'} lineHeight={'1.25'} fontWeight={'500'} color={'foreground_600'}>
								{t('Sign up now and choose free membership that aligns with your business role. Start connecting and enjoying the benefits of our platform today!')}
							</Paragraph>
						</Flexed>
						<Spacer height={0.5} />
						<LinkedInPage invitedFrom={inviteFrom} />
						<Spacer height={0.5} />
						<LogInGoogle invitedFrom={inviteFrom} />
						<Spacer height={0.3} />
						{t('Or')}
						<Spacer height={0.3} />
						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
							{({isSubmitting, errors, setFieldValue, values, touched}) => (
								<Form>
									<NameFieldsFlex direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
										<Field
											component={Input}
											name="first_name"
											type="text"
											label={t('First Name')}
											value={values.first_name ?? null}
											isSignup
											isContact
											onChange={(event: any) => {
												setFieldValue('first_name', event?.value, true)
											}}
											error={touched.first_name && errors.first_name ? {message: errors.first_name ?? ''} : null}
										/>

										<Field
											component={Input}
											name="last_name"
											type="text"
											label={t('Last Name')}
											isSignup
											isContact
											value={values.last_name ?? undefined}
											onChange={(event: any) => {
												setFieldValue('last_name', event?.value, true)
											}}
											error={touched.last_name && errors.last_name ? {message: errors.last_name ?? ''} : null}
										/>
									</NameFieldsFlex>
									<Spacer height="0.7" />
									<Field
										component={InputPhoneNo}
										name="phone"
										label={t('Enter your phone')}
										value={values.phone ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('phone', event?.value, true)
										}}
										error={touched.phone && errors.phone ? errors.phone : null}
									/>
									<Spacer height="0.7" />
									<Field
										component={InputSelect} // You can use another component for single selection if desired
										name="gender"
										type="text"
										padding={'0.2rem 0.15rem'}
										isSignup
										isContact
										label={t('Gender')}
										options={genderOptions}
										placeholder={t('Choose gender')}
										value={values.gender ?? ''}
										onChange={(selectedOption: any) => {
											setFieldValue('gender', selectedOption)
										}}
										error={touched.gender && errors.gender ? errors.gender : null}
									/>
									<Spacer height="0.7" />
									<Field
										component={Input}
										name="email"
										type="email"
										label={t('Email')}
										value={values.email ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('email', event?.value, true)
										}}
										error={touched.email && errors.email ? {message: errors.email ?? ''} : null}
									/>

									<Spacer height="0.7" />
									<Field
										component={Input}
										name="password"
										type="Password"
										label={t('Password')}
										value={values.password ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('password', event?.value, true)
										}}
										error={touched.password && errors.password ? {message: errors.password ?? ''} : null}
									/>

									<Spacer height="0.7" />
									<Field
										component={Input}
										name="confirm_password"
										type="Password"
										label={t('Confirm Password')}
										value={values.confirm_password ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('confirm_password', event?.value, true)
										}}
										error={touched.confirm_password && errors.confirm_password ? {message: errors.confirm_password ?? ''} : null}
									/>
									<Spacer height="0.3" />
									<Flexed direction={'row'} align={'center'} gap={'0.3'}>
										<Checkbox
											label={''}
											defaultChecked={values.accept_terms}
											onChange={(event: any) => {
												setFieldValue('accept_terms', event, true)
												setReadTerm(event)
											}}
										/>
										<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'black'}>
											{t('I agree to the terms and conditions')}
										</Text>
									</Flexed>
									<Spacer height="0.5" />
									<Flexed direction={'row'} align={'center'} justify={'center'}>
										<Button
											small
											hasBorder
											disabled={isSubmitting || !readTerm}
											type="submit"
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													{t('CREATE AN ACCOUNT')} &nbsp;
													<FaArrowRight />
												</>
											}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
										/>
									</Flexed>
								</Form>
							)}
						</Formik>
					</Flexed>
				</Card>
				<Flexed justify="center" align={'center'}>
					<Text pointer fontSize={'0.72'} lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'}>
						{t('By clicking “Create account”, you agree to our')}{' '}
						<a onClick={() => router.navigate('/terms-conditions', {state: 'top'})} style={{color: palette.blue_400}}>
							{' '}
							{t('Terms and Conditions')}
						</a>{' '}
						{t('and')}{' '}
						<a onClick={() => router.navigate('/privacy-policy', {state: 'top'})} style={{color: palette.blue_400}}>
							{t('Privacy Policy.')}
						</a>
					</Text>
					<Spacer height={0.4} />
					<Text fontSize={'0.9'} isCentered lineHeight={'1.25'} fontWeight={'400'} color={'black'}>
						{t('Already have an account?')}
					</Text>
					<Spacer height={0.4} />
					<Button
						small
						hasBorder
						type="submit"
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						// padding={'8px 16px 8px 16px'}
						label={t('SIGN IN')}
						height={'32px'}
						width={'85px'}
						background={'transparent'}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => {
							router.navigate('/login')
						}}
					/>
				</Flexed>
				<Spacer height={1} />
				<AuthTerms />
			</Col>
		</Row>
	)
}

const NameFieldsFlex = styled(Flexed)`
	${media.xs`
flex-direction: column;
    `}
	${media.sm`
	flex-direction: column;

    `}
    ${media.md`
	flex-direction: row;

    `}
	${media.lg`
	flex-direction: row;

    `}
`

export default SignUpForm
