import React, {useState, useEffect} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import TextArea from './common/input/TextArea'

import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Spacer} from '../styled/shared'
import {toast} from 'react-toastify'
import Loader from './common/Loader'
import {useTranslation} from 'react-i18next'
import {BsClock} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {api} from '../helpers/auth-axios'
import {BsPlusCircle} from 'react-icons/bs'
import useRouter from '../hooks/router'

const initialValues = {
	email: '',
	Name: '',
	message: ''
}

const BuyTimeNowForm = ({setRemainingEmails, redirect}: any) => {
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation()
	const _userDetails = useSelector((state: any) => state.auth.userDetails)
	const router = useRouter()

	const validationSchema = Yup.object().shape({
		Name: Yup.string().required(t('prospect’s name is required')),
		email: Yup.string().email(t('Invalid prospect’s email')).required(t('prospect’s email is required')),
		message: Yup.string().max(300, t('Message cannot be longer than 300 characters')).optional()
	})
	const userUrl = `${window.location.origin}/${_userDetails?.user_name}`
	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		const userMessage = values.message || ''

		const finalMessage = userMessage.includes(userUrl) ? userMessage : `${userMessage}\n${userUrl}`
		if (redirect) {
			router.navigate('/signup', {state: 'top'})
			return
		}
		await api
			.post('/user/send_buy_time_now_email', {
				...values,
				userDetail: {name: _userDetails?.full_name, email: _userDetails?.email, profileUrl: userUrl}
			})
			.then((res) => {
				if (res?.data?.status) {
					setRemainingEmails && setRemainingEmails(res?.data?.data?.count)
					actions.setSubmitting(false)
					actions.resetForm()
					setLoading(false)
					toast.success(res?.data?.message)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	return (
		<>
			<Spacer height="1.7" />
			{loading && <Loader />}
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
				{({isSubmitting, errors, setFieldValue, values, touched}) => (
					<Form>
						<Field
							component={Input}
							name="Name"
							type="text"
							label={t('Enter prospect’s name')}
							value={values.Name ?? ''}
							isSignup
							isContact
							onChange={(event: any) => {
								setFieldValue('Name', event.value, true)
							}}
							error={touched.Name && errors.Name ? {message: errors.Name} : null}
						/>
						<Spacer height="1" />
						<Field
							component={Input}
							name="email"
							type="email"
							label={t('Enter prospect’s email')}
							value={values.email ?? ''}
							isSignup
							isContact
							onChange={(event: any) => {
								setFieldValue('email', event.value, true)
							}}
							error={touched.email && errors.email ? {message: errors.email} : null}
						/>
						<Spacer height="1" />
						<Field
							component={TextArea}
							name="message"
							type="textarea"
							label={t('Your Message')}
							placeholder={t('Write your message here...')}
							value={values.message ? values.message : undefined}
							isSignup
							isContact
							url={values.message && !redirect ? userUrl : ''}
							onChange={(event: any) => {
								let isUrlAdded = event?.value?.includes(userUrl) ? event?.value : event?.value + `\n \n${userUrl}`
								setFieldValue('message', event?.value, true)
							}}
							error={touched.message && errors.message ? {message: errors.message ?? ''} : null}
						/>
						<Spacer height="1" />
						<Flexed direction={'row'} align={'center'} justify={'center'}>
							<Button
								medium
								borderRadius={'1.25rem'}
								fontSize={'0.8rem'}
								padding={'8px 16px 8px 16px'}
								backgroundImage={'/images/button_bg.svg'}
								label={
									<>
										<BsPlusCircle style={{color: 'white', fontSize: '1rem'}} />
										&nbsp; {t('Start Prospecting')}
									</>
								}
								background={'transparent'}
								color={palette.white}
								width={'87%'}
								lineHeight={'0.89rem'}
								disabled={isSubmitting}
							/>
						</Flexed>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default BuyTimeNowForm
