import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {BiPencil} from 'react-icons/bi'
import {Icons, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {BuyMyTime} from './ForgotPassword'
import {toast} from 'react-toastify'
import UpdateProfileForm from '../components/UpdateProfileForm'
import {BsClock} from 'react-icons/bs'
import useRouter from '../hooks/router'
import {useSelector} from 'react-redux'
import {api} from '../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {networkUsers, saveUser} from '../actions/authActions'
import {useTranslation} from 'react-i18next' // Import useTranslation hook
import InviteFriends from '../components/InviteFriends'
import {Dot} from './SignUp'
import UserProfile from '../components/UserProfile'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import Loader from '../components/common/Loader'
import Footer from '../components/Footer'
import UserProfileLoader from '../components/common/UserProfileLoader'

const Dashboard = () => {
	const router = useRouter()
	const _searchQuery: any = useSelector<any>((state: any) => state.auth.searchQuery)
	const [updateProfileForm, setUpdateProfileForm] = useState(false)
	const [editProfileForm, setEditProfileForm] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)
	const dispatch = useDispatch()
	const {t} = useTranslation()
	const [recentNetworks, setRecentNetworks] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [isLoading, setIdLoading] = useState(true)
	const userAgent = navigator.userAgent.toLowerCase()
	const isMobile = /iphone|ipad|ipod|android/.test(userAgent)
	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = () => {
		setImageLoaded(true)
	}

	useEffect(() => {
		if (userDetails && localStorage.getItem('authorization')) {
			getRecentUserNetworks()
		}
	}, [userDetails])

	const autoLogin = async () => {
		await api.post('/user/auto_login').then((res: any) => {
			if (res?.data?.status) {
				dispatch(saveUser(res?.data?.data?.user))
				dispatch(networkUsers(res?.data?.data?.user_network))
				localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
			} else {
				toast.error(res?.data?.message)
				router.navigate('/signup')
			}
		})
	}

	const getRecentUserNetworks = async () => {
		setLoading(true)
		await api
			.get('/user/get_user_networks?islastTwoWeeks=true')
			.then((response: any) => {
				let users = response?.data?.data?.users

				if (users && users.length > 0) {
					users.sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
					const latestUsers = users.slice(0, 3)
					setRecentNetworks(latestUsers)
				} else {
					console.log('No users found')
				}

				setLoading(false)
				setIdLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
				toast.error(error.message)
			})
	}

	return userDetails?.id ? (
		<MainContainer>
			<Spacer height={5} />
			{updateProfileForm ? (
				<Container fluid>
					<UpdateProfileForm
						closeForm={(isUpdated: any) => {
							setUpdateProfileForm(!updateProfileForm)
							setEditProfileForm(!editProfileForm)
							if (isUpdated) {
								autoLogin()
							}
						}}
						editProfile={!userDetails?.is_new_user}
					/>
				</Container>
			) : (
				<Container fluid>
					<Row>
						<Col lg={3}>
							<Card overflow={'visible'} height={'100%'}>
								<Flexed direction="row" justify={'flex-end'} margin={'0 0.4rem'}>
									<EditWrapper style={{background: palette.white, borderRadius: '0.2rem', padding: '0.1rem 0.2rem'}} pointer onClick={() => setUpdateProfileForm(!updateProfileForm)}>
										<BiPencil />
										<EditProfileToolTip>
											<Icons src={'/images/tag_complete.svg'} />
										</EditProfileToolTip>
									</EditWrapper>
								</Flexed>
								<UserProfile userDetails={userDetails} />
							</Card>
						</Col>
						<Col lg={6}>
							<Row alignItems="center">
								<Col lg={12}>
									<CustomSmallSpace height={2} />
									<Card background={palette.white} padding={'1rem 1.2rem 0.5rem'} overflow={'visible'}>
										{loading ? (
											<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'}>
												<Loader isComponent={true} />
											</Flexed>
										) : !isLoading && recentNetworks?.length == 0 ? (
											<>
												<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} align={'left'}>
													{t('My Network')}
												</Text>
												<Flexed direction="column" justify={'space-between'} align={'center'} width={'100%'} height={'100%'} padding={isMobile ? '1rem' : '1rem 5rem'}>
													<Icons src={'/images/undraw_add_friends_re_3xte 1.svg'} />
													<Spacer height={0.4} />
													<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'700'} color={'text_color'} isCentered>
														{t('Join JustNetwork Today and Turn Your Networking into Net Worth!')}
													</Text>
													<Spacer height={0.4} />
													<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
														{t('Start adding people to your Network')}
													</Text>
													<Spacer height={0.4} />
													<Button
														small
														hasBorder
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														fontWeight={600}
														padding={'0'}
														label={
															<>
																<Icons src={'/images/icon_add.svg'} /> &nbsp; {t('Add')}
															</>
														}
														background={palette.white}
														color={palette.blue_500}
														lineHeight={'0.91rem'}
														ifClicked={() => router.navigate(`/network`, {state: 'suggestion'})}
													/>
													<Spacer height={0.4} />
												</Flexed>
											</>
										) : (
											<>
												<Flexed direction="row" justify={'space-between'}>
													<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} align={'left'}>
														{t('Recent Network')}
													</Text>
													<Text pointer onClick={() => router.navigate('/network')}>
														<Icons pointer={'pointer'} src={'/images/icon_view_left.svg'} />
													</Text>
												</Flexed>
												<Spacer height={0.4} />
												<div style={{overflowX: 'scroll'}}>
													<Flexed direction="row" justify={'space-between'} align={'center'} padding={'0.5rem '} gap={'1'} width={'max-content'}>
														{recentNetworks.map((user: any, i: any) => (
															<Card background={'transparent'} borderColor={palette.card_border} removeShadow minHeight={'14.5rem'} width={'12rem'} smallHeight={'14rem'} style={{cursor: 'pointer'}}>
																<Flexed direction="row" justify={'flex-end'} align={'end'} padding={'0'}>
																	<Icons
																		src="/images/message-square.svg"
																		onClick={() => {
																			const handleRequest = async () => {
																				if (userDetails) {
																					if (!user?.chat_id) {
																						toast.warning(t('Chat id not exist'))
																					} else if (user?.chat_id) {
																						await CometChat.getUser(user?.chat_id?.toLowerCase()).then(
																							(res: any) => {
																								if (res?.blockedByMe || res?.hasBlockedMe) {
																									toast.warning(res?.blockedByMe ? t('You blocked this user') : t('User blocked'))
																								} else if (myNetworkUsers?.includes(user?.chat_id?.toLowerCase())) {
																									router.navigate(`/inbox?id=${user?.chat_id?.toLowerCase()}&network=${true}`)
																									console.log('User details fetched for user:', res)
																								} else {
																									toast.warning(t('User not in your network'))
																								}
																							},
																							(error) => {
																								toast.warning(t('User not exist in chat'))
																								console.log('User details fetching failed with error:', error)
																							}
																						)
																					} else {
																						toast.warning(t('Network request not accepted yet'))
																					}
																				}
																			}
																			handleRequest()
																		}}
																		pointer={'pointer'}
																	/>
																</Flexed>
																<Flexed
																	direction="column"
																	justify={'space-between'}
																	align={'center'}
																	height={'10rem'}
																	onClick={() => {
																		router.navigate(`/${user.user_name}`, {state: {route: '/dashboard'}})
																	}}>
																	<Flexed direction="column" justify={'space-between'} align={'center'} padding={'0rem '}>
																		<UserProfileLoader width={'6rem'} height={'6rem'} imgUrl={user?.image_Url} />

																		<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
																			<Text
																				fontSize={'1'}
																				lineHeight={'1.2'}
																				fontWeight={'600'}
																				color={'text_color'}
																				style={{
																					whiteSpace: 'nowrap',
																					overflow: 'hidden',
																					textOverflow: 'ellipsis',
																					maxWidth: '10rem' // Adjust this value to fit your desired width
																				}}
																				title={user?.full_name ? user?.full_name : user?.first_name + ' ' + user.last_name}>
																				{user?.full_name ? user?.full_name : user?.first_name + ' ' + user.last_name}
																			</Text>
																			{user?.linkedin_profile_verified || user?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
																		</Flexed>
																		<Spacer height={0.2} />
																		<Text
																			fontSize={'0.8'}
																			lineHeight={'0.92'}
																			fontWeight={'400'}
																			color={'black'}
																			opacity={'60%'}
																			isCentered
																			title={user?.designation}
																			style={{
																				overflowWrap: 'break-word',
																				width: '11rem',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																				height: '1.9rem'
																			}}>
																			{user?.designation}
																		</Text>
																	</Flexed>

																	<Flexed align={'center'} width={'100%'}>
																		<Button
																			small
																			borderRadius={'1rem'}
																			fontSize={'0.8rem'}
																			padding={'0'}
																			label={
																				<>
																					<BsClock /> &nbsp; {t('Buy My Time')}
																				</>
																			}
																			background={palette.default_theme}
																			color={palette.white}
																			width={'80%'}
																			lineHeight={'0.89rem'}
																			ifClicked={() => router.navigate(`/${user.user_name}`, {state: {route: '/dashboard'}})}
																		/>
																	</Flexed>
																</Flexed>
															</Card>
														))}
													</Flexed>
												</div>
											</>
										)}
									</Card>
								</Col>

								<Col lg={12}>
									{userDetails?.invitedFrom?.length == 3 ? '' : <CustomSmallSpace height={2} />}
									{!userDetails?.proMembership && !userDetails?.proMembership?.length ? (
										<>
											{/* <Spacer height={2} /> */}
											<Card background={palette.white} padding={'1rem 0.5rem '} height={'auto'}>
												<Flexed direction="column" justify={''} align={'center'} width={'100%'}>
													<BuyMyTime fontSize={'1.25'} lineHeight={'1.5'} fontWeight={'700'}>
														{t('BMT')}
													</BuyMyTime>
													<Spacer height={0.5} />
													<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'700'} color={'text_color'} isCentered>
														{t('BMT FREE Memberships')}
													</Text>

													<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'300'} color={'zink_400'} isCentered fontStyle={'italic'}>
														{t("A range of options designed to cater to every professional's needs.")}
													</Text>
													<Spacer height={2} />
													<Flexed direction={'row'} justify={'space-between'} gap={'0.3'} flexWrap={'wrap'} padding={'0rem'}>
														<Icons src={'/images/daimond_member_card.svg'} />

														<Icons src={'/images/gold_member_card.svg'} />

														<Icons src={'/images/silver_member_card.svg'} />

														<Icons src={'/images/bronze_member_card.svg'} />
													</Flexed>
												</Flexed>
											</Card>
										</>
									) : (
										<>
											<Spacer height={1.6} />
											{userDetails?.invitedFrom?.length == 3 ? (
												<InviteFriends />
											) : (
												<Card background={palette.white} padding={'1rem 0.5rem 0'}>
													<Flexed direction={'row'}>
														{/* <Icons height={'100%'} width={'100%'} src={'/images/connect-and-cash.svg'} /> */}
														<SideCardCorConnect>
															<Flexed padding={'0rem'}>
																<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'700'} color={'text_color'} isCentered padding={'2rem'}>
																	{t('Connect, Collaborate, and Cash In on Your Professional Relationships')}
																</Text>
															</Flexed>
															<Spacer height={0.5} />
															<Flexed direction={'row'} justify={'center'} align={'center'}>
																<Icons width={'37%'} src={'/images/Illustration_1.svg'} />
															</Flexed>
														</SideCardCorConnect>
													</Flexed>
												</Card>
											)}
										</>
									)}
								</Col>
							</Row>
						</Col>
						<Col lg={3}>
							<CustomSmallSpace height={1} />
							<Card background={palette.default_theme} height={3}>
								<ProBadgeWrapper>
									<ProBadgeCircle></ProBadgeCircle>
									<Icons height={'90%'} width={'90%'} src={'/images/pro_badge.svg'} />
								</ProBadgeWrapper>
								<Spacer height={2} />

								<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
									{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? t('You are already a') : t('Become a')}
								</Text>
								<Spacer height={0.5} />
								<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
									{t('Pro Member')}
								</Text>
								<Spacer height={1} />
								<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
									{t('Setup your own BMT Values')}
								</Text>

								<Spacer height={0.5} />
								<Flexed direction="row" justify={'center'} align={'center'} gap={'0.4'}>
									<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
										{t('Exclusive Access')}
									</Text>
									<Dot bg={palette.secondary_900} />
									<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
										{t('Premium Visibility')}
									</Text>
								</Flexed>
								<Spacer height={1} />
								<Flexed direction="row" justify={'center'} align={'center'} gap={'0.4'}>
									{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? (
										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											fontWeight={600}
											icon={'icon.png'}
											padding={'0 1rem'}
											label={<>{t('BUY COINS')}</>}
											background={palette.white}
											color={palette.text_color}
											lineHeight={'0.91rem'}
											ifClicked={() => router.navigate('/buy-coins')}
										/>
									) : (
										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											fontWeight={600}
											icon={'icon.png'}
											padding={'0 1rem'}
											label={<>{t('UPGRADE NOW')}</>}
											background={palette.white}
											color={palette.text_color}
											lineHeight={'0.91rem'}
											ifClicked={() => router.navigate('/upgrade-bmt-membership')}
										/>
									)}
								</Flexed>
								{/* <Spacer height={0.5} /> */}
							</Card>
							<Spacer height={userDetails?.invitedFrom?.length == 3 ? 1.8 : 1.5} />
							{userDetails?.invitedFrom?.length == 3 ? (
								<Flexed direction={'row'} style={{padding: '0'}}>
									<Card background={`url(/images/mask_group.png) top /auto no-repeat`} padding={'0'} height={'100%'} width={'100%'}>
										<Flexed direction={'row'} style={{padding: '0'}} height={'100%'} width={'100%'}>
											<SideCardCorConnect style={{padding: '0'}}>
												<Card minHeight={'11rem'} removeShadow background={'transparent'}>
													<Flexed padding={'1.5rem 1.5rem 2rem 2rem'}>
														<Text align={'left'} fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'700'} color={'text_color'} isCentered padding={'2rem'}>
															{t('Connect, Collaborate, and Cash In on Your Professional Relationships')}
														</Text>
													</Flexed>
												</Card>

												<Spacer height={1} />
												<Flexed direction={'row'} justify={'center'} align={'center'}>
													<Icons src={'/images/Illustration_1.svg'} />
												</Flexed>
											</SideCardCorConnect>
										</Flexed>
									</Card>
								</Flexed>
							) : (
								<InviteFriends />
							)}
						</Col>
					</Row>
				</Container>
			)}
			<Footer />

			{/* <Container fluid></Container> */}
		</MainContainer>
	) : (
		<Loader />
	)
}
const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	margin-bottom: ${({marginBottom}) => marginBottom};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height: ${({smallHeight}: any) => (smallHeight ? smallHeight : 'auto')};
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`
const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 70%;
	}
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 94px;
	height: 94px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const EditProfileToolTip = styled(Flexed)`
	display: none;
	position: absolute;
	margin-left: 1.3rem;
	top: -28%;
	z-index: 999;
	align-items: center;
	cursor: pointer;
`

const EditWrapper = styled(Text)<any>`
	position: relative;
	display: flex;
	&:hover ${EditProfileToolTip} {
		display: block !important;
	}
`

const CustomSmallSpace = styled(Spacer)`
	display: none;
	${media.xs`
display: block
	 `};
`

const SideCardCorConnect = styled.div`
	background: transparent;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;

	align-items: center;
	height: 100%;
	border-radius: 1.25rem;
`
export default Dashboard
