import React, {useEffect, useState} from 'react'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Text, Flexed, Spacer, Paragraph} from '../styled/shared'
import {FaArrowLeft} from 'react-icons/fa6'
import {palette} from '../styled/colors'
import useRouter from '../hooks/router'
import {Card} from './Dashboard'
import {Icons} from '../components/TopNavBar'
import Button from '../components/common/Button'
import InputField from '../components/common/InputField'
import {toast} from 'react-toastify'
import {FaArrowRight} from 'react-icons/fa'
import BuyCoinsPayment from '../components/bmt/BuyCoinsPayment'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {api} from '../helpers/auth-axios'
import {callAutoLogin, saveUser, selectCoinsValue} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import Input from '../components/common/input/Input'
import {Dot} from './SignUp'
import PolicyModal from '../components/modals/PoliciesModal'
import Footer from '../components/Footer'

const BuyCoins = () => {
	const router: any = useRouter()
	const [isContinue, setIsContinue] = useState(false)
	const [error, setError] = useState<string>('')
	const [isSuccess, setIsSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [modal, setModal] = useState(false)
	const [isPromoSuccess, setIsPromoSuccess] = useState(false)
	const [isProMember, setIsProMember] = useState(false)
	const [promo, setPromo] = useState('')
	const [coins, setCoins] = useState<any>()
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [userMembership, setUserMembership] = useState<any>()
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const options = [
		{label: '25 Coins for $5', value: '5'},
		{label: '50 Coins for $10', value: '10'},
		{label: '100 Coins for $20', value: '20'},
		{label: '200 Coins for $40', value: '40'},
		{label: '500 Coins for $100', value: '100'}
	]
	useEffect(() => {
		if (_userDetails.proMembership && _userDetails.proMembership.length && !_userDetails.proMembership[0].is_deleted) {
			setIsProMember(true)
		} else {
			setUserMembership(_userDetails?.userMembership?.length ? _userDetails?.userMembership[0]?.membershipId?.type : '')
		}
	}, [_userDetails])

	const checkIfPromoCodeExists = async () => {
		try {
			setLoading(true)
			await api.post('/user/checkPromoCode', {name: promo}).then((response: any) => {
				setLoading(false)

				if (response?.data?.status) {
					toast.success(response.data?.message)
					setIsPromoSuccess(true)
					dispatch(callAutoLogin(true))
					// buyCoins(selectCoinsOption.value * 5)
					setIsSuccess(true)
				} else {
					toast.error(response.data?.message)
				}
			})
		} catch (error) {
			setLoading(false)
		}
	}

	const buyCoins = async (coins?: any) => {
		setLoading(true)

		await api
			.post('/user/update', {coins})
			.then((res: any) => {
				setLoading(false)

				if (res?.data) {
					dispatch(saveUser(res?.data?.data))
					const redirect = sessionStorage.getItem('redirect_url')
					if (redirect) {
						// Navigate to the stored path
						router.navigate(redirect)
					} else {
					}
				}
			})
			.catch((er: any) => {
				setLoading(false)

				toast.error(er.message)
			})
	}
	const handleProceed = () => {
		if (isNaN(coins) || coins <= 0) {
			setError(t('Coins must be greater than 0'))
		} else {
			setError('') // Clear any previous errors
			setIsContinue(true)
		}
	}

	const handleInputChange = (value: any) => {
		setCoins(value)
		if (!value || isNaN(value)) {
			setCoins('')
			return
		}
		dispatch(selectCoinsValue({label: coins, value: coins / 5}))
		setCoins(value)
	}

	return (
		<>
			{/* <Spacer height={3} /> */}
			<Section>
				<CenteredContainer fluid isSuccess={isSuccess}>
					{isContinue && !isSuccess ? (
						<BuyCoinsPayment coins={coins} onBack={() => setIsContinue(false)} onSuccess={() => setIsSuccess(true)} />
					) : (
						<Card buyCoins background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
							<>
								<Flexed direction="column" align={'left'} gap={'0.6'}>
									<Flexed direction="column" align={''} gap={'0'}>
										<Flexed direction="row" align={'center'} gap={'0.6'}>
											<Icons
												src={'/images/icon_view.svg'}
												onClick={() => {
													router.navigate('/buy-my-time')
												}}
												pointer={'pointer'}
											/>
											{isContinue ? (
												''
											) : (
												<Text fontSize={'1.12'} lineHeight={'1.3'} fontWeight={'700'} color={'dark_blue'}>
													{' '}
													{t('Buy Coins')}
												</Text>
											)}
										</Flexed>
									</Flexed>
								</Flexed>
							</>
							<Row justifyContent="center" alignItems="center">
								<Col lg={isContinue || isSuccess ? 12 : 6}>
									{isContinue || isSuccess ? (
										''
									) : (
										<>
											<Flexed justy={'center'} direction={'column'} align={'center'} gap={1}>
												<Spacer height={'0.4'} />
												<Flexed gap={'0.6'} pointer height={'6rem'}>
													{userMembership && userMembership?.toLowerCase()?.includes('diamond') ? (
														<Icons width="100%" src={'/images/daimond_member_card.svg'} height={'100%'} />
													) : userMembership && userMembership?.toLowerCase()?.includes('gold') ? (
														<Icons width="100%" src={'/images/gold_member_card.svg'} height={'100%'} />
													) : userMembership && userMembership?.toLowerCase()?.includes('silver') ? (
														<Icons width="100%" src={'/images/silver_member_card.svg'} height={'100%'} />
													) : userMembership && userMembership?.toLowerCase()?.includes('bronze') ? (
														<Icons width="100%" src={'/images/bronze_member_card.svg'} height={'100%'} />
													) : isProMember ? (
														<Icons width="100%" src={isProMember ? '/images/pro_member_card.svg' : '/images/daimond_member_card.svg'} height={'100%'} />
													) : (
														''
													)}
												</Flexed>
												{_userDetails?.proMembership && _userDetails?.proMembership?.length && !_userDetails?.proMembership[0]?.is_cancelled ? (
													<Button
														small
														hasBorder
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														padding={'8px 16px 8px 16px'}
														label={<>{t('MANAGE MEMBERSHIP')}</>}
														background={palette.white}
														color={palette.blue_500}
														lineHeight={'0.89rem'}
														ifClicked={() => router.navigate('/bmt-membership-payment')}
													/>
												) : (
													<></>
												)}
											</Flexed>
										</>
									)}

									{isSuccess ? (
										<Flexed align="center" text-align="center" direction="column" gap={1}>
											<SuccessText fontSize={1.5} lineHeight={'2.12'} fontWeight={600} fontStyle={'italic'}>
												{t('Thank You')}
											</SuccessText>

											<SuccessImg src="/images/thankyou.png" alt="Success" />
											{!isPromoSuccess && (
												<>
													<DimondText>{t('For your purchase')}</DimondText>

													<Card marginLeft={'0'} removeShadow radius={'0.6rem'} background={palette.purpole_gradient} width={'16.62rem'} minHeight={'auto'} height={'6.25rem'}>
														<Flexed justify={'center'} align={'center'} direction="row" height={'100%'}>
															<Text fontSize={'2'} lineHeight={'2.54'} fontWeight={'800'} color={'dark_blue'} isCentered>
																${parseInt((coins / 5).toString())}
															</Text>
														</Flexed>
													</Card>
												</>
											)}

											<Button
												small
												hasBorder
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												padding={'8px 16px 8px 16px'}
												label={
													<>
														<FaArrowLeft />
														&nbsp; {t('BACK TO BMT')}
													</>
												}
												background={palette.white}
												color={palette.blue_500}
												lineHeight={'0.89rem'}
												ifClicked={() => router.navigate('/buy-my-time')}
											/>
										</Flexed>
									) : (
										<>
											<Spacer height={1} />
											<Flexed align="center" text-align="center" direction="column" gap={1}>
												<Card background={'transparent'} borderColor={'#0000001F'} padding={'0'} removeShadow overflow={'visible'}>
													<Flexed buyCoins padding={'1rem 2.5rem'} direction={'column'}>
														<Flexed margin="1rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7}>
															<Text lineHeight="0.908" fontSize="0.75" fontweight="600" color={'default_foreground'} opacity={'60%'}>
																{t('AVAILABLE COINS')}
															</Text>

															<CoinWrapper justify="center" direction={'row'} align="center" gap={'0.5'} radius={'1rem'}>
																<img src={'/images/icon_goldcoin.svg'} width="36px" height="36px" />
																<Text fontSize="2.375" fontWeight="600" lineHeight="2.875">
																	{_userDetails?.coins}
																</Text>
															</CoinWrapper>
														</Flexed>
														<Flexed margin="2rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7}>
															<Col lg={12} style={{padding: '0'}}>
																<Input value={coins} min={1} allowOnlyNumbers label={'Enter Coins'} padding={'0.64rem 0.8rem'} onChange={(e: any) => handleInputChange(e?.value)} />
															</Col>
														</Flexed>
														<Spacer height="0.4" />
														{error && <ErrorText>{error}</ErrorText>}
														<Flexed margin="1rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7} width={'100%'}>
															<Text fontSize="0.8" fontWeight="600" lineHeight="0.95" color={'black'} opacity={'60%'}>
																{t('Promo code')}
															</Text>
															<Flexed direction={'row'} gap={0.7} width={'100%'} align={'center'}>
																<Flexed direction={'row'} gap={0.7} width={'100%'} align={'center'}>
																	<Col lg={12} style={{padding: '0'}}>
																		<InputField placeholder={'Enter code'} padding={'0.64rem 0.8rem'} onChange={(e: any) => setPromo(e.value)} />
																	</Col>
																</Flexed>
																{loading ? (
																	<FadeLoader color={palette.dark_blue} />
																) : (
																	<Button
																		small
																		hasBorder
																		border={'1px solid #0000001F'}
																		borderRadius={'2rem'}
																		fontSize={'0.8rem'}
																		padding={'18px 24px 18px 24px'}
																		label={<>{t('Apply')}</>}
																		background={palette.white}
																		color={palette.black}
																		lineHeight={'0.89rem'}
																		height={'auto'}
																		ifClicked={() => checkIfPromoCodeExists()}
																	/>
																)}
															</Flexed>
														</Flexed>
														<Spacer height={1} />
													</Flexed>
													<Flexed direction={'row'} gap={0.7} padding={'1rem 2.5rem'} width={'100%'} align={'center'} justify={'space-between'} style={{borderTop: '1px solid #0000001F'}} flexWrap={'wrap'}>
														<Flexed direction={'column'} gap={0.7}>
															<Text lineHeight="0.95" fontSize="0.8" fontweight="500" color={'default_foreground'} opacity={'50%'}>
																{t('Amount to be paid')}
															</Text>
															<Text fontSize="1.5" fontWeight="600" lineHeight="1.84" color={'black'}>
																${coins ? coins / 5 : 0}
															</Text>
														</Flexed>

														<Flexed direction={'row'} gap={0.7}>
															<Button
																small
																hasBorder
																borderRadius={'1rem'}
																fontSize={'0.8rem'}
																padding={'8px 16px 8px 16px'}
																label={<>{t('CANCEL')}</>}
																background={palette.white}
																color={palette.blue_500}
																lineHeight={'0.89rem'}
																ifClicked={() => router.navigate('/buy-my-time')}
															/>

															<Button
																small
																hasBorder
																borderRadius={'1rem'}
																fontSize={'0.8rem'}
																padding={'8px 16px 8px 16px'}
																label={
																	<>
																		{t('PROCEED')} &nbsp; <FaArrowRight />
																	</>
																}
																background={palette.blue_500}
																color={palette.white}
																lineHeight={'0.89rem'}
																ifClicked={() => handleProceed()}
															/>
														</Flexed>
													</Flexed>
													<Flexed direction={'row'} justify="center" align={'center'} gap={'0.5'} flexWrap={'wrap'}>
														<Text textDecoration="underline" onClick={() => setModal(true)} isCentered pointer fontSize={'0.72'} lineHeight={'1.25'} fontWeight={'400'} color={palette.blue_400}>
															{t('Payment fees and Policies')}
														</Text>
													</Flexed>
												</Card>
											</Flexed>
										</>
									)}
								</Col>
							</Row>
							<Spacer height={1} />
						</Card>
					)}
					{modal && <PolicyModal onClose={() => setModal(false)} />}
				</CenteredContainer>
				<Footer />
			</Section>
		</>
	)
}
const Section = styled.div<any>`
	background-image: url('/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: ${({isSuccess}) => (isSuccess ? '100%' : '100%')};
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const PaypalFlex = styled(Flexed)`
	background-image: url('/images/withdraw_coins_bg.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 26.87rem;
	height: 40.18rem;
	padding: 1rem;
`

const CenteredContainer = styled(Container)<any>`
	margin-top: 6rem;
	display: flex;
	justify-content: center;
	height: ${({isSuccess}: any) => (isSuccess ? '80vh' : '100%')};
`
const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const ErrorText = styled.div`
	color: rgb(235, 83, 83);
	background: white;
	border: 1px solid rgb(235, 83, 83);
	border-radius: 0px 0px 0.325rem 0.325rem;
	padding: 2px 10px;
	text-align: center;
	margin-top: -3px;
	font-size: 0.75rem;
	font-weight: 300;
`

const DimondText = styled(Text)`
	color: ${palette.dark_blue};
	font-weight: 700;
	font-size: 1.125;
	line-height: 1.361rem;
`
const ButtonMain = styled(Flexed)`
	width: 12.938rem;
	height: 2rem;
	font-size: 0.75rem;
	border: 1px solid;
	border-radius: 1rem;
	padding: 0.5rem, 1rem, 0.5rem, 1rem;
	color: #006fee;
`
const ButtonMainText = styled(Text)`
	color: #006fee;
	font-size: 0.75rem;
`
const Cardimg = styled.img`
	background: linear-gradient();
`

export const CoinWrapper = styled(Flexed)`
	border: 1px solid ${palette.sky_blue};
	background-color: ${palette.sky_blue};
	border-radius: 2.75rem;
	width: max-content;
	padding: 0 1rem;
	height: 3.625rem;
`
export default BuyCoins
