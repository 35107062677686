import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Card} from '../../pages/Dashboard'
import {palette} from '../../styled/colors'
import useRouter from '../../hooks/router'
import {Icons, ProfileImg} from '../TopNavBar'
import {FaArrowRight} from 'react-icons/fa'
import Button from '../common/Button'
import {loadStripe} from '@stripe/stripe-js'
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js'
import CheckoutPage from './checkout/CheckoutPage'
import moment, {duration} from 'moment'
import Input from '../common/input/Input'
import {api} from '../../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../../actions/authActions'
import {toast} from 'react-toastify'
import Loader from '../common/Loader'
import {useTranslation} from 'react-i18next'
import Footer from '../Footer'

const BMTPaymentSuccessful = () => {
	const router: any = useRouter()
	const [proInput, setProInput] = useState(1)
	const [proPlus, setProPlusInput] = useState(1)
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [cardOptions, setCardOptions] = useState<any>('')
	const [proMembership, setProMembership] = useState<any>('')
	const {t} = useTranslation()
	const success = router.query.success
	useEffect(() => {
		getAllMemberships()
		getUserProMemberships()
	}, [])

	useEffect(() => {
		if (cardOptions && proMembership) {
			const proInput = proMembership.find((el: any) => el.m_id === cardOptions['pro'][0].id)
			const proPlus = proMembership.find((el: any) => el.m_id === cardOptions['pro'][1].id)
			setProInput(proInput.price)
			setProPlusInput(proPlus.price)
		}
	}, [cardOptions, proMembership])

	const getUserProMemberships = async () => {
		const response = await api.get('/user/proMemberShip')
		if (response.data.status) {
			setProMembership(response.data.data)
		} else {
			toast.error(response.data.message)
		}
	}

	const getAllMemberships = async () => {
		setLoading(true)
		await api
			.get('/user/memberships')
			.then((res: any) => {
				if (res?.data) {
					setLoading(false)
					const filteredMemberships = res?.data?.data?.filter((membership: any) => membership.type == 'pro')

					const groupedMemberships = filteredMemberships.reduce((acc: any, membership: any) => {
						if (!acc[membership.type]) {
							acc[membership.type] = []
						}
						acc[membership.type].push(membership)
						return acc
					}, {})
					setCardOptions(groupedMemberships)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const handleProceed = async () => {
		let checkProDuration: any = localStorage.getItem('pro_duration') || 'annually'
		if (proInput == 0 || proPlus == 0) {
			toast.error(t('Please enter value greater than 0'))
			return
		}
		const data = [
			{
				m_id: cardOptions['pro'][0]?.id,
				coins: proInput ? proInput * 5 : 5,
				price: proInput ? proInput : 1,
				duration: checkProDuration
			},
			{
				m_id: cardOptions['pro'][1]?.id,
				coins: proPlus ? proPlus * 5 : 5,
				price: proPlus ? proPlus : 1,
				duration: checkProDuration
			}
		]
		setLoading(true)
		await api
			.put('/user/proMembership', {membership: data})
			.then((res: any) => {
				if (res.data?.status) {
					setLoading(false)
					dispatch(callAutoLogin(true))
					toast.success(res.data?.message)
					const redirect = sessionStorage.getItem('redirect_url')
					localStorage.removeItem('upgrade_to_pro')
					if (redirect) {
						router.navigate(redirect)
					} else {
						router.navigate('/bmt-membership-success')
					}
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				toast.error(error.message)
			})
	}

	return (
		<MainContainer>
			{loading && <Loader />}
			<Spacer height={6} />
			<Container fluid>
				<Card background={palette.white} padding={'1rem 1.2rem'} style={{overflow: 'hidden'}}>
					{!success ? (
						<Flexed direction="column" align={''} gap={'0'} padding={'0rem 0.5rem'}>
							<Flexed direction="row" align={'center'} gap={'0.6'}>
								<Icons
									src={'/images/icon_view.svg'}
									onClick={() => {
										!proMembership ? router.navigate('/upgrade-bmt-membership') : router.navigate(-1)
									}}
									pointer={'pointer'}
								/>
								<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
									{t('Set up your Pro Membership')}
								</Text>
							</Flexed>
						</Flexed>
					) : (
						<></>
					)}
					<Row alignItems="center">
						<Col lg={3} md={12} sm={12}></Col>
						<Col lg={6} md={12} sm={12}>
							<Spacer height={'1'} />
							<Flexed align="center" text-align="center" direction="column" gap={1}>
								{success ? (
									<>
										<SuccessImg src="/images/thankyou.png" alt="Success" />

										<Spacer height={'0.5'} />
										<Text fontWeight="700" fontSize="1.125" lineHeight="1.361" align="center" color={'text_color'}>
											{t('Your payment is Successfully Done')} !
										</Text>

										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													{t('Manage Membership')} &nbsp; <FaArrowRight />
												</>
											}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
											ifClicked={() => {
												router.navigate(['/dashboard'])
											}}
										/>
									</>
								) : (
									<>
										<Card background={palette.default_theme} minHeight={'3.71rem'} padding={'1rem'} style={{alignItems: 'center', display: 'flex'}}>
											<Flexed direction={'row'} align={'center'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
												<HandleFlex direction={'row'} align={'center'} gap={'0.7'} flexWrap={'wrap'} padding={'0 1rem'}>
													<Flexed>
														<Icons height={'43px'} width={'32px'} src={'/images/pro_badge.svg'} />
													</Flexed>
													<Spacer height={1} />
													<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
														<Flexed direction={'column'} gap={'0.5'} align={'start'}>
															<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
																{t('Pro Member')}
															</Text>
														</Flexed>
													</HandleFlex>
												</HandleFlex>

												{/* <HandleFlex direction="column" justify={'center'} align={'center'} gap={'1'} padding={'1rem'}>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={'white'}>
														{t('Setup your own Pro Membership Amount')}
													</Text>
												</HandleFlex> */}
											</Flexed>
										</Card>
										<Flexed align={'center'} padding={'0 1.5rem'} width={'100%'}>
											<Flexed align={'end'} gap={'0.2'} direction={'row'} width={'100%'} justify={'flex-end'} padding={'0.5rem 0.8rem'}>
												<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'500'} color={'zink_400'}>
													{t('Enter Amount')}
												</Text>
											</Flexed>

											<Card background={palette.primary_50} minHeight={'4rem'} radius={'8px'}>
												<Flexed align={'center'} padding={'0.5rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
													<Text fontWeight="700" fontSize="1" lineHeight="1.21" align="center" color={'text_color'}>
														{t('PRO')}
													</Text>
													<div style={{height: '2rem', borderLeft: `1px solid ${palette.blue_500}`, margin: '0 1rem'}}></div>
													<Flexed align={'center'} gap={'0.2'} direction={'row'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'600'} color={'text_color'}>
															<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
															&nbsp;{proInput * 5}
														</Text>
														<Text fontSize={'0.8'} lineHeight={'0.91'} fontWeight={'600'} color={'zink_400'}>
															{t('coins')}
														</Text>
													</Flexed>

													<div style={{height: '2rem', borderLeft: `1px solid ${palette.blue_500}`, margin: '0 1rem'}}></div>

													<Flexed align={'center'} gap={'0.2'} direction={'row'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'600'} color={'text_color'}>
															<Icons src={'/images/schedule.svg'} /> {cardOptions?.pro && cardOptions?.pro[0]?.time}
														</Text>
														<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'zink_400'}>
															{t('minutes')}
														</Text>
													</Flexed>

													<Flexed align={'center'} gap={'0.1'} justify={'center'} direction={'row'} style={{background: palette.white, borderRadius: '5px'}} width={'5.3rem'} height={'2.5rem'}>
														&nbsp;$
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
															<Input isContact allowOnlyNumbers min={1} onChange={(e: any) => setProInput(e?.value)} value={proInput} padding={'0.1rem'} />
														</Text>
													</Flexed>
												</Flexed>
											</Card>
										</Flexed>

										<Flexed align={'center'} padding={'0 1.5rem'} width={'100%'}>
											<Card background={palette.primary_50} minHeight={'4rem'} radius={'8px'}>
												<Flexed align={'center'} padding={'0.5rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
													<Text fontWeight="700" fontSize="1" lineHeight="1.21" align="center" color={'text_color'}>
														{t('PRO PLUS')}
													</Text>
													<div style={{height: '2rem', borderLeft: `1px solid ${palette.blue_500}`, margin: '0 1rem'}}></div>
													<Flexed align={'center'} gap={'0.2'} direction={'row'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'600'} color={'text_color'}>
															<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
															&nbsp;{proPlus * 5}
														</Text>
														<Text fontSize={'0.8'} lineHeight={'0.91'} fontWeight={'600'} color={'zink_400'}>
															{t('coins')}
														</Text>
													</Flexed>

													<div style={{height: '2rem', borderLeft: `1px solid ${palette.blue_500}`, margin: '0 1rem'}}></div>

													<Flexed align={'center'} gap={'0.2'} direction={'row'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'600'} color={'text_color'}>
															<Icons src={'/images/schedule.svg'} /> {cardOptions?.pro && cardOptions?.pro[1]?.time}
														</Text>
														<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'zink_400'}>
															{t('minutes')}
														</Text>
													</Flexed>

													<Flexed align={'center'} gap={'0.1'} justify={'center'} direction={'row'} style={{background: palette.white, borderRadius: '5px'}} width={'5.3rem'} height={'2.5rem'}>
														&nbsp;$
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
															<Input min={1} isContact allowOnlyNumbers onChange={(e: any) => setProPlusInput(e?.value)} value={proPlus} padding={'0.1rem'} />
														</Text>
													</Flexed>
												</Flexed>
											</Card>
										</Flexed>
										<Spacer height={'0.5'} />
										<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'dark_blue'} isCentered>
											Upon confirmation you are unable to change this setting until 2 weeks
										</Text>

										<Spacer height={'0.5'} />

										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													{t('PROCEED')} &nbsp; <FaArrowRight />
												</>
											}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
											ifClicked={() => {
												handleProceed()
											}}
										/>
									</>
								)}
							</Flexed>
						</Col>
						<Col lg={3} md={12} sm={12}></Col>
					</Row>
					<Spacer height={'3'} />
				</Card>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
	overflow: hidden;
`

export const CustomSmallScreenSpacer = styled(Spacer)<any>`
	${media.xs`
    display: flex;
    `}
	${media.sm`
    display: flex;

    `}
    ${media.md`

    display: none;
    `}
	${media.lg`
   display: none;
    `}
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
	text-align: center;
	align-items: center;
	justify-content:center;
	width: 100%;
	 `};
`

const ManageCardComponent = styled(Flexed)<any>`
	height: ${({height}) => height};
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
	text-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	 `};
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 50px;
		width: 37px;
	}
	${media.xs`
	display: content;
	width: 100%
	 `};
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const Meeting = styled(Flexed)`
	text-align: center;
	background-color: #66aaf921;
	border-radius: 10px;
	border: 1px solid #66aaf921;
	width: 28.25rem;
	height: 3.25rem;
`

const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
export default BMTPaymentSuccessful
