// Head over to the Credentials section in your app on CometChat Dashboard and where you can find your unique App ID, Region and Auth Key. 😊

export const AppConstants = {
	COMET_CHAT_APP_ID: '2579877281ac2ae3',
	COMET_CHAT_REGION: 'us',
	COMET_CHAT_AUTH_KEY: 'e8aafe79ffe1994e0ebc4ffe99068d49ae4bf3e1',
	COMET_CHAT_REST_API_KEY: '5991fb20e62064a573e03dd1be4b22a23ab658fe',
	PUSHER_APP_KEY: '0c18239c106f401af1b2',
	PUSHER_CLUSTER: 'ap2',
	server_url: 'https://api.justnetwork.tech',
	weather_api_key: 'ab83a2a46e945755945f0d94da2701e0',
	ANNUALLY_SUBSCRIPTION_ID: 'price_1Pieyd01fxlD4SQIS8tNpsUj',
	MONTHLY_SUBSCRIPTION_ID: 'price_1Piezh01fxlD4SQIkU1zo3Mg',
	agora_chat_app_key: '611144202#1329745',
	stripe_public_key: 'pk_live_51PLXIc01fxlD4SQI3795UStjvPCwKDr5qxGlPH0Qo8C7ghqbOAR48G6AvyRRFwzxLrRtoiPmoFM17z4m1qoQ3j1c00ntKCUXxZ'
}
