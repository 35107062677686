import React, {useEffect, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Spacer, Text} from '../styled/shared'
import {Col, Row} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import LinkedInPage from './common/LoginWithLinkedin'
import {Card} from './AuthPage'
import LogInGoogle from './common/LogInGoogle'
import Checkbox from './common/Checkbox'
import {FaArrowRight} from 'react-icons/fa'
import useRouter from '../hooks/router'
import {Dot} from '../pages/SignUp'
import {api} from '../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import Loader from './common/Loader'
import {callAutoLogin, networkUsers, saveUser, setNotification} from '../actions/authActions'
import {useTranslation} from 'react-i18next'
import {CometChatThemeContext} from '@cometchat/chat-uikit-react'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {AppConstants} from '../AppConstants'
import {useSelector} from 'react-redux'
import * as Sentry from '@sentry/react'
import AuthTerms from './common/AuthTerms'
import i18n from '../i18n/i18n'

const initialValues = {
	email: '',
	password: '',
	loginForWeek: false
}

const LoginForm = () => {
	const router = useRouter()
	const dispatch: any = useDispatch()
	const [loading, setLoading] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const {t} = useTranslation()

	useEffect(() => {
		initializeChat()
		if (router?.query?.error) {
			let error: any = router?.query?.error
			toast.error(decodeURIComponent(error))
		} else if (router?.query?.success) {
			let success: any = router?.query?.success
			toast.success(decodeURIComponent(success))
		}
	}, [router?.query?.error, router?.query?.success])

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
		password: Yup.string().min(8).required(t('Password is required'))
	})

	const initializeChat = async () => {
		try {
			await CometChatUIKit.logout()
		} catch (err) {
			console.log('Some Error Occured', err)
		}
	}

	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/login', values)
			.then(async (res: any) => {
				if (res?.data?.status && res?.data?.data?.user?.is_email_verified) {
					actions.setSubmitting(false)
					actions.resetForm()
					localStorage.setItem('user_lang', res?.data?.data?.user?.language)
					localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
					toast.success(res?.data?.message)
					dispatch(saveUser(res?.data?.data?.user))
					dispatch(networkUsers(res?.data?.data?.user_network))
					i18n.changeLanguage(res?.data?.data?.user?.language)
					localStorage.setItem('authenticated', 'true')
					dispatch(setNotification(true))
					await chatLogin(res)
				} else {
					if (res?.data?.data?.user) {
						setLoading(false)
						localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
						router.navigate(`/verify-otp?email=${values?.email}&isloginVerify=${true}`)
					}
					setLoading(false)
					Sentry.captureException(res?.data?.message)

					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				if (error?.message) {
					setLoading(false)
					toast.error(error?.message)
				}
			})
	}

	const chatLogin = async (res: any) => {
		if (res?.data?.data?.user?.chat_id) {
			const newUser = new CometChat.User(res?.data?.data?.user?.chat_id)
			console.log('=====newUser', newUser)
			if (newUser) {
				newUser.setName(res?.data?.data?.user?.full_name)
				newUser.setMetadata(res?.data?.data?.user)
				newUser.setAvatar(res?.data?.data?.user?.image_Url)
				try {
					let user = await CometChatUIKit.login(res?.data?.data?.user?.chat_id)
						?.then((loggedInUser) => {
							return loggedInUser
						})
						.catch((err) => {
							console.log('=====error at login', err)
							return null
						})

					if (user) {
						await CometChat.updateCurrentUserDetails(newUser).then(
							(user) => {
								console.log('updated user', user)
							},
							(error) => {
								console.log('error', error)
							}
						)
					}
					// if (!user) {
					// 	let chat_id = `${res?.data?.data?.user?.first_name?.replaceAll(' ', '')}_${Date.now()}`.toLowerCase()
					// 	const newUser = new CometChat.User(chat_id)
					// 	newUser.setName(res?.data?.data?.user?.full_name)
					// 	newUser.setMetadata(res?.data?.data?.user)
					// 	newUser.setAvatar(res?.data?.data?.user?.image_Url)
					// 	const createdUser = await CometChat.createUser(newUser, AppConstants.COMET_CHAT_AUTH_KEY)
					// 	console.log('======createdUser.getUid()', createdUser.getUid(), createdUser)
					// 	if (createdUser.getUid()) {
					// 		await api.post('/user/update', {chat_id})
					// 		dispatch(callAutoLogin(true))
					// 	}
					// }
				} catch (error) {
					console.log('error', error)
				}
			} else {
				// let chat_id = `${res?.data?.data?.user?.first_name?.replaceAll(' ', '')}_${Date.now()}`.toLowerCase()
				// const newUser = new CometChat.User(chat_id)
				// newUser.setName(res?.data?.data?.user?.full_name)
				// newUser.setMetadata(res?.data?.data?.user)
				// newUser.setAvatar(res?.data?.data?.user?.image_Url)
				// const createdUser = await CometChat.createUser(newUser, AppConstants.COMET_CHAT_AUTH_KEY)
				// console.log('======createdUser.getUid()', createdUser.getUid(), createdUser)
				// if (createdUser.getUid()) {
				// 	await api.post('/user/update', {chat_id})
				// 	dispatch(callAutoLogin(true))
				// }
			}
		} else {
			//add user in cometchat
			let chat_id = `${res?.data?.data?.user?.first_name.replaceAll(' ', '')}_${Date.now()}`.toLowerCase()
			const newUser = new CometChat.User(chat_id)
			newUser.setName(res?.data?.data?.user?.full_name)
			newUser.setAvatar(res?.data?.data?.user?.image_Url)
			newUser.setMetadata(res?.data?.data?.user)
			const createdUser = await CometChat.createUser(newUser, AppConstants.COMET_CHAT_AUTH_KEY)
			console.log('======createdUser.getUid()', createdUser.getUid(), createdUser)
			if (createdUser.getUid()) {
				await api.post('/user/update', {chat_id})
				dispatch(callAutoLogin(true))
			}
		}
	}

	useEffect(() => {
		if (userDetails.id) {
			if ((userDetails?.userMembership && userDetails?.userMembership.length) || (userDetails?.proMembership && userDetails?.proMembership.length)) {
				const redirect = sessionStorage.getItem('redirect_url')
				if (redirect) {
					// Navigate to the stored path
					router.navigate(redirect)
				} else {
					// Navigate to the default path if no stored path is found
					router.navigate('/dashboard')
				}
			} else {
				router.navigate(`/buy-membership?id=${userDetails?.id}`)
			}
		} else {
			sessionStorage.removeItem('sessionId')
			router.navigate('/login')
		}
	}, [userDetails?.id])

	return (
		<Row justifyContent="center">
			{loading && <Loader />}
			<Col xl={8} lg={8} md={8} sm={12} xs={10}>
				<Spacer height={1} />
				<Card marginBottom="1rem">
					<Flexed justify="center">
						<Flexed justify="center">
							<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
								{t('Sign in to your account')}
							</Text>
						</Flexed>
						<Spacer height={1.5} />
						<LinkedInPage isLogin={true} />
						<Spacer height={1} />
						<LogInGoogle isLogin={true} />
						<Spacer height={1} />
						{t('Or')}
						<Spacer height={1} />
						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
							{({isSubmitting, errors, setFieldValue, values, touched}) => (
								<Form>
									<Field
										component={Input}
										name="email"
										type="email"
										label={t('Email')}
										value={values.email ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('email', event?.value, true)
										}}
										error={touched.email && errors.email ? {message: errors.email ?? ''} : null}
									/>

									<Spacer height="1" />

									<Field
										component={Input}
										name="password"
										type="Password"
										label={t('Password')}
										value={values.password ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('password', event?.value, true)
										}}
										error={touched.password && errors.password ? {message: errors.password ?? ''} : null}
									/>
									<Spacer height="0.5" />
									<Text align={'right'} fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'500'} color={'blue_500'} onClick={() => router.navigate('/forgot-password')} pointer>
										{t('Forgot password?')}
									</Text>
									<Spacer height="1" />

									<Flexed direction={'row'} align={'center'} gap={'0.3'}>
										<Checkbox
											label={''}
											defaultChecked={values.loginForWeek}
											onChange={(event: any) => {
												setFieldValue('loginForWeek', event, true)
											}}
										/>
										<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'black'}>
											{t('Stay signed in for a week')}
										</Text>
									</Flexed>
									<Spacer height="1" />
									<Flexed direction={'row'} align={'center'} justify={'center'}>
										<Button
											small
											hasBorder
											disabled={isSubmitting}
											type="submit"
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													{t('CONTINUE TO SIGN IN')} &nbsp;
													<FaArrowRight />
												</>
											}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
										/>
									</Flexed>
								</Form>
							)}
						</Formik>
					</Flexed>
				</Card>
				<Flexed justify="center" align={'center'}>
					<Text fontSize={'0.9'} isCentered lineHeight={'1.25'} fontWeight={'400'} color={'black'}>
						{t('Don’t have an account?')}
					</Text>
					<Spacer height={0.5} />
					<Button
						small
						hasBorder
						type="submit"
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={t('CREATE AN ACCOUNT')}
						background={'transparent'}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => {
							router.navigate('/signup')
						}}
					/>
				</Flexed>
				<Spacer height={2} />
				<AuthTerms />
			</Col>
		</Row>
	)
}

export default LoginForm
