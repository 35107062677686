import styled from 'styled-components'
import {palette} from './colors'
import {media} from 'styled-bootstrap-grid'

interface DividerProps {
	isDarkTheme?: boolean
	margin?: string
}

const handleHeadingFontSize = (level: any) => {
	switch (level) {
		case 1:
			return '3.5rem'
		case 2:
			return '2.25rem'
		case 3:
			return '1.625rem'
		case 4:
			return '1.375rem'
		case 5:
			return '1.125rem'
		default:
			return '1rem'
	}
}

const handleHeadingLineHeight = (level: any) => {
	switch (level) {
		case 1:
			return '2.5rem'
		case 2:
			return '2.5rem'
		case 3:
			return 'normal'
		case 4:
			return '2rem'
		case 5:
			return '2rem'
		default:
			return 'normal'
	}
}

// Heading Levels
//             fontSize              LineHight
// level 1 56px || 3.5rem       .      	40px || 2.5rem
// level 2 36px || 2.25rem      .       40px || 2.5rem
// level 3 26px || 1.625rem     .            || normal
// level 4 22px || 1.375rem     .       32px || 2rem
// level 5 18px || 1.125rem     .       32px || 2rem
// level 6 16px || 1rem         .       	 || normal

export const Heading = styled.div<any>`
	text-transform: ${({textTransform}) => (textTransform ? textTransform : 'capitalize')};
	font-size: ${({level, fontSize}) => (fontSize ? `${fontSize}rem` : handleHeadingFontSize(level))};
	line-height: ${({level, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleHeadingLineHeight(level))};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.white : palette.black)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'inherit')};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 600)};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	letter-spacing: 0.02em;
`

const handleParagraphFontSize = (type: any) => {
	switch (type) {
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		default:
			return '0.875rem'
	}
}

const handleParagraphLineHeight = (type: any) => {
	switch (type) {
		case 'large':
			return '1.688rem'
		case 'medium':
			return '1.563rem'
		case 'normal':
			return '1.5rem'
		case 'small':
			return '1.125rem'
		default:
			return '1.125rem'
	}
}

// Paragraph Levels
//             fontSize              LineHight
// large    20px || 1.25rem     .      27px || 1.688rem
// medium   18px || 1.125rem    .      25px || 1.563rem
// normal   16px || 1rem        .      24px || 1.5rem
// small    14px || 0.875rem    .      18px || 1.125rem

export const Text = styled.div<any>`
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
	height: ${({height}) => (height ? height : 'auto')};
	font-style: ${({fontStyle}) => fontStyle};
	text-decoration: ${({textDecoration}) => textDecoration};
	align-self: 'end';
	display: ${({display}) => display};
	font-size: ${({type, fontSize}) => (fontSize ? `${fontSize}rem` : handleParagraphFontSize(type))};
	line-height: ${({type, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleParagraphLineHeight(type))};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.light_gray : palette.dark_gray)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered, align}) => (align ? align : isCentered ? `center` : 'left')};
	cursor: ${({pointer}) => (pointer ? `pointer !important` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	text-transform: ${({textTransform}) => (textTransform ? textTransform : '')};
	white-space: ${({whiteSpace}) => (whiteSpace ? whiteSpace : '')};
	overflow: ${({overflow}) => (overflow ? overflow : '')};
	text-overflow: ${({textOverflow}) => (textOverflow ? textOverflow : '')};
	width: ${({width}) => (width ? width : '')};
	letter-spacing: 0.02em;
	font: inter;
	box-shadow: ${({shadow}) => `${shadow}`};
	word-break: ${({wordBreak}) => (wordBreak ? 'break-word' : ``)};

	${media.lg`
	width: ${({isBmtLink, width}: any) => (isBmtLink ? '8rem' : width)};
	`};
	${media.xl`
	width: ${({isBmtLink, width}: any) => (isBmtLink ? '14rem' : width)};
	`};
	@media (max-width: 574px) {
		font-size: ${({social, fontSize, banner}) => `${social || banner ? '0.8rem' : fontSize}`}; /* Decrease width for smaller screens */
		width: ${({isBmtLink, width}: any) => (isBmtLink ? '14rem' : width)};
		text-align: ${({banner}: any) => banner && 'center !important'};
		padding: ${({banner}: any) => banner && '10px'};
	}
`

export const Paragraph = styled.p<any>`
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
	font-size: ${({type, fontSize}) => (fontSize ? `${fontSize}rem` : handleParagraphFontSize(type))};
	line-height: ${({type, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleParagraphLineHeight(type))};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.light_gray : palette.dark_gray)};
	margin: ${({margin}) => `${margin}`};
	padding: ${({padding}) => `${padding}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'left')};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	text-transform: ${({textTransform}) => (textTransform ? textTransform : '')};
	letter-spacing: 0.02em;
	font: inter;
`

export const Divider = styled.div<DividerProps>`
	height: 1px;
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	margin: ${({margin}) => `${margin}`};
`

export const Spacer = styled.div<any>`
	height: ${({height}) => `${height}rem`};
	cursor: ${({pointer}) => `${pointer}`};
`

export const VerticalSpacer = styled.span<any>`
	width: ${({width}) => `${width}rem`};
	display: inline-block;
`

export const Flexed = styled.div<any>`
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	border-radius: ${({radius}) => `${radius}`};
	padding: ${({padding}) => `${padding}`};
	gap: ${({gap}) => `${gap}rem`};
	background: ${({background}) => `${background}`};
	height: ${({height}) => `${height}`};
	min-height: ${({minHeight}) => `${minHeight}`};
	width: ${({width}) => `${width}`};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	flex-wrap: ${({flexWrap}) => `${flexWrap}`};
	border: ${({border}) => `${border}`};
	overflow: ${({overflow}) => `${overflow}`};

	@media (max-width: 1000px) {
		justify-content: ${({icons, justify}) => `${icons ? 'start' : justify}`}; /* Decrease width for smaller screens */
	}
	@media (max-width: 576px) {
		padding: ${({buyCoins}: any) => buyCoins && '1rem'};
	}
	@media (max-width: 576px) {
		gap: ${({buyCoins}: any) => buyCoins && '0.2rem'};
	}
	@media (max-width: 576px) {
		margin-bottom: ${({isMeetingModal}: any) => isMeetingModal && '10px'};
	}
	@media (max-width: 600px) {
		flex-direction: ${({bmtDetails}: any) => bmtDetails && 'column'};
	}
`

export const HorizontalRow = styled.div<any>`
	height: ${({height}) => `${height}rem`};
	border: 1px solid ${({borderColor}) => (borderColor ? borderColor : `${palette.blue_500}`)};
	margin: ${({margin}) => `${margin}rem`};
`
