import React, {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import styled from 'styled-components'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import {Card} from './AuthPage'
import Checkbox from './common/Checkbox'
import useRouter from '../hooks/router'
import {CustomSpacer, Dot} from '../pages/SignUp'
import VerificationInput from 'react-verification-input'
import {api} from '../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {callAutoLogin, saveUser, setNotification} from '../actions/authActions'
import Loader from './common/Loader'
import {useTranslation} from 'react-i18next'
import AuthTerms from './common/AuthTerms'
import OTPInput from './shared/OtpInput'

const initialValues = {
	verification_code: ''
}

const passwordInitialValues = {
	new_password: '',
	confirmPassword: '',
	loginForWeek: false
}

const OtpVerificationForm = () => {
	const [otpVerified, setOtpVerified] = useState<any>(false)
	const router = useRouter()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation()
	const [verificationCode, setVerificationCode] = useState<any>({})

	const validationSchema = Yup.object().shape({
		verification_code: Yup.string().min(6).required(t('Otp is required'))
	})

	const validationSchemaFOrPassword = Yup.object().shape({
		new_password: Yup.string().min(8).required(t('Password is required')),
		confirmPassword: Yup.string()
			.min(8)
			.oneOf([Yup.ref('new_password') ?? undefined, ''], t('Passwords must match'))
			.required(t('Confirm Password is required'))
	})
	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/verify_verification_code', {...values, email: router?.query?.email}, {headers: {Authorization: `Bearer ${router?.query?.tmpToken}`}})
			.then((res: any) => {
				if (res?.data?.status) {
					actions.setSubmitting(false)
					actions.resetForm()
					toast.success(res?.data?.message)
					setLoading(false)
					setOtpVerified(true)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const handleUpdateResetPassword = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/update_password', {...values, email: router?.query?.email}, {headers: {Authorization: `Bearer ${router?.query?.tmpToken}`}})
			.then((res: any) => {
				if (res?.data?.status) {
					actions.setSubmitting(false)
					actions.resetForm()
					setOtpVerified(true)
					setLoading(false)
					toast.success(res?.data?.message)
					dispatch(saveUser(res?.data?.data?.user))
					dispatch(callAutoLogin(true))
					dispatch(setNotification(true))
					localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
					localStorage.setItem('authenticated', 'true')
					if ((res?.data?.data?.user?.userMembership && res?.data?.data?.user?.userMembership.length) || (res?.data?.data?.user?.proMembership && res?.data?.data?.user?.proMembership.length)) {
						const redirect = sessionStorage.getItem('redirect_url')
						if (redirect) {
							// Navigate to the stored path
							router.navigate(redirect)
						} else {
							// Navigate to the default path if no stored path is found
							router.navigate('/dashboard')
						}
					} else {
						router.navigate(`/buy-membership?id=${res?.data?.data?.user?.id}`)
					}
					// router.navigate('/dashboard')
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				if (error?.message) {
					setLoading(false)
					toast.error(error?.message)
				}
			})
	}

	const handleOtp = async (values: any) => {
		setVerificationCode(values)
	}
	return (
		<>
			<Row justifyContent="center">
				{loading && <Loader />}
				<Col xl={8} lg={8} md={8} sm={12} xs={10}>
					<Spacer height={4} />
					<Card>
						{otpVerified ? (
							<Flexed justify="space-between" direction={'column'}>
								<Flexed justify="center">
									<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
										{t('Reset your password')}
									</Text>
									<Spacer height={1} />
								</Flexed>
								<Spacer height={1} />

								<Formik initialValues={passwordInitialValues} validationSchema={validationSchemaFOrPassword} onSubmit={handleUpdateResetPassword}>
									{({isSubmitting, errors, setFieldValue, values, touched}) => (
										<Form>
											<Spacer height={1} />
											<Field
												component={Input}
												name="new_password"
												type="Password"
												label={t('Password')}
												value={values.new_password ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('new_password', event?.value, true)
												}}
												error={touched.new_password && errors.new_password ? {message: errors.new_password ?? ''} : null}
											/>

											<Spacer height="1" />
											<Field
												component={Input}
												name="confirmPassword"
												type="Password"
												label={t('Confirm Password')}
												value={values.confirmPassword ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('confirmPassword', event?.value, true)
												}}
												error={touched.confirmPassword && errors.confirmPassword ? {message: errors.confirmPassword ?? ''} : null}
											/>
											<Spacer height="0.5" />
											<Flexed direction={'row'} align={'center'} gap={'0.3'}>
												<Checkbox
													label={''}
													defaultChecked={values.loginForWeek}
													onChange={(event: any) => {
														setFieldValue('loginForWeek', event, true)
													}}
												/>
												<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'black'}>
													{t('Stay signed in for a week')}
												</Text>
											</Flexed>
											<Spacer height="2" />

											<Flexed direction={'row'} align={'center'} justify={'center'}>
												<Button
													small
													hasBorder
													disabled={isSubmitting}
													type="submit"
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={<>{t('RESET PASSWORD')}</>}
													background={palette.blue_500}
													color={palette.white}
													lineHeight={'0.89rem'}
												/>
											</Flexed>
										</Form>
									)}
								</Formik>
							</Flexed>
						) : (
							<Flexed justify="space-between" direction={'column'}>
								<Flexed justify="center">
									<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
										{t('Enter your OTP')}
									</Text>
									<Spacer height={1} />
									{/* <Paragraph fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'}>
										{t("Enter the email address associated with your account and we'll send you a link to reset your password.")}
									</Paragraph> */}
									<Paragraph fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'}>
										{t('We have sent you an OTP to your registered email. Please verify and enter the OTP to proceed.')}
									</Paragraph>
								</Flexed>
								<Spacer height={1} />

								<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
									{({isSubmitting, errors, setFieldValue, values, touched}) => (
										<Form>
											<Spacer height={1} />
											<Flexed direction={'column'} aling={'center'}>
												<Flexed justify={'center'} direction={'row'} aling={'center'}>
													<OTPInput
														onChange={(value: any) => {
															console.log('======value', value)
															setFieldValue('verification_code', value?.verification_code)
															setVerificationCode(value)
														}}
														length={6}
														onComplete={(e) => {
															handleOtp(e)
															setFieldValue('verification_code', e?.verification_code)
														}}
													/>
													{/* <VerificationInput
														autoFocus={true}
														validChars="0-9"
														classNames={{
															character: 'character'
														}}
														onChange={(e: any) => {
															setFieldValue('verification_code', e)
														}}
														value={values.verification_code}
													/> */}
												</Flexed>
												<Flexed justify={'flex-start'} direction={'row'} padding={'0.5rem 0'}>
													{touched.verification_code && errors.verification_code ? (
														<Text fontSize={0.625} type="small" color="danger" align={'start'}>
															{errors?.verification_code}
														</Text>
													) : (
														''
													)}
												</Flexed>
											</Flexed>

											<Spacer height="1" />

											<Flexed direction={'row'} align={'center'} justify={'center'}>
												<Button
													small
													hasBorder
													disabled={isSubmitting}
													type="submit"
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={<>{t('RESET PASSWORD')}</>}
													background={palette.blue_500}
													color={palette.white}
													lineHeight={'0.89rem'}
												/>
											</Flexed>
										</Form>
									)}
								</Formik>
								<Spacer height="1" />
								<Flexed direction={'row'} align={'center'} justify={'center'}>
									<Button
										small
										hasBorder
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										label={<>{t('CHANGE EMAIL')}</>}
										background={'transparent'}
										color={palette.blue_500}
										lineHeight={'0.89rem'}
										ifClicked={() => router.navigate('/forgot-password')}
									/>
								</Flexed>
							</Flexed>
						)}

						<Spacer height={2} />
					</Card>

					<Spacer height={2} />
					<CustomSpacer height={2} />
					<AuthTerms />
				</Col>
			</Row>
		</>
	)
}

const NameFieldsFlex = styled(Flexed)`
	${media.xs`
flex-direction: column;
    `}
	${media.sm`
	flex-direction: column;

    `}
    ${media.md`
	flex-direction: row;

    `}
	${media.lg`
	flex-direction: row;

    `}
`

export default OtpVerificationForm
