import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {Icons, ProfileImg} from '../components/TopNavBar'
import Input from '../components/common/input/Input'
import {Dot} from './SignUp'
import useRouter from '../hooks/router'
import moment from 'moment-timezone'
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import {types} from '../utils/events'
import {BiVideo} from 'react-icons/bi'
import CalendarDatePicker from '../components/common/CalendarDatePicker'
import MultiSelect from '../components/common/MultiSelect'
import {api} from '../helpers/auth-axios'
import Loader from '../components/common/Loader'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {AppConstants} from '../AppConstants'
import MeetingDetailsModel from '../components/modals/MeetingDetailsModel'
import InputField from '../components/common/InputField'
import {subscribeToChannel} from '../service/pusherService'
import Footer from '../components/Footer'
import {MdOutlineLooks} from 'react-icons/md'
import Button from '../components/common/Button'
import {BsCalendar2Plus} from 'react-icons/bs'

const localizer = momentLocalizer(moment)

const CalendarPage = () => {
	const router = useRouter()
	const getUserStatus: any = localStorage.getItem('isNewUser')
	const [currentView, setCurrentView] = useState('month')
	const [selectedDate, setSelectedDate] = useState<any>(moment())
	const [newEventClick, setNewEventClick] = useState<any>(false)
	const [eventsOfTheDay, setEventsOfTheDay] = useState<any[]>([])
	const [tmpEvents, setTmpEvents] = useState<any[]>([])
	const [loading, setLoading] = useState<any>(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [workingHour, setWorkingHour] = useState<any[]>([])
	const {t} = useTranslation()
	const userTimeZone = userDetails?.timezone ? userDetails?.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
	const [weatherData, setWeatherData] = useState<any | null>(null)
	const [location, setLocation] = useState<any>(null)
	const [error, setError] = useState<string | null>(null)
	const [openMeetingModel, setOpenMeetingModel] = useState(false)
	const [meetingDetails, setMeetingDetails] = useState<any>('')
	const [query, setQuery] = useState<any>('')
	const [selectedMeeting, setSelectedMeeting] = useState<any>('')
	const [isVisible, setIsVisible] = useState(false)

	const toggleDropdown = () => {
		setIsVisible((prev) => !prev)
	}

	const handleMouseEnter = () => {
		setIsVisible(true)
	}

	const handleMouseLeave = () => {
		setIsVisible(false)
	}
	// const defaultTimezone = userDetails?.timezone ? userDetails?.timezone : userTimeZone // Replace with your desired timezone
	// moment.tz.setDefault(defaultTimezone)

	useEffect(() => {
		if (router?.query?.error) {
			let error: any = router?.query
			toast.error(t(error?.error))
			router.setParams('')
		}
	}, [router?.query?.error])
	useEffect(() => {
		const unsubscribe = subscribeToChannel('MEETING', `ADD_MEETING_${userDetails.id}`, (data: any) => {
			if (currentView == 'day') {
				getDailyMeetings(moment(selectedDate).format(), false)
			} else if (currentView == 'week') {
				getWeeklyMeetings(moment(selectedDate).format(), false)
			} else if (currentView == 'month') {
				getMonthlyMeetings(moment(selectedDate).format(), false)
			} else if (currentView == 'agenda') {
				getMonthlyMeetings(moment(selectedDate).format(), false)
			}
		})

		const acceptMeeting = subscribeToChannel('MEETING', `ACCEPT_MEETING_${userDetails?.id}`, async (data: any) => {
			if (openMeetingModel) {
				setMeetingDetails({...data?.meeting, meetingFrom: meetingDetails?.meetingFrom, meetingTo: meetingDetails?.meetingTo})
			} else {
				if (currentView == 'day') {
					getDailyMeetings(moment(selectedDate).format(), false)
				} else if (currentView == 'week') {
					getWeeklyMeetings(moment(selectedDate).format(), false)
				} else if (currentView == 'month') {
					getMonthlyMeetings(moment(selectedDate).format(), false)
				} else if (currentView == 'agenda') {
					getMonthlyMeetings(moment(selectedDate).format(), false)
				}
			}
		})

		const declineMeeting = subscribeToChannel('MEETING', `DECLINE_MEETING_${userDetails?.id}`, async (data: any) => {
			if (openMeetingModel) {
				setMeetingDetails({...data?.meeting, meetingFrom: meetingDetails?.meetingFrom, meetingTo: meetingDetails?.meetingTo})
			} else {
				if (currentView == 'day') {
					getDailyMeetings(moment(selectedDate).format(), false)
				} else if (currentView == 'week') {
					getWeeklyMeetings(moment(selectedDate).format(), false)
				} else if (currentView == 'month') {
					getMonthlyMeetings(moment(selectedDate).format(), false)
				} else if (currentView == 'agenda') {
					getMonthlyMeetings(moment(selectedDate).format(), false)
				}
			}
		})

		return () => {
			unsubscribe()
			acceptMeeting()
			declineMeeting()
		}
	}, [userDetails, currentView])

	useEffect(() => {
		if (currentView == 'day') {
			getDailyMeetings(moment(selectedDate).format())
		} else if (currentView == 'week') {
			getWeeklyMeetings(moment(selectedDate).format())
		} else if (currentView == 'month') {
			getMonthlyMeetings(moment(selectedDate).format())
		} else if (currentView == 'agenda') {
			getMonthlyMeetings(moment(selectedDate).format())
		}
	}, [userDetails, currentView, selectedDate])

	useEffect(() => {
		if (router.query?.id && router.query?.isShare && userDetails) {
			getMeetingById(router.query?.id)
		} else if (selectedMeeting) {
			getMeetingById(selectedMeeting)
		}
	}, [router?.query?.id, selectedMeeting])

	useEffect(() => {
		// Check for Geolocation support
		if (!navigator.geolocation) {
			setError(t('Geolocation is not supported by your browser'))
			return
		}

		// Request user's location
		navigator.geolocation.getCurrentPosition(
			(position) => {
				setLocation({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				})
			},
			(err) => {
				setError(`${t('Error retrieving location:')} ${err.message}`)
			}
		)
	}, [])

	useEffect(() => {
		setWorkingHour([])
		const getWeatherData = async () => {
			const dates = Object.keys(groupEvents(eventsOfTheDay)).sort((a, b) => moment(a).diff(moment(b)))
			const data = await fetchWeatherData(dates, userDetails.city, location)
			if (data) {
				setWeatherData((prevWeatherData: any) => {
					const weatherByDate: any = {...prevWeatherData} // Copy previous state

					data.list.forEach((item: any) => {
						const date = moment(item.dt_txt).format('YYYY-MM-DD')
						if (!weatherByDate[date]) {
							weatherByDate[date] = {minTemp: item.main.temp_min, maxTemp: item.main.temp_max}
						} else {
							weatherByDate[date].minTemp = Math.min(weatherByDate[date].minTemp, item.main.temp_min)
							weatherByDate[date].maxTemp = Math.max(weatherByDate[date].maxTemp, item.main.temp_max)
						}
					})

					return weatherByDate // Return the updated state
				})
			}
		}
		getWeatherData()
	}, [userDetails, location])

	useEffect(() => {
		const saveCurrentDateWeather = async () => {
			const currentDate = moment().format('YYYY-MM-DD')
			const weather = weatherData ? weatherData[currentDate] : null
			if (weather) {
				await api.post('/meeting/weather', {
					weather: JSON.stringify(weather),
					timezone: userTimeZone,
					date: currentDate,
					u_id: userDetails.id
				})
			}
		}

		const getWeatherData = async () => {
			const response = await api.get('/meeting/get/weather')
			if (response.data?.status && response.data?.data.length) {
				const previousDates = response.data.data
				const savedWeatherData: any = {}
				previousDates.forEach((el: {date: string; weather: any}) => {
					savedWeatherData[moment(el.date).format('YYYY-MM-DD')] = JSON.parse(el.weather)
				})
				setWeatherData((prevData: any) => ({
					...prevData,
					...savedWeatherData
				}))
			}
		}

		if (weatherData) {
			saveCurrentDateWeather()
		}
		getWeatherData()
	}, [])

	const getMeetingById = async (id?: any, loading = true) => {
		setLoading(loading)
		await api
			.get(`/meeting/get_single_meeting?id=${id}&isShare=${true}`)
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					setMeetingDetails(res?.data?.data)
					setOpenMeetingModel(true)
				} else {
					router.replace('/calendar')
					toast.warning(t('Meeting not found'))
				}
				setLoading(false)
			})
			.catch(() => {
				toast.warning(t('Meeting not found'))
			})
	}

	useEffect(() => {
		if (query?.trim()?.length) {
			// filterMeetings()
		} else if (query?.trim()?.length == 0) {
			setEventsOfTheDay(tmpEvents)
		}
	}, [query])

	//get daily meetings
	const getDailyMeetings = async (date = moment().format(), loading = true) => {
		setLoading(loading)
		await api
			.post('/meeting/daily_meetings', {date: date, timezone: userTimeZone})
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data?.length) {
						let data = res?.data?.data?.map((e: any) => {
							let start: any = moment
								.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)
							let end = moment
								.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)

							let _start = e?.event_without_time
								? moment(e.event_without_time_start_date, 'YYYY-MM-DD')
								: e?.is_outlook
								? moment.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time).tz(userDetails?.timezone)
								: moment.tz(e.confirmation_start_time ? e.confirmation_start_time : e.start_time, userDetails?.timezone).utc()
							let _end = e?.event_without_time
								? moment(e.event_without_time_end_date, 'YYYY-MM-DD')
								: e?.is_outlook
								? moment.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time).tz(userDetails?.timezone)
								: moment.tz(e.confirmation_end_time ? e.confirmation_end_time : e.end_time, userDetails?.timezone).utc()
							return {
								...e,
								start: !e?.google_meeting_id
									? start.toDate()
									: e?.event_without_time
									? new Date(_start.year(), _start.month(), _start.date())
									: new Date(_start.year(), _start.month(), _start.date(), _start.hour(), _start.minute(), _start.second(), _start.millisecond()),
								end: !e?.google_meeting_id
									? end.toDate()
									: e?.event_without_time
									? new Date(_end.year(), _end.month(), _end.date())
									: new Date(_end.year(), _end.month(), _end.date(), _end.hour(), _end.minute(), _end.second(), _end.millisecond())
							}
						})

						setTmpEvents(data)
						setEventsOfTheDay(data)
					} else {
						toast.warn(t('No Meeting available in this range'))
						setEventsOfTheDay([])
					}
				}
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
				console.log('====error', error)
			})
	}

	//get weekly meetings
	const getWeeklyMeetings = async (date = moment().format(), loading = true) => {
		setLoading(loading)
		await api
			.post('/meeting/weekly_meetings', {date: date, timezone: userTimeZone})
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data?.length) {
						let data = res?.data?.data?.map((e: any) => {
							let start: any = moment
								.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)
							let end = moment
								.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)

							let _start = e?.event_without_time
								? moment(e.event_without_time_start_date, 'YYYY-MM-DD')
								: e?.is_outlook
								? moment.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time).tz(userDetails?.timezone)
								: moment.tz(e.confirmation_start_time ? e.confirmation_start_time : e.start_time, userDetails?.timezone).utc()
							let _end = e?.event_without_time
								? moment(e.event_without_time_end_date, 'YYYY-MM-DD')
								: e?.is_outlook
								? moment.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time).tz(userDetails?.timezone)
								: moment.tz(e.confirmation_end_time ? e.confirmation_end_time : e.end_time, userDetails?.timezone).utc()
							return {
								...e,
								start: !e?.google_meeting_id
									? start.toDate()
									: e?.event_without_time
									? new Date(_start.year(), _start.month(), _start.date())
									: new Date(_start.year(), _start.month(), _start.date(), _start.hour(), _start.minute(), _start.second(), _start.millisecond()),
								end: !e?.google_meeting_id
									? end.toDate()
									: e?.event_without_time
									? new Date(_end.year(), _end.month(), _end.date())
									: new Date(_end.year(), _end.month(), _end.date(), _end.hour(), _end.minute(), _end.second(), _end.millisecond())
							}
						})

						setTmpEvents(data)
						setEventsOfTheDay(data)
					} else {
						toast.warn(t('No Meeting available in this range'))
						setEventsOfTheDay([])
					}
				}
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
				console.log('====error', error)
			})
	}

	//get monthly meetings
	const getMonthlyMeetings = async (date = moment().format(), loading = true) => {
		setLoading(loading)
		await api
			.post('/meeting/monthly_meetings', {date: date, timezone: userTimeZone})
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data?.length) {
						let data = res?.data?.data?.map((e: any) => {
							let start: any = moment
								.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)
							let end = moment
								.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)

							let _start = e?.event_without_time
								? moment(e.event_without_time_start_date, 'YYYY-MM-DD')
								: e?.is_outlook
								? moment.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time).tz(userDetails?.timezone)
								: moment.tz(e.confirmation_start_time ? e.confirmation_start_time : e.start_time, userDetails?.timezone).utc()
							let _end = e?.event_without_time
								? moment(e.event_without_time_end_date, 'YYYY-MM-DD')
								: e?.is_outlook
								? moment.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time).tz(userDetails?.timezone)
								: moment.tz(e.confirmation_end_time ? e.confirmation_end_time : e.end_time, userDetails?.timezone).utc()
							return {
								...e,
								start: !e?.google_meeting_id
									? start.toDate()
									: e?.event_without_time
									? new Date(_start.year(), _start.month(), _start.date())
									: new Date(_start.year(), _start.month(), _start.date(), _start.hour(), _start.minute(), _start.second(), _start.millisecond()),
								end: !e?.google_meeting_id
									? end.toDate()
									: e?.event_without_time
									? new Date(_end.year(), _end.month(), _end.date())
									: new Date(_end.year(), _end.month(), _end.date(), _end.hour(), _end.minute(), _end.second(), _end.millisecond())
							}
						})

						setTmpEvents(data)
						setEventsOfTheDay(data)
					} else {
						toast.warn(t('No Meeting available in this range'))
						setEventsOfTheDay([])
					}
				}
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
				console.log('====error', error)
			})
	}

	const CustomToolbar = (toolbar: any) => {
		const goToBack = () => {
			toolbar.onNavigate('PREV')
			setSelectedDate(moment(toolbar?.date).subtract(1, toolbar?.view == 'agenda' ? 'month' : toolbar?.view))
		}

		const goToNext = () => {
			toolbar.onNavigate('NEXT')
			setSelectedDate(moment(toolbar?.date).add(1, toolbar?.view == 'agenda' ? 'month' : toolbar?.view))
		}

		const goToToday = () => {
			toolbar.onNavigate('TODAY')
			setSelectedDate(moment())
		}

		return (
			<>
				<Flexed className="rbc-toolbar" direction={'row'} justify={'space-between !important'} width={'100%'}>
					<Flexed className="rbc-btn-group" gap={'0.09'} direction="row" border={'none !important'} align="center">
						<Flexed onClick={goToBack} pointer>
							<Icons pointer src={'/images/Icon_button_prev.svg'} />
						</Flexed>

						<Flexed background={palette?.calendar_header_bg} padding={'0.36rem'} onClick={goToToday} pointer>
							<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'calendar_header_color'}>
								{t('Today')}
							</Text>
						</Flexed>
						<Flexed onClick={goToNext} pointer>
							<Icons pointer src={'/images/Icon_button_next.svg'} />
						</Flexed>
					</Flexed>
					<Flexed className="rbc-btn-group" direction="row" border={'none !important'} align="center">
						<Flexed
							onClick={(e: any) => {
								toolbar.onView('day')
								setCurrentView('day')
							}}
							padding={'4px 16px 4px 16px'}
							radius={'0.5rem'}
							pointer
							background={toolbar?.view == 'day' ? palette?.red_600 : ''}>
							<Text fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'500'} color={toolbar?.view == 'day' ? 'white' : 'input_text_color'}>
								{t('Day')}
							</Text>
						</Flexed>

						<Flexed
							background={toolbar?.view == 'week' ? palette?.red_600 : ''}
							padding={'4px 16px 4px 16px'}
							radius={'0.5rem'}
							onClick={(e: any) => {
								toolbar.onView('week')
								setCurrentView('week')
							}}
							pointer>
							<Text fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'500'} color={toolbar?.view == 'week' ? 'white' : 'input_text_color'}>
								{t('Week')}
							</Text>
						</Flexed>
						<Flexed
							pointer
							onClick={(e: any) => {
								toolbar.onView('month')
								setCurrentView('month')
							}}
							padding={'4px 16px 4px 16px'}
							radius={'0.5rem'}
							background={toolbar?.view == 'month' ? palette?.red_600 : ''}>
							<Text fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'500'} color={toolbar?.view == 'month' ? 'white' : 'input_text_color'}>
								{t('Month')}
							</Text>
						</Flexed>
						<Flexed
							pointer
							onClick={(e: any) => {
								toolbar.onView('agenda')
								setCurrentView('agenda')
							}}
							padding={'4px 16px 4px 16px'}
							radius={'0.5rem'}
							background={toolbar?.view == 'agenda' ? palette?.red_600 : ''}>
							<Text fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'500'} color={toolbar?.view == 'agenda' ? 'white' : 'input_text_color'}>
								{t('Year')}
							</Text>
						</Flexed>
					</Flexed>

					<Flexed style={{opacity: '0'}}>
						<Input isSearch isSignup isContact label={t('search')} value={query} onChange={(e: any) => setQuery(e?.value)} />
					</Flexed>
				</Flexed>
				<Spacer height={0.5} />
			</>
		)
	}

	const CustomHeader = (props: any) => {
		let splitLabel = props?.label?.split(' ')
		return (
			<>
				{splitLabel?.length == 2 ? (
					<Flexed padding={'0.36rem'} pointer>
						<Text fontSize={'0.7'} lineHeight={'0.8'} fontWeight={'700'} color={'input_text_color'}>
							{splitLabel[1]}
						</Text>
						<Text fontSize={'1.37'} lineHeight={'2'} fontWeight={'500'} color={'black'}>
							{splitLabel[0]}
						</Text>
					</Flexed>
				) : (
					<Flexed padding={'0.36rem'} pointer>
						<Text fontSize={'1.25'} lineHeight={'2'} fontWeight={'500'} color={'black'} isCentered>
							{splitLabel[0]}
						</Text>
					</Flexed>
				)}
				<Spacer height={0.5} />
			</>
		)
	}

	const EventComponent = ({event, children}: any) => {
		return event?.type == 'workingHour' ? (
			<Flexed padding={'0'} pointer direction={'row'} gap={'0.2'} align={'cennter'}>
				<Text fontSize={'0.6'} lineHeight={'0.96'} fontWeight={'400'} color={event?.color}>
					{event?.title}
				</Text>
				<Text fontSize={'0.6'} lineHeight={'0.96'} fontWeight={'400'} color={event?.color}>
					{moment(event?.start).format('h:mm a')}
				</Text>
			</Flexed>
		) : (
			<Flexed padding={'0'} pointer direction={'column'} gap={'0'} align={'cennter'}>
				<Flexed padding={'0'} pointer direction={'row'} gap={'0.4'} align={'cennter'}>
					<Text
						fontSize={'0.73'}
						lineHeight={'0.96'}
						fontWeight={'600'}
						color={
							types.meeting.toLowerCase() == event?.type
								? 'light_blue_700'
								: types.meal.toLowerCase() == event?.type
								? 'voilet_700'
								: types.presentation.toLowerCase() == event?.type
								? 'amber_700'
								: types.conference.toLowerCase() == event?.type
								? 'emeraled_700'
								: types.happy_hour.toLowerCase() == event?.type
								? 'rose_700'
								: 'light_blue_700'
						}>
						{event?.event_without_time ? '' : moment(event?.start).format('hh:mm A')}
					</Text>
					{event?.is_video && !event?.event_without_time ? (
						<Flexed
							// onClick={() => {
							// 	handleVideoClick()
							// }}
							height={'0.9rem'}
							width={'0.9rem'}
							padding={'0.1rem'}
							radius={'100%'}
							justify="center"
							direction={'row'}
							align={'center'}
							background={
								types.meeting.toLowerCase() == event?.type
									? palette.light_blue_700
									: types.meal.toLowerCase() == event?.type
									? palette.voilet_700
									: types.presentation.toLowerCase() == event?.type
									? palette.amber_700
									: types.conference.toLowerCase() == event?.type
									? palette.emeraled_700
									: types.happy_hour.toLowerCase() == event?.type
									? palette.rose_700
									: palette.light_blue_700
							}>
							<BiVideo color="white" />
						</Flexed>
					) : (
						''
					)}
				</Flexed>
				{currentView == 'week' && event?.duration <= 50 && !event?.event_without_time ? (
					''
				) : (
					<Text
						fontSize={'0.73'}
						lineHeight={'0.96'}
						fontWeight={'600'}
						color={
							types.meeting.toLowerCase() == event?.type
								? 'light_blue_700'
								: types.meal.toLowerCase() == event?.type
								? 'voilet_700'
								: types.presentation.toLowerCase() == event?.type
								? 'amber_700'
								: types.conference.toLowerCase() == event?.type
								? 'emeraled_700'
								: types.happy_hour.toLowerCase() == event?.type
								? 'rose_700'
								: 'light_blue_700'
						}>
						{event?.title}
					</Text>
				)}
			</Flexed>
		)
	}

	// Function to group events by date and type
	// const groupEvents = (events: any[]) => {
	// 	const groupedEvents: any = {}

	// 	// Group events by date
	// 	events.forEach((event) => {
	// 		const date = moment(event.start).format('YYYY-MM-DD')

	// 		if (!groupedEvents[date]) {
	// 			groupedEvents[date] = []
	// 		}

	// 		groupedEvents[date].push(event)
	// 	})

	// 	return groupedEvents
	// }

	interface Event {
		start: string
	}

	const groupEvents = (events: Event[]): {[date: string]: Event[]} => {
		const groupedEvents: {[date: string]: Event[]} = {}
		events.forEach((event) => {
			const date = moment(event.start).format('YYYY-MM-DD')

			if (!groupedEvents[date]) {
				groupedEvents[date] = []
			}

			groupedEvents[date].push(event)
		})

		Object.keys(groupedEvents).forEach((date) => {
			groupedEvents[date].sort((a, b) => moment(b.start).diff(moment(a.start)))
		})

		return groupedEvents
	}

	const getRelativeDateLabel = (date: string) => {
		const today = moment().startOf('day')
		const targetDate = moment(date).startOf('day')

		const diffInDays = targetDate.diff(today, 'days')

		if (diffInDays === 0) {
			return t('Today')
		} else if (diffInDays === 1) {
			return t('Tomorrow')
		} else if (diffInDays === -1) {
			return t('Yesterday')
		} else {
			return targetDate.format('dddd') // Returns day name (e.g., "Monday", "Tuesday", etc.)
		}
	}

	const fetchWeatherData = async (dates: string[], userLocation: string, currentLocation: any) => {
		const apiKey = AppConstants.weather_api_key
		if (currentLocation?.latitude) {
			const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${currentLocation?.latitude}&lon=${currentLocation?.longitude}&appid=${apiKey}&units=metric`

			try {
				const response = await fetch(url)
				if (!response.ok) {
					throw new Error(t('Network response was not ok'))
				}
				const data = await response.json()
				return data
			} catch (error) {
				console.error(t('Error fetching weather data:'), error)
				return null
			}
		}
	}

	const renderSidebarEvents = (groupedEvents: any, weatherData: any) => {
		const sortedDates = Object.keys(groupedEvents).sort((a, b) => moment(a).diff(moment(b)))

		return sortedDates.map((date, i: any) => {
			const weatherForDate = weatherData && weatherData[date]
			return (
				<div key={date}>
					<Flexed padding={'0.5rem'} direction={'row'} justify={'space-between'}>
						<Flexed direction={'row'} align={'center'} gap={'0.2'}>
							<Text fontSize={'0.74'} lineHeight={'1.15'} fontWeight={'700'} color={'white_70'}>
								{t(getRelativeDateLabel(date))}
							</Text>
							<Text fontSize={'0.74'} lineHeight={'1.15'} fontWeight={'400'} color={'white_70'}>
								{moment(date).format('M/DD/YYYY')}
							</Text>
						</Flexed>
						<Flexed direction={'row'} align={'center'} gap={'0.2'}>
							<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'white_70'}>
								{weatherForDate ? `${weatherForDate.maxTemp}º/${weatherForDate.minTemp}º` : 'N/A'}
							</Text>

							<Icons src={'/images/sun.svg'} />
						</Flexed>
					</Flexed>

					{i == 0 ? (
						<Flexed direction={'row'} align={'left'} gap={'0.5'} background={palette?.purple_500} padding={'0.1rem 0.3rem'} radius={'5.53px'} width={'80%'}>
							<Text fontSize={'0.89'} lineHeight={'1.15'} fontWeight={'500'} color={'white'}>
								{t('All-Hands Company Meeting')}
							</Text>
						</Flexed>
					) : (
						''
					)}
					{groupedEvents[date].map((event: any, index: any) => (
						<Flexed key={index} padding={'0.5rem'} direction={'row'} justify={'space-between'}>
							<Flexed direction={'row'} align={'left'} gap={'0.5'}>
								<Dot
									height={'0.69rem'}
									width={'0.69rem'}
									bg={
										types.meeting.toLowerCase() == event?.type
											? palette.light_blue_700
											: types.meal.toLowerCase() == event?.type
											? palette.voilet_700
											: types.presentation.toLowerCase() == event?.type
											? palette.amber_700
											: types.conference.toLowerCase() == event?.type
											? palette.emeraled_700
											: types.happy_hour.toLowerCase() == event?.type
											? palette.rose_700
											: palette.light_blue_700
									}
								/>
								<Flexed direction={'column'} align={'left'} gap={'0.3'}>
									<Text fontSize={'0.63'} lineHeight={'0.95'} fontWeight={'600'} color={'zink_400'}>
										{moment(event?.start).format('hh:mm A')} - {event?.event_without_time ? moment(event?.end).endOf('day').format('hh:mm A') : moment(event?.end).format('hh:mm A')}
									</Text>

									<Flexed
										direction={'row'}
										align={'left'}
										gap={'0.5'}
										background={event?.type == 'vacation' ? palette?.green_500 : ''}
										padding={event?.type == 'vacation' ? '0.1rem 0.3rem' : ''}
										radius={event?.type == 'vacation' ? '5.53px' : ''}
										pointer
										onClick={() => {
											setMeetingDetails(event)
											setOpenMeetingModel(true)
										}}>
										<Text fontSize={'0.69'} lineHeight={'0.92'} fontWeight={'500'} color={'white'}>
											{event.title}
										</Text>
										{event?.is_video ? (
											<Flexed height={'0.9rem'} width={'0.9rem'} padding={'0.1rem'} radius={'100%'} justify="center" direction={'row'} align={'center'} background={palette.zink_400}>
												<BiVideo color="black" />
											</Flexed>
										) : (
											''
										)}
									</Flexed>

									<Text fontSize={'0.63'} lineHeight={'0.95'} fontWeight={'600'} color={'zink_400'}>
										{event.zoom_link}
									</Text>
								</Flexed>
							</Flexed>
						</Flexed>
					))}
				</div>
			)
		})
	}

	const renderSidebarNewEvent = (groupedEvents: any, selectedDate: string) => {
		const today = moment(selectedDate).format('YYYY-MM-DD')
		const sortedDates = Object.keys(groupedEvents).sort((a, b) => moment(a).diff(moment(b)))
		const weatherForCurrentDate = weatherData && weatherData[today]

		return (
			<Flexed direction={'column'} justify={'space-between'}>
				{sortedDates.map((date, i: any) => {
					if (date !== today && date !== selectedDate) {
						return null
					}

					return (
						<div key={date}>
							<Flexed padding={'0.5rem'} direction={'row'} justify={'space-between'}>
								<Flexed direction={'row'} align={'center'} gap={'0.2'}>
									<Text fontSize={'0.74'} lineHeight={'1.15'} fontWeight={'700'} color={'white_70'}>
										{getRelativeDateLabel(date)}
									</Text>
									<Text fontSize={'0.74'} lineHeight={'1.15'} fontWeight={'400'} color={'white_70'}>
										{moment(date).format('M/DD/YYYY')}
									</Text>
								</Flexed>
								<Flexed direction={'row'} align={'center'} gap={'0.2'}>
									<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'white_70'}>
										{weatherForCurrentDate ? `${weatherForCurrentDate.maxTemp}º/${weatherForCurrentDate.minTemp}º` : 'N/A'}
									</Text>

									<Icons src={'/images/sun.svg'} />
								</Flexed>
							</Flexed>

							<Flexed padding={'0.3rem 0.5rem'}>
								<Flexed direction={'row'} align={'left'} gap={'0.5'} background={palette?.purple_500} padding={'0.1rem 0.3rem'} radius={'5.53px'} width={'80%'}>
									<Text fontSize={'0.89'} lineHeight={'1.15'} fontWeight={'500'} color={'white'}>
										{t('All-Hands Company Meeting')}
									</Text>
								</Flexed>
							</Flexed>

							{/* Render today's meetings or selected date's meetings */}
							{groupedEvents[date].map((event: any, index: any) => (
								<Flexed key={index} padding={'0.5rem'} direction={'row'} justify={'space-between'}>
									<Flexed direction={'row'} align={'left'} gap={'0.5'}>
										<Dot
											height={'0.69rem'}
											width={'0.69rem'}
											bg={
												types.meeting.toLowerCase() == event?.type
													? palette.light_blue_700
													: types.meal.toLowerCase() == event?.type
													? palette.voilet_700
													: types.presentation.toLowerCase() == event?.type
													? palette.amber_700
													: types.conference.toLowerCase() == event?.type
													? palette.emeraled_700
													: types.happy_hour.toLowerCase() == event?.type
													? palette.rose_700
													: palette.light_blue_700
											}
										/>
										<Flexed direction={'column'} align={'left'} gap={'0.3'}>
											<Text fontSize={'0.63'} lineHeight={'0.95'} fontWeight={'600'} color={'zink_400'}>
												{moment(event?.start).format('hh:mm A')} - {event?.event_without_time ? moment(event?.end).endOf('day').format('hh:mm A') : moment(event?.end).format('hh:mm A')}
											</Text>

											<Flexed
												direction={'row'}
												align={'left'}
												gap={'0.5'}
												background={event?.type == 'vacation' ? palette?.green_500 : ''}
												padding={event?.type == 'vacation' ? '0.1rem 0.3rem' : ''}
												radius={event?.type == 'vacation' ? '5.53px' : ''}
												pointer
												onClick={() => {
													setMeetingDetails(event)
													setOpenMeetingModel(true)
												}}>
												<Text fontSize={'0.69'} lineHeight={'0.92'} fontWeight={'500'} color={'white'}>
													{event.title}
												</Text>
												{event?.isVideo ? (
													<Flexed height={'0.9rem'} width={'0.9rem'} padding={'0.1rem'} radius={'100%'} justify="center" direction={'row'} align={'center'} background={palette.zink_400}>
														<BiVideo color="black" />
													</Flexed>
												) : (
													''
												)}
											</Flexed>

											<Text fontSize={'0.63'} lineHeight={'0.95'} fontWeight={'600'} color={'zink_400'}>
												{event.zoomLink}
											</Text>
										</Flexed>
									</Flexed>
								</Flexed>
							))}
						</div>
					)
				})}

				<CustomFlexedForWrap direction={'column'} justify={'space-between'} height={'90vh'} margin={'auto'}>
					<Flexed padding={'0.3rem 0.8rem'}>
						<Spacer height={0.5} />
						<Text fontSize={'0.74'} lineHeight={'1.15'} fontWeight={'700'} color={'white_70'}>
							{t('Calendars')}
						</Text>
						<Spacer height={0.5} />
						<a href="https://api.justnetwork.tech/just-network/auth/url">
							<Flexed direction={'row'} align={'center'} gap={'0.2'} pointer>
								<Icons src={'/images/google_calendar.svg'} />
								<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'white_70'}>
									{t('Google Calendar')}
								</Text>
							</Flexed>
						</a>
						{/* <Spacer height={0.5} /> */}
						{/* <Flexed direction={'row'} align={'center'} gap={'0.2'} pointer>
							<Icons src={'/images/apple_calendar.svg'} />
							<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'white_70'}>
								Apple Calendar
							</Text>
						</Flexed> */}
					</Flexed>

					<Flexed direction={'column'} justify={'space-between'} padding={'0.3rem 0.8rem'}>
						<Flexed>
							<Text fontSize={'0.73'} lineHeight={'1.15'} fontWeight={'700'} color={'white_70'}>
								{t('Time Zone')}
							</Text>
						</Flexed>
						<Flexed width={'100%'}>
							<MultiSelect
								placeholder={'Choose timezone'}
								padding={'0'}
								radius={'5px'}
								options={moment.tz.names().map((timezone: any) => {
									return {label: timezone, value: timezone}
								})}
								noOptionsMessage={t('no option available')}
							/>
						</Flexed>
					</Flexed>
				</CustomFlexedForWrap>
			</Flexed>
		)
	}

	const currentDate = moment().format('YYYY-MM-DD')
	const weatherForCurrentDate = weatherData && weatherData[currentDate]

	const filterMeetings = async () => {
		setLoading(true)
		await api
			.post('/meeting/filter_meetings', {date: selectedDate, timezone: userTimeZone, currentView: currentView, query})
			.then((res: any) => {
				if (res?.data?.data) {
					if (res?.data?.data?.meetings?.length && query == res?.data?.data?.query) {
						let data = res?.data?.data?.meetings?.map((e: any) => {
							let start: any = moment
								.utc(e.confirmation_start_time ? e.confirmation_start_time : e.start_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)
							let end = moment
								.utc(e.confirmation_end_time ? e.confirmation_end_time : e.end_time)
								.tz(e?.google_meeting_id ? Intl.DateTimeFormat().resolvedOptions().timeZone : e?.u_id == userDetails?.id ? e.timezone : e?.receiver_timezone)
							let _start = moment.tz(e.confirmation_start_time ? e.confirmation_start_time : e.start_time, userDetails?.timezone).utc()
							let _end = moment.tz(e.confirmation_end_time ? e.confirmation_end_time : e.end_time, userDetails?.timezone).utc()
							return {
								...e,
								start: !e?.google_meeting_id ? start.toDate() : new Date(_start.year(), _start.month(), _start.date(), _start.hour(), _start.minute(), _start.second(), _start.millisecond()),
								end: !e?.google_meeting_id ? end.toDate() : new Date(_end.year(), _end.month(), _end.date(), _end.hour(), _end.minute(), _end.second(), _end.millisecond())
							}
						})
						setEventsOfTheDay(data)
					} else {
						setEventsOfTheDay([])
					}
					setLoading(false)
				}
				setLoading(false)
			})
			.catch((e: any) => {
				console.log('error', e)
				setLoading(false)
			})
	}

	const handleLogin = () => {
		window.location.href = 'http://localhost:3001/auth/microsoft'
	}

	return (
		<MainContainer>
			<Spacer height={5} />
			{loading && <Loader />}
			<Container fluid>
				<Card background={palette.white} padding={'1rem 1rem '} height={'auto'} style={{zIndex: '0'}}>
					<Row>
						<Col lg={3}>
							<Card background={palette.calendar_bg}>
								<Flexed padding={'0.5rem'} direction={'row'} justify={'space-between'}>
									<Flexed direction={'column'} align={'left'} gap={'0.2'}>
										<DropdownContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
											<Flexed direction="row" pointer>
												{/* <Icons pointer={'pointer'} src="/images/Icon_plus.svg" /> */}
												<Button
													small
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={
														<>
															<BsCalendar2Plus style={{fontSize: '1rem'}} /> &nbsp; {t('Add Calendar')}
														</>
													}
													background={'linear-gradient(90deg, #9515FE, #705AFD, #5694FF)'}
													color={palette.white}
													lineHeight={'0.89rem'}
													ifClicked={toggleDropdown}
													// isBMT
												/>
											</Flexed>
											<DropdownContent isVisible={isVisible}>
												{/* Add dropdown items here */}
												<a href={`${AppConstants.server_url}/fetch-outlook-calendar/events?token=${localStorage.getItem('authorization')}&id=${userDetails?.id}`}>
													<DropMenu>
														<Flexed direction={'row'} align={'left'} gap={'0.2'} pointer>
															<Icons height={'25rem'} src="/images/outlook-svgrepo-com.svg" pointer={'pointer'} /> Outlook.com
														</Flexed>
													</DropMenu>
												</a>
												<a
													href={`${AppConstants.server_url}/just-network/sync?code=${userDetails?.google_sessions_code}&access_token=${userDetails?.google_access_token}&token=${localStorage.getItem('authorization')}&id=${
														userDetails?.id
													}`}>
													<DropMenu>
														<Flexed direction={'row'} align={'left'} gap={'0.2'} pointer>
															<Icons src={'/images/google_calendar.svg'} />
															<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'}>
																{t('Google Calendar')}
															</Text>
														</Flexed>
													</DropMenu>
												</a>
											</DropdownContent>
										</DropdownContainer>
										{/* <Flexed direction={'row'} pointer>
											<Icons src={'/images/Icon_plus.svg'} />
										</Flexed> */}
									</Flexed>

									<Flexed direction={'row'} align={'center'} gap={'0.2'}>
										<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'white_70'}>
											{weatherForCurrentDate ? `${weatherForCurrentDate.maxTemp}º/${weatherForCurrentDate.minTemp}º` : 'N/A'}
										</Text>
										<Icons src={'/images/sun.svg'} />
									</Flexed>
								</Flexed>
								<CalendarDatePicker events={eventsOfTheDay} selectedDay={selectedDate} handleSelection={(e: any) => setSelectedDate(e)} />

								<Flexed direction="column" align={'left'} gap={'0'} height={'40.81rem'} style={{overflow: 'hidden', overflowY: 'scroll'}}>
									{newEventClick ? renderSidebarNewEvent(groupEvents(eventsOfTheDay), selectedDate) : renderSidebarEvents(groupEvents(eventsOfTheDay), weatherData)}
								</Flexed>
							</Card>
						</Col>
						<Col lg={9}>
							<Card background={'transparent'} removeShadow padding={'0rem'} style={{overflowY: 'scroll'}}>
								<Flexed direction={'row'} justify={'space-between'}>
									<div></div>
									<Flexed direction={'row'} width={'25%'} style={{position: 'absolute', zIndex: loading ? '000' : '1', right: '0'}}>
										<Input isSearch isSignup isContact label="search" value={query} onKeyPress={() => filterMeetings()} onChange={(e: any) => setQuery(e?.value)} />
									</Flexed>
								</Flexed>
								<Calendar
									localizer={localizer}
									events={[...workingHour, ...eventsOfTheDay]}
									defaultView={Views.MONTH}
									startAccessor="start"
									// selectable
									components={{
										toolbar: CustomToolbar,
										header: CustomHeader,

										event: EventComponent,
										agenda: {
											// date:
											// event: CustomAgenda
										}
									}}
									eventPropGetter={(event: any) => {
										return {
											style: {
												backgroundColor: 'lightblue',
												borderRadius: '5px',
												textAlign: 'center',
												fontSize: '14px',
												fontWeight: 'bold',
												background:
													types.meeting.toLowerCase() == event?.type
														? palette.dark_blue_event
														: types.meal.toLowerCase() == event?.type
														? palette.voilet
														: types.presentation.toLowerCase() == event?.type
														? palette.amber
														: types.conference.toLowerCase() == event?.type
														? palette.emeraled
														: types.happy_hour.toLowerCase() == event?.type
														? palette.rose_100
														: 'transparent',
												color:
													types.meeting.toLowerCase() == event?.type
														? palette.light_blue_700
														: types.meal.toLowerCase() == event?.type
														? palette.voilet_700
														: types.presentation.toLowerCase() == event?.type
														? palette.amber_700
														: types.conference.toLowerCase() == event?.type
														? palette.emeraled_700
														: types.happy_hour.toLowerCase() == event?.type
														? palette.rose_700
														: palette.light_blue_700,
												padding: '5.53px',
												radius: '3.9px',
												border: 'none',
												width: '100%',
												flexWrap: 'wrap',
												textOverflow: 'ellipsis',
												borderLeft: `${event?.type == 'workingHour' ? '1rem' : '3px'} solid ${
													types.meeting.toLowerCase() == event?.type
														? palette.light_blue_500
														: types.meal.toLowerCase() == event?.type
														? palette.voilet_500
														: types.presentation.toLowerCase() == event?.type
														? palette.amber_500
														: types.conference.toLowerCase() == event?.type
														? palette.emeraled_500
														: types.happy_hour.toLowerCase() == event?.type
														? palette.rose_500
														: 'rgb(225, 243, 252)'
												}`
											},
											title: event.title
										}
									}}
									views={['day', 'week', 'month', 'agenda']}
									endAccessor="end"
									step={15}
									timeslots={4}
									showAllEvents={true}
									dayLayoutAlgorithm={'no-overlap'}
									defaultDate={selectedDate}
									date={currentView == 'agenda' ? moment(selectedDate)?.startOf('month').format() : selectedDate}
									onSelectEvent={(event: any) => {
										if (event?.type != 'workingHour') {
											setSelectedMeeting(event?.id)
											//  router.navigate(`/request-reschedule?id=${event?.id}`)
										}
									}}
									formats={{
										eventTimeRangeStartFormat: (date: any) => {
											return moment(date).format('YYYY-MM-DDTHH:mm:ss')
										},

										timeGutterFormat: 'h A',
										eventTimeRangeFormat: () => {
											return ''
										}
									}}
								/>
							</Card>
						</Col>
					</Row>
				</Card>
				{/* <Spacer height={4} /> */}
				<Footer />
			</Container>
			{openMeetingModel && meetingDetails && (
				<MeetingDetailsModel
					onClose={() => {
						setSelectedMeeting('')
						setOpenMeetingModel(false)
						router.replace('/calendar')
					}}
					header={t('Manage meeting')}
					eventDetails={meetingDetails}
				/>
			)}
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 0;
`

const DropdownContainer = styled.div<any>`
	position: relative;
	width: fit-content;
`

const DropdownContent = styled.div<any>`
	display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
	position: absolute;
	/* margin-top: 0.3rem;/ */
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: max-content;
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
	color: ${palette.text_color};
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`

const CustomFlexedForWrap = styled(Flexed)<any>`
	margin-left: 0;
	margin-right: 0;
	flex-wrap: nowrap;
	${media.xs`

	 `};
`

const DropMenu = styled.div<any>`
	color: ${({isDarkTheme, active}) => (isDarkTheme ? palette.light_gray : active ? palette.blue_500 : palette.text_color)};
	font-size: 0.875rem;
	padding: 0.425rem 1rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	cursor: pointer;
	align-items: center;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.silver)};
		color: ${palette.blue};
	}
`
export default CalendarPage
