import React, {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Spacer, Text} from '../styled/shared'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import {Card} from './AuthPage'
import useRouter from '../hooks/router'
import {api} from '../helpers/auth-axios'
import Loader from './common/Loader'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {callAutoLogin, setNotification} from '../actions/authActions'

const passwordInitialValues = {
	new_password: '',
	confirmPassword: '',
	current_password: '',
	loginForWeek: false
}

const UpdatePasswordForm = () => {
	const router = useRouter()
	const [loading, setLoading] = useState(false)
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const {t} = useTranslation()
	const dispatch = useDispatch()

	const validationSchemaFOrPassword = Yup.object().shape({
		new_password: Yup.string().min(8, t('Password must be at least 8 characters long')).required(t('Password is required')),
		current_password: Yup.string().min(8, t('Current Password must be at least 8 characters long')).required(t('Current Password is required')),
		confirmPassword: Yup.string()
			.min(8, t('New Password must be at least 8 characters long'))
			.oneOf([Yup.ref('new_password') ?? undefined, ''], t('Passwords must match'))
			.required(t('Confirm Password is required'))
	})
	const handleUpdateResetPassword = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/update_password', {...values, email: _userDetails?.email})
			.then((res: any) => {
				if (res?.data?.status) {
					actions.setSubmitting(false)
					actions.resetForm()
					setLoading(false)
					toast.success(t(res?.data?.message))
					dispatch(setNotification(true))
					dispatch(callAutoLogin(true))
					router.navigate('/dashboard')
				} else {
					setLoading(false)
					toast.error(t(res?.data?.message))
				}
			})
			.catch((error: any) => {
				if (error?.message) {
					setLoading(false)
					toast.error(error?.message)
				}
			})
	}

	return (
		<>
			<Row justifyContent="center">
				{loading && <Loader />}
				<Col xl={8} lg={8} md={8} sm={12} xs={10}>
					<Spacer height={4} />
					<Card>
						<Flexed justify="space-between" direction={'column'}>
							<Flexed justify="center">
								<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
									{t('Update your password')}
								</Text>
								<Spacer height={1} />
							</Flexed>
							<Spacer height={1} />

							<Formik initialValues={passwordInitialValues} validationSchema={validationSchemaFOrPassword} onSubmit={handleUpdateResetPassword}>
								{({isSubmitting, errors, setFieldValue, values, touched}) => (
									<Form>
										<Spacer height={1} />
										<Field
											component={Input}
											name="current_password"
											type="Password"
											label={t('Current Password')}
											value={values.current_password ?? undefined}
											isSignup
											isContact
											onChange={(event: any) => {
												setFieldValue('current_password', event?.value, true)
											}}
											error={touched.current_password && errors.current_password ? {message: errors.current_password ?? ''} : null}
										/>

										<Spacer height={1} />
										<Field
											component={Input}
											name="new_password"
											type="Password"
											label={t('Password')}
											value={values.new_password ?? undefined}
											isSignup
											isContact
											onChange={(event: any) => {
												setFieldValue('new_password', event?.value, true)
											}}
											error={touched.new_password && errors.new_password ? {message: errors.new_password ?? ''} : null}
										/>

										<Spacer height="1" />
										<Field
											component={Input}
											name="confirmPassword"
											type="Password"
											label={t('Confirm Password')}
											value={values.confirmPassword ?? undefined}
											isSignup
											isContact
											onChange={(event: any) => {
												setFieldValue('confirmPassword', event?.value, true)
											}}
											error={touched.confirmPassword && errors.confirmPassword ? {message: errors.confirmPassword ?? ''} : null}
										/>
										<Spacer height="0.5" />

										<Spacer height="2" />

										<Flexed direction={'row'} align={'center'} justify={'center'}>
											<Button
												small
												hasBorder
												disabled={isSubmitting}
												type="submit"
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												padding={'8px 16px 8px 16px'}
												label={<>{t('UPDATE PASSWORD')}</>}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.89rem'}
											/>
										</Flexed>
									</Form>
								)}
							</Formik>
						</Flexed>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default UpdatePasswordForm
