import React, {useEffect, useState} from 'react'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Text, Flexed, Spacer, Paragraph} from '../styled/shared'
import {FaArrowLeft} from 'react-icons/fa6'
import {palette} from '../styled/colors'
import useRouter from '../hooks/router'
import {Card} from './Dashboard'
import {Icons, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'
import MultiSelect from '../components/common/MultiSelect'
import InputField from '../components/common/InputField'
import {toast} from 'react-toastify'
import {FaArrowRight} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {api} from '../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../actions/authActions'
import Loader from '../components/common/Loader'
import UserProfileLoader from '../components/common/UserProfileLoader'
import Footer from '../components/Footer'

const SendCoins = () => {
	const router: any = useRouter()
	const [isContinue, setIsContinue] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [coins, setCoins] = useState<any>(0)
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const {t} = useTranslation()
	const dispatch = useDispatch()

	const [newMessageUsers, setNewMessageUsers] = useState<any>([])
	const [selectUsers, setSelectedUsers] = useState<any>()
	const getUserNetworks = async () => {
		try {
			const response = await api.get(`/user/getAllNetworkUsers`)
			const fetchedUsers = response?.data?.data?.users || []

			const newMessageUsers = fetchedUsers.map((user: any) => ({
				label: (
					<Flexed direction="row" align="center" gap="0.5">
						<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={user.image_Url ? user.image_Url : `/images/user_default.png`} />
						<Text fontSize="0.9" lineHeight="1.12" fontWeight="500" color="dark_blue">
							{user?.full_name}
						</Text>
					</Flexed>
				),
				value: user
			}))

			setNewMessageUsers((prevNewMessageUsers: any) => [...prevNewMessageUsers, ...newMessageUsers])
		} catch (error: any) {
			toast.error(error.message)
		} finally {
		}
	}

	const handleSendCoins = async () => {
		const mappedIds = selectUsers?.map((el: any) => el.value.id)
		if (!mappedIds) {
			toast.error(t('Please select users from the network'))
			return
		} else {
			if (mappedIds.length > 0 && coins > 0) {
				setLoading(true)
				await api
					.post('/user/send_coins', {coins: coins, f_ids: mappedIds})
					.then((response: any) => {
						setLoading(false)
						if (response.data?.status) {
							setIsSuccess(true)
							dispatch(callAutoLogin(true))
							toast.success(response.data.message)
						} else {
							toast.error(response.data?.message)
						}
					})
					.catch((err) => {
						toast.error(err.message)
					})
			} else {
				toast.error(t('Please select users from the network'))
			}
		}
	}

	useEffect(() => {
		getUserNetworks()
	}, [])

	return (
		<Section>
			{loading && <Loader />}
			{/* <Spacer height={4} /> */}
			<CenteredContainer fluid>
				<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
					<>
						<Flexed direction="column" align={'left'} gap={'0.6'}>
							<Flexed direction="column" align={''} gap={'0'}>
								<Flexed direction="row" align={'center'} gap={'0.6'}>
									<Icons
										src={'/images/icon_view.svg'}
										onClick={() => {
											router.navigate('/buy-my-time')
										}}
										pointer={'pointer'}
									/>
									{isContinue ? (
										''
									) : (
										<Text fontSize={'1.12'} lineHeight={'1.3'} fontWeight={'700'} color={'dark_blue'}>
											{' '}
											{t('Send Coins')}
										</Text>
									)}
								</Flexed>
							</Flexed>
						</Flexed>
					</>
					<Row justifyContent="center" alignItems="center">
						<Col lg={6}>
							<Spacer height={0} />

							{isSuccess ? (
								<Flexed align="center" text-align="center" direction="column" gap={1}>
									<SuccessText fontSize={1.5} lineHeight={'2.12'} fontWeight={600} fontStyle={'italic'}>
										{t('Congratulations')}
									</SuccessText>

									<SuccessImg src="/images/successfully_sent.svg" alt="Success" />
									<DimondText>{t('You have Successfully sent')}</DimondText>

									<Flexed justify={'center'} align={'center'} direction="row" height={'100%'} flexWrap={'wrap'} gap={'1'}>
										<CoinWrapper padding={'1rem'} justify="center" direction={'row'} align="center" gap={'0.5'} radius={'1rem'} style={{width: 'auto', height: '58px'}}>
											<img src={'/images/icon_goldcoin.svg'} width="36px" height="36px" />
											<Text fontSize="2.375" fontWeight="600" lineHeight="2.875" color={'black'}>
												{parseInt(coins, 10)}
											</Text>
										</CoinWrapper>
										<Text fontSize="1.12" fontWeight="700" lineHeight="1.3" color={'dark_blue'}>
											{' '}
											{t('to')}
										</Text>
										<Flexed justify={'center'} align={'center'} direction="column" height={'100%'} flexWrap={'wrap'} gap={'0.5'}>
											{selectUsers?.map((res: any) => (
												<Card background={palette.primary_50} minHeight={'3.6rem'} width={'maz-content'} removeShadow radius={'29px'}>
													<Flexed
														gap={0.3}
														direction="row"
														align="center"
														padding={'0.2rem'}
														pointer
														onClick={() => {
															router.navigate(`/${res?.value?.user_name}`, {
																state: {route: `/send-coins`}
															})
														}}>
														<UserProfileLoader height={'2.79rem'} width={'2.79rem'} imgUrl={res?.value.image_Url ? res?.value.image_Url : `/images/user_default.png`} />

														<Flexed gap={0.3} direction="column" align="center" margin={'0 0.5rem'}>
															<Text fontWeight="600" fontSize="1" lineHeight="1.21" align="center" color={'default_foreground'} opacity={'87%'}>
																{res?.value?.full_name}
															</Text>
															<Text fontWeight="400" fontSize="0.8" lineHeight="0.9" align="center" color={'default_foreground'} opacity={'60%'}>
																{res?.value?.designation}
															</Text>
														</Flexed>
													</Flexed>
												</Card>
											))}
										</Flexed>
									</Flexed>
									<Spacer height={1} />

									<Button
										small
										hasBorder
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'8px 16px 8px 16px'}
										label={
											<>
												<FaArrowLeft />
												&nbsp; {t('BACK TO BMT')}
											</>
										}
										background={palette.white}
										color={palette.blue_500}
										lineHeight={'0.89rem'}
										ifClicked={() => router.navigate('/buy-my-time')}
									/>
								</Flexed>
							) : (
								<>
									<Spacer height={1} />
									<Flexed align="center" text-align="center" direction="column" gap={1}>
										<Card background={'transparent'} borderColor={'#0000001F'} padding={'0'} removeShadow overflow={'visible'}>
											<Flexed padding={'1rem 1.5rem'} direction={'column'}>
												<Flexed margin="1rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7}>
													<Text lineHeight="0.908" fontSize="0.75" fontweight="600" color={'default_foreground'} opacity={'60%'}>
														{t('AVAILABLE COINS')}
													</Text>

													<CoinWrapper justify="center" direction={'row'} align="center" gap={'0.5'} radius={'1rem'}>
														<img src={'/images/icon_goldcoin.svg'} width="36px" height="36px" />
														<Text fontSize="2.375" fontWeight="600" lineHeight="2.875">
															{_userDetails?.coins}
														</Text>
													</CoinWrapper>
												</Flexed>

												<Flexed margin="1rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7} width={'100%'}>
													<Text fontSize="0.8" fontWeight="600" lineHeight="0.95" color={'black'} opacity={'60%'}>
														{t('Send coins')}
													</Text>
													<Flexed direction={'row'} gap={0.7} width={'100%'} align={'center'}>
														<Col lg={12} style={{padding: '0'}}>
															<InputField min={1} placeholder={'Enter number of coins'} allowOnlyNumbers padding={'0.64rem 0.8rem'} onChange={(e: any) => setCoins(e?.value)} />
														</Col>
													</Flexed>
												</Flexed>
												<Flexed margin="2rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7}>
													<Text fontSize="0.8" fontWeight="600" lineHeight="0.95" color={'black'} opacity={'60%'}>
														{t('Choose network')}
													</Text>
													<MultiSelect
														placeholder={'Choose network'}
														isChat={true}
														options={newMessageUsers}
														isMulti={true}
														noOptionsMessage={'No network user found'}
														value={selectUsers}
														onChange={(e: any) => setSelectedUsers(e)}
													/>
												</Flexed>
												<Spacer height={1} />
											</Flexed>
											<Flexed direction={'row'} gap={0.7} padding={'1rem 2.5rem'} width={'100%'} align={'center'} justify={'space-between'} style={{borderTop: '1px solid #0000001F'}} flexWrap={'wrap'}>
												<Flexed direction={'column'} gap={0.7}>
													{/* <Text lineHeight="0.95" fontSize="0.8" fontweight="500" color={'default_foreground'} opacity={'50%'}>
														{t('Amount to be paid')}
													</Text>
													<Text fontSize="1.5" fontWeight="600" lineHeight="1.84" color={'black'}>
														${coins / 5}
													</Text> */}
												</Flexed>

												<Flexed direction={'row'} gap={0.7}>
													<Button
														small
														hasBorder
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														padding={'8px 16px 8px 16px'}
														label={<>{t('CANCEL')}</>}
														background={palette.white}
														color={palette.blue_500}
														lineHeight={'0.89rem'}
														ifClicked={() => router.navigate('/buy-my-time')}
													/>
													<Button
														small
														hasBorder
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														padding={'8px 16px 8px 16px'}
														label={
															<>
																{t('PROCEED')} &nbsp; <FaArrowRight />
															</>
														}
														background={palette.blue_500}
														disabled={loading}
														color={palette.white}
														lineHeight={'0.89rem'}
														ifClicked={() => handleSendCoins()}
													/>
												</Flexed>
											</Flexed>
										</Card>
									</Flexed>
								</>
							)}
						</Col>
					</Row>
					<Spacer height={1} />
				</Card>
			</CenteredContainer>
			<Footer />
		</Section>
	)
}
const Section = styled.div`
	background-image: url('/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const PaypalFlex = styled(Flexed)`
	background-image: url('/images/withdraw_coins_bg.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 26.87rem;
	height: 40.18rem;
	padding: 1rem;
`

const CenteredContainer = styled(Container)`
	margin-top: 6rem;
	display: flex;
	justify-content: center;
`
const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const DimondText = styled(Text)`
	color: ${palette.dark_blue};
	font-weight: 700;
	font-size: 1.125;
	line-height: 1.361rem;
`

export const CoinWrapper = styled(Flexed)`
	border: 1px solid ${palette.sky_blue};
	background-color: ${palette.sky_blue};
	border-radius: 2.75rem;
	width: max-content;
	padding: 0 0.5rem;
	height: 3.625rem;
`
export default SendCoins
