import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {api} from '../helpers/auth-axios'
import {Flexed, Spacer, Text} from '../styled/shared'
import {useSelector} from 'react-redux'
import {palette} from '../styled/colors'
import {useTranslation} from 'react-i18next'
import {Icons, ProfileImg} from './TopNavBar'
import {BuyMyTime} from '../pages/ForgotPassword'
import styled from 'styled-components'
import {media, Row, Col} from 'styled-bootstrap-grid'
import copy from 'copy-to-clipboard'
import {FacebookShareButton, LinkedinShareButton, WhatsappShareButton, TwitterShareButton} from 'react-share'
import EmailInput from './EmailInput'
import useRouter from '../hooks/router'
import Button from './common/Button'
import Loader from './common/Loader'
import UserProfileLoader from './common/UserProfileLoader'
import DynamicMetaTags from './DynamicMetadata'

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	cursor: ${({cursor}) => (cursor ? cursor : 'default')};
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
 min-height:auto;
 /* width:auto; */
 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
 `};
`

const InviteFriends = () => {
	const [invitedUser, setInvitedUser] = useState<any>([])
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [users, setUsers] = useState([])
	const {t} = useTranslation()
	const [suggestions, setSuggestions] = useState(false)
	const [loading, setLoading] = useState(false)
	const router = useRouter()
	const title = `Join ${userDetails?.full_name ? userDetails?.full_name : userDetails?.first_name} on the JustNetwork platform and turn your valuable time into profit! Connect, collaborate, and profit from your time.`

	let linkedinTitle = `Join me on JustNetwork Platform. A revolutionary prospecting platform that forster connectivity among business professionals. The Magic ” BMT feature stands for Buy My Time, users can Sign up free and take a free membership that align with your professional roles, therefore you will access bmt link to share with whoever must value your time.`

	useEffect(() => {
		if (userDetails?.id) {
			getUserInvites()
		}
	}, [userDetails])
	useEffect(() => {
		if (userDetails?.id && suggestions) {
			getUserSuggestions()
		}
	}, [userDetails, suggestions])

	const getUserInvites = async () => {
		try {
			const response = await api.get('/user/get_invite_user')
			setInvitedUser(response.data?.data)
			const invites: any = response.data?.data
			const checkIfAllAccepted = invites.length === 3 && invites.every((el: any) => el.is_accepted)

			if (checkIfAllAccepted) {
				setSuggestions(true)
			}
		} catch (error: any) {
			// toast.error(error.message)
		}
	}
	const getUserSuggestions = async (page = 1) => {
		try {
			setLoading(true)
			const response = await api.get(`/user/user_suggestions?page=${page}`, {
				params: {page, suggestions: true}
			})

			setUsers(response?.data?.data?.users)
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.log(err)
		}
	}

	return (
		<Row>
			{/* <DynamicMetaTags
				title={userDetails ? `Join ${userDetails?.full_name} on the JustNetwork platform and turn your valuable time into profit! Connect, collaborate, and profit from your time.` : ''}
				imageUrl={userDetails?.image_Url ? userDetails?.image_Url : ''}
				url={`${window.location.origin}/${userDetails?.user_name}`}
				description={userDetails?.specifications ? userDetails?.specifications : ''}></DynamicMetaTags> */}
			<Col lg={12}>
				<CustomSmallSpace height={router?.pathname == '/dashboard' ? 0 : router?.pathname == '/inbox' || router?.pathname == '/notifications' ? 2 : 4} />
				{router?.pathname == '/inbox' ? <Spacer height={1.2} /> : ''}
				<Card background={palette.white} padding={'1rem'} height={'auto'}>
					<CustomFlexed direction={suggestions && router?.pathname == '/dashboard' ? 'row' : 'column'} width={'100%'} justify={'space-between'} align={'center'}>
						<Flexed direction="column" width={'100%'}>
							<Text fontSize={'1.12'} lineHeight={'1.27'} fontWeight={'600'} color={'text_color'} align={'left !important'}>
								{t('Invite friends')}
							</Text>
							<Spacer height={0.5} />
							<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} align={'left !important'}>
								{t("Monetize Your Networking Time with JustNetwork's Revolutionary BMT Feature")}
							</Text>
							<Spacer height={1} />
						</Flexed>

						<Flexed icons direction="row" width={'100%'} justify={'space-between'} isIcons align={'center'}>
							<WhatsappShareButton title={title} url={`${window.location.origin}/${userDetails?.user_name}`}>
								<Icons isSocialIcon pointer={'pointer'} src={'/images/whatsApp.svg'} />
							</WhatsappShareButton>

							<FacebookShareButton title={title} url={`${window.location.origin}/${userDetails?.user_name}`}>
								<Icons
									isSocialIcon
									onClick={() => {
										toast.success('Content coppied to clipboard')
										copy(`${title}`)
									}}
									pointer={'pointer'}
									src={'/images/facebook.svg'}
								/>
							</FacebookShareButton>
							<TwitterShareButton title={title} url={`${window.location.origin}/${userDetails?.user_name}`}>
								<Icons
									width="42px"
									isSocialIcon
									pointer={'pointer'}
									onClick={() => {
										toast.success('Content coppied to clipboard')
										copy(`${title}`)
									}}
									src={'/images/x.png'}
								/>
							</TwitterShareButton>

							<LinkedinShareButton source="JustNetwork" summary={linkedinTitle} title={title} url={`${window.location.origin}/${userDetails?.user_name}`}>
								<Icons
									isSocialIcon
									pointer={'pointer'}
									onClick={() => {
										toast.success('Content coppied to clipboard')
										copy(`${linkedinTitle}` + '\n' + `${window.location.origin}/${userDetails?.user_name}`)
									}}
									src={'/images/linkedin_1.svg'}
								/>
							</LinkedinShareButton>
						</Flexed>
					</CustomFlexed>
					<Spacer height={'0.5'} />
					<CustomContainer
						background={palette.yellow_100}
						isDashboard={suggestions && router?.pathname == '/dashboard'}
						minHeight={suggestions && router?.pathname == '/dashboard' ? '6rem' : '4rem'}
						padding={'0.4rem'}
						height={'auto'}
						radius={'0.7rem'}>
						<CustomFlexed direction="column" width={suggestions && router?.pathname == '/dashboard' ? 'auto' : '100%'} align={'center'}>
							<Flexed direction="row" align={'center'} gap={0.2}>
								<Text fontSize={'0.9'} lineHeight={'1.5'} fontWeight={'500'} color={'black'}>
									{t('Your')}
								</Text>
								<BuyMyTime fontSize={'1'} lineHeight={'1.5'} fontWeight={'800'} color={'black'}>
									{t('BMT')}
								</BuyMyTime>
								<Text fontSize={'0.9'} lineHeight={'1.5'} fontWeight={'500'} color={'black'}>
									{t('link')}
								</Text>
							</Flexed>
						</CustomFlexed>

						<Flexed width={suggestions && router?.pathname == '/dashboard' ? '80%' : '100%'}>
							<Card cursor="pointer" title={`${window.location.origin}/${userDetails?.user_name}`} background={palette.white} minHeight={router?.pathname == '/dashboard' ? '3rem' : '2rem'} height={'auto'} radius={'1.7rem'}>
								<Flexed direction="row" align={'center'} justify={'space-between'} flexWrap={'wrap'}>
									<Text
										fontSize={'0.8'}
										lineHeight={'1.5'}
										fontWeight={'400'}
										align={'left'}
										color={'input_text_color'}
										isCentered
										width={router?.pathname == '/dashboard' ? '80% !important' : '16rem'}
										isBmtLink
										whiteSpace="nowrap"
										overflow="hidden"
										textOverflow="ellipsis"
										onClick={() => {
											toast.success(t('Copied to clipboard'))
											copy(`${window.location.origin}/${userDetails?.user_name}`)
										}}>
										{`${window.location.origin}/${userDetails?.user_name}`}
									</Text>
									<Icons
										src={'/images/content_copy.svg'}
										style={{cursor: 'pointer'}}
										isCopy
										onClick={() => {
											toast.success(t('Copied to clipboard'))
											copy(`${window.location.origin}/${userDetails?.user_name}`)
										}}
									/>
									<CustomSmallSpace height={'0.5'} />
								</Flexed>
							</Card>
						</Flexed>
					</CustomContainer>
					<Spacer height={0.5} />

					{!suggestions && (
						<Flexed direction="row" width={'100%'} gap={'0.3'} align={'center'}>
							<Text fontSize={'0.9'} lineHeight={'1.5'} fontWeight={'500'} color={'text_color'}>
								{t('Invite 3 friends and earn 5 coins each')}
							</Text>
							<Icons src={'/images/icon_goldcoin.svg'} />
						</Flexed>
					)}

					{!suggestions &&
						[...invitedUser, ...Array.from({length: Math.max(0, 3 - invitedUser.length)})].map((user, index) => <EmailInput key={index} isAccepted={user?.is_accepted} userEmail={user?.email} getUserInvites={getUserInvites} />)}

					{suggestions && (
						<>
							<Spacer height="0.5" />

							<Flexed direction="row" justify="space-between" align={'center'} gap={'0.6'}>
								<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
									{t('Network suggestions')}
								</Text>
								<Icons src={'/images/icon_view_left.svg'} onClick={() => router.navigate('/network')} pointer={'pointer'} />
							</Flexed>
							<Spacer height="0.5" />
							<Flexed id="scrollableDiv" direction="column" gap={'0.5'} style={{height: suggestions && router?.pathname == '/dashboard' ? '11.5rem' : '13.5rem', overflow: 'scroll'}}>
								{loading ? (
									<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'}>
										<Loader isComponent={true} />
									</Flexed>
								) : users?.length > 0 ? (
									users.map((user: any, i: any) => (
										<Flexed radius="1rem" direction="column" gap={'0.3'} align={'center'} padding={' 0 1rem'} style={{border: `1px solid ${palette.zink_300}`}} key={user.id}>
											<Flexed direction="row" width={'100%'} align={'center'} justify={'space-between'}>
												<Flexed
													direction="row"
													align={'center'}
													pointer
													onClick={() => {
														router.navigate(`/${user.user_name}`, {
															state: {route: '/network'}
														})
													}}>
													<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
														<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={user?.image_Url} />
														<AvailabilityIconWrapper
															src={`/images/${user?.is_online ? 'green_circle' : user?.is_online === 'offline' ? 'pink_circle' : user?.is_online === 'red' ? 'red_circle' : 'gray_circle'}.svg`}
															alt="upload"
														/>
													</ProfileImgWrapper>
													<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
														<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
															<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
																{user?.first_name + ' ' + user.last_name}
															</Text>
															{user?.linkedin_profile_verified || user?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : null}
														</Flexed>
														<Spacer height={0.2} />
														<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
															{user?.designation}
														</Text>
													</Flexed>
												</Flexed>
												<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														hasBorder
														padding={'0'}
														// ifClicked={() => addToNetwork(user.id)}
														label={<>{t('ADD NETWORK')}</>}
														background={palette.white}
														color={palette.blue_500}
														minWidth={'8rem'}
														lineHeight={'0.89rem'}
														ifClicked={() => {
															router.navigate(`/${user.user_name}`, {
																state: {route: '/network'}
															})
														}}
													/>
												</Flexed>
											</Flexed>
											<Spacer height={0.2} />
										</Flexed>
									))
								) : users?.length == 0 ? (
									<Flexed align={'center'} justify={'center'} direction={'row'} height={'100%'}>
										<Text fontSize={'0.9'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
											{t('No network suggested user found!')}
										</Text>
									</Flexed>
								) : (
									<></>
								)}
							</Flexed>
						</>
					)}
				</Card>
				{/* <Spacer height={3.5} /> */}
			</Col>
		</Row>
	)
}

const CustomFlexed = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	flex-direction: column;
	 `};
`
const CustomSmallSpace = styled(Spacer)`
	display: none;
	${media.xs`
display: block
	 `};
`
const AvailabilityIconWrapper = styled.img`
	position: absolute;
	bottom: 0;
	margin-left: 1.8rem;
`
const ProfileImgWrapper = styled(Flexed)`
	position: relative;
	display: flex;
`

const CustomContainer = styled(Card)<any>`
	display: flex;
	flex-direction: ${({isDashboard}) => (isDashboard ? 'row' : 'column')};
	justify-content: ${({isDashboard}) => (isDashboard ? 'space-between' : 'center')};
	align-items: ${({isDashboard}) => (isDashboard ? 'center' : 'center')};

	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`
export default InviteFriends
