import React, {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../common/input/Input'
import Button from '../../common/Button'
import {palette} from '../../../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../../../styled/shared'
import styled from 'styled-components'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import {Card} from '../../AuthPage'
import Checkbox from '../../common/Checkbox'
import useRouter from '../../../hooks/router'
import {CustomSpacer, Dot} from '../../../pages/SignUp'
import VerificationInput from 'react-verification-input'
import {api} from '../../../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {saveUser} from '../../../actions/authActions'
import Loader from '../../common/Loader'
import {AiOutlineClose} from 'react-icons/ai'
import {useTranslation} from 'react-i18next'
import AuthTerms from '../../common/AuthTerms'

const initialValues = {
	verification_code: ''
}

const passwordInitialValues = {
	new_password: '',
	confirmPassword: '',
	loginForWeek: false
}

const CloseButton = styled.button`
	position: absolute;
	top: 8px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`
const OtpVerificationForm = ({onClose}: {onClose: any}) => {
	const router = useRouter()
	const dispatch: any = useDispatch()
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation()

	const validationSchema = Yup.object().shape({
		verification_code: Yup.string().min(6).required(t('Otp is required'))
	})

	const handleSubmit = async (values: any, actions: any) => {
		setTimeout(() => actions.setSubmitting(false), 10000)
		setLoading(true)
		await api
			.post('/user/verify_register_code', {...values, email: localStorage.getItem('email')})
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					actions.setSubmitting(false)
					actions.resetForm()
					toast.success(res?.data?.message)
					dispatch(saveUser(res?.data?.data))
					localStorage.setItem('authenticated', 'true')
					localStorage.removeItem('email')
					localStorage.setItem('authorization', res?.data?.data?.login_token)
					onClose(true)
					router.navigate(`/buy-membership?id=${res?.data?.data?.id}`)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}
	const sendAgainOtp = async () => {
		setLoading(true)
		await api
			.post('/user/send_register_code', {email: router?.query?.email})
			.then((res: any) => {
				setLoading(false)
				if (res?.data?.status) {
					toast.success(res?.data?.message)
				} else {
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	return (
		<>
			<Row justifyContent="center">
				{loading && <Loader />}
				<Col xl={8} lg={8} md={8} sm={12} xs={10}>
					{/* <CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton> */}
					<Spacer height={4} />
					<Card>
						<Flexed justify="space-between" direction={'column'}>
							<Flexed justify="center">
								<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
									{t('Enter your OTP')}
								</Text>
								<Spacer height={1} />
							</Flexed>
							<Spacer height={1} />

							<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
								{({isSubmitting, errors, setFieldValue, values, touched}) => (
									<Form>
										<Spacer height={1} />
										<Flexed justify={'center'} direction={'row'}>
											<VerificationInput
												autoFocus={true}
												validChars="0-9"
												classNames={{
													character: 'character'
												}}
												onChange={(e: any) => {
													setFieldValue('verification_code', e)
												}}
												value={values.verification_code}
											/>
										</Flexed>

										<Spacer height="2" />

										<Flexed direction={'row'} align={'center'} justify={'center'}>
											<Button
												small
												hasBorder
												disabled={isSubmitting}
												type="submit"
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												padding={'8px 16px 8px 16px'}
												label={<>{t('VERIFY OTP')}</>}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.89rem'}
											/>
										</Flexed>
									</Form>
								)}
							</Formik>
							<Spacer height="1" />
							<Flexed direction={'row'} align={'center'} justify={'center'}>
								<Button small hasBorder borderRadius={'1rem'} fontSize={'0.8rem'} label={<>{t('SEND AGAIN')}</>} background={'transparent'} color={palette.blue_500} lineHeight={'0.89rem'} ifClicked={() => sendAgainOtp()} />
							</Flexed>
						</Flexed>
						<Spacer height={2} />
					</Card>

					<Spacer height={2} />
					<CustomSpacer height={2} />
					<AuthTerms />
				</Col>
			</Row>
		</>
	)
}

const NameFieldsFlex = styled(Flexed)`
	${media.xs`
flex-direction: column;
    `}
	${media.sm`
	flex-direction: column;

    `}
    ${media.md`
	flex-direction: row;

    `}
	${media.lg`
	flex-direction: row;

    `}
`

export default OtpVerificationForm
