import {Container} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../styled/shared'
import styled from 'styled-components'
import useRouter from '../hooks/router'
import {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import Footer from '../components/Footer'

const TermsAndConditions = () => {
	const router = useRouter()
	const contactFormRef: any = useRef(null)
	const {t} = useTranslation()

	useEffect(() => {
		if (router.location.state == 'top' && contactFormRef?.current) {
			contactFormRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [router.location.state])
	return (
		<MainContainer ref={contactFormRef}>
			<Spacer height="4" />

			<Container fluid>
				<Card>
					<Text isCentered fontSize={'2'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						{t('JustNetwork Terms and Conditions')}
					</Text>
				</Card>

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						1. {t('Introduction')}
					</Text>
					<InfoDetails>
						{t('Welcome to')} <b>JustNetwork</b>. {t('By accessing and using our website and services, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully. If you do not agree with any part of these terms, you must not use our website or services')}.
					</InfoDetails>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						2. {t('Definitions')}
					</Text>
					<ul>
						<li>
							<InfoCategory>JustNetwork:</InfoCategory>
							<InfoDetails>"you," or "your": Refers to any individual or entity accessing or using our website and services.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('User')}:</InfoCategory>
							<InfoDetails>{t('Billing details, transaction history, and payment method')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Services')}:</InfoCategory>
							<InfoDetails>
								{t('Refers to all the features, functions, and applications provided by')} <b>JustNetwork</b> {t('through the website')}.
							</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						3. {t('Use of the Services')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('Eligibility')}:</InfoCategory>
							<InfoDetails>{t('You must be at least 18 years old and capable of entering into a binding agreement to use our services')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Account Registration')}:</InfoCategory>
							<InfoDetails> {t('You may need to create an account to access certain features. You agree to provide accurate and complete information and to keep this information up-to-date')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Account Security')}:</InfoCategory>
							<InfoDetails>{t('You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Prohibited Conduct')}:</InfoCategory>
							<InfoDetails>{t('You agree not to use our services for any unlawful purpose or in any way that could damage or impair our website or services')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						4. {t('Payment and Fees')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('Subscription Fees')}:</InfoCategory>
							<InfoDetails>{t('Certain features may require payment of subscription fees. You agree to pay all applicable fees as described on our website')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Payment Method')}:</InfoCategory>
							<InfoDetails>{t('You must provide a valid payment method. You authorize us to charge your payment method for any fees due')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Refund Policy')}:</InfoCategory>
							<InfoDetails>{t('Fees are non-refundable except as required by law or stated otherwise on our website')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						5. {t('User Content')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('Ownership')}:</InfoCategory>
							<InfoDetails>{t('You retain ownership of any content you submit to our website (e.g., reviews, comments, or profile information)')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('License')}:</InfoCategory>
							<InfoDetails>{t('By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content in connection with our services')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Prohibited Content')}:</InfoCategory>
							<InfoDetails>{t('You agree not to submit any content that is illegal, offensive, or infringes on the rights of others')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						6. {t('Intellectual Property')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('Ownership')}:</InfoCategory>
							<InfoDetails>{t('All content and materials on our website, including text, graphics, logos, and software, are the property of <b>JustNetwork</b> or its licensors and are protected by intellectual property laws')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('License')}:</InfoCategory>
							<InfoDetails>{t('You are granted a limited, non-exclusive, non-transferable license to access and use the website and services for your personal or internal business use.')}</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						7. {t('Limitation of Liability')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('No Warranty')}:</InfoCategory>
							<InfoDetails>
								{t('Our services are provided')} "{t('as is')}" {t('and')} "{t('as available')}" {t('without any warranties of any kind')}, {t('either express or implied')}.
							</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Limitation')}:</InfoCategory>
							<InfoDetails>
								{t('To the fullest extent permitted by law')}, <b>JustNetwork</b> {t('will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.')}
							</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						8. {t('Indemnification')}
					</Text>
					<InfoDetails>
						{t('You agree to indemnify, defend, and hold harmless')} <b>JustNetwork</b> {t('and its affiliates from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of our services or violation of these terms')}
					</InfoDetails>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						9. {t('Termination')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('By You')}:</InfoCategory>
							<InfoDetails>{'You may terminate your account at any time by contacting us.'}</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('By Us')}:</InfoCategory>
							<InfoDetails>{t('We may terminate or suspend your account and access to our services at our sole discretion, without prior notice, if you violate these terms.')}</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						10. {t('Changes to This Privacy Policy')}
					</Text>
					<InfoDetails>{t('We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new terms on our website. Your continued use of our services after any changes indicates your acceptance of the new terms')}</InfoDetails>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						11. {t('Changes to the Terms')}
					</Text>
					<InfoDetails>{t('We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new terms on our website. Your continued use of our services after any changes indicates your acceptance of the new terms')}</InfoDetails>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						12. {t('Governing Law')}
					</Text>
					<InfoDetails>{t('These Terms and Conditions are governed by and construed in accordance with the laws of US, without regard to its conflict of law principles')}</InfoDetails>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						13. {t('Personal Use and Compliance')}:
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('*Personal Use:*')}</InfoCategory>
							<InfoDetails>{`JustNetwork is designed for personal use to allow individuals to monetize their time and expertise. Users are responsible for ensuring that their use of JustNetwork complies with their employer's policies and any applicable laws, including procurement and anti-bribery regulations.`}</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('*User Responsibility:*')}:</InfoCategory>
							<InfoDetails>
								{t(
									`Users must ensure that their use of JustNetwork does not conflict with any corporate policies, contracts, or agreements they are subject to. JustNetwork is not liable for any consequences arising from users' failure to comply with such obligations.`
								)}
							</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						14. {t('Misuse and Liability')}:
					</Text>
					<ul>
						<li>
							<InfoCategory>*{t('Prohibited Activities')}:*</InfoCategory>
							<InfoDetails>{`Any use of JustNetwork that violates laws or regulations, including but not limited to bribery, fraud, and conflicts of interest, is strictly prohibited.`}</InfoDetails>
						</li>
						<li>
							<InfoCategory>*{t('Indemnification')}:*</InfoCategory>
							<InfoDetails>
								{t(
									`Users agree to indemnify and hold harmless JustNetwork and its affiliates, officers, agents, and employees from any claim or demand arising out of their use of the service, including any violation of these terms and conditions.`
								)}
							</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						15. {t('Contact us')}
					</Text>
					<InfoDetails>
						{t('If you have any questions or concerns about these Terms and Conditions, please contact us at:')}
						<br />
						<b>{t('Email')}: support@justnetwork.tech</b>
					</InfoDetails>
				</Flexed>
			</Container>
			<Spacer height={1} />
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	/* background-image: url('/images/auth-bg.png'); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 10rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background-image: url('/images/auth-bg.png');
`

const InfoCategory = styled.h3`
	font-size: 1.2rem;
	font-weight: 700;
	margin-top: 1rem;
`

const InfoDetails = styled.p`
	font-size: 1rem;
	line-height: 1.6;
	margin-left: 1rem;
`

export default TermsAndConditions
