import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../../styled/colors'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'

interface Option {
	label: string
	value?: string | number
	render?: string
}

export interface InputSelectProps {
	label?: string
	setFileValue?: any
	required?: boolean
	placeholder?: string
	disabled?: boolean
	error?: boolean
	errorMsg?: string
	isSignup?: boolean
	isContact?: boolean
	name?: string
	type?: string
	id?: string
	value?: string | number
	className?: string
	readOnly?: boolean
	min?: any
	autoComplete?: string
	maxLength?: any
	allowNumber?: boolean
	slotLeft?: React.ReactNode
	onBlur?(): void
	onFocus?(): void
	step?: any
	pattern?: any
	onInput?: any
	allowPhoneNumberOnly?: boolean
	inputMode?: any
	allowText?: any
	allowAddess?: any
	allowOnlyNumbers?: any
	allowOnlyNumbersAndDecimal?: boolean
	options?: any[]
	onChange?(selectedOptions: Option[]): void
	isMulti?: any
	noOptionsMessage?: any
	defalutValue?: any
	searchMembers?: any
	padding?: any
	radius?: any
	isChat?: boolean
	inputChange?: any
	defaultInputValue?: any
}

const InputSelect = ({label, setFileValue, required, placeholder, noOptionsMessage, disabled, isMulti, error, padding, defaultInputValue, isChat, radius, errorMsg, isSignup, isContact, onChange, value, name, options}: InputSelectProps) => {
	const [selectedOptions, setSelectedOptions] = useState<any[]>([])
	const [isFocused, setFocused] = useState(false)
	const [inputValue, setInputValue] = useState<any>('')
	const [isActive, setActive] = useState(false)

	useEffect(() => {
		setSelectedOptions(options ?? [])
	}, [options])

	useEffect(() => {
		setInputValue(defaultInputValue)
	}, [defaultInputValue])

	const inputRef = useRef<any>(null)

	useEffect(() => {
		if ((inputRef.current && inputRef.current.value?.length > 0) || (inputRef.current && inputRef.current.value?.length > 1)) setActive(true)
	}, [])

	useEffect(() => {
		setInputValue(value ?? '')
		// setActive(!!value)
	}, [value])

	useEffect(() => {
		if (inputValue) setActive(true)
	}, [inputValue])

	const focusInput = () => {
		setActive(true)
		setFocused(true)
		if (inputRef.current) inputRef.current.focus()
	}

	const handleFocus = () => {
		setFocused(true)
	}

	const colourStyles = {
		control: (provided: any, state: any) => {
			setFocused(state.isFocused)
			return {
				...provided,

				border: 'none',
				borderColor: 'none',
				color: `${palette.text_color} !important`,
				width: '100% !important',
				padding: padding ? padding : '0.45rem 0.5rem',
				backgroundColor: '#F5F5F5',
				boxShadow: 'none !important',
				textAlign: 'start',
				minHeight: '1rem',
				borderRadius: radius ? radius : '0.8rem',
				'&:hover': {
					border: 'none',
					boxShadow: 'none !important'
				}
			}
		},
		input: (provided: any, state: any) => ({
			...provided,
			color: `${palette.text_color} !important`,
			fontSize: '0.8rem',
			padding: '0rem',
			margin: '0rem'
		}),
		ValueContainer: (provided: any, state: any) => ({
			...provided,
			color: `${palette.text_color} !important`,
			fontSize: '0.8rem',
			padding: '0.8rem'
		}),
		singleValue: (provided: any, state: any) => ({
			...provided,
			color: `${palette.text_color} !important`,
			fontSize: '0.9rem',
			padding: '0rem'
		}),
		indicatorSeparator: (provided: any, state: any) => ({
			...provided,
			display: 'none',
			fontSize: '0.9rem',
			padding: '0rem'
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			color: '#1C1B1F',
			padding: '0rem',
			fontSize: '0.9rem',
			width: '1rem'
		}),
		multiValue: (provided: any, state: any) => ({
			...provided,
			background: isChat ? palette.primary_50 : '',
			borderRadius: isChat ? '20px' : ''
		}),
		placeholder: (provided: any, state: any) => ({
			...provided,
			fontSize: '0.9rem',
			lineHeight: '1.2rem',
			fontWeight: '500',
			color: 'black',
			opacity: '50%'
		}),

		option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
			return {
				...styles,
				width: '100% !important',
				textAlign: 'left',
				zIndex: 5,
				cursor: isDisabled ? 'not-allowed' : 'default',
				color: isSelected ? 'white' : `${palette.text_color} !important`,
				backgroundColor: isSelected ? palette.text_color : null,
				':hover': {
					backgroundColor: isSelected ? palette.white : palette.text_color,
					color: isDisabled ? null : isSelected ? 'black' : `${palette.white} !important`
				},
				':focus': {
					backgroundColor: isSelected ? palette.secondary : null,
					color: isDisabled ? null : palette.white
				},
				':active': {
					backgroundColor: isSelected ? palette.secondary : null,
					color: isDisabled ? null : palette.white
				}
			}
		}
	}
	const _noOptionsMessage = () => (noOptionsMessage ? noOptionsMessage : '')

	return (
		<InputContainer onClick={() => focusInput()}>
			<Wrapper error={error} isContact={isContact} isSignup={isSignup}>
				<div>
					<Select
						ref={inputRef}
						placeholder={placeholder || label}
						styles={colourStyles}
						name={name}
						value={value}
						onChange={(e: any) => onChange && onChange(e)}
						options={selectedOptions}
						isMulti={isMulti}
						isSearchable={false}
						isDisabled={disabled}
						noOptionsMessage={_noOptionsMessage}
						isClearable={false}
					/>
					<LabelWrapper value={inputValue} error={error} isContact={isContact} isSignup={isSignup}>
						<label>{label}</label>
					</LabelWrapper>
				</div>
			</Wrapper>
			{error && <Message>{error}</Message>}
		</InputContainer>
	)
}

const InputContainer = styled.div<any>`
	width: 100%;
	position: relative;
	display: block;
`

const Wrapper = styled.div<InputSelectProps>`
	padding: 0.45rem 0.25rem;
	align-items: stretch;
	border-radius: 0.325rem;
	border-style: ${({isContact, error}) => (isContact ? 'none' : 'solid !important')};
	border-width: 0.5px !important;

	background-color: ${({isContact, isSignup}) => (isSignup ? '#F4F4F5' : '#fff')};
	transition: border-color 0.15s ease-in-out;
	border-color: ${({error}) => (error ? `#eb5353` : 'rgba(223, 226, 230)')};
`

const LabelWrapper = styled.label<any>`
	position: absolute;
	max-width: calc(100% - 24px);
	top: ${({isActive, isFocused}) => (isActive || isFocused ? '-10px' : '24%')};
	left: ${({isActive, isFocused}) => (isActive || isFocused ? '10px' : '4%')};
	top: -1px;
	label {
		color: ${({error}) => (error ? `#eb5353` : '#8C8B88')};
		font-size: 10px;
		display: block;
		padding: 0 6px;
		margin-left: -6px;
		font-weight: 300;
		text-transform: capitalize;
		line-height: 1;
		opacity: ${({value, type}) => (value || type == 'date' ? `1` : '0')};
		transform: ${({value, type}) => (value || type == 'date' ? `translateY(-50%)` : 'translateY(50%)')};
		transition: ${({value}) => (value ? `transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;` : 'all 0.15s ease-in-out')};
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		background-color: ${({isContact, error, isSignup}) => (isSignup ? 'transparent' : isContact ? 'transparent' : 'white')};
		&::before,
		&::after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 50%;
			z-index: -1;
			left: 0;
		}
		&::before {
			top: 50%;
		}
		&::after {
			top: 0;
			background-color: transparent;
		}
	}
`

const Message = styled.div`
	color: #eb5353;
	background: white;
	border: 1px solid #eb5353;
	border-radius: 0px 0px 0.325rem 0.325rem;
	padding: 2px 10px;
	margin-top: -3px;
	font-size: 0.75rem;
	font-weight: 300;
`

export default InputSelect
