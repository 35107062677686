import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {api} from '../../helpers/auth-axios'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {callAutoLogin, saveUser} from '../../actions/authActions'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import VerificationInput from 'react-verification-input'
import Loader from '../common/Loader'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const Header = styled(Text)`
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const StyledSelect = styled.select`
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: 1px solid ${palette.gray_300};
	padding: 10px;
	margin-bottom: 10px;

	&:focus {
		border-color: ${palette.blue_500};
		outline: none;
	}
`

interface ReportPopupProps {
	header?: any
	body?: any
	onReportAndBlock?: (reason: any) => void
	onCancel?: () => void
	onVerified?: () => void
	onClose?: () => void
	buttonTitle?: string
	phone?: any
}

const VerifyPhoneModal = ({header, body, onVerified, onClose, phone}: ReportPopupProps) => {
	const {t, i18n} = useTranslation()
	const dispatch = useDispatch()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [loading, setLoading] = useState(false)

	const handleCancel = () => {
		onClose && onClose()
	}
	const validationSchema = Yup.object().shape({
		verification_code: Yup.string().min(6).required(t('Otp is required'))
	})

	const initialValues = {
		verification_code: ''
	}

	const handleSubmit = async (values: any, actions: any) => {
		setTimeout(() => actions.setSubmitting(false), 10000)
		setLoading(true)
		await api
			.post('/user/verify_user_phone', {...values, phone: phone, email: userDetails?.email})
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					actions.setSubmitting(false)
					actions.resetForm()
					toast.success(res?.data?.message)
					dispatch(callAutoLogin(true))
					onVerified && onVerified()
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const sendOtp = async () => {
		if (!phone) {
			toast.warn(t('Please enter phone first to verify'))
			return
		}
		setLoading(true)
		await api
			.post('/user/send_phone_otp', {phone: phone, email: userDetails?.email})
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					toast.success(res?.data?.message)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	return (
		<Overlay>
			<Modal>
				{loading && <Loader />}
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>

				<Spacer height={2} />
				<Text>{t(body)}</Text>

				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
					{({isSubmitting, errors, setFieldValue, values, touched}) => (
						<Form>
							<Spacer height={1} />
							<Flexed justify={'center'} direction={'row'}>
								<VerificationInput
									autoFocus={true}
									validChars="0-9"
									classNames={{
										character: 'character'
									}}
									onChange={(e: any) => {
										setFieldValue('verification_code', e)
									}}
									value={values.verification_code}
								/>
							</Flexed>

							<Spacer height="2" />

							<ButtonContainer>
								<Button small hasBorder borderRadius={'1rem'} fontSize={'0.8rem'} padding={'8px 16px'} label={<>{t('Cancel')}</>} background={palette.white} color={palette.blue_500} lineHeight={'0.89rem'} ifClicked={handleCancel} />

								<Flexed direction={'row'} gap={'0.2'}>
									<Button
										small
										hasBorder
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'8px 16px 8px 16px'}
										label={<>{t('SEND AGAIN')}</>}
										background={palette.blue_500}
										color={palette.white}
										ifClicked={sendOtp}
										lineHeight={'0.89rem'}
									/>
									<Button
										small
										hasBorder
										disabled={isSubmitting}
										type="submit"
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'8px 16px 8px 16px'}
										label={<>{t('VERIFY OTP')}</>}
										background={palette.blue_500}
										color={palette.white}
										lineHeight={'0.89rem'}
									/>
								</Flexed>
							</ButtonContainer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Overlay>
	)
}

export default VerifyPhoneModal
