import React, {useEffect, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../common/input/Input'
import Button from '../../common/Button'
import {palette} from '../../../styled/colors'
import {Flexed, Spacer, Text} from '../../../styled/shared'
import {Col, Row} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import LinkedInPage from '../../common/LoginWithLinkedin'
import {Card} from '../../AuthPage'
import LogInGoogle from '../../common/LogInGoogle'
import Checkbox from '../../common/Checkbox'
import {FaArrowRight} from 'react-icons/fa'
import useRouter from '../../../hooks/router'
import {Dot} from '../../../pages/SignUp'
import {api} from '../../../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import Loader from '../../common/Loader'
import {callAutoLogin, saveUser} from '../../../actions/authActions'
import {useTranslation} from 'react-i18next'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {AppConstants} from '../../../AppConstants'
import {useSelector} from 'react-redux'
import {AiOutlineClose} from 'react-icons/ai'
import styled from 'styled-components'
import AuthTerms from '../../common/AuthTerms'
import i18n from '../../../i18n/i18n'

const initialValues = {
	email: '',
	password: '',
	loginForWeek: false
}

const LoginForm = ({setSignUpModal, onClose}: {setSignUpModal: any; onClose: any}) => {
	const router = useRouter()
	const dispatch: any = useDispatch()
	const [loading, setLoading] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const {t} = useTranslation()

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
		password: Yup.string().min(8).required(t('Password is required'))
	})

	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/login', values)
			.then(async (res: any) => {
				if (res?.data?.status && res?.data?.data?.user?.is_email_verified) {
					actions.setSubmitting(false)
					actions.resetForm()
					toast.success(res?.data?.message)
					dispatch(saveUser(res?.data?.data?.user))
					localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
					localStorage.setItem('user_lang', res?.data?.data?.user?.language)
					i18n.changeLanguage(res?.data?.data?.user?.language)
					localStorage.setItem('authenticated', 'true')
					onClose()
					setLoading(false)
					if (res?.data?.data?.user?.chat_id) {
						let user = await CometChatUIKit.login(res?.data?.data?.user?.chat_id)?.then((loggedInUser) => {
							return loggedInUser
						})

						if (user) {
							const newUser = new CometChat.User(res?.data?.data?.user?.chat_id)
							newUser.setName(res?.data?.data?.user?.full_name)
							newUser.setMetadata(res?.data?.data?.user)
							newUser.setAvatar(res?.data?.data?.user?.image_Url)
							await CometChat.updateCurrentUserDetails(newUser).then(
								(user) => {
									console.log('updated user', user)
								},
								(error) => {
									console.log('error', error)
								}
							)
						}
						if (!user) {
							// let chat_id = `${res?.data?.data?.user?.first_name.replaceAll(' ', '')}_${Date.now()}`
							// const newUser = new CometChat.User(chat_id)
							// newUser.setName(res?.data?.data?.user?.full_name)
							// newUser.setMetadata(res?.data?.data?.user)
							// newUser.setAvatar(res?.data?.data?.user?.image_Url)
							// const createdUser = await CometChat.createUser(newUser, AppConstants.COMET_CHAT_AUTH_KEY)
							// if (createdUser.getUid()) {
							// 	await api.post('/user/update', {chat_id})
							// 	dispatch(callAutoLogin(true))
							// }
						}
					} else {
						//add user in cometchat
						let chat_id = `${res?.data?.data?.user?.first_name.replaceAll(' ', '')}_${Date.now()}`
						const newUser = new CometChat.User(chat_id)
						newUser.setName(res?.data?.data?.user?.full_name)
						newUser.setMetadata(res?.data?.data?.user)
						newUser.setAvatar(res?.data?.data?.user?.image_Url)
						const createdUser = await CometChat.createUser(newUser, AppConstants.COMET_CHAT_AUTH_KEY)
						if (createdUser.getUid()) {
							await api.post('/user/update', {chat_id})
							dispatch(callAutoLogin(true))
						}
					}
				} else {
					if (res?.data?.data?.user) {
						setLoading(false)
						localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
						router.navigate(`/verify-otp?email=${values?.email}&isloginVerify=${true}`)
					}
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}
	const CloseButton = styled.button`
		position: absolute;
		top: 8px;
		right: 10px;
		padding: 5px;
		background: none;
		border: none;
		cursor: pointer;
	`
	return (
		<Row justifyContent="center">
			{loading && <Loader />}
			<Col md={12}>
				{/* <CloseButton onClick={onClose}>
					<AiOutlineClose />
				</CloseButton> */}
				<Spacer height={1} />
				<Card marginBottom="1rem">
					<Flexed justify="center">
						<Flexed justify="center">
							<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
								{t('Sign in to your account')}
							</Text>
						</Flexed>
						<Spacer height={1.5} />
						<LinkedInPage />
						<Spacer height={1} />
						<LogInGoogle />
						<Spacer height={1} />
						{t('Or')}
						<Spacer height={1} />
						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
							{({isSubmitting, errors, setFieldValue, values, touched}) => (
								<Form>
									<Field
										component={Input}
										name="email"
										type="email"
										label={t('Email')}
										value={values.email ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('email', event?.value, true)
										}}
										error={touched.email && errors.email ? {message: errors.email ?? ''} : null}
									/>

									<Spacer height="1" />

									<Field
										component={Input}
										name="password"
										type="Password"
										label={t('Password')}
										value={values.password ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('password', event?.value, true)
										}}
										error={touched.password && errors.password ? {message: errors.password ?? ''} : null}
									/>
									<Spacer height="0.5" />
									<Text align={'right'} fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'500'} color={'blue_500'} onClick={() => router.navigate('/forgot-password')} pointer>
										{t('Forgot password?')}
									</Text>
									<Spacer height="1" />

									<Flexed direction={'row'} align={'center'} gap={'0.3'}>
										<Checkbox
											label={''}
											defaultChecked={values.loginForWeek}
											onChange={(event: any) => {
												setFieldValue('loginForWeek', event, true)
											}}
										/>
										<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'black'}>
											{t('Stay signed in for a week')}
										</Text>
									</Flexed>
									<Spacer height="1" />
									<Flexed direction={'row'} align={'center'} justify={'center'}>
										<Button
											small
											hasBorder
											disabled={isSubmitting}
											type="submit"
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													{t('CONTINUE TO SIGN IN')} &nbsp;
													<FaArrowRight />
												</>
											}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
										/>
									</Flexed>
								</Form>
							)}
						</Formik>
					</Flexed>
				</Card>
				<Flexed justify="center" align={'center'}>
					<Text fontSize={'0.9'} isCentered lineHeight={'1.25'} fontWeight={'400'} color={'black'}>
						{t('Don’t have an account?')}
					</Text>
					<Spacer height={0.5} />
					<Button
						small
						hasBorder
						type="submit"
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={t('CREATE AN ACCOUNT')}
						background={'transparent'}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => {
							setSignUpModal()
						}}
					/>
				</Flexed>
				<Spacer height={2} />
				<AuthTerms />
			</Col>
		</Row>
	)
}

export default LoginForm
