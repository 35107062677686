import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {api} from '../../helpers/auth-axios'
import {useSelector} from 'react-redux'
import MultiSelect from '../common/MultiSelect'
import {useDispatch} from 'react-redux'
import {callAutoLogin, saveUser} from '../../actions/authActions'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const Header = styled(Text)`
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const StyledSelect = styled.select`
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: 1px solid ${palette.gray_300};
	padding: 10px;
	margin-bottom: 10px;

	&:focus {
		border-color: ${palette.blue_500};
		outline: none;
	}
`

interface ReportPopupProps {
	header?: any
	body?: any
	onReportAndBlock?: (reason: any) => void
	onCancel?: () => void
	onSelect?: () => void
	onClose?: () => void
	buttonTitle?: string
}

const ChangeLanguagePopup = ({header, body, onSelect, onClose, onReportAndBlock}: ReportPopupProps) => {
	const [language, setLanguage] = useState<any>({})
	const [errors, setErrors] = useState<any>({})
	const {t, i18n} = useTranslation()
	const dispatch = useDispatch()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)

	const languageOptions = [
		{label: t('English'), value: 'en'},
		{label: t('Spanish'), value: 'es'},
		{label: t('French'), value: 'fr'}
	]

	const handleCancel = () => {
		onClose && onClose()
	}

	useEffect(() => {
		setLanguage(languageOptions.find((e: any) => e.value == userDetails?.language))
	}, [userDetails])

	const handleChange = async () => {
		try {
			await api
				.post('/user/update', {language: language?.value})
				.then((res: any) => {
					dispatch(saveUser(res?.data?.data))
					localStorage.setItem('user_lang', language?.value)
					i18n.changeLanguage(language?.value)
					dispatch(callAutoLogin(true))
					onSelect && onSelect()
					onClose && onClose()
				})
				.catch((err) => {})
		} catch (error) {}
	}

	return (
		<Overlay>
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>

				<MultiSelect value={language} options={languageOptions} onChange={(e: any) => setLanguage(e)} error={errors.language} />

				<Spacer height={2} />
				<Text>{t(body)}</Text>
				<ButtonContainer>
					<Button small hasBorder borderRadius={'1rem'} fontSize={'0.8rem'} padding={'8px 16px'} label={<>{t('Cancel')}</>} background={palette.white} color={palette.blue_500} lineHeight={'0.89rem'} ifClicked={handleCancel} />
					<Button small hasBorder borderRadius={'1rem'} fontSize={'0.8rem'} padding={'8px 16px'} label={<>{t('Select Language')}</>} background={palette.blue_500} color={palette.white} lineHeight={'0.89rem'} ifClicked={handleChange} />
				</ButtonContainer>
			</Modal>
		</Overlay>
	)
}

export default ChangeLanguagePopup
