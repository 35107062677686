import * as types from './types'

interface SwitchThemeProps {
	payload: boolean
}

export const switchTheme = (payload: any) => {
	return {
		type: types.SWITCH_THEME,
		value: payload
	}
}
export const selectCoinsValue = (payload: any) => {
	return {
		type: types.SELECT_COINS_VALUE,
		value: payload
	}
}
export const setNotification = (payload: any) => {
	return {
		type: types.SET_NOTIFICATION,
		value: payload
	}
}

export const saveSearchQuery = (payload: any) => {
	return {
		type: types.SEARCH_QUERY,
		value: payload
	}
}

export const saveInvitedUser = (payload: any) => {
	return {
		type: types.INVITED_USERS,
		value: payload
	}
}

export const saveUser = (payload: any) => {
	return {
		type: types.SAVE_USERS,
		value: payload
	}
}

export const callAutoLogin = (payload: any) => {
	return {
		type: types.CALL_AUTO_LOGIN,
		value: payload
	}
}

export const chatUsers = (payload: any) => {
	return {
		type: types.CHAT_USERS,
		value: payload
	}
}

export const chatAllUsers = (payload: any) => {
	return {
		type: types.CHAT_ALL_USERS,
		value: payload
	}
}

export const networkUsers = (payload: any) => {
	return {
		type: types.NETWORK_USERS,
		value: payload
	}
}

export const chatUnreadMessages = (payload: any) => {
	return {
		type: types.CHAT_UNREAD_MESSAGES,
		value: payload
	}
}

export const chatUserMessages = (payload: any) => {
	return {
		type: types.CHAT_USER_MESSAGES,
		value: payload
	}
}

export const chatUserMessagesBottom = (payload: any) => {
	return {
		type: types.CHAT_USER_MESSAGES_BOTTOM,
		value: payload
	}
}

export const chatUserDeleteMessages = (payload: any) => {
	return {
		type: types.CHAT_USER_DELETE_MESSAGES,
		value: payload
	}
}

export const chatUserDeleteMessagesBottom = (payload: any) => {
	return {
		type: types.CHAT_USER_DELETE_MESSAGES_BOTTOM,
		value: payload
	}
}
