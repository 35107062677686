import {useEffect, useRef, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {DropContent, Icons, MenuList, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {Dot} from './SignUp'
import {BsClock, BsPersonAdd} from 'react-icons/bs'
import moment from 'moment-timezone'
import useRouter from '../hooks/router'
import {FaArrowRight} from 'react-icons/fa'
import CustomCalendar from '../components/common/DatePicker'
import MultiSelect from '../components/common/MultiSelect'
import BMTSuccess from './BMTSuccess'
import ConfirmationPopup from '../components/modals/ConfirmationModel'
import {api} from '../helpers/auth-axios'
import Loader from '../components/common/Loader'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../actions/authActions'
import Input from '../components/common/input/Input'
import ReportPopup from '../components/modals/ReportModal'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import BeforeSignInModals from '../components/modals/BeforeSignInModals'
import LoginForm from '../components/modals/forms/LoginForm'
import SignUpForm from '../components/modals/forms/SignUpForm'
import OtpVerificationForm from '../components/modals/forms/OtpForm'
import ShareModal from '../components/modals/ShareModal'
import ReportAndBlockModal from '../components/modals/ReportAndBlockModal'
import UserProfile from '../components/UserProfile'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import PageNotFound from './PageNotFound'
import {useLocation, useNavigate} from 'react-router-dom'
import Footer from '../components/Footer'
import DynamicMetaTags from '../components/DynamicMetadata'

interface IProps {
	isDarkTheme?: boolean
}

const BookNetwrokUserMeeting = () => {
	const router: any = useRouter()
	const menuRef: any = useRef(null)
	const [openMenu, setOpenMenu] = useState(false)
	const getUserStatus: any = localStorage.getItem('isNewUser')
	const [selectedBMTOption, setSelectedBMTOption] = useState('')
	const [userData, setUserData] = useState<any>()
	const [scheduleMeeting, setScheduleMeeting] = useState<any>(false)
	const [isBMTtrue, setIsBMTtrue] = useState<any>(false)
	const [userSelected, setUserSelected] = useState<any>({})
	const [selectedDate, setSelectedDate] = useState<any>(moment())
	const [availableSlots, setAvailableSlots] = useState<any[]>([])
	const [selectedSlot, setSelectedSlot] = useState<any>('')
	const [btmlSuccess, setBtmSuccess] = useState<any>(false)
	const [addNetworkSuccess, setAddNetworkSuccess] = useState<any>(false)
	const [confirmationModel, setConfirmationModel] = useState(false)
	const [reportModal, setReportModal] = useState(false)
	const [shareModal, setShareModal] = useState(false)
	const [meetingTitle, setMeetingTitle] = useState<any>('')
	const [meetingTitleError, setMeetingTitleError] = useState<any>('')
	const [loading, setLoading] = useState(false)
	const [selectedTimezone, setSelectedTimezone] = useState<any>('')
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [userTimeZone, setUserTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
	const dispatch = useDispatch()
	const [availableDays, setAvailableDays] = useState<any[]>([])
	const [addedUserInNetwork, setUserAddedInNetwork] = useState(false)
	const [modal, setModal] = useState(false)
	const [login, setloginForm] = useState(true)
	const [apiCall, setIsApiCall] = useState(true)
	const [signUp, setSignUp] = useState(false)
	const [otpForm, setOtpForm] = useState(false)
	const [isProMember, setIsProMember] = useState(false)
	const [membershipOptions, setMembershipOptions] = useState<any[]>([])
	const [createdMeeting, setCreatedMeeting] = useState<any>('')
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)
	const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'))
	const [isDataLoaded, setIsDataLoaded] = useState(true)
	const [isInNetwork, setIsInNetwork] = useState(false)
	const [isNetwork, setIsNetwork] = useState(true)
	const {t} = useTranslation()

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			if (router.location?.state?.isShare) {
				router.navigate(router.pathname, {state: null})
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [router.location?.state?.isShare])

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentTime(moment().format('HH:mm'))
		}, 1000) // Update current time every second

		return () => clearInterval(timer)
	}, [])
	// console.log('=====router.location?.state', router.location, router.query?.username, router.location?.state)

	useEffect(() => {
		if (router.location?.state?.id || router.query?.id) {
			getUserProfile(router.location?.state?.id || router.query?.id)
		} else if (router.query?.username && !router.location?.state?.id && !router.query?.id) {
			router.navigate(router.pathname, {state: null})
			getUserProfile(router.query?.username, true)
		}
	}, [router.location?.state?.id, router.query?.id, router.query?.username])

	useEffect(() => {
		if ((router.location?.state?.id || router.query?.id || userData?.id) && userSelected?.time) {
			getAvailableSlots(selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), selectedTimezone?.value ? selectedTimezone?.value : userTimeZone, userSelected?.time, false)
		}
		if (userData?.id) getUserNetworkRequest(userData?.id)
	}, [router.location?.state?.id, userData, userSelected, router.query?.id])

	useEffect(() => {
		if (router.query?.username && !router.location?.state?.id && !router.query?.id) {
			setIsBMTtrue(true)
			if (userData?.proMembership[0]) {
				setSelectedBMTOption(userData?.proMembership[0].id)
				let options = userData?.proMembership?.map((e: any) => ({label: e?.proMembershipId?.name, value: e}))
				setMembershipOptions(options)
			} else {
				setSelectedBMTOption(userData?.userMembership[0]?.id)
				let options = userData?.userMembership?.map((e: any) => ({label: e?.membershipId?.name, value: e}))
				setMembershipOptions(options)
			}
			const redirectDataString = sessionStorage.getItem('redirect_data')
			const data: any = redirectDataString ? JSON.parse(redirectDataString) : null

			if (data) {
				setSelectedSlot(data.selectedSlot)
				setMeetingTitle(data.meetingTitle)
				getAvailableSlots(moment(data.selectedDate).format('YYYY-MM-DD'), data.timezone, data.duration)
				setSelectedDate(moment(data.selectedDate))
				setSelectedSlot(data.selectedSlot)
			}
			if (data && data.timezone && data.duration && meetingTitle && selectedDate && userSelected && apiCall) {
				setIsApiCall(false)
				buyMyTime(null, data.timezone, data.duration)
			}
		}
	}, [userData, userSelected, router.query?.username])

	useEffect(() => {
		if (userData?.proMembership && userData?.proMembership.length) {
			setIsProMember(true)
			const data = {
				coins: userData?.proMembership[0].coins,
				time: userData?.proMembership[0].proMembershipId.time,
				name: userData?.proMembership[0].proMembershipId.name,
				price: userData?.proMembership[0].price,
				id: userData?.proMembership[0].proMembershipId.id
			}
			setUserSelected(data)
		} else {
			setUserSelected(userData?.userMembership[0]?.membershipId)
		}
	}, [userData])

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenMenu(false) // Close the menu if clicked outside of it
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [menuRef])

	const getUserAvailableDays = async (user_id?: any, date = moment().format(), timezone?: any) => {
		await api.post(`/meeting/user_availability`, {user_id, date, timezone}).then((res: any) => {
			if (res?.data?.status) {
				setAvailableDays(res?.data?.data)
			}
		})
	}

	const getUserProfile = async (id?: any, isShare = false) => {
		setLoading(true)
		await api
			.get(`/user/get_user_profile?id=${id}&isShare=${isShare}&username=${id}`)
			.then(async (res: any) => {
				if (res?.data) {
					setLoading(false)
					setUserTimezone(res?.data?.data?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
					setUserData(res?.data?.data)
					setIsDataLoaded(false)
				}
			})
			.catch((err: any) => {
				setLoading(false)
				setIsDataLoaded(false)
			})
	}

	const reportUser = async (reason: string, isBlock = false) => {
		setLoading(true)

		await api
			.post(`/user/report_user`, {f_id: userData.id, reason, isBlock})
			.then((res: any) => {
				if (res?.data) {
					setLoading(false)
					toast.success(res.data.message)
					setUserData(res?.data?.data)
					router.navigate('/network')
				}
			})
			.catch((err: any) => {
				setLoading(false)
			})
	}

	const blockUser = async () => {
		setLoading(true)

		await api
			.post(`/user/block_user`, {f_id: userData.id})
			.then((res: any) => {
				if (res?.data) {
					setLoading(false)
					toast.success(res.data.message)
					router.navigate('/network')
				}
			})
			.catch((err: any) => {
				setLoading(false)
			})
	}

	const removeNetwork = async () => {
		await api
			.post(`/user/remove_network`, {f_id: userData.id})
			.then((res: any) => {
				if (res?.data) {
					toast.success(res.data.message)
					// setUserData(res?.data?.data)/
					setIsInNetwork(false)
					// router.navigate('/network')
				}
			})
			.catch((err: any) => {})
	}

	const getAvailableSlots = async (selectedDate?: any, timezone?: any, duration?: any, loading = true) => {
		setLoading(loading)
		if (!localStorage.getItem('authorization')) {
			const data = {
				selectedDate: selectedDate,
				timezone: timezone,
				duration: duration,
				selectedSlot
			}

			sessionStorage.setItem('redirect_data', JSON.stringify(data))
		} else {
		}
		if (router.query?.id || router.location?.state?.id || userData?.id) {
			await api
				.post(`/meeting/get_available_stols`, {user_id: router.query?.id || router.location?.state?.id || userData?.id, date: selectedDate, timezone: timezone, duration})
				.then((res: any) => {
					if (res?.data?.status) {
						setAvailableSlots(res?.data?.data?.slots)
					}
					setLoading(false)
				})
				.catch((error: any) => {
					setLoading(false)
				})
		}
	}

	const types = ['meeting', 'conference', 'presentation', 'training', 'meal', 'happy hour']
	const getRandomType = () => {
		const randomIndex = Math.floor(Math.random() * types.length)
		return types[randomIndex]
	}
	const dateTimeString = moment(selectedDate).format('YYYY-MM-DD') + 'T' + selectedSlot + ':00'
	const start = moment(dateTimeString)
	const formattedStart = start.format()

	// console.log('====userSelected?.timezone', userData?.timezone)
	//add meeting
	const buyMyTime = async (selectedDate?: any, timezone?: any, duration?: any, selectedSlot?: any) => {
		let end = moment(formattedStart).add(duration, 'minute').format()
		if (meetingTitle?.trim()?.length) {
			setMeetingTitleError('')
			setLoading(true)
			const randomType = getRandomType()
			await api
				.post(`/meeting/add_meeting`, {
					f_id: userData?.id,
					start_time: formattedStart,
					end_time: end,
					timezone: userDetails?.timezone ? userDetails?.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
					duration,
					type: randomType,
					is_video: true,
					title: meetingTitle,
					required_coins: userSelected?.coins,
					m_id: userSelected?.id,
					receiver_timezone: userTimeZone
				})
				.then((res: any) => {
					if (res?.data?.status) {
						setBtmSuccess(true)
						setLoading(false)
						setCreatedMeeting(res?.data?.data)
						toast.success(res?.data?.message)
						sessionStorage.removeItem('redirect_data')
						sessionStorage.removeItem('redirect_url')
						setAvailableSlots(res?.data?.data?.slots)
						dispatch(callAutoLogin(true))
					} else {
						if (res?.data?.message === 'Please buy coins to schedule meeting') {
							router.navigate('/buy-coins')
						} else {
							const redirectDataString = sessionStorage.getItem('redirect_data')
							const data: any = redirectDataString ? JSON.parse(redirectDataString) : null
							if (data) {
								getAvailableSlots(moment(data.selectedDate).format('YYYY-MM-DD'), data.timezone, data.duration, false)
								setSelectedDate(moment(data.selectedDate))
								setSelectedSlot(data.selectedSlot)
							}
						}
						setIsApiCall(true)

						toast.error(res?.data?.message)
					}

					setLoading(false)
				})
				.catch((error: any) => {
					if (error?.response?.status === 403) {
						const redirectUrl = `${window.location.pathname}${window.location.search}`
						const redirectDataString = sessionStorage.getItem('redirect_data')
						const data: any = redirectDataString ? JSON.parse(redirectDataString) : null
						const newData = {...data, meetingTitle: meetingTitle}
						sessionStorage.setItem('redirect_data', JSON.stringify(newData))
						sessionStorage.setItem('redirect_url', redirectUrl)
						setModal(true)
						setIsApiCall(true)
					}
					setLoading(false)
				})
		} else {
			setMeetingTitleError('Meeting title is required')
		}
	}

	const addToNetwork = async (id: number) => {
		try {
			setLoading(true)
			await api.post(`/user/add_network`, {f_id: id})
			setUserAddedInNetwork(true)
			setAddNetworkSuccess(true)
			setIsInNetwork(true)
			toast.success(t('User added to network successfully.'))
			setLoading(false)
		} catch (error) {
			toast.error('Failed to add user to network.')
			setLoading(false)
		}
	}

	const getUserNetworkRequest = async (id: any) => {
		const response = await api.post('/user/single_network_request', {f_id: id ? id : userData?.id})
		if (response.data.status) {
			setIsNetwork(false)
			if (response.data.data) {
				setIsInNetwork(true)
				setScheduleMeeting(true)
			}
		} else {
			setIsNetwork(false)
		}
	}

	const handleSelectedSlot = (slot: any) => {
		setMeetingTitleError('')
		const redirectDataString = sessionStorage.getItem('redirect_data')
		const data: any = redirectDataString ? JSON.parse(redirectDataString) : null
		const newData = {...data, selectedSlot: slot}
		sessionStorage.setItem('redirect_data', JSON.stringify(newData))
		setSelectedSlot(slot)
	}

	const currentDay = moment(selectedDate ? selectedDate : moment())

	const currentMonthDays = currentDay.daysInMonth()
	const _currentDay = moment().date()
	const daysArray = Array.from({length: currentMonthDays}, (_, index) => index + 1 >= _currentDay && index + 1)
	const setSignUpModal = () => {
		setloginForm(false)
		setSignUp(true)
	}

	const setOtpFormModal = () => {
		setSignUp(false)
		setOtpForm(true)
	}

	const handleProUserSelected = (card: any) => {
		console.log(card, 'This is the card')
		const data = {
			coins: card.coins,
			time: card.proMembershipId.time,
			price: card.price,
			id: card.m_id,
			name: card?.proMembershipId?.name
		}
		setUserSelected(data)
	}

	return (
		<MainContainer>
			{loading && <Loader />}

			<Spacer height={btmlSuccess ? 4 : 5} />
			<DynamicMetaTags title={userData ? `Join ${userData?.full_name} on the JustNetwork platform and turn your valuable time into profit! Connect, collaborate, and profit from your time.` : ''} imageUrl={userData?.image_Url ? userData?.image_Url : ''} url={`${window.location.origin}/${userDetails?.user_name}`} description={userData?.specifications ? userData?.specifications : ''}></DynamicMetaTags>
			<Container fluid>
				{btmlSuccess ? (
					<Row>
						<Col lg={12}>
							<Flexed direction="column" align={'center'} gap={'0.6'}>
								<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
									<>
										<Flexed direction="column" align={'left'} gap={'0.6'}>
											<Flexed direction="column" align={''} gap={'0'}>
												<Flexed direction="row" align={'center'} gap={'0.6'}>
													<Icons
														src={'/images/icon_view.svg'}
														onClick={() => {
															setIsBMTtrue(false)
															setUserSelected('')
															setSelectedDate('')
															setBtmSuccess(false)
															setSelectedSlot('')
														}}
														pointer={'pointer'}
													/>
												</Flexed>
											</Flexed>

											<BMTSuccess data={{userSelected, selectedDate, selectedSlot, userData, createdMeeting}} />
										</Flexed>
									</>
								</Card>
								{/* <Spacer height={'0.5'} /> */}
							</Flexed>
						</Col>
					</Row>
				) : userData ? (
					<Row>
						<Col xl={3} lg={12} md={12} sm={12}>
							<Card overflow={'visible'}>
								{userDetails && userDetails?.id !== userData?.id && (
									<Flexed direction="row" justify={'flex-end'} margin={'0.4rem'}>
										<MenuList type="normal" onSmall>
											<Text onClick={() => setOpenMenu(true)}>
												<Icons pointer={'pointer'} src={'/images/icon_options.svg'} />
											</Text>
											<DropContent ref={menuRef} top={'92%'} display={openMenu ? true : false}>
												<DropMenu
													onClick={() => {
														const handleRequest = async () => {
															if (userDetails) {
																if (!userData?.chat_id) {
																	toast.warning(t('Chat id not exist'))
																} else if (userData?.chat_id) {
																	await CometChat.getUser(userData?.chat_id?.toLowerCase()).then(
																		(res: any) => {
																			if (res?.blockedByMe || res?.hasBlockedMe) {
																				toast.warning(res?.blockedByMe ? t('You blocked this user') : t('User blocked'))
																			} else if (myNetworkUsers?.includes(userData?.chat_id?.toLowerCase())) {
																				router.navigate(`/inbox?id=${userData?.chat_id?.toLowerCase()}&network=${true}`)
																				console.log('User details fetched for user:', res)
																			} else {
																				toast.warning(t('User not in your network'))
																			}
																		},
																		(error) => {
																			toast.warning(t('User not exist in chat'))
																			console.log('User details fetching failed with error:', error)
																		}
																	)
																} else {
																	toast.warning(t('Network request not accepted yet'))
																}
															}
														}

														handleRequest()
													}}>
													<Icons src={'/images/icon_message.svg'} /> {t('Message')}
												</DropMenu>
												<DropMenu onClick={() => setShareModal(true)}>
													<Icons src={'/images/icon_share.svg'} /> {t('Share Network')}
												</DropMenu>
												<DropMenu
													onClick={() => {
														!isInNetwork ? setConfirmationModel(true) : removeNetwork()
													}}>
													{!isInNetwork ? (
														<>
															<BsPersonAdd style={{fontSize: '1.2rem'}} /> {t('Add Network')}
														</>
													) : (
														<>
															<Icons src={'/images/icon_block.svg'} /> {t('Remove Network')}
														</>
													)}
												</DropMenu>
												<DropMenu onClick={() => setReportModal(true)}>
													<Icons src={'/images/icon_report.svg'} /> {t('Report /Block')}
												</DropMenu>
											</DropContent>
										</MenuList>
									</Flexed>
								)}
								<UserProfile userDetails={userData} />
							</Card>
						</Col>
						<Col xl={9} lg={12} md={12} sm={12}>
							<CustomSmallSpace height={2} />
							{isBMTtrue ? (
								<>
									<Flexed direction="column" align={'center'} gap={'0.6'}>
										<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
											<>
												<Row>
													<Col lg={6}>
														<Flexed direction="column" align={'left'} gap={'0.6'}>
															<Flexed direction="column" align={''} gap={'0'}>
																<Flexed direction="row" align={'center'} gap={'0.6'}>
																	{router.query?.username && !router.location?.state?.id && !router.query?.id ? '' : <Icons src={'/images/icon_view.svg'} onClick={() => setIsBMTtrue(false)} pointer={'pointer'} />}
																	<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} align={'left'}>
																		{t('Schedule Meeting')}
																	</Text>
																</Flexed>
																<Spacer height={'1'} />
																<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
																	{t(`Please book a one-on-one networking call with`)} <b> {userData.full_name} </b> {t('by selecting the membership below.')}
																</Text>
															</Flexed>
															<Spacer height={'0.5'} />
															<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={0.8} flexWrap={'wrap'}>
																<Text fontSize={'1.5'} lineHeight={'1.84'} fontWeight={'700'} color={'text_color'}>
																	<Icons src={'/images/appointment_card_time.svg'} />
																	&nbsp;{userSelected?.time} {t('minutes')}
																</Text>

																<Flexed direction={'row'} justify={'space-between'}>
																	<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
																		<Icons src={'/images/video_meeting_icon.svg'} /> {t('Web conferencing details provided upon confirmation')}
																	</Text>
																</Flexed>
																<Spacer height={'1'} />
															</Flexed>
														</Flexed>
													</Col>
													<Col lg={6}>
														<Flexed direction="column" justify={'space-between'} align={''} height={'100%'}>
															<CustomFlexedForWrap direction={router.query?.username && !router.location?.state?.id && !router.query?.id ? 'column' : 'row'} align={'center'} gap={'0.5'} flexWrap={router.query?.username && !router.location?.state?.id && !router.query?.id ? 'nowrap' : 'wrap'}>
																<Flexed direction={'row'} align={'center'} flexWrap={'wrap'} width={'100%'} gap={'1rem'} justify={'center'}>
																	{userSelected?.type?.toLowerCase().includes('diamond') ? <Icons height={'100%'} src={'/images/daimond_member_card.svg'} /> : userSelected?.type?.toLowerCase().includes('gold') ? <Icons height={'100%'} src={'/images/gold_member_card.svg'} /> : userSelected?.type?.toLowerCase().includes('silver') ? <Icons height={'100%'} src={'/images/silver_member_card.svg'} /> : userSelected?.type?.toLowerCase().includes('bronze') ? <Icons height={'100%'} src={'/images/bronze_member_card.svg'} /> : isProMember ? <Icons height={'100%'} src={isProMember ? '/images/pro_member_card.svg' : '/images/daimond_member_card.svg'} /> : ''}
																	&nbsp;
																	<AddNnetworkFlexed direction={'row'} align={'end'} flexWrap={'wrap'} width={'9%'}>
																		{isInNetwork && userData
																			? ''
																			: isNetwork == false && (
																					<Button
																						small
																						borderRadius={'1rem'}
																						fontSize={'0.8rem'}
																						// disabled={!selectedBMTOption}
																						padding={'0'}
																						label={<>{t('ADD TO NETWORK')}</>}
																						background={palette.blue_500}
																						color={palette.white}
																						minWidth={'10rem'}
																						lineHeight={'0.89rem'}
																						ifClicked={() => {
																							setConfirmationModel(true)
																						}}
																					/>
																			  )}
																	</AddNnetworkFlexed>
																</Flexed>

																{router.query?.username && !router.location?.state?.id && !router.query?.id && userData?.userMembership?.length && !isProMember ? (
																	<Flexed direction={'row'} gap={'1'} flexWrap={'wrap'} justify={'center'}>
																		{userData?.userMembership?.map((card: any, i: any) => (
																			<CustomCards
																				removeShadow
																				radius={'0.6rem'}
																				background={'transparent'}
																				borderColor={palette.blue_500}
																				width={'12.31rem'}
																				minHeight={'auto'}
																				height={'4.5rem'}
																				pointer={'pointer'}
																				onClick={() => {
																					setSelectedBMTOption(card?.id)
																					setUserSelected(card?.membershipId)
																				}}>
																				<NumberCircle fontSize={'1'} height={'1.5rem'} width={'1.5rem'} lineHeight={'1.21'} fontWeight={'800'} left={'-0.8rem'} color={userSelected?.id == card?.membershipId?.id ? 'white' : 'blue_500'} isCentered selected={userSelected?.id == card?.membershipId?.id}>
																					{i + 1}
																				</NumberCircle>

																				<Flexed align={'center'} padding={'0 1rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																					<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
																						{card?.membershipId?.name}
																					</Text>
																				</Flexed>
																				<Spacer height={0.1} />

																				<Flexed align={'center'} padding={'0.2rem 0.9rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																					<Flexed align={'start'} gap={'0.2'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																						<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																							<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																								<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
																								&nbsp;{parseInt(card?.membershipId?.coins)}
																							</Text>
																							<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																								{t('coins')}
																							</Text>
																						</Flexed>

																						<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																							<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																								<Icons src={'/images/schedule.svg'} style={{height: '0.9rem'}} />
																								&nbsp;{parseInt(card?.membershipId?.time)}
																							</Text>
																							<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																								{t('minutes')}
																							</Text>
																						</Flexed>
																					</Flexed>

																					<Flexed
																						align={'center'}
																						gap={'0.2'}
																						justify={'center'}
																						direction={'row'}
																						style={{background: palette.input_bg, borderRadius: '5px'}}
																						// width={'4.3rem'}
																						padding={'0 0.3rem '}
																						height={'2rem'}>
																						<Text fontSize={'1.25'} lineHeight={'1.53'} fontWeight={'700'} color={'text_color'}>
																							${card?.membershipId?.price}
																						</Text>
																					</Flexed>
																				</Flexed>
																			</CustomCards>
																		))}
																	</Flexed>
																) : router.query?.username && !router.location?.state?.id && !router.query?.id && isProMember ? (
																	<Flexed direction={'row'} gap={'1'} flexWrap={'wrap'} justify={'center'}>
																		{userData?.proMembership?.map((card: any, i: any) => (
																			<CustomCards
																				removeShadow
																				radius={'0.6rem'}
																				background={'transparent'}
																				borderColor={palette.blue_500}
																				width={'12.31rem'}
																				minHeight={'auto'}
																				height={'4.5rem'}
																				pointer={'pointer'}
																				onClick={() => {
																					setSelectedBMTOption(card?.id)
																					handleProUserSelected(card)
																				}}>
																				<NumberCircle fontSize={'1'} lineHeight={'1.21'} fontWeight={'800'} color={userSelected?.id == card?.m_id ? 'white' : 'blue_500'} isCentered selected={userSelected?.id == card?.m_id}>
																					{i + 1}
																				</NumberCircle>
																				<Flexed align={'center'} padding={'0 1rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																					<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
																						{card?.proMembershipId?.name}
																					</Text>
																				</Flexed>
																				<Spacer height={0.1} />

																				<Flexed align={'center'} padding={'0.2rem 0.9rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																					<Flexed align={'start'} gap={'0.2'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																						<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																							<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																								<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
																								&nbsp;{parseInt(card?.coins)}
																							</Text>
																							<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																								{t('coins')}
																							</Text>
																						</Flexed>

																						<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																							<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																								<Icons src={'/images/schedule.svg'} style={{height: '0.9rem'}} />
																								&nbsp;{parseInt(card?.proMembershipId?.time)}
																							</Text>
																							<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																								{t('minutes')}
																							</Text>
																						</Flexed>
																					</Flexed>

																					<Flexed
																						align={'center'}
																						gap={'0.2'}
																						justify={'center'}
																						direction={'row'}
																						style={{background: palette.input_bg, borderRadius: '5px'}}
																						// width={'4.3rem'}
																						padding={'0 0.3rem '}
																						height={'2rem'}>
																						<Text fontSize={'1.25'} lineHeight={'1.53'} fontWeight={'700'} color={'text_color'}>
																							${card?.price}
																						</Text>
																					</Flexed>
																				</Flexed>
																			</CustomCards>
																		))}
																	</Flexed>
																) : (
																	<CustomCards removeShadow marginLeft={'0.1rem'} radius={'0.6rem'} background={userSelected?.type?.toLowerCase().includes('diamond') ? palette.diamond_ligh : userSelected?.type?.toLowerCase().includes('gold') ? palette?.gold_light : userSelected?.type?.toLowerCase().includes('silver') ? palette.silver_light : userSelected?.type?.toLowerCase().includes('bronz') ? palette.bronze_light : palette.purpole_gradient} width={'12.31rem'} minHeight={'auto'} height={'auto'} onClick={() => setSelectedBMTOption('bronz')}>
																		<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'} isCentered>
																			{userSelected?.name}
																		</Text>

																		<Flexed align={'center'} padding={userSelected?.name?.toLowerCase()?.includes('pro') ? undefined : '0rem '} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																			<Flexed align={'start'} gap={'0.7'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																				<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																					<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																						<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
																						&nbsp;{userSelected?.coins}
																					</Text>
																					<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																						{'coins'}
																					</Text>
																				</Flexed>

																				<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																					<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																						<Icons src={'/images/schedule.svg'} style={{height: '0.9rem'}} />
																						&nbsp;{userSelected?.time}
																					</Text>
																					<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																						{t('minutes')}
																					</Text>
																				</Flexed>
																			</Flexed>

																			<Flexed align={'center'} gap={'0.2'} justify={'center'} direction={'row'} style={{background: palette.white, borderRadius: '5px'}} width={'3.75rem'} height={'2.5rem'}>
																				<Text fontSize={'1.25'} lineHeight={'1.53'} fontWeight={'700'} color={'text_color'}>
																					${userSelected?.price}
																				</Text>
																			</Flexed>
																		</Flexed>
																	</CustomCards>
																)}
															</CustomFlexedForWrap>
															<Spacer height={0} />
															{/* <Flexed direction="column" padding={'2rem'}>
																<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'black'} isCentered fontStyle={'italic'}>
																	In this exclusive {userSelected?.time}-minute call, you’ll have the undivided attention of Will Smith who is dedicated to providing you with insightful advice & guidance.
																</Text>
															</Flexed> */}
														</Flexed>
													</Col>
												</Row>
											</>
										</Card>
										<Spacer height={'0.5'} />
									</Flexed>
									<Row>
										<Col lg={8} md={12} sm={12}>
											<Card background={palette.white} padding={'1rem 1.2rem'} style={{overflow: 'hidden'}}>
												<CustomCalendar
													availableDays={daysArray}
													handleSelection={(date: any) => {
														setMeetingTitleError('')
														setMeetingTitle('')
														setSelectedSlot('')
														setSelectedDate(date)
														getAvailableSlots(moment(date).format('YYYY-MM-DD'), selectedTimezone?.value ? selectedTimezone?.value : userTimeZone, userSelected?.time)
													}}
													selecteddate={selectedDate}
												/>
												<Flexed direction={'row'} align={'center'} justify={'space-between'} padding={'0.1rem 1rem'} flexWrap={'wrap'} gap={'0.5'}>
													<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
														<Dot height={'1rem'} width={'1rem'} bg={palette.grey_1} />
														<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
															{t('Unavailable Dates')}
														</Text>
													</Flexed>
													<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
														<Dot height={'1rem'} width={'1rem'} bg={palette.blue_100} />
														<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
															{t('Available Dates')}
														</Text>
													</Flexed>
													<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
														<Dot height={'1rem'} width={'1rem'} bg={palette.blue_100} border={`1px solid ${palette.blue_400}`} />
														<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
															{t('Today’s Date')}
														</Text>
													</Flexed>
													<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
														<Dot height={'1rem'} width={'1rem'} bg={palette.blue_500} />
														<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
															{t('Selected Date')}
														</Text>
													</Flexed>
												</Flexed>
											</Card>
										</Col>
										<Col lg={4} md={12} sm={12}>
											<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
												<>
													<Flexed direction="row" align={'center'} gap={'0.6'}>
														<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} align={'left'}>
															{t('Choose Time Slot')}
														</Text>
													</Flexed>
													<Spacer height={'1'} />
													<Flexed direction={'row'} justify={'space-between'} align={'center'}>
														<Flexed width={'30%'}>
															<Text fontSize={'0.81'} lineHeight={'0.95'} fontWeight={'600'} color={'text_color'} align={'left'}>
																{t('Time Zone')}
															</Text>
														</Flexed>
														<Flexed width={'70%'}>
															<MultiSelect
																placeholder={t('Choose timezone')}
																padding={'0'}
																radius={'5px'}
																value={selectedTimezone ? selectedTimezone : userDetails?.timezone ? {label: userDetails?.timezone, value: userDetails?.timezone} : {label: userTimeZone, value: userTimeZone}}
																options={moment.tz.names().map((timezone: any) => {
																	return {label: timezone, value: timezone}
																})}
																onChange={(e: any) => {
																	setSelectedTimezone(e)
																	getAvailableSlots(selectedDate, e?.value, userSelected?.time)
																}}
																noOptionsMessage={t('no option available')}
															/>
														</Flexed>
													</Flexed>
													<Spacer height={'1'} />
													{selectedDate ? (
														<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'text_color'} align={'left'}>
															{moment(selectedDate).format('dddd, MMMM Do, YYYY')}
														</Text>
													) : (
														''
													)}
													<Spacer height={'1'} />
													<Flexed direction="column" align={'center'} gap={'0.6'} height={'24rem'} style={{overflowY: 'scroll'}}>
														{availableSlots?.length ? (
															availableSlots?.map((slot: any, index: any) =>
																selectedSlot == slot ? (
																	<Flexed direction="column" align={'center'}>
																		<Spacer height={0.5} />
																		<Input label="Enter Meeting Title" value={meetingTitle} error={meetingTitle?.trim()?.length == 0 && meetingTitleError ? {message: meetingTitleError} : undefined} onChange={(e: any) => setMeetingTitle(e?.value)} />
																		<Flexed direction="row" align={'center'} gap={'0.6'} padding={'0.8rem'} justify={'center'} flexWrap={'wrap'}>
																			<Flexed background={palette.blue_500} height={'3rem'} width={'7rem'} radius={'5px'} direction={'row'} align={'center'} justify={'center'}>
																				<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'600'} color={'white'} isCentered>
																					{moment(moment().format() + ' ' + slot, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
																				</Text>
																			</Flexed>
																			<Button
																				small
																				borderRadius={'1rem'}
																				fontSize={'0.8rem'}
																				disabled={!selectedBMTOption}
																				label={
																					<>
																						<BsClock /> &nbsp; {t('Buy My Time')}
																					</>
																				}
																				background={palette.default_theme}
																				color={palette.white}
																				padding={'8px 16px 8px 16px'}
																				lineHeight={'0.89rem'}
																				ifClicked={() => buyMyTime(selectedDate, selectedTimezone?.value ? selectedTimezone?.value : userTimeZone, userSelected?.time, selectedSlot)}
																			/>
																		</Flexed>
																	</Flexed>
																) : moment()
																		.tz(userTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
																		.format('DD-MM') == moment(selectedDate).format('DD-MM') ? (
																	currentTime <= slot ? (
																		<Flexed pointer direction="row" align={'center'} gap={'0.6'} padding={'0.8rem'} justify={'center'} border={`1px solid ${palette.blue_500}`} width={'100%'} radius={'5px'} onClick={() => handleSelectedSlot(slot)}>
																			<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'600'} color={'blue_500'} isCentered>
																				{moment(moment().format() + ' ' + slot, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
																			</Text>
																		</Flexed>
																	) : (
																		<>
																			{index == availableSlots?.length - 1 ? (
																				<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'}>
																					<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
																						{'No slot available!'}
																					</Text>
																				</Flexed>
																			) : (
																				''
																			)}
																		</>
																	)
																) : (
																	<Flexed pointer direction="row" align={'center'} gap={'0.6'} padding={'0.8rem'} justify={'center'} border={`1px solid ${palette.blue_500}`} width={'100%'} radius={'5px'} onClick={() => handleSelectedSlot(slot)}>
																		<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'600'} color={'blue_500'} isCentered>
																			{moment(moment().format() + ' ' + slot, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
																		</Text>
																	</Flexed>
																)
															)
														) : (
															<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'}>
																<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
																	{selectedDate ? 'No slot available!' : 'Please select date!'}
																</Text>
															</Flexed>
														)}
													</Flexed>
												</>
											</Card>
											<Spacer height={2} />
										</Col>
									</Row>
								</>
							) : (
								<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'} height={'100vh'}>
									<>
										<Flexed direction="row" align={'center'} gap={'0.6'}>
											<Icons src={'/images/icon_view.svg'} onClick={() => router.navigate(router?.location?.state?.route ? router?.location?.state?.route : '/network')} pointer={'pointer'} />
											{router?.location?.state?.suggested && scheduleMeeting == false ? (
												''
											) : (
												<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} align={'left'}>
													{t('Schedule Meeting')}
												</Text>
											)}
										</Flexed>
										<Spacer height={'1'} />
										{router?.location?.state?.suggested && scheduleMeeting == false ? (
											''
										) : (
											<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
												{t(`Please book a one-on-one networking call with`)} <b> {userData.full_name} </b> {t('by selecting the membership below.')}
											</Text>
										)}

										<CustomFlexed direction="column" align={'center'} width={'100%'} height={'100%'} padding={' 2rem'}>
											{!addNetworkSuccess && (
												<>
													{router?.location?.state?.suggested && scheduleMeeting == false ? (
														''
													) : (
														<>
															<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'400'} color={'black'} isCentered>
																{t('You have two BMT options to choose')}
															</Text>
														</>
													)}

													{userData?.userMembership[0]?.membershipId?.type?.toLowerCase().includes('diamond') && !isProMember ? <Icons width={'100%'} src={'/images/daimond_member_card.svg'} height={'19%'} /> : userData?.userMembership[0]?.membershipId?.type?.toLowerCase().includes('gold') && !isProMember ? <Icons width={'100%'} src={'/images/gold_member_card.svg'} height={'19%'} /> : userData?.userMembership[0]?.membershipId?.type?.toLowerCase().includes('silver') && !isProMember ? <Icons width={'100%'} src={'/images/silver_member_card.svg'} height={'19%'} /> : userData?.userMembership[0]?.membershipId?.type?.toLowerCase().includes('bronze') && !isProMember ? <Icons width={'100%'} src={'/images/bronze_member_card.svg'} height={'19%'} /> : isProMember ? <Icons width={'100%'} src={isProMember ? '/images/pro_member_card.svg' : '/images/daimond_member_card.svg'} height={'19%'} /> : ''}
												</>
											)}

											<Spacer height={1} />
											{!addNetworkSuccess && (
												<Flexed direction="column" align={'center'} gap={'0.6'}>
													{userData?.userMembership?.length && !isProMember ? (
														userData?.userMembership?.map((card: any, i: any) => (
															<CustomCards
																removeShadow
																radius={'0.6rem'}
																background={'transparent'}
																borderColor={palette.blue_500}
																width={'14.31rem'}
																minHeight={'auto'}
																height={'6rem'}
																pointer={scheduleMeeting ? 'pointer' : 'default'}
																onClick={() => {
																	if (scheduleMeeting) {
																		setSelectedBMTOption(card?.id)
																		setUserSelected(card?.membershipId)
																	}
																}}>
																{scheduleMeeting && (
																	<NumberCircle fontSize={'1'} lineHeight={'1.21'} fontWeight={'800'} color={selectedBMTOption == card?.id ? 'white' : 'blue_500'} isCentered selected={selectedBMTOption == card?.id}>
																		{i + 1}
																	</NumberCircle>
																)}

																<Flexed align={'center'} padding={'0 1rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																	<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
																		{card?.membershipId?.name}
																	</Text>
																</Flexed>
																<Spacer height={0.4} />

																<Flexed align={'center'} padding={'0.2rem 0.9rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																	<Flexed align={'start'} gap={'0.7'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																		<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																			<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																				<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
																				&nbsp;{parseInt(card?.membershipId?.coins)}
																			</Text>
																			<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																				{t('coins')}
																			</Text>
																		</Flexed>

																		<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																			<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																				<Icons src={'/images/schedule.svg'} style={{height: '0.9rem'}} />
																				&nbsp;{parseInt(card?.membershipId?.time)}
																			</Text>
																			<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																				{t('minutes')}
																			</Text>
																		</Flexed>
																	</Flexed>

																	<Flexed align={'center'} gap={'0.2'} justify={'center'} direction={'row'} style={{background: palette.input_bg, borderRadius: '5px'}} width={'5.3rem'} height={'3rem'}>
																		<Text fontSize={'1.25'} lineHeight={'1.53'} fontWeight={'700'} color={'text_color'}>
																			${card?.membershipId?.price}
																		</Text>
																	</Flexed>
																</Flexed>
															</CustomCards>
														))
													) : isProMember ? (
														userData?.proMembership?.map((card: any, i: any) => (
															<CustomCards
																removeShadow
																radius={'0.6rem'}
																background={'transparent'}
																borderColor={palette.blue_500}
																width={'14.31rem'}
																minHeight={'auto'}
																height={'6rem'}
																pointer={scheduleMeeting ? 'pointer' : 'defualt'}
																onClick={() => {
																	if (scheduleMeeting) {
																		setSelectedBMTOption(card?.id)
																		handleProUserSelected(card)
																	}
																}}>
																{scheduleMeeting && (
																	<NumberCircle fontSize={'1'} lineHeight={'1.21'} fontWeight={'800'} color={selectedBMTOption == card?.id ? 'white' : 'blue_500'} isCentered selected={selectedBMTOption == card?.id}>
																		{i + 1}
																	</NumberCircle>
																)}

																<Flexed align={'center'} padding={'0 1rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																	<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
																		{card?.proMembershipId?.name}
																	</Text>
																</Flexed>
																<Spacer height={0.4} />

																<Flexed align={'center'} padding={'0.2rem 0.9rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																	<Flexed align={'start'} gap={'0.7'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																		<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																			<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																				<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.9rem'}} />
																				&nbsp;{parseInt(card?.coins)}
																			</Text>
																			<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																				{t('coins')}
																			</Text>
																		</Flexed>

																		<Flexed align={'center'} gap={'0.2'} direction={'row'}>
																			<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'text_color'}>
																				<Icons src={'/images/schedule.svg'} style={{height: '0.9rem'}} />
																				&nbsp;{parseInt(card?.proMembershipId?.time)}
																			</Text>
																			<Text fontSize={'0.7'} lineHeight={'0.81'} fontWeight={'400'} color={'zink_400'}>
																				{t('minutes')}
																			</Text>
																		</Flexed>
																	</Flexed>

																	<Flexed align={'center'} gap={'0.2'} justify={'center'} direction={'row'} style={{background: palette.input_bg, borderRadius: '5px'}} width={'5.3rem'} height={'3rem'}>
																		<Text fontSize={'1.25'} lineHeight={'1.53'} fontWeight={'700'} color={'text_color'}>
																			${card?.price}
																		</Text>
																	</Flexed>
																</Flexed>
															</CustomCards>
														))
													) : (
														<Text fontSize={'0.9'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
															{t('No active membership found!')}
														</Text>
													)}
												</Flexed>
											)}

											<Spacer height={'1'} />
											{addedUserInNetwork == false && router?.location?.state?.suggested ? (
												<>
													<Spacer height={'1.5'} />
													<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} isCentered>
														{t('Add to your Network')}
													</Text>
													<Spacer height={'0.5'} />
													<Paragraph fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'400'} color={'text_color'} isCentered>
														{t(`Please add`)} <b>{userData.full_name}</b> {t(`to your Network to book a one-on-one networking call`)}
													</Paragraph>
													<Spacer height={'1'} />
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														// disabled={!selectedBMTOption}
														padding={'0'}
														label={<>{t('ADD NETWORK')}</>}
														background={palette.blue_500}
														color={palette.white}
														minWidth={'10rem'}
														lineHeight={'0.89rem'}
														ifClicked={() => {
															setConfirmationModel(true)
														}}
													/>
													<Spacer height={'4'} />
												</>
											) : addedUserInNetwork && router?.location?.state?.suggested && !scheduleMeeting ? (
												<>
													<Icons src={'/images/added_in_network.svg'} />
													<Spacer height={'1'} />
													<Paragraph fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} isCentered>
														{t('Successfully added to your Network')}
													</Paragraph>
													<Spacer height={'1'} />
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														padding={'8px 16px 8px 16px'}
														label={
															<>
																{t('SCHEDULE MEETING')} &nbsp;
																<FaArrowRight />
															</>
														}
														background={palette.blue_500}
														color={palette.white}
														// minWidth={'10rem'}
														lineHeight={'0.89rem'}
														ifClicked={() => {
															setAddNetworkSuccess(false)
															setScheduleMeeting(true)
														}}
													/>
												</>
											) : (
												<>
													{' '}
													<Spacer height={1} />
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														disabled={!selectedBMTOption}
														padding={'0'}
														label={
															<>
																<BsClock /> &nbsp; {t('Buy My Time')}
															</>
														}
														background={palette.default_theme}
														color={palette.white}
														minWidth={'10rem'}
														lineHeight={'0.89rem'}
														ifClicked={() => setIsBMTtrue(true)}
													/>
												</>
											)}

											<Spacer height={0.4} />
										</CustomFlexed>
									</>
								</Card>
							)}
						</Col>
						{confirmationModel && (
							<ConfirmationPopup
								header="Are you sure you want to add in network"
								buttonTitle="Add"
								onConfirm={() => {
									addToNetwork(userData?.id)
									setConfirmationModel(false)
								}}
								onClose={() => setConfirmationModel(false)}
							/>
						)}
						{reportModal && (
							<ReportAndBlockModal
								user={userData.full_name}
								onReport={(reason) => {
									reportUser(reason)
									setReportModal(false)
								}}
								onBlock={() => {
									blockUser()
									setReportModal(false)
								}}
								onClose={() => setReportModal(false)}
							/>
						)}

						{shareModal && <ShareModal body={"Monetize Your Networking Time with JustNetwork's Revolutionary BMT Feature"} user={userData} header="Share BMT Link" onClose={() => setShareModal(false)} />}
					</Row>
				) : (
					!loading && !userData?.id && !isDataLoaded && <PageNotFound />
				)}
			</Container>
			{modal && (
				<BeforeSignInModals onClose={() => setModal(false)} signUp={signUp}>
					{login && <LoginForm onClose={() => setModal(false)} setSignUpModal={setSignUpModal} />}
					{signUp && <SignUpForm onClose={() => setModal(false)} setOtpFormModal={setOtpFormModal} />}
					{otpForm && <OtpVerificationForm onClose={() => setModal(false)} />}
				</BeforeSignInModals>
			)}
			{!loading && userData?.id && !isDataLoaded ? <Footer /> : ''}
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 0;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};

	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`

const OuterDiv = styled.div`
	width: 100px;
	border-radius: 1rem;
	overflow: hidden;
`

const InnerDiv = styled.div`
	width: 100%;
	height: 100%;
	border-width: 2px;
	border-style: solid;
	/* border-image: ${palette.blue_500} 1; */
	border-color: ${palette.blue_500};
`

const CustomFlexed = styled(Flexed)<any>`
	${media.xs`
	padding: 1rem;
	 `};
`

// const ProBadgeWrapper = styled(Flexed)`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	position: relative;
// 	> img {
// 		position: absolute;
// 		height: 70%;
// 	}
// `

// const ProBadgeCircle = styled(Flexed)`
// 	background: white;
// 	opacity: 5%;
// 	width: 94px;
// 	height: 94px;
// 	border-radius: 50%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	position: relative;
// `

const EditProfileToolTip = styled(Flexed)`
	display: none;
	position: absolute;
	margin-left: 1.3rem;
	top: -28%;
	z-index: 999;
	align-items: center;
	cursor: pointer;
`

const CustomSmallSpace = styled(Spacer)`
	display: none;
	${media.xs`
display: block
	 `};
`

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-direction: column;
	align-items: center;
	 `};
`

const CustomCards = styled(Card)<any>`
	position: relative;
	bottom: 0;
	margin-left: ${({marginLeft}) => (marginLeft ? marginLeft : `0rem`)};
	overflow: visible;
	cursor: ${({pointer}) => (pointer ? pointer : `pointer`)};
	@media (min-width: 768px) and (max-width: 1210px) {
		padding: 0.3rem;
	}
`

const NumberCircle = styled(Text)<any>`
	position: absolute;
	bottom: 50%; /* Position the circle at the vertical center */
	height: ${({height}) => (height ? height : '2rem')};
	width: ${({width}) => (width ? width : '2rem')};
	border: 1px solid ${palette.blue_500};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	left: ${({left}) => (left ? left : '-1rem')};
	transform: translateY(50%); /* Adjust the position vertically */
	z-index: 0;
	/* background: white; */
	background: ${({selected}) => (selected ? palette.blue_500 : palette.white)};
	/* margin-left: 1.8rem; */
`
const DropMenu = styled.div<any>`
	color: ${({isDarkTheme, active}) => (isDarkTheme ? palette.light_gray : active ? palette.blue_500 : palette.text_color)};
	font-size: 0.875rem;
	padding: 0.425rem 1rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	align-items: center;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.silver)};
		color: ${palette.blue};
	}
`

const AddNnetworkFlexed = styled(Flexed)`
	${media.xs`
	width: auto
	 `};
	${media.lg`
width: 9%
	 `};
`
export default BookNetwrokUserMeeting
