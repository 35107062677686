import React, {useState} from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import Input from '../common/input/Input'
import {api} from '../../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {chatAllUsers, chatUsers, saveSearchQuery, saveUser} from '../../actions/authActions'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import useRouter from '../../hooks/router'
import {toast} from 'react-toastify'
import Loader from '../common/Loader'
import {useSelector} from 'react-redux'
import {AppConstants} from '../../AppConstants'
import axios from 'axios'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const Header = styled(Text)`
	margin: 0 0 10px;
`

const Body = styled.p`
	margin: 0 0 20px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const PasswordInput = styled.input`
	width: 100%;
	padding: 8px;
	margin: 10px 0;
	border: 1px solid ${palette.gray_300};
	border-radius: 4px;
`

interface ConfirmationPopupProps {
	header?: any
	body?: any
	onCancel?: () => void
	onConfirm?: (password: string) => void
	onClose?: () => void
	buttonTitle?: any
	cancelButtonTitle?: any
}

const CloseAccountModal = ({header, body, onConfirm, onClose, buttonTitle, onCancel, cancelButtonTitle}: ConfirmationPopupProps) => {
	const {t} = useTranslation()
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const dispatch = useDispatch()
	const router = useRouter()
	const [loading, setLoading] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm(password)
		}
	}

	const handleChange = async () => {
		try {
			if (!password) {
				setPasswordError('Please enter password to continue')
				return
			} else {
				setLoading(true)
				await api
					.delete(`/user/delete_user?password=${password}`)
					.then(async (res: any) => {
						if (res?.data?.status) {
							await initializeChat(userDetails?.chat_id)
							onClose && onClose()
							router.navigate('/login')
							localStorage.clear()
							dispatch(saveUser({}))
							dispatch(chatUsers([]))
							dispatch(chatAllUsers([]))
							dispatch(saveSearchQuery(''))

							setLoading(false)
							toast.success(res?.data?.message)
						} else {
							setLoading(false)
							toast.warning(res?.data?.message)
						}
					})
					.catch((err) => {
						setLoading(false)
					})
			}
		} catch (error) {}
	}

	const initializeChat = async (chatId: any) => {
		try {
			await CometChatUIKit.logout()

			const url = `https://${AppConstants.COMET_CHAT_APP_ID}.api-us.cometchat.io/v3/users/${chatId}`
			const options = {
				method: 'DELETE',
				headers: {
					accept: 'application/json',
					'content-type': 'application/json',
					apiKey: AppConstants.COMET_CHAT_REST_API_KEY
				},
				data: {
					permanent: true
				}
			}

			await axios(url, options)
				.then((response) => console.log('user deleted', response.data))
				.catch((error) => console.error('error:', error))

			console.log('User logged out successfully')
		} catch (error) {
			console.error('Error during logout:', error)
			// Handle network errors or other issues here (e.g., display an error message)
		}
	}

	const handleDeleteAndRedirect = async () => {
		setLoading(true)

		try {
			if (userDetails?.is_social_login) {
				await api.post('/user/update', {is_delete_request: true})

				if (userDetails?.social_provider === 'linkedin') {
					window.location.href = `${AppConstants.server_url}/auth/linkedin`
				} else {
					window.location.href = `${AppConstants.server_url}/just-network/auth/google`
				}
			} else {
				handleChange()
			}
		} catch (error) {
			console.error('Error during operation:', error)
		} finally {
			setLoading(false)
		}
	}
	return (
		<Overlay>
			<Modal>
				{loading && <Loader />}
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					&nbsp;&nbsp;&nbsp;
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>
				<Spacer height={1} />

				<Text color={'dark_blue'}>
					{userDetails?.is_social_login
						? `For your security, we need to verify your identity before proceeding. Please log in again to confirm that this request is being made by you. This step helps us ensure that your account and personal information are protected.`
						: t('To close your account, please enter your password. This action cannot be undone.')}
				</Text>
				<Spacer height={2} />
				{!userDetails?.is_social_login ? (
					<Input type="Password" label={t('Enter your password')} value={password} onChange={(e: any) => setPassword(e.value)} error={passwordError ? {message: t(passwordError)} : undefined} name="password" isSignup isContact />
				) : (
					''
				)}
				{!userDetails?.is_social_login ? <Spacer height={2} /> : ''}
				<ButtonContainer>
					<Button
						small
						hasBorder
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={<>{cancelButtonTitle ? cancelButtonTitle : t('Cancel')}</>}
						background={palette.white}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => (onCancel ? onCancel() : onClose && onClose())}
					/>
					<Button
						small
						hasBorder
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={<>{userDetails?.is_social_login ? t('Continue') : t(buttonTitle)}</>}
						background={palette.blue_500}
						color={palette.white}
						lineHeight={'0.89rem'}
						ifClicked={async () => {
							if (userDetails?.is_social_login) {
								handleDeleteAndRedirect()
							} else {
								handleChange()
							}
						}}
					/>
				</ButtonContainer>
			</Modal>
		</Overlay>
	)
}

export default CloseAccountModal
