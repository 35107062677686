import React, {useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../../styled/colors'
import {Flexed} from '../../../styled/shared'

export interface InputProps {
	name?: string
	type?: string
	id?: string
	value?: string | number
	label?: string
	className?: string
	readOnly?: boolean
	disabled?: boolean
	min?: any
	autoComplete?: string
	error?: {
		message: string
	}
	maxLength?: any
	allowNumber?: boolean
	slotLeft?: React.ReactNode
	onBlur?(): void
	onFocus?(): void
	onChange?: any
	required?: any
	step?: any
	pattern?: any
	onInput?: any
	allowPhoneNumberOnly?: boolean
	inputMode?: any
	allowText?: any
	allowAddess?: any
	allowOnlyNumbers?: any
	allowOnlyNumbersAndDecimal?: boolean
	isContact?: boolean
	isSignup?: boolean
	url?: any
}

const TextArea = ({
	name,
	type,
	id,
	value,
	label,
	className,
	readOnly,
	disabled,
	autoComplete,
	error,
	slotLeft,
	maxLength,
	allowNumber,
	onBlur,
	onFocus,
	onChange,
	min,
	step,
	required,
	pattern,
	onInput,
	allowPhoneNumberOnly,
	inputMode,
	allowText,
	allowAddess,
	allowOnlyNumbers,
	allowOnlyNumbersAndDecimal,
	isContact,
	isSignup,
	url,
	...props
}: InputProps) => {
	const [isActive, setActive] = useState(false)
	const [isFocused, setFocused] = useState(false)
	const [inputValue, setInputValue] = useState(value ?? '')
	const inputRef = useRef<HTMLTextAreaElement>(null)
	const [keyValue, setKeyValue] = useState<any>('')

	useEffect(() => {
		if (inputRef.current && inputRef.current.value?.length > 0) setActive(true)
	}, [type])

	useEffect(() => {
		setInputValue(value ?? '')
		setActive(value ? true : false)
	}, [value])

	useEffect(() => {
		if (inputValue) setActive(true)
	}, [inputValue])

	const focusInput = () => {
		setActive(true)
		setFocused(true)
		if (inputRef.current) inputRef.current.focus()
	}

	const handleFocus = () => {
		if (onFocus) onFocus()
	}

	const unfocusInput = () => {
		setFocused(false)
		if (inputRef.current && inputRef.current.value?.length === 0) setActive(false)
		if (onBlur) onBlur()
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (allowNumber) {
			const re = /^[0-9 ]+/
			let val = event.target.value.replace(' ', '')
			if (re.test(val) || val?.length == 0) {
				setInputValue(event.target.value.replace(/[\s]/g, ''))
				if (onChange)
					onChange({
						value: event.target.value.replace(/[\s]/g, ''),
						name: event.target.name
					})
			}
		} else if (allowPhoneNumberOnly) {
			const input = event.target.value.replace(/[^0-9]/g, '')

			// Check if the input starts with "+1"

			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				setInputValue(event.target.value)
				onChange({
					value: event.target.value,
					name: event.target.name
				})
			} else if (input.startsWith('+')) {
				setInputValue(`+${input.slice(1)}`)
				onChange({
					value: `+${input.slice(1)}`,
					name: event.target.name
				})
			} else {
				setInputValue(`+${input}`)
				onChange({
					value: `+${input}`,
					name: event.target.name
				})
			}
		} else if (allowAddess) {
			const re = /^[#.0-9a-zA-Z\s,-]+$/
			let val = event.target.value
			if (re.test(val) || val?.length == 0) {
				setInputValue(event.target.value)
				if (onChange)
					onChange({
						value: event.target.value,
						name: event.target.name
					})
			}
		} else if (allowText) {
			const re = /^[a-z]+$/
			let val = event.target.value
			if (re.test(val) || val?.length == 0) {
				setInputValue(event.target.value)
				if (onChange)
					onChange({
						value: event.target.value,
						name: event.target.name
					})
			}
		} else if (allowOnlyNumbers) {
			const re = /^\d+$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				onChange({
					value: event.target.value,
					name: event.target.name
				})
			} else {
				if (re.test(val) || val?.length == 0) {
					setInputValue(event.target.value.replace(/[\s]/g, ''))
					if (onChange)
						onChange({
							value: event.target.value.replace(/[\s]/g, ''),
							name: event.target.name
						})
				}
			}
		} else if (allowOnlyNumbersAndDecimal) {
			const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				onChange({
					value: event.target.value,
					name: event.target.name
				})
			} else {
				if (re.test(val) || val?.length == 0) {
					setInputValue(event.target.value.replace(/[\s]/g, ''))
					if (onChange)
						onChange({
							value: event.target.value.replace(/[\s]/g, ''),
							name: event.target.name
						})
				}
			}
		} else {
			setInputValue(event.target.value)
			if (onChange) onChange({value: event.target.value, name: event.target.name})
		}
	}
	const onKeyDown = (e: any) => {
		e.preventDefault()
	}

	const [showPassword, setShowPassword] = useState(false)

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword)
	}
	return (
		<InputContainer onClick={() => focusInput()}>
			<Wrapper error={error} isContact={isContact} isSignup={isSignup}>
				<div>
					<InputField
						ref={inputRef}
						placeholder={!isActive ? label : ''}
						id={name}
						name={name}
						// min={min}

						onChange={(event: any) => handleChange(event)}
						value={inputValue}
						className={className}
						disabled={disabled}
						readOnly={readOnly}
						autoComplete={autoComplete ? autoComplete : 'off'}
						onBlur={() => unfocusInput()}
						onFocus={handleFocus}
						maxLength={maxLength}
						isSignup={isSignup}
						required={required}
						onInput={onInput}
						inputMode={inputMode}
						onKeyDown={(e: any) => {
							if (type == 'date') {
								type == 'date' && onKeyDown(e)
							} else {
								setKeyValue(e.key)
							}
						}}
					/>
					{url ? (
						<Flexed align="start">
							<a href={url} contentEditable>
								{url}
							</a>{' '}
						</Flexed>
					) : (
						''
					)}
					{type === 'Password' && (
						<TogglePasswordVisibility onClick={togglePasswordVisibility} showPassword={showPassword}>
							{showPassword ? 'Hide' : 'Show'}
						</TogglePasswordVisibility>
					)}
					<LabelWrapper value={inputValue} type={type} error={error} isContact={isContact} isSignup={isSignup}>
						<label>{label}</label>
					</LabelWrapper>
				</div>
			</Wrapper>
			{error && <Message>{error.message}</Message>}
		</InputContainer>
	)
}

const TogglePasswordVisibility = styled.span<any>`
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 14px;
	color: #007bff;

	&:hover {
		text-decoration: underline;
	}
`

const InputContainer = styled.div`
	width: 100%;
	position: relative;
	display: block;
`

const Wrapper = styled.div<InputProps>`
	padding: 0.75rem 1.15rem;
	align-items: stretch;
	border-radius: 0.325rem;
	border-style: ${({isContact, error}) => (isContact ? 'none' : 'solid !important')};
	border-width: 0.5px !important;
	background-color: ${({isContact, isSignup}) => (isSignup ? '#F4F4F5' : '#fff')};
	transition: border-color 0.15s ease-in-out;
	border-color: ${({error}) => (error ? `#eb5353` : 'rgba(223, 226, 230)')};
`

const LabelWrapper = styled.div<InputProps>`
	position: absolute;
	max-width: calc(100% - 24px);
	top: -1px;
	label {
		color: ${({error}) => (error ? `#eb5353` : '#8C8B88')};
		font-size: 10px;
		display: block;
		padding: 0 6px;
		margin-left: -6px;
		font-weight: 300;
		text-transform: capitalize;
		line-height: 1;
		opacity: ${({value, type}) => (value || type == 'date' ? `1` : '0')};
		transform: ${({value, type}) => (value || type == 'date' ? `translateY(-50%)` : 'translateY(50%)')};
		transition: ${({value}) => (value ? `transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;` : 'all 0.15s ease-in-out')};
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		/* background-color: ${({isContact, error}) => (isContact ? 'transparent' : 'white')}; */
		background-color: ${({isContact, error, isSignup}) => (isSignup ? 'transparent' : isContact ? 'transparent' : 'white')};
		&::before,
		&::after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 50%;
			z-index: -1;
			left: 0;
		}
		&::before {
			top: 50%;
		}
		&::after {
			top: 0;
			background-color: transparent;
		}
	}
`

const InputField = styled.textarea<any>`
	display: block;
	/* background-color: transparent; */
	background-color: ${({isContact, isSignup, background}) => (background ? background : isSignup ? '#F4F4F5' : isContact ? 'white' : 'transparent')};
	width: 100%;
	border: none;
	min-width: 0;
	font-size: 0.9rem;
	font-weight: 400;
	color: ${palette.text_color};
	line-height: 24px;
	&::placeholder {
		font-weight: 300;
		color: ${palette.input_text_color};
	}
	&:focus {
		outline: 0;
	}
	&:focus + ${LabelWrapper} {
		label {
			transform: translateY(-50%) !important;
			opacity: 1 !important;
			transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;
		}
	}
`

export const Message = styled.div`
	color: #eb5353;
	background-color: white;
	border: 1px solid #eb5353;
	border-radius: 0px 0px 0.325rem 0.325rem;
	padding: 2px 10px;
	margin-top: -3px;
	font-size: 0.75rem;
	font-weight: 300;
`

export default TextArea
