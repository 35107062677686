import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {Container, media} from 'styled-bootstrap-grid'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	width: 600px;
	height: 500px;
	overflow-y: scroll;
	position: relative;
`

const Header = styled(Text)`
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const InfoCategory = styled.h3`
	font-size: 1.2rem;
	font-weight: 700;
	margin-top: 1rem;
`

const InfoDetails = styled.p`
	font-size: 0.9rem;
	line-height: 1.2;
	margin-left: 1rem;
`

interface PolicyModalProps {
	header?: any
	body?: any
	user?: any
	onClose?: () => void
	buttonTitle?: string
}

const PolicyModal = ({header, onClose, user, body}: PolicyModalProps) => {
	const {t} = useTranslation()

	return (
		<Overlay>
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>
				<Spacer height={2} />
				<Container fluid>
					<Text isCentered fontSize={'2'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						{t('Payment and fees Policy')}
					</Text>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							1. {t('Payment Methods')}
						</Text>
						<InfoDetails>
							{t(
								'Welcome to JustNetwork. We are committed to protecting your privacy and ensuring the security of your personal data. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website and use our services.'
							)}
						</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							2. {t('Purchase of Coins')}
						</Text>
						<InfoDetails>2.1. {t('When purchasing coins, users must ensure that the payment information provided is accurate and complete.')}</InfoDetails>
						<InfoDetails>
							2.2. If a user purchases coins and does not receive them, the user must contact JustNetwork's support team. JustNetwork will investigate the issue and, if appropriate, either refund the amount paid or provide the correct
							amount of coins to the user's account.
						</InfoDetails>
					</Flexed>
					<Spacer height="1" />

					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							3. {t('Use of Coins')}
						</Text>
						<InfoDetails>3.1. {t("Coins can be used to purchase time with prospects on JustNetwork. Each transaction will deduct the appropriate amount of coins from the user's account.")}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							4. {t('Quality of Service')}
						</Text>
						<InfoDetails>4.1. {t("If a user purchases time with a prospect and feels that the prospect did not give full attention or sabotaged the call, the user must report the issue to JustNetwork's support team.")}</InfoDetails>
						<InfoDetails>
							4.2. {t('JustNetwork will investigate all claims regarding unsatisfactory calls. If it is determined that the prospect did not fulfill their obligations during the call, JustNetwork will refund the coins to the user')}
						</InfoDetails>
					</Flexed>
					<Spacer height="1" />

					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							5. {t('Refund Policy')}
						</Text>
						<InfoDetails>5.1. {t("Refunds will be issued at JustNetwork's discretion based on the results of our investigation into the user's claim.")}</InfoDetails>
						<InfoDetails>5.2. {t('Refunds will be processed via the original payment method used for the purchase. Users must provide accurate and complete information to facilitate the refund process.')}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							6. {t('Fees')}
						</Text>
						<InfoDetails>6.1. {t('JustNetwork charges a 10% service fee on every conference call transaction. This fee will be deducted from the coins paid by the user.')}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							7. {t('Disputes and Chargebacks')}
						</Text>
						<InfoDetails>7.1. {t("In the event of a dispute or chargeback, JustNetwork reserves the right to suspend the user's account until the matter is resolved.")}</InfoDetails>
						<InfoDetails>7.2. {t("Users are encouraged to contact JustNetwork's support team to resolve any payment-related issues before initiating a chargeback.")}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							8. {t('Changes to Payment and Fees')}
						</Text>
						<InfoDetails>8.1. {t('JustNetwork reserves the right to modify its payment and fees policies at any time. Users will be notified of any changes through the platform or via email.')}</InfoDetails>
						<InfoDetails>8.2. {t("Continued use of JustNetwork's services after any changes to the payment and fees policies constitutes acceptance of the new terms.")}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
				</Container>

				{/* Social Media Share Options */}
			</Modal>
		</Overlay>
	)
}

const CustomFlexed = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	flex-direction: column;
	`};
`
export default PolicyModal
