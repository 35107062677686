//kainat - dev
import React, {useEffect, useState} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Text, Flexed, Spacer} from '../../styled/shared'
import {FaArrowLeft} from 'react-icons/fa6'
import {palette} from '../../styled/colors'
import moment from 'moment'
import useRouter from '../../hooks/router'
import {Card} from '../../pages/Dashboard'
import Button from '../common/Button'
import {Icons, ProfileImg} from '../TopNavBar'
import {CustomSmallScreenSpacer} from './RescheduleRequest'
import {api} from '../../helpers/auth-axios'
import {useTranslation} from 'react-i18next'
import Footer from '../Footer'

const DeclineRequests = ({data}: any) => {
	const [userMeeting, setUserMeeting] = useState<any>('')
	const router: any = useRouter()
	const {t} = useTranslation()

	useEffect(() => {
		if (router?.query?.id) {
			getMeetingById(router?.query?.id)
		}
	}, [router?.query?.id])

	const getMeetingById = async (id?: any) => {
		await api.get(`/meeting/get_single_meeting?id=${id}`).then((res: any) => {
			if (res?.data?.status) {
				setUserMeeting(res?.data?.data)
			}
		})
	}

	return (
		<Section justify="center">
			<Spacer height={5} />
			<Container fluid>
				<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
					<Row>
						<Col>
							<>
								<Flexed direction="column" align={''} gap={'0'}>
									<Flexed direction="row" align={'center'} gap={'0.6'}>
										<Icons
											src={'/images/icon_view.svg'}
											onClick={() => {
												router.navigate(-1)
											}}
											pointer={'pointer'}
										/>
									</Flexed>
								</Flexed>
							</>
							<CustomSmallScreenSpacer height={2} />
							<Flexed align="center" text-align="center" direction="column" gap={1}>
								<Text fontWeight="600" fontSize="1.5" lineHeight="2.15" align="center" fontStyle={'italic'} color={'decline_request'}>
									{t('Declined')}
								</Text>
								<SuccessImg src="/images/decline.svg" alt="Success" />
								{/* <Spacer height={'0.5'} /> */}
								<Text fontWeight="700" fontSize="1.125" lineHeight="1.361" align="center" color={'dark_blue'}>
									{t('You have declined the Meeting with')}
								</Text>
								<Card background={palette.primary_50} minHeight={'3.6rem'} width={'max-content'} removeShadow radius={'29px'}>
									<Flexed gap={0.3} direction="row" align="center" padding={'0.2rem'}>
										<ProfileImg height={'2.8rem'} width={'2.8rem'}>
											<Icons src={userMeeting?.meetingFrom?.image_Url ? userMeeting?.meetingFrom?.image_Url : userMeeting?.meetingFrom?.image_Url ? userMeeting?.meetingFrom?.image_Url : '/images/user_default.png'} alt="i" />
										</ProfileImg>

										<Flexed gap={0.3} direction="column" align="center" margin={'0 1rem'}>
											<Text fontWeight="600" fontSize="1" lineHeight="1.21" align="center" color={'default_foreground'} opacity={'87%'}>
												{userMeeting?.meetingFrom?.full_name ? userMeeting?.meetingFrom?.full_name : userMeeting?.meetingTo?.full_name ? userMeeting?.meetingTo?.full_name : ''}
											</Text>
											<Text fontWeight="400" fontSize="0.8" lineHeight="0.9" align="center" color={'default_foreground'} opacity={'60%'}>
												{userMeeting?.meetingFrom?.designation ? userMeeting?.meetingFrom?.designation : userMeeting?.meetingTo?.designation ? userMeeting?.meetingTo?.designation : ''}
											</Text>
										</Flexed>
									</Flexed>
								</Card>
								<Meeting justify="center" direction="row" align="center" gap={1} flexWrap={'wrap'}>
									<Spacer height={'0.5'} />
									<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'dark_blue'}>
										{t('Meeting Date & Time')}
									</Text>
									<Spacer height={'0.5'} />
									<CustomFlex align={'center'} direction={'row'} justify={'space-between'} gap={1} flexWrap={'wrap'}>
										<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'dark_blue'} isCentered>
											<Icons src={'/images/calendar_blue.svg'} /> {moment(userMeeting?.confirmation_start_time ? userMeeting?.confirmation_start_time : userMeeting?.start_time).format('dddd, MMMM Do, YYYY')}
										</Text>
										<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'dark_blue'} isCentered>
											<Icons src={'/images/appointment_card_time.svg'} />{' '}
											{`${userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time} ${t('minutes')} - ${moment(
												userMeeting?.confirmation_start_time ? userMeeting?.confirmation_start_time : userMeeting?.start_time
											).format('hh:mm a')} ${t('to')} ${moment(userMeeting?.confirmation_end_time ? userMeeting?.confirmation_end_time : userMeeting?.end_time).format('hh:mm a')}`}
										</Text>
									</CustomFlex>
								</Meeting>
								{/* <Text fontWeight="400" fontSize=".9" lineHeight="1.05" align="center" color={'dark_blue'}>
									Perhaps you could consider Re-Scheduling the Call
								</Text> */}
								<Spacer height={1} />
								{/* <Button
									small
									hasBorder
									borderRadius={'1rem'}
									fontSize={'0.8rem'}
									padding={'8px 16px 8px 16px'}
									label={<>RE-SCHEDULE</>}
									background={palette.white}
									color={palette.blue_500}
									fontWeight={'600'}
									lineHeight={'0.90rem'}
									ifClicked={() => router.navigate(`/request-reschedule?id=${router?.query?.id}`)}
								/> */}
								<Spacer height={1} />
								<CustomSmallScreenSpacer height={0.5} />
								<Button
									small
									hasBorder
									borderRadius={'1rem'}
									fontSize={'0.8rem'}
									padding={'8px 16px 8px 16px'}
									label={
										<>
											<FaArrowLeft />
											&nbsp; {t('BACK TO NETWORK')}
										</>
									}
									background={palette.white}
									color={palette.blue_500}
									fontWeight={'600'}
									lineHeight={'0.90rem'}
									ifClicked={() => router.navigate('/network')}
								/>
								<CustomSmallScreenSpacer height={2} />
							</Flexed>
						</Col>
					</Row>
				</Card>
			</Container>
			<Footer />
		</Section>
	)
}

const Section = styled(Flexed)`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
	flex-direction: column;
`
const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const Meeting = styled(Flexed)`
	text-align: center;
	border: 1px solid var(--colors-base-default-200, #e4e4e7);
	border-radius: 20px;
	width: 49.37rem;
	height: 4rem;
	${media.xs`
	width: 100%;
	border: none;
	text-align: center;
	flex-wrap: wrap;
	 `};
	${media.sm`
	text-align: center;
	flex-wrap: wrap;
	 `};
	${media.md`
	text-align: center;
	flex-wrap: wrap;
	 `};
	${media.lg`
	text-align: center;
	flex-wrap: wrap;
	 `};
`

const CustomFlex = styled(Flexed)`
	${media.xs`
	width: 100%;
	border: none;
	justify-content: center
	flex-wrap: wrap;
	 `};
	${media.sm`
	border: none;
	justify-content: center
	flex-wrap: wrap;
	 `};
`

export default DeclineRequests
