import React, {useState, useEffect, FormEvent, ChangeEvent, useCallback} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js'
import {api} from '../../../helpers/auth-axios'
import {AppConstants} from '../../../AppConstants'
import Loader from '../../common/Loader'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'

// Load your publishable key from the Stripe dashboard
const stripePromise = loadStripe(AppConstants.stripe_public_key)

const App = ({priceId}: {priceId: any}) => {
	const [clientSecret, setClientSecret] = useState(null)
	const [loading, setLoading] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)

	const fetchClientSecret = useCallback(async () => {
		setLoading(true)
		return await api
			.post('/create-checkout-session-subscription', {priceId, email: userDetails?.email})
			.then((res) => {
				setLoading(false)
				return res.data.clientSecret
			})
			.catch((err: any) => {
				if (err?.message) {
					toast.error(err?.message)
				}
				setLoading(false)
			})
	}, [priceId])

	useEffect(() => {
		fetchClientSecret().then(setClientSecret)
	}, [fetchClientSecret])

	const options = {clientSecret}

	return (
		<div id="checkout">
			{loading && <Loader />}
			{clientSecret && (
				<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
					<EmbeddedCheckout />
				</EmbeddedCheckoutProvider>
			)}
		</div>
	)
}

export default App
