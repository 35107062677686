import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from '../styled/colors'
import {Flexed, Spacer, Text} from '../styled/shared'

import moment from 'moment'
import {Icons, ProfileImg} from '../components/TopNavBar'
import useRouter from '../hooks/router'
import {useState} from 'react'
import UserProfileLoader from './common/UserProfileLoader'
import {useSelector} from 'react-redux'

const NotificationItem = ({notification, updateNotification, readNotification, index, total}: {notification: any; updateNotification: any; readNotification: any; index: any; total: any}) => {
	const [timer, setTimer] = useState<any>(null)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const router = useRouter()
	const getNotificationMessage = (notification: any) => {
		if (notification.type === 'DECLINE_BMT') {
			if (notification?.userNotificationTo && notification.meta && JSON.parse(notification.meta)?.isSelf) {
				return (
					<>
						{notification.message?.startsWith('Reminder:') ? <div dangerouslySetInnerHTML={{__html: notification.message}} /> : notification.message} <b>{notification.userNotificationTo?.full_name}</b>
					</>
				)
			} else {
				return (
					<>
						<b>{notification.userNotificationTo?.full_name}</b> {notification.message?.startsWith('Reminder:') ? <div dangerouslySetInnerHTML={{__html: notification.message}} /> : notification.message}
					</>
				)
			}
		}
		if (notification.type === 'BUY_TIME_NOW_INVITE_ACCEPTED' && notification.meta && JSON.parse(notification.meta)?.email) {
			return `${notification.message} ${JSON.parse(notification.meta).email} `
		}
		if (notification.type === 'SEND_EMAIL_INVITE' && notification.meta && JSON.parse(notification.meta)?.email) {
			return `${notification.message} ${JSON.parse(notification.meta).email} `
		} else if (notification.type === 'ACCEPT_NETWORK_REQUEST' && JSON.parse(notification.meta)?.isSelf) {
			return (
				<>
					{notification.message} <b>{notification.userNotificationTo?.full_name}</b>
				</>
			)
		} else if (notification.type === 'REJECT_NETWORK_REQUEST' && JSON.parse(notification.meta)?.isSelf) {
			return (
				<>
					{notification.message} <b>{notification.userNotificationTo?.full_name}</b>
				</>
			)
		} else if (notification.type === 'SEND_NETWORK_REQUEST' && JSON.parse(notification.meta)?.isSelf) {
			return (
				<>
					{notification.message} <b>{notification.userNotificationTo?.full_name}</b>
				</>
			)
		} else if (notification?.userNotificationTo && notification.type !== 'SEND_COINS') {
			return (
				<>
					<b>{notification.userNotificationTo?.full_name}</b> {notification.message}
				</>
			)
		} else {
			return (
				<>
					<div dangerouslySetInnerHTML={{__html: notification.message}} />
				</>
			)
		}
	}
	const handleMouseEnter = () => {
		readNotification(notification)
	}

	const handleMouseLeave = () => {
		if (timer) {
			clearTimeout(timer)
			setTimer(null)
		}
	}

	return (
		<Item
			direction="column"
			align={'center'}
			padding={'10px 12px 10px 12px'}
			background={notification?.is_read ? '' : palette.primary_50}
			radius={'0'}
			pointer
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={index == total - 1 ? {} : {borderBottom: `1px solid rgba(0, 0, 0, 0.12)`}}
			onClick={() => {
				updateNotification(notification)
			}}>
			<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
				<Flexed direction="row" align={'center'} gap="1" width={'80%'}>
					<ProfileImgWrapper
						justify="center"
						direction={'row'}
						align={'center'}
						gap={'0.2'}
						onClick={(e: any) => {
							e.stopPropagation()
							if (notification?.f_id) {
								router.navigate(`/${notification?.userNotificationTo?.user_name}`, {
									// state: {id: notification.f_id}
								})
							}
						}}>
						<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={!notification?.f_id ? userDetails?.image_Url : notification?.userNotificationTo?.image_Url} />
					</ProfileImgWrapper>
					<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'400'} color={'black'} align={'left'} opacity={'87%'}>
						{getNotificationMessage(notification)}
					</Text>
				</Flexed>
				<Flexed direction="row" align={'center'} justify={'flex-end'} gap="1" width={'20%'}>
					<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'} align={'right'} opacity={'60%'}>
						{moment(notification.createdAt).isSame(moment(), 'day')
							? `${moment(moment()).diff(notification.createdAt, 'hours')}h`
							: moment(notification.createdAt).calendar(null, {
									lastWeek: '[Last] dddd',
									sameDay: '[Today]',
									lastDay: '[Yesterday]',
									sameElse: 'DD MMM'
							  })}
					</Text>
				</Flexed>
			</CustomFlexedForWrap>
			<Spacer height={0.2} />
		</Item>
	)
}

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`

const Item = styled(Flexed)<any>`
	:hover {
		background: ${({background}) => (background ? palette.white : palette.primary_50)};
	}
`
export default NotificationItem
