import React, {useState} from 'react'
import {Flexed, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {Icons} from './TopNavBar'
import {Card} from '../pages/BMT'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import SubscriptionCheckoutPage from '../components/bmt/checkout/SubscriptionCheckoutPage'
import {AppConstants} from '../AppConstants'
import {useTranslation} from 'react-i18next'

const ProSubscriptionCheckout = ({onClose}: {onClose: any}) => {
	const [monthlyMemberShip, setMonthlyMemberShip] = useState<boolean>(true)
	const {t} = useTranslation()

	return (
		<MainContainer>
			<TopLeftContainer direction="column" align={''} gap={'0'} padding={'0rem 0.1rem'}>
				<Flexed direction="row" align={'center'} gap={'0.6'}>
					<Icons
						src={'/images/icon_view.svg'}
						onClick={() => {
							onClose()
						}}
						pointer={'pointer'}
					/>
					<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
						{t('Join Pro Membership')}
					</Text>
				</Flexed>
			</TopLeftContainer>
			<FlexContainer direction={'row'} align={'center'} justify={'center'} flexWrap={'wrap'}>
				<CardContainer>
					<Card background={palette.default_theme} height={5} padding={'1rem'} style={{alignItems: 'center', display: 'flex'}}>
						<Flexed direction={'row'} align={'center'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
							<HandleFlex direction={'row'} align={'center'} gap={'0.5'} flexWrap={'wrap'}>
								<ProBadgeWrapper>
									<ProBadgeCircle />
									<Icons height={'80%'} width={'80%'} src={'/images/pro_badge.svg'} />
								</ProBadgeWrapper>
								<Spacer height={2} />
								<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
									<Flexed direction={'column'} gap={'0.5'} align={'start'}>
										<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
											{t('Become a')}
										</Text>
										<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
											{t('Pro Member')}
										</Text>
									</Flexed>
									<Text banner fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'white'} fontStyle={'italic'}>
										{t('Tailored for top entrepreneurs, VCs, influencers, celebrities, Public Figures and similar high-profile individuals, whose time is exceptionally valuable.')}
									</Text>
								</HandleFlex>
							</HandleFlex>
							<HandleFlex direction="column" justify={'center'} align={'center'} gap={'1'} padding={'1rem'}>
								<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
									{t('Subscription')}
								</Text>
								<Flexed direction="row" align={'center'} width={'8.12rem'} height={'3.31rem'} gap={0.3} padding={'0.3rem'} background={`#FAFAFA`} radius={'8px'}>
									<Flexed
										align={'center'}
										gap={'0.8'}
										width={'48%'}
										height={'100%'}
										padding={'1rem'}
										pointer
										radius={'8px'}
										style={monthlyMemberShip ? {boxShadow: '0px 1px 4px 0px #00000040'} : {}}
										onClick={() => setMonthlyMemberShip(true)}>
										<Text fontSize={'0.7'} lineHeight={'0.83'} fontWeight={'600'} color={monthlyMemberShip ? 'blue_500' : 'foreground_600'}>
											{t('Monthly')}
										</Text>
									</Flexed>
									<Flexed
										align={'center'}
										gap={'0.8'}
										width={'48%'}
										height={'100%'}
										padding={'1rem'}
										pointer
										radius={'8px'}
										style={!monthlyMemberShip ? {boxShadow: '0px 1px 4px 0px #00000040'} : {}}
										onClick={() => setMonthlyMemberShip(false)}>
										<Text fontSize={'0.7'} lineHeight={'0.83'} fontWeight={'600'} color={!monthlyMemberShip ? 'blue_500' : 'foreground_600'}>
											{t('Annually')}
										</Text>
									</Flexed>
								</Flexed>
							</HandleFlex>
						</Flexed>
					</Card>
				</CardContainer>
				<FormContainer>
					<SubscriptionCheckoutPage priceId={AppConstants.ANNUALLY_SUBSCRIPTION_ID} />
				</FormContainer>
			</FlexContainer>
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 0;
	position: relative; /* Ensure position relative to place the top-left container */
`

const TopLeftContainer = styled(Flexed)`
	position: absolute;
	top: 1rem;
	left: 1rem;
`

const FlexContainer = styled(Flexed)`
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	${media.xs`
		flex-direction: column;
		align-items: center;
	`}
`

const CardContainer = styled.div`
	flex: 1;
	margin-right: 1rem;
	${media.xs`
		margin-right: 0;
		margin-bottom: 1rem;
		width: 100%;
	`}
`

const FormContainer = styled.div`
	flex: 1;
	${media.xs`
		width: 100%;
	`}
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
		text-align: center;
		align-items: center;
		justify-content: center;
		width: 100%;
	`}
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 50px;
		width: 37px;
	}
	${media.xs`
		display: content;
		width: 100%
	`}
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

export default ProSubscriptionCheckout
