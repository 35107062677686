import React, {ChangeEvent, KeyboardEvent, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {Col, Row} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import {Card} from './AuthPage'

import useRouter from '../hooks/router'
import {api} from '../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {saveUser} from '../actions/authActions'
import Loader from './common/Loader'
import {Dot} from '../pages/SignUp'
import OTPInput from './shared/OtpInput'
import {useTranslation} from 'react-i18next'
import AuthTerms from './common/AuthTerms'

// Define the shape of the form values
interface FormValues {
	verification_code: string
}

const OtpVerification: React.FC = () => {
	const {t} = useTranslation()
	const router = useRouter()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState<boolean>(false)
	const [verificationCode, setVerificationCode] = useState<any>({})

	// Handle form submission
	const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
		setLoading(true)

		try {
			const res = await api.post<{status: boolean; message: string; data: any}>('/user/verify_register_code', {
				...verificationCode,
				email: router?.query?.email
			})
			if (res?.data?.status) {
				toast.success(res?.data?.message)
				dispatch(saveUser(res?.data?.data))
				localStorage.setItem('authenticated', 'true')
				localStorage.setItem('authorization', res?.data?.data?.login_token)
				router.navigate(`/buy-membership?id=${res?.data?.data?.id}`)
			} else {
				toast.error(res?.data?.message)
			}
		} catch (error: any) {
			toast.error(error?.message)
		} finally {
			setLoading(false)
			actions.setSubmitting(false) // Reset Formik's submitting state
		}
	}
	// Handle form submission
	const handleOtp = async (values: any) => {
		setVerificationCode(values)
	}

	const sendAgainOtp = async () => {
		setLoading(true)
		await api
			.post('/user/send_register_code', {email: router?.query?.email})
			.then((res: any) => {
				setLoading(false)
				if (res?.data?.status) {
					toast.success(t(res?.data?.message))
				} else {
					toast.error(t(res?.data?.message))
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(t(error?.message))
				}
			})
	}

	return (
		<>
			<Row justifyContent="center">
				{loading && <Loader />}
				<Col xl={8} lg={8} md={8} sm={12} xs={10}>
					<Spacer height={4} />
					<Card>
						<Flexed justify="space-between" direction={'column'}>
							<Flexed justify="center">
								<Text fontSize={1.5} lineHeight={2} fontWeight={600} color={'text_color'}>
									{t('Enter your OTP')}
								</Text>
								<Spacer height={1} />
								<Paragraph fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'}>
									{t('OTP will be sent to your email')}
								</Paragraph>
							</Flexed>
							<Spacer height={1} />

							{/* Formik for OTP verification form */}
							<Formik initialValues={{verification_code: ''}} onSubmit={handleSubmit}>
								{({isSubmitting, setFieldValue, values}) => (
									<Form>
										<Spacer height={1} />
										<Flexed justify={'center'} direction={'row'}>
											{/* OTPInput component */}
											<OTPInput onChange={(value) => setVerificationCode(value)} length={6} onComplete={handleOtp} />
										</Flexed>

										<Spacer height={2} />

										{/* Submit button */}
										<Flexed direction={'row'} align={'center'} justify={'center'}>
											<Button
												small
												hasBorder
												disabled={isSubmitting}
												type="submit"
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												padding={'8px 16px'}
												label={<>{t('VERIFY OTP')}</>}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.89rem'}
											/>
										</Flexed>
									</Form>
								)}
							</Formik>

							<Spacer height="1" />

							{/* Resend OTP button */}
							<Flexed direction={'row'} align={'center'} justify={'center'}>
								<Button small hasBorder borderRadius={'1rem'} fontSize={'0.8rem'} label={<>{t('SEND AGAIN')}</>} background={'transparent'} color={palette.blue_500} lineHeight={'0.89rem'} ifClicked={sendAgainOtp} />
							</Flexed>
						</Flexed>
						<Spacer height={2} />
					</Card>

					<Spacer height={2} />
					<AuthTerms />
					{/* <Spacer height={11} /> */}
				</Col>
			</Row>
		</>
	)
}

// type InputProps = {
// 	length?: number
// 	onComplete: (pin: any) => void
// }

// const OTPInput = ({length = 4, onComplete}: InputProps) => {
// 	const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null))
// 	const [OTP, setOTP] = useState<string[]>(Array(length).fill(''))

// 	const handleTextChange = (input: string, index: number) => {
// 		const newPin = [...OTP]
// 		newPin[index] = input
// 		setOTP(newPin)

// 		if (input.length === 1 && index < length - 1) {
// 			inputRef.current[index + 1]?.focus()
// 		}

// 		if (newPin.every((digit) => digit !== '')) {
// 			onComplete({verification_code: newPin.join('')})
// 		}
// 	}

// 	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
// 		if (e.key === 'ArrowLeft' && index > 0) {
// 			inputRef.current[index - 1]?.focus()
// 		}
// 		if (e.key === 'ArrowRight' && index < length - 1) {
// 			inputRef.current[index + 1]?.focus()
// 		}
// 		if (e.key === 'Backspace') {
// 			if (OTP[index] === '' && index > 0) {
// 				inputRef.current[index - 1]?.focus()
// 			} else {
// 				const newPin = [...OTP]
// 				newPin[index] = ''
// 				setOTP(newPin)
// 			}
// 		}
// 		if (e.key === 'Delete') {
// 			if (OTP[index] === '' && index > 0) {
// 				inputRef.current[index - 1]?.focus()
// 			} else {
// 				const newPin = [...OTP]
// 				newPin[index] = ''
// 				setOTP(newPin)
// 			}
// 		}
// 	}

// 	return (
// 		<>
// 			{Array.from({length}, (_, index) => (
// 				<input
// 					key={index}
// 					type="text"
// 					maxLength={1}
// 					placeholder="."
// 					style={{
// 						backgroundColor: '#f6f5fa',
// 						textAlign: 'center',
// 						border: '1px solid transparent',
// 						borderRadius: '8px',
// 						width: '54px',
// 						height: '54px',
// 						margin: '0 2px',
// 						fontSize: '24px',
// 						color: '#000',
// 						outline: 'none'
// 					}}
// 					value={OTP[index]}
// 					onChange={(e) => handleTextChange(e.target.value, index)}
// 					onKeyDown={(e) => handleKeyDown(e, index)}
// 					ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
// 				/>
// 			))}
// 		</>
// 	)
// }

export default OtpVerification
