import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {Icons, ProfileImg} from './TopNavBar'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from '../styled/colors'
import Button from './common/Button'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import copy from 'copy-to-clipboard'
import Linkify from 'react-linkify'
import {useState} from 'react'
import {MdOutlineVerifiedUser} from 'react-icons/md'
import useRouter from '../hooks/router'
import UserProfileLoader from './common/UserProfileLoader'

const UserProfile = ({userDetails}: {userDetails: any}) => {
	const {t} = useTranslation()
	const [isExpanded, setIsExpanded] = useState(false)
	const toggleExpand = () => {
		setIsExpanded(!isExpanded)
	}
	const text = userDetails?.specifications || ''
	const words = text.split(' ')
	const truncatedText = words.slice(0, 40).join(' ')
	const isLongText = words.length > 40
	const router = useRouter()
	let checkPathname = router.pathname?.includes('.bmt')

	return (
		<Flexed direction="column" justify={'start'} align={'center'} height={'100%'}>
			<Flexed direction="column" justify={'center'} align={'center'}>
				<UserProfileLoader width={'6rem'} height={'6rem'} imgUrl={userDetails?.image_Url} />
				<Text fontSize={'1.2'} lineHeight={'1.7'} fontWeight={'600'} color={'white'}>
					{userDetails?.full_name ? userDetails?.full_name : ''} {userDetails?.linkedin_id || userDetails?.linkedin_profile_verified ? <Icons src={`/images/linkedin.svg`} /> : ''}
				</Text>
				{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? (
					<Badge>
						<Icons src={'/images/probadge.png'} />
						{t('Pro Member')}
					</Badge>
				) : (
					''
				)}

				<Text
					fontSize={'0.8'}
					lineHeight={'1.9'}
					fontWeight={'400'}
					color={'white'}
					pointer
					onClick={() => {
						toast.success('Copied to clipboard')
						router.navigate(router.pathname, {state: router.location.state?.id ? {...router.location.state, isShare: true} : {}})
						copy(`${window.location.origin}/${userDetails?.user_name}`)
					}}>
					{userDetails?.user_name ? userDetails?.user_name : ''} <Icons pointer="cursor" src={'/images/icon_attach_file.svg'} />
				</Text>
				<Spacer height={0.3} />
				{userDetails?.organization ? (
					<Text fontSize={'0.8'} lineHeight={'1.9'} fontWeight={'400'} color={'white'}>
						<Icons src={'/images/icon_company.svg'} /> {userDetails?.organization}
					</Text>
				) : (
					<Text fontSize={'0.8'} lineHeight={'1.9'} fontWeight={'400'} color={'white'}></Text>
				)}
				{userDetails?.country ? (
					<>
						<Spacer height={0.3} />

						<Text fontSize={'0.8'} lineHeight={'1.9'} fontWeight={'400'} color={'white'}>
							<Icons src={'/images/icon_location_white.svg'} /> {userDetails?.country}, {userDetails?.city}
						</Text>
					</>
				) : (
					<Spacer height={0.3} />
				)}

				{userDetails?.website_url || userDetails?.linkedin_profile_verified || userDetails?.tiktok || userDetails?.insta ? (
					<>
						<Spacer height={0.3} />
						<Flexed direction="row" justify={'center'} align={'center'} gap={'1'} width={'100%'}>
							{userDetails?.linkedin_profile_verified ? (
								<Button
									small
									borderRadius="1rem"
									fontSize="0.9rem"
									fontWeight={400}
									padding="0"
									label={
										<>
											<Icons src="/images/linkedin.svg" pointer /> <MdOutlineVerifiedUser style={{fontSize: '24px', marginLeft: '10px'}} color="#338EF7" />
										</>
									}
									// ifClicked={() => window.open(userDetails.linkedin_url, '_blank')}
									background={palette.white}
									color={palette.black}
									width="80%"
									lineHeight="1.05rem"
								/>
							) : (
								''
							)}
							{userDetails?.website_url ? (
								<Button
									small
									borderRadius={'1rem'}
									fontSize={'0.9rem'}
									fontWeight={400}
									padding={'0'}
									label={
										<>
											<Icons src={'/images/netwrok.svg'} /> &nbsp; {t('Website')}
										</>
									}
									ifClicked={() => window.open(userDetails?.website_url, '_blank')}
									background={palette.white}
									color={palette.black}
									width={'80%'}
									lineHeight={'1.05rem'}
								/>
							) : (
								''
							)}
							{userDetails?.insta ? (
								<Button
									small
									borderRadius={'100%'}
									fontSize={'0.9rem'}
									fontWeight={400}
									padding={'0'}
									label={
										<>
											<Icons src={'/images/instagram_1.svg'} />
										</>
									}
									background={palette.white}
									color={palette.black}
									minWidth={'2rem'}
									lineHeight={'1.05rem'}
								/>
							) : (
								''
							)}
							{userDetails?.tiktok ? (
								<Button
									small
									borderRadius={'100%'}
									fontSize={'0.9rem'}
									fontWeight={400}
									padding={'0'}
									label={
										<>
											<Icons src={'/images/tik-tok-1.svg'} />
										</>
									}
									background={palette.white}
									color={palette.black}
									minWidth={'2rem'}
									lineHeight={'1.05rem'}
								/>
							) : (
								''
							)}
						</Flexed>
					</>
				) : (
					<Spacer height={0.3} />
				)}
			</Flexed>
			{(userDetails?.specifications || userDetails?.designation) && (
				<Flexed direction="column" margin="26px" justify={'flex-end'} align={'center'} width="100%">
					<Card background={palette.white} style={{width: '100%', marginBottom: '0.5rem'}}>
						<Flexed direction="column" justify={'center'} align={'center'} gap={'1'} width={'100%'}>
							<OuterDiv>
								<InnerDiv />
							</OuterDiv>
							<Card background={palette.cyn_50} minHeight={'3rem'} width={'90%'}>
								{userDetails?.designation ? (
									<Flexed direction="column" justify={'center'} align={'center'} width={'100%'}>
										<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'text_color'} isCentered>
											{userDetails?.designation}
										</Text>
									</Flexed>
								) : (
									<></>
								)}
							</Card>
							{userDetails?.specifications ? (
								<Flexed direction="column" justify={'center'} align={'center'} width={'100%'}>
									<Paragraph wordBreak="break-word" fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'text_color'} isCentered>
										<Linkify componentDecorator={customDecorator}>{isExpanded || !isLongText ? text : `${truncatedText}...`}</Linkify>
										{isLongText && <ToggleText onClick={toggleExpand}>{isExpanded ? t('Show less') : t('Show more')}</ToggleText>}
									</Paragraph>
								</Flexed>
							) : (
								<></>
							)}
						</Flexed>
					</Card>
					<Spacer height={2} />
				</Flexed>
			)}
		</Flexed>
	)
}

const customDecorator = (href: any, text: any, key: any) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">
		{text}
	</a>
)

const ToggleText = styled.span`
	color: #65a5fe;
	cursor: pointer;
	font-weight: 500;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`

const Badge = styled.span`
	display: inline-block;
	padding: 0.2rem 1rem;
	border-radius: 0.5rem;
	background: linear-gradient(134.73deg, #5f6afc 13.02%, #936af2 50.84%, #bf67e4 86.65%);
	color: white;
	font-weight: bold;
`
const OuterDiv = styled.div`
	width: 100px;
	border-radius: 1rem;
	overflow: hidden;
`

const InnerDiv = styled.div`
	width: 100%;
	height: 100%;
	border-width: 2px;
	border-style: solid;
	/* border-image: ${palette.blue_500} 1; */
	border-color: ${palette.blue_500};
`
export default UserProfile
