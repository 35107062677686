import {useMemo} from 'react'
import {useParams, useSearchParams, useLocation, useNavigate, useMatch} from 'react-router-dom'
import queryString from 'query-string'

const useRouter = () => {
	const [searchParam, setSearchParams] = useSearchParams()
	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const match = useMatch('')

	return useMemo(() => {
		return {
			navigate: navigate,
			replace: navigate,
			pathname: location.pathname,
			query: {
				...queryString.parse(location.search),
				...params
			},
			match,
			location,
			setParams: setSearchParams
		}
	}, [params, match, location, navigate, setSearchParams])
}

export default useRouter
