import moment from 'moment'

// Get the start and end of the current week
const startOfWeek = moment().startOf('week')
const endOfWeek = moment().endOf('week')

// Provided titles with corresponding types
const providedTitles = [
	{title: 'Monday Wake-Up Hour', type: 'meeting'},
	{title: 'All-Team Kickoff', type: 'meeting'},
	{title: 'Monday Wake-Up Hour', type: 'meeting'},
	{title: 'Webinar: Figma ...', type: 'webinar'},
	{title: '🍷 Happy Hour', type: 'happy hour'},
	{title: 'Conference', type: 'conference'},
	{title: 'Meeting', type: 'meeting'},
	{title: '☕️ Coffee Chat', type: 'meeting'},
	{title: 'Concept Design Review II', type: 'meeting'},
	{title: 'Happy Hour', type: 'happy hour'},
	{title: 'Dinner', type: 'meal'},
	{title: 'Planning Meeting with Paige', type: 'meeting'},
	// Add new events
	{title: 'Monthly Catchup', type: 'meeting'},
	{title: 'Spring Break 2021!', type: 'vacation'}
	// Add more events here if needed
]

// Generate random titles and types
const generateRandomTitle = () => {
	const titles = ['Meeting', 'Conference', 'Workshop', 'Presentation', 'Training', 'Lunch', 'Happy Hour', 'Gym Session', 'Coffee Chat']
	const types = ['meeting', 'conference', 'presentation', 'training', 'meal', 'happy hour']
	const randomIndex = Math.floor(Math.random() * titles.length)
	return {title: titles[randomIndex], type: types[randomIndex]}
}

// Generate random events within the current week
const generateRandomEvent = (index: any) => {
	const randomTitle = Math.random() < 0.5 ? providedTitles[Math.floor(Math.random() * providedTitles.length)] : generateRandomTitle()
	let randomStart: any, randomEnd: any
	do {
		randomStart = moment(startOfWeek)
			.add(Math.random() * 7, 'days')
			.add(Math.random() * 24 * 30, 'minutes')
		randomEnd = moment(randomStart).add(Math.random() * 24 * 30, 'minutes')
	} while (events.some((event: any) => randomStart.isBetween(event.start, event.end) || randomEnd.isBetween(event.start, event.end)))
	return {
		title: randomTitle.title,
		start: randomStart.toDate(),
		end: randomEnd.toDate(),
		type: randomTitle.type,
		isVideo: index % 2 == 0,
		zoomLink: index % 2 == 0 ? 'https://zoom.us/i/1983475281' : 'https://zoom.us/i/1983475281'
	}
}

const events: any[] = []

// Generate 40 random events
for (let i = 0; i < 14; i++) {
	const event = generateRandomEvent(i)
	events.push(event)
}

export default events

export const types = {
	meeting: 'Meeting',
	conference: 'Conference',
	workshop: 'Workshop',
	presentation: 'Presentation',
	training: 'Training',
	meal: 'Meal',
	happy_hour: 'Happy Hour',
	exercise: 'Exercise'
}
