import React from 'react'
import {Col} from 'styled-bootstrap-grid'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {CustomSpacer} from '../pages/SignUp'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import { useTranslation } from 'react-i18next'

const SideCover = () => {
	const { t } = useTranslation()
	return (
		<Col xl={6} lg={6} md={6} sm={12} xs={12}>
			<Flexed justify={'center'} direction={'column'} align={'center'}>
				<Spacer height={2} />
				<CustomSpacer height={4} />
				<img width={'80%'} src="/images/signup-sideCover.svg" />
				<Spacer height={2} />
				{/* <CustomSpacer height={3} /> */}

				<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
					{t("Monetize Your Networking Time with JustNetwork's Revolutionary")}
				</Paragraph>
				<Spacer height={1} />
				<BuyMyTime direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
					{t("Buy My Time")}
				</BuyMyTime>
				<Spacer height={0.5} />
				<Paragraph direction={'column'} isCentered fontSize={'2'} lineHeight={'2.4'} color={'text_color'} fontWeight={'700'}>
					({t("Feature")})
				</Paragraph>
			</Flexed>
			<Spacer height={2} />
		</Col>
	)
}

const BuyMyTime = styled(Text)`
	background-clip: text;
	background-image: ${palette.default_theme};
	color: transparent;
`
export default SideCover
