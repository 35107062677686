import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import {Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {AppConstants} from '../../AppConstants'

function LinkedInPage({invitedFrom, isLogin}: any) {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const {t} = useTranslation()
	return (
		<>
			<a
				href={
					invitedFrom?.email
						? `${AppConstants.server_url}/auth/linkedin?invite_email=${invitedFrom?.email}&invite_user_id=${invitedFrom?.userId}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
						: `${AppConstants.server_url}/auth/linkedin?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
				}>
				<Button justify={'center'} gap={'0.5rem'}>
					<img src={'/images/linkedin-logo.svg'} alt="Sign in with Linked In" style={{maxWidth: '180px', cursor: 'pointer'}} />

					<Text social type="normal" isDarkTheme={_isDarkTheme}>
						{isLogin ? t('Sign In with LinkedIn') : t('Sign Up with LinkedIn')}
					</Text>
				</Button>
			</a>
		</>
	)
}

const Button = styled.button<any>`
	align-items: center;
	padding: 0.563rem 0rem;
	font-weight: 600;
	width: 100%;
	cursor: pointer;
	white-space: nowrap;
	font-size: 0.875rem;
	position: relative;
	display: flex;
	justify-content: ${({justify}) => justify};
	gap: ${({gap}) => gap};
	transition: all ease 0.25s;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	border: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 0.375rem;
`

const Icon = styled.img`
	margin-right: 0.7rem;
	height: 1.563rem;
	width: 1.563rem;
`
export default LinkedInPage
