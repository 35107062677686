import React, {useState, useEffect} from 'react'

const Timer = ({duration, onComplete}: any) => {
	const [remainingTime, setRemainingTime] = useState(duration)

	useEffect(() => {
		if (remainingTime <= 0) {
			onComplete()
			return
		}

		const interval = setInterval(() => {
			setRemainingTime((prevTime: any) => prevTime - 1)
		}, 1000)

		return () => clearInterval(interval) // Cleanup the interval on component unmount
	}, [remainingTime, onComplete])

	useEffect(() => {
		setRemainingTime(duration) // Update remaining time if duration changes
	}, [duration])

	const formatTime = (time: any) => {
		const minutes = Math.floor(time / 60)
		const seconds = time % 60
		return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
	}

	return <p style={{width: 'max-content'}}>{formatTime(remainingTime)}</p>
}

export default Timer
