import * as types from '../actions/types'

const initialState = {
	isDarkTheme: false,
	searchQuery: '',
	isNotification: false,
	invitedUsers: 0,
	userDetails: {},
	selectedCoinsValue: {},
	isCallLogin: false,
	chatUsers: [],
	chatAllUsers: [],
	networkUser: [],
	chatNotifications: 0,
	userMessage: {},
	userMessageBottom: {},
	deleteUserMessage: {},
	userMessageDeleteBottom: {}
}

export default function authReducer(state = initialState, action: any) {
	switch (action.type) {
		case types.SAVE_USERS: {
			return {
				...state,
				userDetails: action.value
			}
		}
		case types.SWITCH_THEME: {
			return {
				...state,
				isDarkTheme: action.value
			}
		}
		case types.SET_NOTIFICATION: {
			return {
				...state,
				isNotification: action.value
			}
		}
		case types.SELECT_COINS_VALUE: {
			return {
				...state,
				selectedCoinsValue: action.value
			}
		}
		case types.SEARCH_QUERY: {
			return {
				...state,
				searchQuery: action.value
			}
		}
		case types.INVITED_USERS: {
			return {
				...state,
				invitedUsers: action.value
			}
		}

		case types.CALL_AUTO_LOGIN: {
			return {
				...state,
				isCallLogin: action.value
			}
		}

		case types.CHAT_USERS: {
			return {
				...state,
				chatUsers: action.value
			}
		}
		case types.CHAT_ALL_USERS: {
			return {
				...state,
				chatAllUsers: action.value
			}
		}
		case types.NETWORK_USERS: {
			return {
				...state,
				networkUser: action.value
			}
		}
		case types.CHAT_UNREAD_MESSAGES: {
			return {
				...state,
				chatNotifications: action.value
			}
		}
		case types.CHAT_USER_MESSAGES: {
			return {
				...state,
				userMessage: action.value
			}
		}
		case types.CHAT_USER_MESSAGES_BOTTOM: {
			return {
				...state,
				userMessageBottom: action.value
			}
		}
		case types.CHAT_USER_DELETE_MESSAGES: {
			return {
				...state,
				deleteUserMessage: action.value
			}
		}
		case types.CHAT_USER_DELETE_MESSAGES_BOTTOM: {
			return {
				...state,
				userMessageDeleteBottom: action.value
			}
		}

		default:
			return state
	}
}
