import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {BiChevronLeft, BiChevronRight} from 'react-icons/bi'
import {palette} from '../../styled/colors'
import {types} from '../../utils/events'
import {Dot} from '../../pages/SignUp'
import {useTranslation} from 'react-i18next'

const CalendarDatePicker = ({selectedDay, handleSelection, title, events}: {selectedDay?: any; handleSelection?: any; title?: any; events?: any}) => {
	const [selectedDate, setSelectedDate] = useState<number | null>(null)
	const [currentMonth, setCurrentMonth] = useState<any>(moment())
	const [daysInMonth, setDaysInMonth] = useState<any>([])
	const {t} = useTranslation()

	useEffect(() => {
		setDaysInMonth(getDaysInMonth(currentMonth))
	}, [currentMonth])

	useEffect(() => {
		setSelectedDate(selectedDay)
		setCurrentMonth(selectedDay)
	}, [selectedDay])

	const getDaysInMonth = (month: moment.Moment): any[] => {
		const firstDayOfMonth = month.clone().startOf('month')
		const lastDayOfMonth = month.clone().endOf('month')
		const daysInMonth = lastDayOfMonth.date()
		const daysInPreviousMonth = firstDayOfMonth.weekday()
		const totalDays = daysInMonth + daysInPreviousMonth
		let days: any[] = []

		// Add days from previous month
		const previousMonthDays = firstDayOfMonth.clone().subtract(daysInPreviousMonth, 'days')
		for (let i = 0; i < daysInPreviousMonth; i++) {
			days.push(previousMonthDays.clone().add(i, 'day').format('YYYY-MM-DD'))
		}

		// Add days from current month
		for (let i = 1; i <= daysInMonth; i++) {
			days.push(month.clone().date(i).format('YYYY-MM-DD'))
		}

		// Add days from next month to complete 42 days
		const nextMonthDays = lastDayOfMonth.clone().add(1, 'day')
		const remainingDays = 42 - days.length
		for (let i = 0; i < remainingDays; i++) {
			days.push(nextMonthDays.clone().add(i, 'day').format('YYYY-MM-DD'))
		}

		return days
	}

	const handleDayClick = (date: any) => {
		setSelectedDate(date)
		handleSelection(date)
	}

	const goToPreviousMonth = () => {
		const previousMonth = moment(currentMonth).subtract(1, 'month')
		setCurrentMonth(previousMonth)
		handleSelection(previousMonth)
		setDaysInMonth(getDaysInMonth(previousMonth))
	}

	const goToNextMonth = () => {
		const nextMonth = moment(currentMonth).add(1, 'month')
		setCurrentMonth(nextMonth)
		handleSelection(nextMonth)
		setDaysInMonth(getDaysInMonth(nextMonth))
	}

	// Function to group events by date and type
	const groupEvents = (events: any[]) => {
		const groupedEvents: any = {}

		// Group events by date and type
		events.forEach((event) => {
			const date = moment(event.start).format('YYYY-MM-DD')
			const type = event.type

			if (!groupedEvents[date]) {
				groupedEvents[date] = new Set()
			}

			groupedEvents[date].add(type)
		})

		return groupedEvents
	}

	// Group the events
	const groupedEvents = groupEvents(events)

	// Function to count unique event types for each date
	const countUniqueEventTypes = (groupedEvents: any) => {
		const uniqueEventCounts: any = {}
		const uniqueEventTypes: any = {}

		Object.keys(groupedEvents).forEach((date) => {
			const typesSet = groupedEvents[date]
			const uniqueTypes = Array.from(typesSet)
			uniqueEventTypes[date] = uniqueTypes
		})

		return {types: uniqueEventTypes}
	}

	// Count unique event types for each date
	const {types: uniqueEventTypes} = countUniqueEventTypes(groupedEvents)

	const renderDots = (date: string) => {
		const eventTypes = uniqueEventTypes[moment(date).format('YYYY-MM-DD')]
		if (eventTypes && eventTypes.length > 0) {
			return eventTypes.map((type: string, index: number) => (
				<Flexed key={index}>
					<Dot
						bg={
							moment(date).format() == moment(selectedDate).format()
								? 'white'
								: types.meeting.toLowerCase() == type
								? palette.light_blue_700
								: types.meal.toLowerCase() == type
								? palette.voilet_700
								: types.presentation.toLowerCase() == type
								? palette.amber_700
								: types.conference.toLowerCase() == type
								? palette.emeraled_700
								: types.happy_hour.toLowerCase() == type
								? palette.rose_700
								: palette.light_blue_700
						}
					/>
					<Spacer height={'0.1'} />
					{/* Render your dot here */}
					{/* Example: <Dot color="red" /> */}
				</Flexed>
			))
		} else {
			return null // No dots to render
		}
	}

	return (
		<CalendarWrapper>
			<CalendarHeader>
				<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
					<Text fontSize={'1.73'} lineHeight={'2.30'} fontWeight={'400'} color={'white'}>
						{currentMonth.format('MMMM')}
					</Text>
					<Text fontSize={'1.73'} lineHeight={'2.30'} fontWeight={'400'} color={'red_500'}>
						{currentMonth.format('YYYY')}
					</Text>
				</Flexed>
				<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.2'}>
					<BiChevronLeft fontSize={'1.5rem'} color="white" cursor={'pointer'} onClick={goToPreviousMonth} />
					<BiChevronRight fontSize={'1.5rem'} color="white" cursor={'pointer'} onClick={goToNextMonth} />
				</Flexed>
			</CalendarHeader>
			<Spacer height={'1'} />
			<CalendarBody>
				{[t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')].map((dayName) => (
					<CustomText key={dayName} fontSize={'0.7'} lineHeight={'0.95'} fontWeight={'800'} align={'right'} color={'input_text_color'}>
						{t(dayName)}
					</CustomText>
				))}
				{daysInMonth.map((day: any, i: any) => {
					return (
						<DayCell
							key={day}
							cursor={true}
							onClick={() => {
								setCurrentMonth(moment(day))
								handleDayClick(moment(day))
							}}
							direction={'column'}
							today={moment().format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')}
							selected={moment(day).format() == moment(selectedDate).format()}>
							<Text fontSize={'1'} lineHeight={'1.23'} fontWeight={'800'} align={'center'} color={day == moment(selectedDate).date() ? 'white' : moment(currentMonth).isSame(moment(day), 'month') ? 'white' : 'input_text_color'}>
								{moment(day).format('D')}
							</Text>
							<Flexed direction={'row'} gap={'0.15'}>
								{renderDots(day)}
							</Flexed>
						</DayCell>
					)
				})}
			</CalendarBody>
		</CalendarWrapper>
	)
}

const CalendarWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const CalendarHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
`

const CalendarBody = styled.div`
	display: grid;
	padding: 0.5rem;
	gap: 0.8rem; /* Adjusted gap */
	grid-template-columns: repeat(7, 1fr);
	flex-wrap: wrap;
	align-items: center;
`

const DayCell = styled(Flexed)<any>`
	position: relative;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: ${({cursor}) => (cursor ? 'pointer' : 'not-allowed')};
	border: ${({today, available}) => (today ? `1px solid blue` : 'none')};
	border-radius: 92.52px;
	height: 1.8rem;
	background: ${({today, available, selected}) => (selected ? palette.blue_500 : '')};
	border: ${({today, available}) => (today ? `1px solid ${palette.blue_400}` : 'none')};
	/* width: calc((100% / 7) - 1rem);  */
`

const CustomText = styled(Text)<any>`
	text-align: right;
`

export default CalendarDatePicker
