import React, {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import Button from './common/Button'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import axios from 'axios'
import Select from './common/Select'
import {palette} from '../styled/colors'
import {useTranslation} from 'react-i18next'
import {ButtonContainer} from './modals/MeetingFeedbackModel'
import {Flexed, Spacer, Text} from '../styled/shared'
import TextArea from './common/input/TextArea'
import {Rating} from 'react-simple-star-rating'
import {api} from '../helpers/auth-axios'
import useRouter from '../hooks/router'
import MultiSelect from './common/MultiSelect'
import Loader from './common/Loader'
import moment from 'moment-timezone'
import {useSelector} from 'react-redux'
import UserProfileLoader from './common/UserProfileLoader'
import {Icons, Logo} from './TopNavBar'

// Define the shape of form values
interface FeedbackFormValues {
	call_quality: number
	call_expectation: string // 'true' | 'false'
	liked_most: string
	improvement: string
	platform_ease: number
	recommend: string // 'true' | 'false'
	additional_comments: string
}

// Initial values for the feedback form
const initialValues: FeedbackFormValues = {
	call_quality: 5,
	call_expectation: 'false',
	liked_most: '',
	improvement: '',
	platform_ease: 5,
	recommend: 'false',
	additional_comments: ''
}

const FeedbackForm = ({onClose, eventDetails}: any) => {
	const {t} = useTranslation()
	const router = useRouter()
	const [loading, setLoading] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)

	const handleSubmit = async (values: FeedbackFormValues, actions: any) => {
		try {
			setLoading(true)
			await api.post(`/meeting/meeting_feedback`, {...values, m_id: router?.query?.id}).then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					toast.success(t(res?.data?.message))
					actions.resetForm()
					router.navigate(`/calendar?id=${router.query?.id}&isShare=${true}`)
				} else {
					setLoading(false)
					toast.error(t(res?.data?.message))
					// actions.resetForm()
				}
			})
		} catch (error) {
			setLoading(false)
			toast.error('Error submitting feedback')
		} finally {
			actions.setSubmitting(false)
		}
	}

	// Validation schema
	const validationSchema = Yup.object().shape({
		call_quality: Yup.number().required(t('Rating is required')).min(1).max(5),
		call_expectation: Yup.string().required(t('This field is required')),
		liked_most: Yup.string().required(t('This field is required')),
		improvement: Yup.string().required(t('This field is required')),
		platform_ease: Yup.number().required(t('Rating is required')).min(1).max(5),
		recommend: Yup.string().required(t('This field is required')),
		additional_comments: Yup.string()
	})
	return (
		<Container>
			{loading && <Loader />}

			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
				{({isSubmitting, values, setFieldValue}) => (
					<Form>
						<Body>
							<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
								{eventDetails?.meetingFrom ? (
									<Flexed direction={'column'} justify={'center'} align={'center'}>
										<UserProfileLoader
											height={'4.8rem'}
											width={'4.8rem'}
											imgUrl={eventDetails?.meetingFrom?.image_Url ? eventDetails?.meetingFrom?.image_Url : eventDetails?.meetingFrom?.image_Url ? eventDetails?.meetingFrom?.image_Url : null}
										/>
										<Spacer height={0.5} />
										<Text fontWeight={'600'}>{eventDetails?.meetingFrom?.full_name}</Text>
									</Flexed>
								) : (
									''
								)}
								{eventDetails?.meetingTo ? (
									<Flexed direction={'column'} justify={'center'} align={'center'}>
										<UserProfileLoader
											height={'4.8rem'}
											width={'4.8rem'}
											imgUrl={eventDetails?.meetingTo?.image_Url ? eventDetails?.meetingTo?.image_Url : eventDetails?.meetingTo?.image_Url ? eventDetails?.meetingTo?.image_Url : null}
										/>
										<Spacer height={0.5} />
										<Text fontWeight={'600'}>{eventDetails?.meetingTo?.full_name}</Text>
									</Flexed>
								) : (
									''
								)}
							</Flexed>
							<Spacer height={1} />
							<Flexed justify={'space-between'} direction="row" padding={'0.3rem'}>
								<Text fontWeight={'600'}>{t('Meeting Title')}</Text>
								<Text fontWeight={'600'}>{eventDetails?.title}</Text>
							</Flexed>
							<Flexed justify={'space-between'} direction="row" padding={'0.3rem'}>
								<Text fontWeight={'600'}>Date</Text>
								<Text fontWeight={'600'}>
									{eventDetails?.google_meeting_id
										? moment.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time).format('DD-MM-YYYY')
										: moment
												.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time)
												.tz(eventDetails?.google_meeting_id ? userDetails?.timezone : eventDetails?.u_id == userDetails?.id ? eventDetails.timezone : eventDetails?.receiver_timezone)
												.format('DD-MM-YYYY')}
								</Text>
							</Flexed>
							<Flexed justify={'space-between'} direction="row" padding={'0.3rem'}>
								<Text fontWeight={'600'}>{t('Start Time')}</Text>
								<Text fontWeight={'600'}>
									{eventDetails?.google_meeting_id
										? moment.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time).format('hh:mm A')
										: moment
												.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time)
												.tz(eventDetails?.google_meeting_id ? userDetails?.timezone : eventDetails?.u_id == userDetails?.id ? eventDetails.timezone : eventDetails?.receiver_timezone)
												.format('hh:mm A')}
								</Text>
							</Flexed>
							<Flexed justify={'space-between'} direction="row" padding={'0.3rem'}>
								<Text fontWeight={'600'}>{t('End Time')}</Text>
								<Text fontWeight={'600'}>
									{eventDetails?.google_meeting_id
										? moment.utc(eventDetails.confirmation_end_time ? eventDetails.confirmation_end_time : eventDetails.end_time).format('hh:mm A')
										: moment
												.utc(eventDetails.confirmation_end_time ? eventDetails.confirmation_end_time : eventDetails.end_time)
												.tz(eventDetails?.google_meeting_id ? userDetails?.timezone : eventDetails?.u_id == userDetails?.id ? eventDetails.timezone : eventDetails?.receiver_timezone)
												.format('hh:mm A')}
								</Text>
							</Flexed>
							<Flexed justify={'space-between'} direction="row" padding={'0.3rem'}>
								<Text fontWeight={'600'}>{t('Duration')}</Text>
								<Text fontWeight={'600'}>
									{eventDetails?.duration} {t('min')}
								</Text>
							</Flexed>
							<Label>{t('Rate the quality of your conversation:')}</Label>

							<Rating onClick={(e: any) => setFieldValue('call_quality', e)} initialValue={values.call_quality} />
							<ErrorMessage name="call_quality" component={ErrorText} />

							<Label>{t('Did the call meet your expectations?')}</Label>
							<Field
								component={MultiSelect}
								options={[
									{label: 'Yes', value: 'true'},
									{label: 'No', value: 'false'}
								]}
								isSearchable={false}
								radius={'0.2rem'}
								onChange={(e: any) => setFieldValue('call_expectation', e?.value)}
								name="call_expectation"></Field>
							<ErrorMessage name="call_expectation" component={ErrorText} />

							<Label>{t('What did you like most about the call?')}</Label>
							<Field component={TextArea} name="liked_most" type="textarea" onChange={(e: any) => setFieldValue('liked_most', e?.value)} />
							<ErrorMessage name="liked_most" component={ErrorText} />

							<Label>{t('What could be improved?')}</Label>
							<Field component={TextArea} name="improvement" type="textarea" onChange={(e: any) => setFieldValue('improvement', e?.value)} />
							<ErrorMessage name="improvement" component={ErrorText} />

							<Label>{t('Rate the ease of using JustNetwork:')}</Label>

							<Rating onClick={(e: any) => setFieldValue('platform_ease', e)} initialValue={values.platform_ease} />
							<ErrorMessage name="platform_ease" component={ErrorText} />

							<Label>{t('Would you recommend JustNetwork to others?')}</Label>

							<Field
								component={MultiSelect}
								options={[
									{label: 'Yes', value: 'true'},
									{label: 'No', value: 'false'}
								]}
								isSearchable={false}
								radius={'0.2rem'}
								onChange={(e: any) => setFieldValue('recommend', e?.value)}
								name="recommend"></Field>
							<ErrorMessage name="recommend" component={ErrorText} />

							<Label>{t('Any additional comments or feedback?')}</Label>
							<Field component={TextArea} name="additional_comments" type="textarea" onChange={(e: any) => setFieldValue('additional_comments', e?.value)} />
							<ErrorMessage name="additional_comments" component={ErrorText} />
						</Body>
						<Spacer height={1} />
						<ButtonContainer style={{justifyContent: 'flex-end', gap: '1rem'}}>
							<Button
								small
								hasBorder
								borderRadius={'1rem'}
								fontSize={'0.8rem'}
								padding={'8px 16px 8px 16px'}
								label={<>{t('Cancel')}</>}
								background={palette.white}
								color={palette.blue_500}
								lineHeight={'0.89rem'}
								ifClicked={() => onClose && onClose()}
							/>
							<Button
								small
								hasBorder
								borderRadius={'1rem'}
								fontSize={'0.8rem'}
								padding={'8px 16px 8px 16px'}
								label={<>{t('Submit Feedback')}</>}
								background={palette.white}
								color={palette.blue_500}
								lineHeight={'0.89rem'}
								disabled={isSubmitting}
							/>
						</ButtonContainer>
					</Form>
				)}
			</Formik>
		</Container>
	)
}

const Container = styled.div`
	/* max-width: 600px; */
	border-radius: 8px;
`

const Label = styled.label`
	display: block;
	margin-top: 20px;
	font-weight: bold;
`

const ErrorText = styled.div`
	color: red;
	font-size: 0.8rem;
`

const Body = styled.div`
	/* margin: 0 0 20px; */
	overflow-y: scroll;
	height: 60vh;
	&::-webkit-scrollbar {
		width: 12px; /* Width of the scrollbar */
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1; /* Background of the scrollbar track */
	}

	&::-webkit-scrollbar-thumb {
		background: #9f9d9d; /* Color of the scrollbar thumb */
		border-radius: 10px; /* Round the corners */
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #8a8888; /* Color when hovered */
	}
	&::-webkit-scrollbar {
		display: flex;
	}
`

interface StarRatingProps {
	name: string
	currentRating: number
	setFieldValue: (field: string, value: any) => void
}

const StarRating: React.FC<StarRatingProps> = ({name, currentRating, setFieldValue}) => (
	<div className="star-rating">
		{[...Array(5)].map((_, index) => {
			const ratingValue = index + 1
			return (
				<span key={ratingValue} className={`star ${ratingValue <= currentRating ? 'filled' : ''}`} onClick={() => setFieldValue(name, ratingValue)}>
					★
				</span>
			)
		})}
	</div>
)

export default FeedbackForm
