import React, {useState} from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import moment from 'moment-timezone'
import {toast} from 'react-toastify'
import useRouter from '../../hooks/router'
import {Icons, ProfileImg} from '../TopNavBar'
import {api} from '../../helpers/auth-axios'
import ConfirmationPopup from './ConfirmationModel'
import Loader from '../common/Loader'
import copy from 'copy-to-clipboard'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
import UserProfileLoader from '../common/UserProfileLoader'
import {callAutoLogin} from '../../actions/authActions'
import {useDispatch} from 'react-redux'
const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 600px;
	position: relative;
`

const Header = styled(Text)`
	margin: 0 0 10px;
`

const Body = styled(Flexed)`
	margin: 0 0 20px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	${media.xs`
	flex-direction: column;
`}
	${media.sm`
	flex-direction: row;;
`}
`

interface ConfirmationPopupProps {
	header?: any
	body?: any
	onCancel?: () => void
	onConfirm?: () => void
	onClose?: () => void
	buttonTitle?: any
	eventDetails?: any
}
const MeetingDetailsModel = ({header, body, onConfirm, onClose, buttonTitle, eventDetails}: ConfirmationPopupProps) => {
	const {t} = useTranslation()
	const router = useRouter()
	const [loading, setLoading] = useState(false)
	const [selectedMeeting, setSelectedMeeting] = useState<any>('')
	const [confirmationModel, setConfirmationModel] = useState(false)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const dispatch = useDispatch()
	const currentTime = moment()

	const eventStartTime = moment(eventDetails.confirmation_start_time || eventDetails.start_time)
	const eventEndTime = moment(eventDetails.confirmation_end_time || eventDetails.end_time)
	const isUpcoming = currentTime.isBefore(eventStartTime)

	const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
	const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
	const duration = diffMinutes < 0 ? eventDetails.duration + diffMinutes : eventDetails.duration - diffMinutes

	const handleVideoClick = () => {
		const currentTime = moment()
		const eventStartTime = moment(eventDetails.confirmation_start_time || eventDetails.start_time)
		const eventEndTime = moment(eventDetails.confirmation_end_time || eventDetails.end_time)
		const isUpcoming = currentTime.isBefore(eventStartTime)

		const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
		const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
		const duration = diffMinutes < 0 ? eventDetails.duration + diffMinutes : eventDetails.duration - diffMinutes
		const hasRemainingTime = duration > 0
		if (duration && duration <= 0 && !hasRemainingTime && !isUpcoming) {
			toast.warn(t('The meeting has been ended.'))
		} else if (isUpcoming) {
			toast.warn(t('The meeting will start at the exact time.'))
		} else if ((isInProgress || hasRemainingTime) && duration && duration >= 1 && eventDetails?.status == 'accepted') {
			router.navigate(`/initialize-video?id=${eventDetails?.id}`)
		} else {
			if (duration && duration < 0 && eventDetails?.status == 'accepted') {
				toast.warn(t('The meeting has already started.'))
			} else {
				if (eventDetails?.status == 'accepted') {
					toast.warn(t('The meeting is scheduled to start in more than 5 minutes.'))
				} else {
					toast.warn(t('The meeting is not confirmed.'))
				}
			}
		}
	}
	const _handleDecline = async (meeting: any) => {
		setLoading(true)
		setConfirmationModel(false)

		await api
			.put(`/meeting/update_meeting_status`, {id: meeting?.id, status: 'decline'})
			.then((res: any) => {
				if (res?.data?.status) {
					setSelectedMeeting('')
					setLoading(false)
					toast.success(res?.data?.message)
					router.navigate(`/decline-request?id=${meeting?.id}`, {state: {meetingId: eventDetails?.id, router: `/calendar?id=${eventDetails?.id}&isShare=true`}})
				} else {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const checkMeeting = (user: any) => {
		const currentTime = moment()

		const eventStartTime = moment.utc(user.confirmation_start_time || user.start_time).tz(user?.google_meeting_id ? userDetails?.timezone : user?.timezone)
		const eventEndTime = moment(user.end_time)
		const isUpcoming = currentTime.isBefore(eventStartTime)

		const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
		const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
		const duration = diffMinutes < 0 ? user.duration + diffMinutes : user.duration - diffMinutes
		const hasRemainingTime = duration > 0

		return {duration, isUpcoming, hasRemainingTime}
	}

	const _handleAccept = async (meeting: any) => {
		setLoading(true)
		setConfirmationModel(false)

		await api
			.put(`/meeting/update_meeting_status`, {id: meeting?.id, status: 'accepted'})
			.then((res: any) => {
				if (res?.data?.status) {
					setSelectedMeeting('')
					setLoading(false)
					dispatch(callAutoLogin(true))
					toast.success(res?.data?.message)
					router.navigate(`/accept-reschedule-successful?id=${meeting?.id}`, {state: {meetingId: eventDetails?.id, router: `/calendar?id=${eventDetails?.id}&isShare=true`}})
				} else {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	console.log('=====userDetails', eventDetails)

	return (
		<Overlay>
			{loading && <Loader />}
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} align={'center'} isCentered lineHeight={'2'} fontWeight={'600'} color={'dark_blue'} width={'100%'}>
						{eventDetails?.google_meeting_id ? (
							<Text fontWeight={'600'} isCentered fontSize={'1.3'} color={'dark_blue'}>
								<Spacer height={1} />
								{eventDetails?.is_outlook ? t('Outlook Meeting') : eventDetails?.google_meeting_id ? t('Google Meeting') : ''}
							</Text>
						) : (
							''
						)}
					</Header>{' '}
					&nbsp;&nbsp;&nbsp;
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>

				<Body padding={'1rem'}>
					<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
						{eventDetails?.meetingFrom ? (
							<Flexed direction={'column'} justify={'center'} align={'center'}>
								<UserProfileLoader
									height={'4.8rem'}
									width={'4.8rem'}
									imgUrl={eventDetails?.meetingFrom?.image_Url ? eventDetails?.meetingFrom?.image_Url : eventDetails?.meetingFrom?.image_Url ? eventDetails?.meetingFrom?.image_Url : null}
								/>
								<Spacer height={0.5} />
								<Text fontWeight={'600'}>{eventDetails?.meetingFrom?.full_name}</Text>
							</Flexed>
						) : (
							''
						)}
						{eventDetails?.meetingTo ? (
							<Flexed direction={'column'} justify={'center'} align={'center'}>
								<UserProfileLoader height={'4.8rem'} width={'4.8rem'} imgUrl={eventDetails?.meetingTo?.image_Url ? eventDetails?.meetingTo?.image_Url : eventDetails?.meetingTo?.image_Url ? eventDetails?.meetingTo?.image_Url : null} />
								<Spacer height={0.5} />
								<Text fontWeight={'600'}>{eventDetails?.meetingTo?.full_name}</Text>
							</Flexed>
						) : (
							''
						)}
					</Flexed>
					<Spacer height={1} />
					<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
						<Text fontWeight={'600'}>{t('Meeting Title')}</Text>
						<Text fontWeight={'600'}>{eventDetails?.title}</Text>
					</Flexed>
					<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
						<Text fontWeight={'600'}>Date</Text>
						<Text fontWeight={'600'}>
							{eventDetails?.event_without_time
								? moment(eventDetails?.event_without_time_start_date).format('DD-MM-YYYY')
								: eventDetails?.google_meeting_id
								? moment.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time).format('DD-MM-YYYY')
								: moment
										.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time)
										.tz(eventDetails?.google_meeting_id ? userDetails?.timezone : eventDetails?.u_id == userDetails?.id ? eventDetails.timezone : eventDetails?.receiver_timezone)
										.format('DD-MM-YYYY')}
						</Text>
					</Flexed>
					{!eventDetails?.event_without_time ? (
						<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
							<Text fontWeight={'600'}>{t('Start Time')}</Text>
							<Text fontWeight={'600'}>
								{eventDetails?.google_meeting_id
									? eventDetails?.is_outlook
										? moment
												.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time)
												.tz(userDetails?.timezone)
												.format('hh:mm A')
										: moment.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time).format('hh:mm A')
									: moment
											.utc(eventDetails.confirmation_start_time ? eventDetails.confirmation_start_time : eventDetails.start_time)
											.tz(eventDetails?.google_meeting_id ? userDetails?.timezone : eventDetails?.u_id == userDetails?.id ? eventDetails.timezone : eventDetails?.receiver_timezone)
											.format('hh:mm A')}
							</Text>
						</Flexed>
					) : (
						''
					)}
					{!eventDetails?.event_without_time ? (
						<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
							<Text fontWeight={'600'}>{t('End Time')}</Text>
							<Text fontWeight={'600'}>
								{eventDetails?.google_meeting_id
									? eventDetails?.is_outlook
										? moment
												.utc(eventDetails.confirmation_end_time ? eventDetails.confirmation_end_time : eventDetails.end_time)
												.tz(userDetails?.timezone)
												.format('hh:mm A')
										: moment.utc(eventDetails.confirmation_end_time ? eventDetails.confirmation_end_time : eventDetails.end_time).format('hh:mm A')
									: moment
											.utc(eventDetails.confirmation_end_time ? eventDetails.confirmation_end_time : eventDetails.end_time)
											.tz(eventDetails?.google_meeting_id ? userDetails?.timezone : eventDetails?.u_id == userDetails?.id ? eventDetails.timezone : eventDetails?.receiver_timezone)
											.format('hh:mm A')}
							</Text>
						</Flexed>
					) : (
						''
					)}
					{!eventDetails?.event_without_time ? (
						<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
							<Text fontWeight={'600'}>{t('Duration')}</Text>
							<Text fontWeight={'600'}>{eventDetails?.duration}</Text>
						</Flexed>
					) : (
						''
					)}
					<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
						<Text fontWeight={'600'}>{t('Status')}</Text>
						<Text fontWeight={'600'}>
							{!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming
								? eventDetails?.status == 'decline'
									? t(`ENDED / ${eventDetails?.status?.toUpperCase()}`)
									: t('ENDED')
								: eventDetails?.status?.toUpperCase()}
						</Text>
					</Flexed>

					{!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming ? (
						''
					) : (
						<Flexed justify={'space-between'} direction="row" padding={'0.5rem'}>
							<Text fontWeight={'600'}>{`${window.location.origin}/calendar?id=${eventDetails?.id}&isShare=${true}`}</Text>
							<Icons
								src={'/images/content_copy.svg'}
								style={{cursor: 'pointer'}}
								isCopy
								onClick={() => {
									toast.success(t('Copied to clipboard'))
									copy(`${window.location.origin}/calendar?id=${eventDetails?.id}&isShare=${true}`)
								}}
							/>
						</Flexed>
					)}
				</Body>
				<ButtonContainer>
					<Flexed isMeetingModal direction={'row'} gap={'0.4'}>
						<Button
							small
							type={'danger'}
							hasBorder
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{t('Decline Meeting')}</>}
							background={palette.transparent}
							color={palette.danger}
							lineHeight={'0.89rem'}
							disabled={
								!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming
									? true
									: eventDetails?.status == 'decline'
									? true
									: eventDetails?.user1_joind || eventDetails?.user2_joind
									? true
									: eventDetails?.google_meeting_id ?? false
							}
							ifClicked={() => {
								setSelectedMeeting(eventDetails)
								setConfirmationModel(true)
							}}
						/>
						<Button
							small
							hasBorder
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{t('Re-Schedule Meeting')}</>}
							background={palette.blue_500}
							color={palette.white}
							disabled={
								!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming
									? true
									: eventDetails?.status == 'decline'
									? true
									: eventDetails?.user1_joind || eventDetails?.user2_joind
									? true
									: eventDetails?.google_meeting_id ?? false
							}
							lineHeight={'0.89rem'}
							ifClicked={() => router.navigate(`/request-reschedule?id=${eventDetails?.id}`, {state: {meetingId: eventDetails?.id, router: `/calendar?id=${eventDetails?.id}&isShare=true`}})}
						/>
					</Flexed>

					{userDetails?.id == eventDetails?.u_id && eventDetails?.status == 'accepted' && !eventDetails?.proposed_start_time ? (
						<Button
							small
							hasBorder
							// type={eventDetails?.status == 'accepted' ? undefined : 'success'}
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{t('Join Meeting')}</>}
							background={palette.blue_500}
							color={palette.white}
							lineHeight={'0.89rem'}
							disabled={!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming ? true : eventDetails?.status == 'decline' ? true : eventDetails?.google_meeting_id ? true : false}
							ifClicked={() => (eventDetails?.status != 'accepted' ? _handleAccept(eventDetails) : handleVideoClick())}
						/>
					) : userDetails?.id != eventDetails?.u_id && eventDetails?.status != 'accepted' && !eventDetails?.proposed_start_time ? (
						<Button
							small
							hasBorder
							type={eventDetails?.status == 'accepted' ? undefined : 'success'}
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{eventDetails?.status == 'accepted' ? t('Join Meeting') : t('ACCEPT MEETING')}</>}
							background={eventDetails?.status == 'accepted' ? palette.blue_500 : palette.success}
							color={palette.white}
							lineHeight={'0.89rem'}
							disabled={!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming ? true : eventDetails?.status == 'decline' ? true : eventDetails?.google_meeting_id ? true : false}
							ifClicked={() => (eventDetails?.status != 'accepted' ? _handleAccept(eventDetails) : handleVideoClick())}
						/>
					) : userDetails?.id != eventDetails?.re_scheduled_from && eventDetails?.status == 're-scheduled' && eventDetails?.proposed_start_time ? (
						<Button
							small
							hasBorder
							type={eventDetails?.status == 'accepted' ? undefined : 'success'}
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{eventDetails?.status == 'accepted' ? t('Join Meeting') : t('ACCEPT MEETING')}</>}
							background={eventDetails?.status == 'accepted' ? palette.blue_500 : palette.success}
							color={palette.white}
							lineHeight={'0.89rem'}
							disabled={!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming ? true : eventDetails?.status == 'decline' ? true : eventDetails?.google_meeting_id ? true : false}
							ifClicked={() => (eventDetails?.status != 'accepted' ? _handleAccept(eventDetails) : handleVideoClick())}
						/>
					) : (
						<Button
							small
							hasBorder
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{t('Join Meeting')}</>}
							background={palette.blue_500}
							color={palette.white}
							lineHeight={'0.89rem'}
							disabled={
								!checkMeeting(eventDetails).hasRemainingTime && !checkMeeting(eventDetails).isUpcoming
									? true
									: eventDetails?.status == 'decline'
									? true
									: eventDetails?.google_meeting_id
									? true
									: eventDetails?.status != 'accepted'
									? true
									: false
							}
							ifClicked={() => handleVideoClick()}
						/>
					)}
				</ButtonContainer>
			</Modal>

			{confirmationModel && (
				<ConfirmationPopup
					header={t('Are you sure you want to decline this meeting?')}
					onClose={() => {
						setSelectedMeeting('')
						setConfirmationModel(false)
					}}
					buttonTitle={t('Decline')}
					onConfirm={() => {
						if (selectedMeeting) {
							_handleDecline(selectedMeeting)
						}
					}}
				/>
			)}
		</Overlay>
	)
}

export default MeetingDetailsModel
