import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Icons, ProfileImg} from '../../components/TopNavBar'
import Button from '../../components/common/Button'
import {toast} from 'react-toastify'
import {api} from '../../helpers/auth-axios'
import {useEffect, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useTranslation} from 'react-i18next'
import Loader from '../common/Loader'
import useRouter from '../../hooks/router'
import {useSelector} from 'react-redux'
import {subscribeToChannel} from '../../service/pusherService'
import ConfirmationPopup from '../modals/ConfirmationModel'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../../actions/authActions'
import UserProfileLoader from '../common/UserProfileLoader'

const NetworkRequestCards = ({searchChatUserQuery, isSearch, setSearch, handleLoad}: {searchChatUserQuery?: any; isSearch: any; setSearch: any; handleLoad?: any}) => {
	const router = useRouter()
	const [hasMore, setHasMore] = useState(true)
	const [confirmationModel, setConfirmationModel] = useState({open: false, type: ''})
	const [loading, setLoading] = useState(false)
	const [users, setUsers] = useState<any>([])
	const [page, setPage] = useState(1)
	const [selectedRequest, setSelectedRequest] = useState<any>({})
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)

	useEffect(() => {
		const unsubscribe = subscribeToChannel('NETWORK_CHANNEL', `ADD_NETWORK_${userDetails.id}`, (data: any) => {
			// Update state with the received data
			setUsers((prev: any) => [...prev, data])
		})

		const unsubscribeAccept = subscribeToChannel('NETWORK_CHANNEL', `ACCEPT_NETWORK_${userDetails.id}`, (data: any) => {
			dispatch(callAutoLogin(true))
			// Update state with the received data
			setUsers((prevUsers: any) => prevUsers.filter((el: any) => el.addedTo.id !== data.id))
		})

		const unsubscribeReject = subscribeToChannel('NETWORK_CHANNEL', `REJECT_NETWORK_${userDetails.id}`, (data: any) => {
			setUsers((prevUsers: any) => prevUsers.filter((el: any) => el.id !== data.id))
		})

		return () => {
			unsubscribe()
			unsubscribeAccept()
			unsubscribeReject()
		}
	}, [])

	const changeStatus = async (status: string, f_id: number) => {
		try {
			setLoading(true)
			setConfirmationModel({type: '', open: false})
			await api
				.put('/user/update_network_requests', {f_id, status})
				.then((res: any) => {
					setLoading(false)

					if (res.data.status) {
						toast.success(res.data.message)
						dispatch(callAutoLogin(true))
						handleLoad && handleLoad()
					}
				})
				.catch((error: any) => {
					toast.error(error.message)
					setLoading(false)
				})
			setUsers((prevUsers: any) => prevUsers.filter((el: any) => el.id !== f_id))
		} catch (error) {
			setLoading(false)
		}
	}

	const getUserNetworkRequests = async (page = 1, resetUsers = false) => {
		setLoading(true)
		try {
			const response = await api.get(`/user/user_network_requests?page${page}`, {
				params: {page, search: searchChatUserQuery}
			})

			const fetchedUsers = response?.data?.data?.users || []
			const newUsers = resetUsers ? fetchedUsers : [...users, ...fetchedUsers]
			if (resetUsers) {
				setSearch(false)
			}
			setUsers(newUsers)
			setHasMore(response?.data?.data?.users.length !== 0)
		} catch (error: any) {
			toast.error(error.message)
		} finally {
			setLoading(false)
		}
	}

	const fetchMoreData = () => {
		setPage((prevPage) => prevPage + 1)
	}

	const handleAccept = (id: any) => {
		setSelectedRequest({id})
		setConfirmationModel({open: true, type: 'accept'})
	}

	const handleReject = (id: any) => {
		setSelectedRequest({id})
		setConfirmationModel({open: true, type: 'reject'})
	}
	useEffect(() => {
		if (isSearch) {
			setPage(1) // Reset page to 1 for new search
			getUserNetworkRequests(1, true) // Pass true to reset users
		}
	}, [isSearch])

	useEffect(() => {
		// Prevent fetching data when resetting page
		getUserNetworkRequests(page)
	}, [page])

	// if (loading) return <Loader />

	return (
		<Flexed id="scrollableDiv" direction="column" gap={'0.5'} style={{height: '45.5rem', overflow: 'hidden', overflowY: 'scroll'}}>
			{loading && <Loader color={palette.dark_blue} />}
			<InfiniteScroll scrollableTarget="scrollableDiv" scrollThreshold={0.9} dataLength={users.length} next={fetchMoreData} hasMore={hasMore} loader={!loading ? <></> : <></>} endMessage={users?.length ? <></> : ''}>
				{users?.length ? (
					users?.map((user: any, i: any) => (
						<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0 1rem'} style={i == users.length - 1 ? {} : {borderBottom: `1px solid ${palette.zink_300}`}}>
							<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
								<Flexed
									direction="row"
									align={'center'}
									onClick={() => {
										router.navigate(`/${user.u_id === userDetails.id ? user?.addedTo?.user_name : user?.addedFrom?.user_name}`, {
											state: {route: '/network'}
										})
									}}
									pointer>
									<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
										<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={user.u_id === userDetails.id ? user?.addedTo?.image_Url : user.u_id !== userDetails.id ? user?.addedFrom?.image_Url : '/images/user_default.png'} />
										<AvailabilityIconWrapper src={`/images/${user?.is_online ? 'green_circle' : user?.is_online == 'offline' ? 'pink_circle' : user?.is_online == 'red' ? 'red_circle' : 'gray_circle'}.svg`} alt="upload" />
									</ProfileImgWrapper>
									{user.u_id === userDetails.id ? (
										<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
											<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
												<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
													{user?.addedTo.full_name}
												</Text>
												{user?.addedTo?.linkedin_profile_verified || user?.addedTo?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
											</Flexed>
											<Spacer height={0.2} />
											<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
												{user?.addedTo.designation}
											</Text>
										</Flexed>
									) : (
										<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
											<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
												<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
													{user?.addedFrom.full_name}
												</Text>
												{user?.addedFrom?.linkedin_profile_verified || user?.addedFrom?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
											</Flexed>
											<Spacer height={0.2} />
											<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
												{user?.addedFrom.designation}
											</Text>
										</Flexed>
									)}
								</Flexed>
								<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
									{user?.requestStatus == 'accepted' ? (
										<Flexed direction={'row'} justify={'space-between'} align={'center'} gap={'0.2'}>
											<Flexed>
												<Icons src={'/images/icon_tick.svg'} />
											</Flexed>
											<Text color={'success'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'}>
												{t('Request Accepted')}
											</Text>
										</Flexed>
									) : user?.requestStatus == 'ignored' ? (
										<Flexed direction={'row'} justify={'space-between'} align={'center'} gap={'0.2'}>
											<Flexed>
												<Icons src={'/images/not_verified.svg'} />
											</Flexed>
											<Text color={'danger_1'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'}>
												{t('Request Rejected')}
											</Text>
										</Flexed>
									) : user.u_id === userDetails.id ? (
										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											hasBorder
											padding={'0'}
											label={<>{t('Cancel')}</>}
											background={palette.white}
											color={palette.blue_500}
											lineHeight={'0.89rem'}
											ifClicked={() => handleReject(user?.id)}
										/>
									) : (
										<>
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												hasBorder
												padding={'0'}
												label={<>{t('Decline')}</>}
												background={palette.white}
												color={palette.blue_500}
												lineHeight={'0.89rem'}
												ifClicked={() => handleReject(user?.id)}
											/>
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												padding={'0'}
												label={<>{t('ACCEPT')}</>}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.89rem'}
												ifClicked={() => handleAccept(user?.id)}
											/>
										</>
									)}
								</Flexed>
							</CustomFlexedForWrap>
							<Spacer height={0.2} />
						</Flexed>
					))
				) : (
					<></>
				)}
				{!loading && users.length == 0 && (
					<Flexed align={'center'} justify={'center'} direction={'row'} height={'100%'}>
						<Text fontSize={'0.9'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
							{t('No network requests found!')}
						</Text>
					</Flexed>
				)}
				{confirmationModel.open && (
					<ConfirmationPopup
						header={confirmationModel.type == 'accept' ? t('Are you sure you want to accept this network request?') : t('Are you sure you want to reject this network request?')}
						onClose={() => {
							setSelectedRequest({})
							setConfirmationModel({open: false, type: ''})
						}}
						buttonTitle={confirmationModel.type == 'accept' ? t('Accept') : t('Reject')}
						onConfirm={() => {
							confirmationModel.type == 'accept' ? changeStatus('accepted', selectedRequest?.id) : changeStatus('rejected', selectedRequest?.id)
						}}
					/>
				)}
			</InfiniteScroll>
		</Flexed>
	)
}

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`
const AvailabilityIconWrapper = styled(Icons)<any>`
	position: absolute;
	bottom: 0;
	margin-left: 1.8rem;
`

export default NetworkRequestCards
