import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Paragraph, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {api} from '../../helpers/auth-axios'
import {useSelector} from 'react-redux'
import MultiSelect from '../common/MultiSelect'
import {useDispatch} from 'react-redux'
import {callAutoLogin, saveUser} from '../../actions/authActions'
import {Card} from '../../pages/Dashboard'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Input from '../common/input/Input'
import {toast} from 'react-toastify'
import useRouter from '../../hooks/router'
import Loader from '../common/Loader'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const Header = styled(Text)`
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const StyledSelect = styled.select`
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: 1px solid ${palette.gray_300};
	padding: 10px;
	margin-bottom: 10px;

	&:focus {
		border-color: ${palette.blue_500};
		outline: none;
	}
`

interface ReportPopupProps {
	header?: any
	body?: any
	onReportAndBlock?: (reason: any) => void
	onCancel?: () => void
	onSubmit?: () => void
	onClose?: () => void
	buttonTitle?: string
	coins?: any
	amount?: any
}

const EmailModel = ({header, body, onSubmit, onClose, onReportAndBlock, amount, coins}: ReportPopupProps) => {
	const [language, setLanguage] = useState<any>({})
	const [errors, setErrors] = useState<any>({})
	const {t, i18n} = useTranslation()
	const dispatch = useDispatch()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [loading, setLoading] = useState(false)
	const router = useRouter()

	const initialValues = {
		email: userDetails?.email
	}

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t('Invalid email')).required(t('Email is required'))
	})

	const handleCancel = () => {
		onClose && onClose()
	}

	const handleCashout = async () => {
		setLoading(true)
		router.setParams(`?coins=${router?.query?.coins}`)
		try {
			await api
				.post('/user/transfer_coins_paypal', {coins: coins, amount})
				.then((res: any) => {
					if (res.data?.status) {
						if (res?.data?.data?.url) {
							setLoading(false)
							dispatch(callAutoLogin(true))
							window.location.href = res?.data?.data?.url
						} else {
							setLoading(false)
							dispatch(callAutoLogin(true))
							toast.success(res.data?.message)
						}
					} else {
						toast.success(res.data?.message)
					}
				})
				.catch((err) => {
					toast.error('Error during cashout: ' + err.response?.data?.message)
					setLoading(false)
				})
		} catch (error: any) {
			setLoading(false)
			console.log('====eeee', error)
			toast.error('Error during cashout: ' + error.response?.data?.message)
		}
	}
	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/transfer_coins_paypal', {...values, coins: coins, amount})
			.then((res: any) => {
				if (res?.data?.status) {
					actions.setSubmitting(false)
					actions.resetForm()
					setLoading(false)
					dispatch(callAutoLogin(true))
					toast.success(res?.data?.message)
					onSubmit && onSubmit()
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	return (
		<Overlay>
			{loading && <Loader />}
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>

				<Flexed justify="center">
					<Flexed justify="center">
						<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
							{t('Withdraw coins using paypal')}
						</Text>
						<Spacer height={1} />
						<Paragraph fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'}>
							{t('Please enter the email address associated with your PayPal account to withdraw your coins.')}
						</Paragraph>
					</Flexed>
					<Spacer height={1} />

					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
						{({isSubmitting, errors, setFieldValue, values, touched}) => (
							<Form>
								<Field
									component={Input}
									name="email"
									type="email"
									label={t('Email')}
									value={values.email ?? undefined}
									isSignup
									isContact
									onChange={(event: any) => {
										setFieldValue('email', event?.value, true)
									}}
									error={errors.email ? {message: errors.email ?? ''} : null}
								/>

								<Spacer height="1" />

								<Flexed direction={'row'} align={'center'} justify={'center'}>
									<Button
										small
										hasBorder
										disabled={isSubmitting}
										type="submit"
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'8px 16px 8px 16px'}
										label={<>{t('Continue')}</>}
										background={palette.blue_500}
										color={palette.white}
										lineHeight={'0.89rem'}
									/>
								</Flexed>
							</Form>
						)}
					</Formik>
				</Flexed>
				<Spacer height={2} />
			</Modal>
		</Overlay>
	)
}

export default EmailModel
