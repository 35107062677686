import React from 'react'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Text, Flexed, Spacer} from '../styled/shared'
import {FaArrowLeft} from 'react-icons/fa6'
import {palette} from '../styled/colors'
import moment from 'moment'
import useRouter from '../hooks/router'
import {Card} from './Dashboard'
import Button from '../components/common/Button'
import {useTranslation} from 'react-i18next'
import {Icons, ProfileImg} from '../components/TopNavBar'
import {FaArrowRight} from 'react-icons/fa'
import UserProfileLoader from '../components/common/UserProfileLoader'

const BMTSuccess = ({data}: any) => {
	const router = useRouter()
	const {t} = useTranslation()
	return (
		<Section justify="center">
			<Container fluid>
				<Row>
					<Col>
						<Flexed align="center" text-align="center" direction="column" gap={1}>
							<SuccessText fontSize={1.5} lineHeight={'2.12'} fontWeight={600} fontStyle={'italic'}>
								{t('Thank You')}
							</SuccessText>

							<SuccessImg src="/images/thankyou.png" alt="Success" />

							<Text fontWeight="700" fontSize="1.125" lineHeight="1.361" align="center" color={'dark_blue'}>
								{t('Your BMT Request on')}
							</Text>

							<Meeting justify="center" direction="row" align="center" gap={1}>
								<Text fontWeight="600" fontSize="1" lineHeight="1.21" align="center" color={'dark_blue'}>
									{moment(data?.selectedDate).format('dddd, MMMM Do, YYYY')}
								</Text>
								<div style={{height: '1.2rem', borderLeft: `1px solid ${palette.blue_500}`, margin: '0 '}}></div>
								<Text fontWeight="600" fontSize="1" lineHeight="1.21" align="center" color={'dark_blue'}>
									{moment(moment().format() + ' ' + data?.selectedSlot, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
								</Text>
								<div style={{height: '1.2rem', borderLeft: `1px solid ${palette.blue_500}`, margin: '0 '}}></div>
								<Text fontWeight="600" fontSize="1" lineHeight="1.21" align="center" color={'dark_blue'}>
									{data?.createdMeeting?.duration}
									{t('m')}
								</Text>
							</Meeting>

							<Text fontWeight="700" fontSize="1.125" lineHeight="1.361" align="center" color={'dark_blue'}>
								{t('has been sent Successfully')}
							</Text>

							<Card background={palette.primary_50} minHeight={'3.6rem'} width={'auto'} removeShadow radius={'29px'}>
								<Flexed gap={0.3} direction="column" align="center" margin={'0 1rem'}>
									<Flexed gap={0.3} direction="row" align="center" padding={'0.2rem'}>
										<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={data?.userData?.image_Url ? data?.userData?.image_Url : '/images/user_default.png'} />

										<Flexed gap={0.3} direction="column" align="center" margin={'0 1rem'}>
											<Text fontWeight="600" fontSize="1" lineHeight="1.21" align="center" color={'default_foreground'} opacity={'87%'}>
												{data?.userData?.full_name}
											</Text>
											<Text fontWeight="400" fontSize="0.8" lineHeight="0.9" align="center" color={'default_foreground'} opacity={'60%'}>
												{data?.userData?.designation}
											</Text>
										</Flexed>
									</Flexed>
								</Flexed>
							</Card>

							<Text fontWeight="600" fontSize="1" lineHeight="1.5" align="center" color={'dark_blue'}>
								{data?.userData?.gender == 'female' ? t('She') : t('He')} {t('will respond you soon!')}
							</Text>

							<Button
								small
								hasBorder
								borderRadius={'1rem'}
								fontSize={'0.8rem'}
								padding={'8px 16px 8px 16px'}
								label={
									<>
										&nbsp; {t('GO TO DASHBOARD')}
										<FaArrowRight />
									</>
								}
								background={palette.white}
								color={palette.blue_500}
								fontWeight={'600'}
								lineHeight={'0.90rem'}
								ifClicked={() => router.navigate('/dashboard')}
							/>
						</Flexed>
					</Col>
				</Row>
			</Container>
			<Spacer height="1.2" />
		</Section>
	)
}

const Section = styled(Flexed)`
	background-color: white;
	height: 100%;
	padding: 1rem;
	align-items: center;
`
const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const Meeting = styled(Flexed)`
	text-align: center;
	background-color: #e6f1fe;
	border-radius: 10px;
	width: 28.25rem;
	height: 3.25rem;
	@media (max-width: 574px) {
		width: 17.25rem;
	}
`

export default BMTSuccess
