import {useEffect, useState} from 'react'
import {Container} from 'styled-bootstrap-grid'
import {Flexed, Text, Spacer, Paragraph} from '../styled/shared'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Row, Col} from 'styled-bootstrap-grid'

import useRouter from '../hooks/router'
import {Icons} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {api} from '../helpers/auth-axios'
import moment from 'moment'
import Input from '../components/common/input/Input'
import InfiniteScroll from 'react-infinite-scroll-component'
import FadeLoader from 'react-spinners/FadeLoader'
import Loader from '../components/common/Loader'
import Footer from '../components/Footer'

const Withdraw = () => {
	const router = useRouter()
	const {t} = useTranslation()
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [withdrawAmount, setWithdrawAmount] = useState<any>('')
	const [coins, setcoins] = useState([])
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState('')
	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(false)
	const [convertedAmount, setConvertedAmount] = useState<string>('')

	const handleInputChange = (value: any) => {
		setWithdrawAmount(value)
		if (!value || isNaN(value)) {
			setConvertedAmount('')
			return
		}
		const withdrawValue = parseFloat(value)
		setConvertedAmount(`$${(withdrawValue / 5).toFixed(2)}`)
	}

	const handleWithdraw = () => {
		if (!withdrawAmount || isNaN(withdrawAmount)) {
			setError(t('Please enter a valid amount'))
			return
		}

		const withdrawValue = parseFloat(withdrawAmount)
		const min = _userDetails.coins - withdrawAmount
		if (min < 50) {
			setError(t('Minimum balance should be 10$ = 50 coins'))
			return
		}

		if (withdrawValue > _userDetails.coins) {
			setError(t('Withdrawal amount exceeds available coins'))
			return
		}
		router.navigate(`/withdraw-paypal?coins=${withdrawValue}`)
	}

	useEffect(() => {
		if (_userDetails.id) {
			getCoinsTransaction(page)
		}
	}, [_userDetails.id, page])

	const getCoinsTransaction = async (page: any) => {
		setLoading(true)
		const response = await api.get(`/user/getCoinsTransaction/${_userDetails.id}?page=${page}`)
		if (response.data.status) {
			setLoading(false)
			const newCoins: any = [...coins, ...response.data.data]
			setcoins(newCoins)
			setHasMore(response.data.data.length !== 0)
		} else {
			// do what gotta do
			setLoading(false)
		}
	}

	const fetchMoreData = () => {
		setPage((prev: any) => prev + 1)
	}

	return (
		<Section>
			{loading && <Loader />}
			{/* <Spacer height={4} /> */}
			<CenteredContainer fluid>
				<Main>
					<Container fluid>
						<Row>
							<Col lg={6} sm={6}>
								<Flexed margin="1rem 0rem 0rem 0rem" direction="row" gap={0.5} align="center ">
									<Icons src={'/images/icon_view.svg'} onClick={() => router.navigate('/buy-my-time')} pointer={'pointer'} />
									<WithdrawText lineHeight="1.36" fontSize="1.12" fontWeight="700">
										{t('Withdraw Coins')}
									</WithdrawText>
								</Flexed>
								<Flexed margin="2rem 0rem 0rem 0rem" justify="flex-start" direction="col" gap={0.7}>
									<Text lineHeight="0.908" fontSize="0.75" fontweight="600" color={'default_foreground'} opacity={'60%'}>
										{t('AVAILABLE COINS')}
									</Text>

									<CoinWrapper justify="center" direction={'row'} align="center" gap={'0.5'} radius={'1rem'}>
										<img src={'/images/icon_goldcoin.svg'} width="36px" height="36px" />
										<Text fontSize="2.375" fontWeight="600" lineHeight="2.875">
											{_userDetails?.coins}
										</Text>
										<Text fontSize="1.5" fontWeight="400" lineHeight="2.875">
											{_userDetails?.coins > 0 ? `$${(_userDetails?.coins / 5).toFixed(2)}` : _userDetails?.coins}
										</Text>
									</CoinWrapper>

									<Text color={'default_foreground'} fontsize="1" ineHeight="1.22" fontweight="400">
										{t('Your earnings after JustNetwork fees & taxes')}
									</Text>
									{/* Input Field */}
									<Input allowOnlyNumbers min={1} label="Enter withdrawal amount" onChange={(e: any) => handleInputChange(e?.value)} value={withdrawAmount} />
									{error && <ErrorText>{error}</ErrorText>}

									{convertedAmount && (
										<ConversionText>
											{t('Converted amount')}: {convertedAmount}
										</ConversionText>
									)}

									<Button
										small
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'8px 16px 8px 16px'}
										label={
											<>
												<Icons src={'/images/icon_withdraw.svg'} /> &nbsp;{t('WITHDRAW COINS')}
											</>
										}
										background={palette.blue_500}
										color={palette.white}
										lineHeight={'0.89rem'}
										ifClicked={() => handleWithdraw()}
									/>
								</Flexed>
							</Col>

							<Col lg={6}>
								<Flexed margin="1rem 0rem 0rem 0rem" justify="flex-end" direction="row">
									<img src="/images/withdraw.png" alt="error" width="228px" height="204px" />
								</Flexed>
							</Col>
						</Row>
					</Container>
					<Spacer height={1} />
					<HR />
					<Spacer height={1} />
					<Container fluid>
						<Text fontWeight="600" color={'default_foreground'} fontSize="1.25" lineheight="1.519">
							{t('Recent transactions')}
						</Text>
						<Spacer height={1.6} />

						<Row>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<Text fontSize="1" fontWeight="600" lineheight="1.21" color={'default_foreground'} opacity={'60%'}>
									{t('Date')}
								</Text>
							</Col>
							<Col xs={8} sm={8} md={8} xl={8} lg={8}>
								<Text fontSize="1" fontWeight="600" lineheight="1.21" color={'default_foreground'} opacity={'60%'}>
									{t('Description')}
								</Text>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<Flexed justify="flex-end" direction="row">
									<Text align="flex-end" fontSize="1" fontWeight="600" lineheight="1.21">
										{t('Coins')}
									</Text>
								</Flexed>
							</Col>
						</Row>
						<Container />
						<HR />
					</Container>
					<div id="withdraw" style={{height: '30rem', overflowY: 'scroll'}}>
						<InfiniteScroll scrollableTarget="withdraw" scrollThreshold={0.9} dataLength={coins.length} next={fetchMoreData} hasMore={hasMore} loader={<></>} endMessage={coins?.length && !hasMore ? <></> : ''}>
							{coins.length
								? coins?.map((value: any) => (
										<Container fluid key={value.id}>
											<Row>
												<Col xs={2} sm={2} md={2}>
													<Paragraph fontSize="1.125" color={'default_foreground'} opacity={'50%'} fontWeight="700" lineheight="1.361">
														{moment(value.createdAt).format('MMM D, YYYY')}
													</Paragraph>
												</Col>
												<Col xs={8} sm={8} md={8}>
													<Text fontSize="1.125" color={'default_foreground'} displa fontWeight="600" width="100%" lineheight="1.361" opacity={'87%'}>
														{value.type === 'purchase'
															? t(`Received coins through purchase`)
															: value.type === 'membership'
															? t('You subscribed to pro subscription')
															: value.type === 'transfer' && value.userCoinsFrom.id !== _userDetails.id
															? `${t('Received coins from')} ${value.userCoinsFrom?.full_name}`
															: value.type === 'transfer' && value.userCoinsFrom.id === _userDetails.id
															? `${t('Send coins to')} ${value.userCoinsTo?.full_name}`
															: value.type === 'applyPromo'
															? t('Received coins through applying promo')
															: value.type === 'receive' && value.u_id == _userDetails.id
															? t(`You receive coins from your recent meeting`)
															: value.type === 'withdraw'
															? t(`You withdraw coins`)
															: value.type === 'invite'
															? t(`Coins were receive on invite`)
															: value.type === 'deduct'
															? t(`Coins were deducted from your recent meeting`)
															: value.type == 'adminTransfer' && t(`Coins were received from admin`)}
													</Text>
												</Col>

												<Col xs={2} sm={2} md={2}>
													<Flexed padding="0.5rem 0rem 0.5rem 0rem" justify="flex-end" direction="row">
														<AmountTotal
															align="flex-end"
															fontSize="1.125"
															color={value.type === 'withdraw' || (value.userCoinsFrom.id === _userDetails.id && value.type === 'transfer') || value.type === 'deduct' ? '#800000' : '#008000'}
															fontWeight="600"
															lineheight="1.36">
															{value.userCoinsFrom.id !== _userDetails.id && value.type === 'transfer'
																? '+'
																: value.userCoinsFrom.id === _userDetails.id && value.type === 'transfer'
																? '-'
																: value.type === 'withdraw' || value.type === 'deduct'
																? '-'
																: value.coins
																? '+'
																: ''}
															{value.coins}
														</AmountTotal>
													</Flexed>
												</Col>
											</Row>
											<HR />
										</Container>
								  ))
								: !loading && coins?.length == 0 && <>There is no transaction to show</>}
						</InfiniteScroll>
					</div>

					<Spacer height={5} />
				</Main>
				<Spacer height={5} />
			</CenteredContainer>
			<div style={{padding: '0rem 1.2rem', alignSelf: 'flex-start'}}>
				<Footer />
			</div>
		</Section>
	)
}

const Section = styled.div`
	background-image: url('/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const WithdrawInput = styled.input`
	width: 100%;
	padding: 0.5rem;
	border: 1px solid ${palette.blue_500};
	border-radius: 4px;
	font-size: 1rem;
	color: ${palette.default_foreground};
	outline: none;
	transition: border-color 0.3s;

	&:focus {
		border-color: ${palette.sky_blue};
	}
`
const ErrorText = styled.div`
	color: rgb(235, 83, 83);
	background: white;
	border: 1px solid rgb(235, 83, 83);
	border-radius: 0px 0px 0.325rem 0.325rem;
	padding: 2px 10px;
	text-align: center;
	margin-top: -3px;
	font-size: 0.75rem;
	font-weight: 300;
`
const CenteredContainer = styled(Container)`
	margin-top: 6rem;
	display: flex;
	justify-content: center;
`

const Main = styled.div`
	padding: 1rem;
	border-radius: 20px;
	width: 83rem;
	background-color: white;
`
const WithdrawText = styled(Text)`
	color: ${palette.dark_blue};
`
const CoinWrapper = styled(Flexed)`
	border: 1px solid ${palette.sky_blue};
	background-color: ${palette.sky_blue};
	border-radius: 2.75rem;
	width: max-content;
	padding: 0 0.5rem;
	height: 3.625rem;
`
const ConversionText = styled(Text)`
	color: ${palette.default_foreground};
	font-size: 1rem;
	margin-top: 0.5rem;
	opacity: 0.7;
`

const ButtonMain = styled(Flexed)`
	width: 11.375rem;
	height: 2rem;
	font-size: 0.75rem;
	border: 1px solid;
	border-radius: 1rem;
	padding: 0.5rem, 1rem, 0.5rem, 1rem;
	background-color: #006fee;
	color: white;
`
const ButtonMainText = styled(Text)`
	color: white;
`
const PreviousIcon = styled.div`
	/* border: 1px solid gainsboro; */
	border-radius: 0.3rem;
`
const HR = styled.hr`
	border: 0.1px solid gainsboro;
`
const AmountTotal = styled(Text)`
	color: ${({color}) => `${color}`};
`

export default Withdraw
