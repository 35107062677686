import {combineReducers, createStore} from 'redux'
import auth from './authReducer'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// const persistConfig = {
// 	key: 'root',
// 	storage
// }

// const rootReducer = combineReducers({
// 	auth
// })
// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store = createStore(persistedReducer)
// export const persistor = persistStore(store)
export default combineReducers({
	auth
})
