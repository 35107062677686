import {useEffect, useReducer, useState} from 'react'
import {toast} from 'react-toastify'
import {api} from '../helpers/auth-axios'
import {Flexed} from '../styled/shared'
import {useSelector} from 'react-redux'
import Input from './common/input'
import Button from './common/Button'
import {palette} from '../styled/colors'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {useDispatch} from 'react-redux'
import {setNotification} from '../actions/authActions'
import Loader from './common/Loader'
import {useTranslation} from 'react-i18next'

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	cursor: ${({cursor}) => (cursor ? cursor : 'default')};
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
 min-height:auto;
 /* width:auto; */
 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
 `};
`

const EmailInput = ({userEmail, isAccepted, getUserInvites}: {userEmail: any; isAccepted: any; getUserInvites: any}) => {
	const [email, setEmail] = useState(userEmail)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const {t} = useTranslation()

	const _isNotification = useSelector<any>((state) => state.auth.isNotification)
	const dispatch = useDispatch()
	useEffect(() => {
		setEmail(userEmail)
	}, [userEmail])

	const handleEmailChange = (e: any) => {
		const newEmail = e.value
		if (newEmail) {
			setEmail(newEmail)
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

			if (!emailRegex.test(newEmail)) {
				setError(t('Please enter a valid email address.'))
			} else {
				setError('')
			}
		} else {
			setError(t('Email is required'))
			setEmail('')
		}
	}

	const inviteUser = async () => {
		if (email?.trim()?.length) {
			setLoading(true)

			try {
				const res = await api.post('/user/invite_user', {email})
				setLoading(false)

				if (res?.data?.status) {
					dispatch(setNotification(!_isNotification))
					toast.success(res?.data?.message)
					getUserInvites()
				} else {
					toast.error(res?.data?.message)
				}
			} catch (error) {
				setLoading(false)
				toast.error(t('Failed to send invite'))
			}
		} else {
			setError(t('Please enter a valid email address.'))
		}
	}

	const cancelEmail = async () => {
		if (email?.trim()?.length) {
			setLoading(true)

			try {
				const res = await api.put('/user/cancel_invite_user', {email})
				setLoading(false)

				if (res?.data?.status) {
					setEmail('')
					dispatch(setNotification(!_isNotification))
					toast.success(res?.data?.message)
					getUserInvites()
				} else {
					toast.error(t('Failed to cancel invite'))
				}
			} catch (error) {
				setLoading(false)
				toast.error(t('An error occurred while canceling the invite'))
			}
		} else {
			setError(t('Please enter a valid email address.'))
		}
	}

	return (
		<div style={{marginTop: '1rem'}}>
			<Card background={palette.input_bg} minHeight={'auto'} padding={'0.5rem'} height={'auto'} radius={'0.7rem'}>
				<Flexed direction="row" width={'100%'} align={'center'}>
					<Input background={'transparent'} type={'email'} isContact value={email} label={t('Email')} disabled={!!userEmail} error={error ? {message: error} : undefined} onChange={handleEmailChange} />
					{loading ? <Loader color={palette.dark_blue} /> : ''}

					<Button small hasBorder borderRadius={'1rem'} fontSize={'0.8rem'} fontWeight={600} padding={'0'} label={isAccepted ? 'INVITED' : userEmail && !isAccepted ? t('CANCEL') : t('INVITE')} background={palette.white} color={palette.blue_500} lineHeight={'0.91rem'} disabled={!!userEmail && isAccepted} ifClicked={userEmail && !isAccepted ? cancelEmail : inviteUser} />
				</Flexed>
			</Card>
		</div>
	)
}

export default EmailInput
