import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Flexed, Heading, Paragraph, Spacer, Text} from '../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import InputField from '../components/common/InputField'
import Button from '../components/common/Button'
import LogInGoogle from '../components/common/LogInGoogle'
import {useNavigate} from 'react-router-dom'
import LinkedInPage from './common/LoginWithLinkedin'
import SignUpForm from './SignupForm'
import {useTranslation} from 'react-i18next'

interface IProps {
	isDarkTheme?: boolean
}

const AuthPageComponent = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const {t} = useTranslation()

	return (
		<MainContainer>
			<Container fluid>
				<Row justifyContent="center">
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<Spacer height={2} />
						<SignUpForm />
					</Col>

					<Col xl={6} lg={6} md={6} sm={6} xs={10}>
						<Spacer height={2} />
						<Flexed justify="center">
							<Heading level={2} isCentered isDarkTheme={_isDarkTheme}>
								{t('Create an Account')}
							</Heading>

							<Spacer height={2} />

							<LogInGoogle />

							<Spacer height={1} />

							<Border isDarkTheme={_isDarkTheme}>
								<CustomText fontSize="1" lineHeight={2} isDarkTheme={_isDarkTheme}>
									{t('Or SignUp With')}
								</CustomText>
							</Border>

							<Spacer height={2} />
							<Column lg={12}>
								<InputField label={t('First Name')} type="text" placeholder="Enter" />
							</Column>
							<Spacer height={1} />

							<Column lg={12}>
								<InputField label={t('Last Name')} type="text" placeholder="Enter" />
							</Column>
							<Spacer height={1} />

							<Column lg={12}>
								<InputField label={t('Email')} type="email" placeholder="Enter" />
							</Column>
							<Spacer height={1} />

							<Column lg={12}>
								<InputField label={t('Password')} type="password" placeholder="Password" />
							</Column>
							<Spacer height={1} />

							<Column lg={12}>
								<InputField label={t('Confirm Password')} type="password" placeholder="Password" />
							</Column>
							<Spacer height={1} />

							<Flexed direction="row" gap={2}>
								<Text type="normal" fontWeight={500} margin>
									Gender:
								</Text>
								<Flexed direction="row" gap={2}>
									<Flexed direction="row" align="center" gap={0.5}>
										<input name="gender" type="radio" />
										<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
											{t('Male')}
										</Label>
									</Flexed>
									<Flexed direction="row" align="center" gap={0.5}>
										<input name="gender" type="radio" />
										<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
											{t('Female')}
										</Label>
									</Flexed>
								</Flexed>
							</Flexed>

							<Spacer height={2} />
							<Column lg={12}>
								<Button label={t('Create an Account')} width="100%" />

								<Spacer height={1} />

								<Flexed direction="row" justify="center" gap={0.5} align="center">
									<Text textTransform="capitalize" type="normal" isDarkTheme={_isDarkTheme}>
										{t('Already have an account?')}
									</Text>
									<Heading
										level={5}
										isDarkTheme={_isDarkTheme}
										pointer
										onClick={() => {
											_navigate('/Login')
										}}>
										Login
									</Heading>
								</Flexed>
							</Column>
						</Flexed>
						<Spacer height={2} />
					</Col>
				</Row>
			</Container>
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`
const Border = styled.div<IProps>`
	display: block;
	width: 100%;
	text-align: center;
	border-bottom: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
`

const CustomText = styled(Text)`
	text-align: center;
	position: relative;
	top: 0.938rem;
	padding: 0 0.938rem;
	display: inline-block;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.white)};
	color: ${palette.opacity.black};
	text-transform: capitalize;
`

const Column = styled(Col)`
	padding: 0rem;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-weight: 500;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: hidden;
	text-align: center;
	padding: 1.5rem;
	border-radius: 15px;
	/* box-shadow: ${palette.shadow}; */
	min-height: 13rem;
	cursor: default;
	margin-bottom: ${({marginBottom}) => (marginBottom ? marginBottom : '1.875rem')};
	border: 2px solid;
	background: #ffffff;

	border-image-source: linear-gradient(112.31deg, #ffffff 6.93%, rgba(255, 255, 255, 0) 98.02%);
	box-shadow: 0px 0px 20px 0px #0000000d;

	${media.xs` min-height: 12rem;`};
`
export default AuthPageComponent
