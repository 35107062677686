import React, {useTransition} from 'react'
import styled from 'styled-components'
import Button from '../components/common/Button'
import {useTranslation} from 'react-i18next'
import useRouter from '../hooks/router'
import {palette} from '../styled/colors'

const PageNotFound = () => {
	const {t} = useTranslation()
	const router = useRouter()
	return (
		<Container>
			<h1>404</h1>
			<p>{t('Page Not Found')}</p>
			<Button
				label={t('Back to home')}
				small
				borderRadius={'1rem'}
				fontSize={'0.8rem'}
				background={palette.default_theme}
				color={palette.white}
				padding={'8px 16px 8px 16px'}
				lineHeight={'0.89rem'}
				ifClicked={() => router.navigate(localStorage.getItem('authorization') ? '/dashboard' : '/')}
			/>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: ${({theme}) => theme.background};
	color: ${({theme}) => theme.text};

	h1 {
		font-size: 4rem;
		margin-bottom: 1rem;
	}

	p {
		font-size: 1.5rem;
	}
`

export default PageNotFound
