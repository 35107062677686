import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Icons} from '../TopNavBar'
import {palette} from '../../styled/colors'
import {media} from 'styled-bootstrap-grid'
import {Dot} from '../../pages/SignUp'
import {useTranslation} from 'react-i18next'

const CalendarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-x: scroll;
`

const CalendarBody = styled.div`
	display: grid;
	padding: 1rem;
	gap: 1rem;
	grid-template-columns: repeat(7, 1fr);
	flex-wrap: wrap;
	${media.xs`
    gap: 0.3rem;
  `};

	${media.sm`
    gap:  0.5rem;
  `};

	${media.md`
    gap:  0.5rem;
  `};

	${media.lg`
    gap:  0.5rem;
  `};

	${media.xl`
    gap: 1rem;
  `};
`

const DayCell = styled.div<any>`
	position: relative;
	text-align: right;
	width: 4rem;
	height: 4rem;
	cursor: ${({cursor}) => (cursor ? 'pointer' : 'not-allowed')};
	background: ${({today, available, selected}) => (selected ? palette.blue_500 : available ? palette.primary_50 : palette.input_bg)};
	border: ${({today, available}) => (today ? `1px solid ${palette.blue_400}` : 'none')};
	border-radius: 5px;

	${media.xs`
    height: 2rem;
    width: 2rem;
  `};

	${media.sm`
    height: 2rem;
    width: 2rem;
  `};

	${media.md`
    width: 3.5rem;
    height: 3.5rem;
  `};

	${media.lg`
    width: 3.5rem;
    height: 3.5rem;
  `};

	${media.xl`
    width: 4rem;
    height: 4rem;
  `};
`

const CustomText = styled(Text)<any>`
	margin: 0.1rem 1rem;
	${media.xs`
    margin: 0.1rem;
    text-align: center;
  `};

	${media.sm`
    margin: 0.1rem;
    text-align: center;
  `};

	${media.md`
    margin: 0.1rem 0.5rem;
    text-align: center;
  `};

	${media.lg`
    margin: ${({margin}: any) => (margin ? margin : `0.1rem 1rem`)};
    text-align: right;
  `};

	${media.xl`
    margin: ${({margin}: any) => (margin ? margin : `0.1rem 1rem`)};
    text-align: right;
  `};
`

const CustomCalendar = ({availableDays, handleSelection, title, selecteddate}: {availableDays?: any; handleSelection?: any; title?: any; selecteddate?: any}) => {
	const [selectedDate, setSelectedDate] = useState<number | null>(null)
	const [currentMonth, setCurrentMonth] = useState<any>(moment())
	const [daysInMonth, setDaysInMonth] = useState<any>([])
	const {t} = useTranslation()

	useEffect(() => {
		setDaysInMonth(getDaysInMonth(moment()))
	}, [])

	useEffect(() => {
		if (selecteddate) {
			setSelectedDate(selecteddate)
		}
	}, [selecteddate])

	const getDaysInMonth = (month: moment.Moment): any[] => {
		const firstDayOfMonth = month.clone().startOf('month')
		const lastDayOfMonth = month.clone().endOf('month')
		const daysInMonth = lastDayOfMonth.date()
		const daysInPreviousMonth = firstDayOfMonth.weekday()
		const totalDays = daysInMonth + daysInPreviousMonth
		let days: any[] = []

		const previousMonthDays = firstDayOfMonth.clone().subtract(daysInPreviousMonth, 'days')
		for (let i = 0; i < daysInPreviousMonth; i++) {
			days.push(previousMonthDays.clone().add(i, 'day').format('YYYY-MM-DD'))
		}

		for (let i = 1; i <= daysInMonth; i++) {
			days.push(month.clone().date(i).format('YYYY-MM-DD'))
		}

		const nextMonthDays = lastDayOfMonth.clone().add(1, 'day')
		const remainingDays = 42 - days.length
		for (let i = 0; i < remainingDays; i++) {
			days.push(nextMonthDays.clone().add(i, 'day').format('YYYY-MM-DD'))
		}

		return days
	}

	const handleDayClick = (date: any) => {
		setSelectedDate(date)
		handleSelection(date)
	}

	const goToPreviousMonth = () => {
		const previousMonth = moment(currentMonth).subtract(1, 'month')
		setCurrentMonth(previousMonth)
		setDaysInMonth(getDaysInMonth(previousMonth))
	}

	const goToNextMonth = () => {
		const nextMonth = moment(currentMonth).add(1, 'month')
		setCurrentMonth(nextMonth)
		setDaysInMonth(getDaysInMonth(nextMonth))
	}

	const _currentDay = moment().format('YYYY-MM-DD')
	return (
		<CalendarWrapper>
			<Flexed direction={'row'} align={'center'} justify={'space-between'}>
				<Text fontSize={'1.12'} lineHeight={'1.34'} fontWeight={'600'} color={'text_color'}>
					{title ? title : t('Choose Date')}
				</Text>
				<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'1'}>
					<Icons src={'/images/icon_view.svg'} onClick={goToPreviousMonth} pointer={'pointer'} />

					<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
						{currentMonth.format('MMMM YYYY')}
					</Text>
					<Icons src={'/images/icon_view_left.svg'} onClick={goToNextMonth} pointer={'pointer'} />
				</Flexed>
			</Flexed>
			<Spacer height={'1'} />
			<CalendarBody>
				{[t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')].map((dayName) => (
					<CustomText key={dayName} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} align={'right'} margin={'0.1rem 1rem'} color={'days_color'}>
						{dayName}
					</CustomText>
				))}
				{daysInMonth.map((day: any) => (
					<DayCell
						key={day}
						cursor={_currentDay <= day ? true : false}
						onClick={() => {
							_currentDay <= day && handleDayClick(moment(day).format())
						}}
						today={moment().isSame(currentMonth, 'month') ? moment().format('YYYY-MM-DD') === day : false}
						available={_currentDay <= day ? true : false}
						selected={day == moment(selectedDate).format('YYYY-MM-DD')}>
						<Text
							style={{position: 'absolute', top: '0', right: '0'}}
							margin={'0.1rem 0.3rem'}
							fontSize={'1'}
							lineHeight={'1.23'}
							fontWeight={'800'}
							align={'right'}
							color={day == moment(selectedDate).format('YYYY-MM-DD') ? 'white' : _currentDay <= day ? 'blue_500' : 'black'}
							opacity={day == moment(selectedDate).format('YYYY-MM-DD') ? 1 : moment(currentMonth).format('YYYY-MM-DD') <= day && moment().format('YYYY-MM-DD') <= day ? 1 : moment().format('YYYY-MM-DD') >= day ? '20%' : 1}>
							{moment(day).format('D')}
						</Text>
					</DayCell>
				))}
			</CalendarBody>
		</CalendarWrapper>
	)
}

export default CustomCalendar
