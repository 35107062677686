import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Link} from 'react-router-dom'
import {Text, Flexed} from '../styled/shared'
import {palette} from '../styled/colors'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Button from './common/Button'
import {FaBars} from 'react-icons/fa'
import {BsClock, BsXLg} from 'react-icons/bs'
import {FaAngleDown} from 'react-icons/fa'
import useRouter from '../hooks/router'
import {useDispatch} from 'react-redux'
import Suggestions from './common/SuggestedSearchInput'
import {api} from '../helpers/auth-axios'
import {callAutoLogin, networkUsers, saveUser, setNotification} from '../actions/authActions'
import {useTranslation} from 'react-i18next'
import ChangeLanguagePopup from './modals/ChangeLanguageModal'
import {subscribeToChannel} from '../service/pusherService'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import i18n from '../i18n/i18n'
import ClipLoader from 'react-spinners/ClipLoader'
import UserProfileLoader from './common/UserProfileLoader'
import CloseAccountModal from './modals/CloseAccountModel'
import {BiChevronDown, BiChevronUp} from 'react-icons/bi'

interface IProps {
	scroll: number
	path?: boolean
	active?: boolean
	isDarkTheme?: boolean
	activePath?: boolean
	onSmall?: boolean
}

const TopNavBar = () => {
	const {pathname} = useLocation()
	let _navigate = useNavigate()
	let router = useRouter()

	// const verifyAuthenticated = localStorage.getItem('authenticated')
	const verifyAuthenticated = localStorage.getItem('authorization')
	const dispatch = useDispatch()

	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _searchQuery: any = useSelector<any>((state: any) => state.auth.searchQuery)
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const _isCallLogin: any = useSelector<any>((state: any) => state.auth.isCallLogin)
	const _unreadMessages: any = useSelector<any>((state: any) => state.auth.chatNotifications)
	const _isNotification: any = useSelector<any>((state: any) => state.auth.isNotification)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [selectLngModal, setLngModal] = useState(false)
	const [closeAccountModel, setCloseAccountModel] = useState(false)
	const [openSubMenu, setOpenSubMenu] = useState(false)
	const [totalNotifications, setTotalNotiic] = useState(0)
	const [navBarListOpen, setNavBarListOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState<any>('')
	const token = localStorage.getItem('authorization')
	const {t} = useTranslation()
	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = () => {
		setImageLoaded(true)
	}

	let checkPathname = router.pathname?.includes('.bmt')

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScroll, {passive: true})
	}, [])

	useEffect(() => {
		setSearchQuery(_searchQuery ? _searchQuery : '')
	}, [_searchQuery])

	useEffect(() => {
		if (token && token != 'undefined') {
			autoLogin()
			getTotalNotific()
		}
	}, [])

	useEffect(() => {
		if (token && token != 'undefined' && _isNotification) {
			getTotalNotific()
			autoLogin()
		}
	}, [_isNotification, token])

	useEffect(() => {
		const unsubscribe = subscribeToChannel('NOTIFICATIONS', `NOTIFICATIONS_COUNT_${_userDetails.id}`, (data: any) => {
			// Update state with the received data
			dispatch(setNotification(true))
		})

		return () => {
			unsubscribe()
		}
	}, [_userDetails.id])
	function shouldShowButton(userDetails: any) {
		return userDetails?.proMembership?.length || userDetails?.userMembership?.length
	}

	const getTotalNotific = async () => {
		if (token) {
			const response = await api.get('/user/notifications/length')
			if (response.data.status) {
				dispatch(setNotification(false))
				setTotalNotiic(response.data.data.data)
			}
		}
	}

	useEffect(() => {
		if (token && _isCallLogin) {
			autoLogin()
			if (_userDetails?.is_social_login) {
				getTotalNotific()
			}
		}
	}, [_isCallLogin, _userDetails])

	const initializeChat = async () => {
		try {
			await CometChatUIKit.logout()
		} catch (err) {
			console.log('Some Error Occured', err)
		}
	}

	const autoLogin = async () => {
		await api
			.post('/user/auto_login')
			.then(async (res: any) => {
				if (res?.data?.status) {
					if ((res?.data?.data?.user?.is_email_verified || res?.data?.data?.user?.google_id || res?.data?.data?.user?.linkedin_id) && (res?.data?.data?.user?.userMembership?.length || res?.data?.data?.user?.proMembership?.length)) {
						dispatch(networkUsers(res?.data?.data?.user_network))
						dispatch(saveUser(res?.data?.data?.user))
						dispatch(callAutoLogin(false))
						localStorage.setItem('authorization', res?.data?.data?.user?.login_token)
						localStorage.setItem('user_lang', res?.data?.data?.user?.language)
					} else {
						dispatch(networkUsers(res?.data?.data?.user_network))
						dispatch(callAutoLogin(false))
						if (pathname !== '/subscription_success' && res?.data?.data?.user?.is_email_verified) {
							router.navigate(`/buy-membership?id=${res?.data?.data?.user?.id}`)
						}
					}
					i18n.changeLanguage(res?.data?.data?.user?.language)
					localStorage.setItem('user_lang', res?.data?.data?.user?.language)
					if (!_isCallLogin) {
						checkProMemberFlow()
					}
				} else {
					await initializeChat()
					sessionStorage.removeItem('sessionId')
					if (router.pathname != '/privacy-policy' && router.pathname != '/terms-conditions' && !checkPathname && router.pathname != '/') {
						router.navigate('/login')
					}
				}
			})
			.catch(async (error: any) => {
				await initializeChat()
				sessionStorage.removeItem('sessionId')

				if (router.pathname != '/privacy-policy' && router.pathname != '/terms-conditions' && !checkPathname && router.pathname != '/') {
					router.navigate('/login')
				}
			})
	}

	const handleNavigateNewTab = () => {
		window.open('/privacy-policy', '_blank') // Opens in a new tab
	}

	function getMembershipLength(userDetails: any) {
		// Check if proMembership exists and has a length greater than 0
		if (userDetails?.proMembership && userDetails.proMembership.length) {
			return true
		}
		// If proMembership does not exist or is empty, check userMembership
		if (userDetails?.userMembership && userDetails.userMembership.length) {
			return true
		}
		// If neither exists or both are empty, return undefined
		return false
	}

	const checkProMemberFlow = async () => {
		const response = await api.get('/user/auto_cancel_pro')
		if (response.data.status) {
		} else {
		}
	}

	return (
		<Main
			scroll={scrollPosition}
			path={
				pathname === '/' ||
				pathname === '/signup' ||
				pathname === '/login' ||
				pathname === '/forgot-password' ||
				pathname === '/verify-otp' ||
				pathname === '/dashboard' ||
				pathname === '/network' ||
				pathname == '/book-meeting' ||
				pathname === '/buy-my-time' ||
				pathname === '/withdraw-coins' ||
				pathname === '/inbox' ||
				pathname === '/notifications' ||
				pathname === '/upgrade-bmt-membership' ||
				pathname == '/calendar' ||
				pathname == '/searched-users' ||
				pathname == '/withdraw-paypal' ||
				pathname == '/buy-coins' ||
				pathname == '/bmt-membership-success' ||
				pathname == '/accept-reschedule-successful' ||
				pathname == '/decline-request' ||
				pathname == '/request-reschedule' ||
				pathname == '/accept-reschedule-successful' ||
				pathname == '/logout' ||
				pathname == '/bmt-membership-payment' ||
				pathname == '/update-password' ||
				pathname === '/buy-membership' ||
				pathname == '/send-coins' ||
				checkPathname ||
				pathname == '/reschedule-successful' ||
				pathname == '/subscription_success' ||
				pathname == '/buy_coins_payment'
			}
			isDarkTheme={_isDarkTheme}
			activePath={pathname == '/'}>
			<Container fluid>
				<Row justifyContent={'center'}>
					<CustomCol>
						{verifyAuthenticated &&
						!(!_userDetails.is_membership || pathname === '/signup' || pathname === '/privacy-policy' || pathname === '/terms-conditions' || pathname === '/login' || pathname === '/forgot-password' || pathname === '/verify-otp') ? (
							<CustomFlexed direction={'row'} align={'center'} justify={'space-between'} gap={pathname == '/' ? 1 : '1'} width={'100%'}>
								<CustomFlexed direction={'row'} align={'center'} justify={'space-between'} gap={0.9}>
									<Flexed
										direction={'row'}
										align={'center'}
										gap={'0.3'}
										pointer
										onClick={() => {
											if (!_userDetails?.is_email_verified) {
												localStorage.clear()
												dispatch(saveUser(''))
												router.navigate('/')
											} else if (_userDetails?.id && _userDetails?.is_email_verified && _userDetails.is_membership) {
												router.navigate('/dashboard')
											} else {
												localStorage.clear()
												dispatch(saveUser(''))
												router.navigate('/')
											}
										}}>
										<Logo src="/images/logo.svg" alt="logo" />

										<BrandName
											fontSize={'1.8'}
											path={
												pathname === '/' ||
												pathname === '/dashboard' ||
												pathname === '/network' ||
												pathname == '/book-meeting' ||
												pathname === '/buy-my-time' ||
												pathname === '/withdraw-coins' ||
												pathname === '/inbox' ||
												pathname === '/notifications' ||
												pathname === '/upgrade-bmt-membership' ||
												pathname == '/calendar' ||
												pathname == '/searched-users' ||
												pathname == '/withdraw-paypal' ||
												pathname == '/buy-coins' ||
												pathname == '/bmt-membership-success' ||
												pathname == '/accept-reschedule-successful' ||
												pathname == '/decline-request' ||
												pathname == '/request-reschedule' ||
												pathname == '/accept-reschedule-successful' ||
												pathname == '/logout' ||
												pathname == '/bmt-membership-payment' ||
												pathname == '/buy-membership' ||
												pathname == '/update-password' ||
												pathname == '/buy_coins_payment' ||
												pathname == '/send-coins' ||
												pathname.includes('@') ||
												pathname == '/subscription_success' ||
												checkPathname ||
												pathname == '/buy_coins_payment'
											}
											display={verifyAuthenticated ? false : pathname === '/' || pathname === '/signup' || pathname === '/login' || pathname === '/forgot-password' || pathname === '/verify-otp' || pathname === '/'}
											lineHeight={'2.11'}
											type={'small'}
											fontWeight={'800'}
											color={'text_color'}>
											JustNetwork
										</BrandName>
									</Flexed>

									<Menu direction="row" align="center" isDarkTheme={_isDarkTheme} scroll={scrollPosition} path={pathname === '/'}>
										<MenuList type="normal" color={'input_text_color'} active={pathname === '/dashboard'} isDarkTheme={_isDarkTheme} scroll={scrollPosition} path={pathname === '/'} onClick={() => router.navigate('/dashboard')}>
											<Icons src={pathname === '/dashboard' ? '/images/grid_view_blue.svg' : '/images/dashboard-grid.svg'} /> {t('Dashboard')}
										</MenuList>
										<MenuList
											type="normal"
											isDarkTheme={_isDarkTheme}
											color={'input_text_color'}
											scroll={scrollPosition}
											path={pathname === '/network'}
											active={pathname === '/network' || checkPathname || pathname === '/book-meeting'}
											onClick={() => router.navigate('/network')}>
											<Icons src={pathname == '/network' || pathname == '/book-meeting' || checkPathname ? '/images/network_blue.svg' : '/images/user_group.svg'} /> {t('Network')}
										</MenuList>
										<MenuList
											type="normal"
											isDarkTheme={_isDarkTheme}
											color={'input_text_color'}
											scroll={scrollPosition}
											path={pathname === '/calendar'}
											active={pathname === '/calendar'}
											onClick={() => router.navigate('/calendar')}>
											<Icons src={pathname == '/calendar' ? '/images/calendar_month_active.svg' : '/images/calendar_month.svg'} /> {t('Calendar')}
										</MenuList>
										<MenuList type="normal" isDarkTheme={_isDarkTheme} color={'input_text_color'} scroll={scrollPosition} path={pathname === '/inbox'} active={pathname === '/inbox'} onClick={() => router.navigate('/inbox')}>
											<Icons src={pathname == '/inbox' ? '/images/chat_bubble_active.svg' : '/images/chat_bubble.svg'} /> {t('Inbox')}
											{_unreadMessages > 0 && <NotificationBadge>{_unreadMessages}</NotificationBadge>}
										</MenuList>
										<MenuList
											type="normal"
											isDarkTheme={_isDarkTheme}
											color={'input_text_color'}
											scroll={scrollPosition}
											// onMouseEnter={() => dispatch(setNotification(true))}
											// onMouseLeave={() => dispatch(setNotification(false))}
											path={pathname === '/notifications'}
											active={pathname === '/notifications'}
											onClick={() => router.navigate('/notifications')}>
											<Icons src={pathname == '/notifications' ? '/images/notifications_active.svg' : '/images/notifications.svg'} />
											{t('Notifications')}
											{totalNotifications > 0 && <NotificationBadge>{totalNotifications}</NotificationBadge>}
										</MenuList>
									</Menu>
								</CustomFlexed>
								<Suggestions />
								<CustomFlexed direction={'row'} align={'center'} justify={'space-between'} gap={0.9}>
									<Menu direction="row" align="center" style={{cursor: 'pointer'}} isDarkTheme={_isDarkTheme} onClick={() => router.navigate('/buy-coins')}>
										<CoinWrapper direction={'row'} align={'center'} gap={'0.5'} radius={'1rem'}>
											<Icons src={'/images/icon_goldcoin.svg'} />
											<Text color={'blue_500'}>{_userDetails?.coins}</Text>
										</CoinWrapper>
									</Menu>
									<Menu direction="row" align="center" isDarkTheme={_isDarkTheme}>
										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													<BsClock /> &nbsp; {t('Buy My Time')}
												</>
											}
											background={palette.default_theme}
											color={palette.white}
											lineHeight={'0.89rem'}
											ifClicked={() => router.navigate('/buy-my-time')}
											isBMT
										/>
									</Menu>
									<ActionMenu direction="row" align="center" gap={1}>
										<BarWrapper isDarkTheme={_isDarkTheme} scroll={scrollPosition} path={pathname === '/'}>
											<MenuList type="normal" isDarkTheme={_isDarkTheme} scroll={scrollPosition} onSmall={true}>
												{navBarListOpen ? (
													<BsXLg
														onClick={() => {
															setNavBarListOpen(false)
														}}
													/>
												) : (
													<FaBars
														onClick={() => {
															setNavBarListOpen(true)
														}}
													/>
												)}
												<DropContent isDarkTheme={_isDarkTheme} display={navBarListOpen}>
													<DropMenu isDarkTheme={_isDarkTheme} onClick={() => router.navigate('/dashboard')} active={pathname === '/dashboard'}>
														<Icons src={pathname === '/dashboard' ? '/images/grid_view_blue.svg' : '/images/dashboard-grid.svg'} /> {t('Dashboard')}
													</DropMenu>
													<DropMenu isDarkTheme={_isDarkTheme} onClick={() => router.navigate('/network')} active={pathname === '/network'}>
														<Icons src={pathname == '/network' ? '/images/network_blue.svg' : '/images/user_group.svg'} /> {t('Network')}
													</DropMenu>
													<DropMenu isDarkTheme={_isDarkTheme} onClick={() => router.navigate('/calendar')} active={pathname === '/calendar'}>
														<Icons src={pathname == '/calendar' ? '/images/calendar_month_active.svg' : '/images/calendar_month.svg'} /> {t('Calendar')}
													</DropMenu>
													<DropMenu isDarkTheme={_isDarkTheme} onClick={() => router.navigate('/inbox')} active={pathname === '/inbox'}>
														<Icons src={pathname == '/inbox' ? '/images/chat_bubble_active.svg' : '/images/chat_bubble.svg'} /> {t('Inbox')}
													</DropMenu>
													<DropMenu isDarkTheme={_isDarkTheme} style={{display: 'flex', alignItems: 'center'}} onClick={() => router.navigate('/notifications')} active={pathname === '/notifications'}>
														<Icons src={pathname == '/notifications' ? '/images/notifications_active.svg' : '/images/notifications.svg'} /> {t('Notifications')}
														{totalNotifications > 0 && <NotificationBadge>{totalNotifications}</NotificationBadge>}
													</DropMenu>

													<DropMenu isDarkTheme={_isDarkTheme} style={{display: 'flex', alignItems: 'center'}} onClick={() => router.navigate('/buy-coins')}>
														<CoinWrapper direction={'row'} align={'center'} gap={'0.5'} radius={'1rem'}>
															<Icons src={'/images/icon_goldcoin.svg'} />
															<Text color={'blue_500'}>{_userDetails?.coins}</Text>
														</CoinWrapper>
													</DropMenu>
													<DropMenu isDarkTheme={_isDarkTheme} style={{display: 'flex', alignItems: 'center'}}>
														<Button
															small
															borderRadius={'1rem'}
															fontSize={'0.8rem'}
															padding={'8px 16px 8px 16px'}
															label={
																<>
																	<BsClock /> &nbsp; {t('Buy My Time')}
																</>
															}
															background={palette.default_theme}
															color={palette.white}
															lineHeight={'0.89rem'}
															ifClicked={() => router.navigate('/buy-my-time')}
															isBMT
														/>
													</DropMenu>
												</DropContent>
											</MenuList>
										</BarWrapper>
									</ActionMenu>
									<MenuList type="normal" isDarkTheme={_isDarkTheme} scroll={scrollPosition}>
										<Text>
											<UserProfileLoader width={'2.5rem'} height={'2.5rem'} imgUrl={_userDetails?.image_Url} />
										</Text>
										<DropContent isDarkTheme={_isDarkTheme}>
											<DropSubMenu isDarkTheme={_isDarkTheme}>
												<Flexed direction={'row'} onClick={() => setOpenSubMenu(!openSubMenu)} pointer gap={'0.3'} align={'center'}>
													<Icons src={'/images/icon_privacy.svg'} /> {t('Privacy settings')} {openSubMenu ? <BiChevronUp fontSize={'1.5rem'} /> : <BiChevronDown fontSize={'1.5rem'} />} &nbsp; &nbsp;
												</Flexed>
												<SubMenu isDarkTheme={_isDarkTheme} display={openSubMenu}>
													{_userDetails?.is_social_login ? (
														''
													) : (
														<DropMenu isDarkTheme={_isDarkTheme} onClick={() => router.navigate('/update-password')}>
															<Icons src={'/images/icon_password.svg'} /> {t('Change Password')}
														</DropMenu>
													)}
													<DropMenu isDarkTheme={_isDarkTheme} onClick={() => setLngModal(true)}>
														<Icons src={'/images/icon_globe.svg'} /> {t('Change Language')}
													</DropMenu>
													<DropMenu isDarkTheme={_isDarkTheme} onClick={() => setCloseAccountModel(true)}>
														<i className="fal fa-user-times" color={palette.text_color} /> {t('Close Account')}
													</DropMenu>
												</SubMenu>
											</DropSubMenu>

											<DropMenu isDarkTheme={_isDarkTheme} href="#">
												<Icons src={'/images/icon_help.svg'} /> {t('Help')}
											</DropMenu>
											{/* <DropMenu isDarkTheme={_isDarkTheme} href="#" onClick={() => setLngModal(true)}>
												<Icons src={'/images/icon_globe.svg'} /> {t('Change Language')}
											</DropMenu>
											<DropMenu isDarkTheme={_isDarkTheme} href="#" onClick={() => router.navigate('/update-password')}>
												<Icons src={'/images/icon_password.svg'} /> {t('Change Password')}
											</DropMenu> */}
											<DropMenu isDarkTheme={_isDarkTheme} href="#" onClick={() => router.navigate('/logout')}>
												<Icons src={'/images/icon_log-out.svg'} /> {t('Logout')}
											</DropMenu>
										</DropContent>
									</MenuList>
								</CustomFlexed>
							</CustomFlexed>
						) : (
							<Flexed
								direction={'row'}
								align={'center'}
								gap={'0.3'}
								pointer
								onClick={() => {
									if (!_userDetails?.is_email_verified) {
										localStorage.clear()
										dispatch(saveUser(''))
										router.navigate('/')
									} else if (!_userDetails && (pathname == '/buy-membership' || pathname == '/upgrade-bmt-membership')) {
										localStorage.clear()
										dispatch(saveUser(''))
										router.navigate('/')
									} else if (_userDetails?.id && _userDetails?.is_email_verified) {
										router.navigate('/dashboard')
									} else {
										localStorage.clear()
										dispatch(saveUser(''))
										router.navigate('/')
									}
								}}>
								<Logo src="/images/logo.svg" alt="logo" />

								<BrandName
									fontSize={checkPathname && window.innerWidth <= 400 ? 1.2 : '1.8'}
									path={pathname === '/' || checkPathname}
									display={
										pathname === '/' ||
										pathname === '/signup' ||
										pathname === '/login' ||
										pathname === '/forgot-password' ||
										pathname === '/verify-otp' ||
										pathname == '/buy-membership' ||
										pathname === '/upgrade-bmt-membership' ||
										pathname === '/terms-conditions' ||
										pathname === '/privacy-policy'
									}
									lineHeight={'2.11'}
									type={'small'}
									fontWeight={'800'}
									color={'text_color'}>
									JustNetwork
								</BrandName>
								{/* {pathname === '/buy-membership' ? (
									<Flexed>
										<Text fontSize={'1.25'} fontWeight={'bold'} lineHeight={'1.5'} color={'dark_blue'}>
											&nbsp; JustNetwork
										</Text>
									</Flexed>
								) : (
									''
								)} */}
							</Flexed>
						)}
						{(router.pathname == '/' || pathname == '/book-meeting' || checkPathname) && !verifyAuthenticated ? (
							<Flexed direction="row" gap={0.5} align={'center'} flexWrap={'wrap'}>
								<CustomButtonFlexed direction="row" align={'center'} gap={0.3} background={palette.white} radius={'20px'} height={'40px'}>
									<CustomButton
										small
										type={'clear'}
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										label={t('SIGN IN')}
										lineHeight={'0.9rem'}
										ifClicked={() => {
											if (pathname === '/book-meeting') {
												// Serialize the pathname and query parameters into a single string
												const redirectUrl = `${pathname}${window.location.search}`
												sessionStorage.setItem('redirect_url', redirectUrl)
											}
											_navigate('/login')
										}}
									/>
								</CustomButtonFlexed>
								<Link to="/signup">
									<CustomButtonFlexed direction="row" align={'center'} gap={0.3} background={palette.blue_500} radius={'20px'} height={'40px'}>
										<CustomButton
											small
											type={'clear'}
											borderRadius={'1rem'}
											background="none"
											fontSize={'0.8rem'}
											color="#fff"
											// padding={checkPathname && window.innerWidth <= 400 ? '4px 8px 4px 8px' : '8px 16px 8px 16px'}
											label={t('Sign Up for Free')}
											margin={'0 0.2rem'}
											lineHeight={'0.9rem'}
											ifClicked={() => {
												_navigate('/signup')
											}}
										/>
									</CustomButtonFlexed>
								</Link>
							</Flexed>
						) : (
							''
						)}
						{/* !(shouldShowButton(_userDetails) || pathname === '/login' || pathname === '/signup') ? (
							<Flexed direction="row" gap={0.5} align={'center'} flexWrap={'wrap'}>
								<Button
									small
									type={'clear'}
									borderRadius={'1rem'}
									fontSize={'0.8rem'}
									padding={'8px 16px 8px 16px'}
									label={t('LOGOUT')}
									lineHeight={'0.9rem'}
									ifClicked={() => {
										localStorage.clear()
										dispatch(saveUser(''))
										_navigate('/')
									}}
								/>
							</Flexed>
						) : */}
					</CustomCol>
				</Row>
			</Container>
			{selectLngModal && <ChangeLanguagePopup header={'Select Language'} onSelect={() => setLngModal(false)} onClose={() => setLngModal(false)} />}
			{closeAccountModel && <CloseAccountModal header={'Close Account ?'} buttonTitle={'Confirm'} onCancel={() => setCloseAccountModel(false)} onClose={() => setCloseAccountModel(false)} onConfirm={() => alert(2)} />}
		</Main>
	)
}

const CustomButtonFlexed = styled(Flexed)<any>`
	${media.xs`
	height: 30px`};
	${media.sm`
	height: 30px`};
	${media.lg`
	height: 40px`};
`

const CustomButton = styled(Button)<any>`
	${media.xs`
	padding: 4px 8px 4px 8px`};
	${media.sm`
	padding: 4px 8px 4px 8px

	`};
	${media.lg`
	padding: 8px 16px 8px 16px
	`};
`
const Main = styled.div<IProps>`
	background: ${({scroll, path, isDarkTheme}) => (scroll < 20 && path ? 'transparent' : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	/* box-shadow: ${palette.shadow}; */
	position: fixed;
	z-index: 5;
	width: 100%;
	border-bottom: ${({activePath}) => (activePath ? `none` : ` 1px solid #0000001f`)};
`
const Menu = styled(Flexed)<IProps>`
	display: none;
	${media.lg`display:flex`};
`

const ActionMenu = styled(Flexed)`
	${media.lg`display:none`};
`

const CustomCol = styled(Col)<any>`
	display: flex;
	height: 4.375rem;

	justify-content: ${({isCenter}) => (isCenter ? 'center' : 'space-between')};
	align-items: center;
	padding: 0 2rem;
	/* ${media.lg`padding:0 2rem;`}; */
`

export const Logo = styled.img`
	height: 3.25rem;
	width: 3.25rem;
	background: ${palette.default_theme} !important;
	border-radius: 5px;
	padding: 0.5rem;
`

export const DropContent = styled.div<any>`
	display: ${({display}) => (display ? 'block' : 'none')};
	margin-top: 0.3rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: max-content;
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
	color: ${palette.text_color};
	top: ${({display, top}) => (top ? top : display ? '200%' : '90%')};
	right: ${({right}) => (right ? right : '18%')};
`

export const DropMenu = styled.div<any>`
	color: ${({isDarkTheme, active}) => (isDarkTheme ? palette.light_gray : active ? palette.blue_500 : palette.text_color)};
	font-size: 0.875rem;
	padding: 0.425rem 1rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	align-items: center;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.silver)};
		color: ${palette.blue};
	}
`

const Arrow = styled(FaAngleDown)<any>`
	transition: transform 0.2s;
	transform: rotate(0deg);
`

export const MenuList = styled(Text)<IProps>`
	position: relative;
	color: ${({scroll, path, isDarkTheme, active}) => (isDarkTheme ? `${palette.light_gray}` : active ? palette.blue_500 : scroll < 680 && path ? `${palette.input_text_color}` : `${palette.input_text_color}`)};
	letter-spacing: 0.05em;
	font-weight: 500;
	align-items: center;
	display: flex;
	font-size: 14.55px;
	line-height: 17.61px;
	cursor: pointer;
	> img {
		padding-right: 0.13rem;
		color: ${({scroll, path, isDarkTheme, active}) => (isDarkTheme ? `${palette.light_gray}` : active ? palette.blue_500 : scroll < 680 && path ? `${palette.input_text_color}` : `${palette.input_text_color}`)};
	}
	&:not(:last-child) {
		padding-right: 2.5rem;
		${media.xs`padding-right: 0.5rem;`};
		${media.sm`padding-right: 0.5rem;`};
		${media.md`padding-right: 1rem;`};
		${media.lg`padding-right: 1rem;`};
		${media.xl`padding-right: 2rem;`};
	}

	&:hover ${DropContent} {
		display: ${({onSmall}) => (onSmall ? '' : 'block')};
	}
	${media.md`font-size: 0.8rem;`};
	${media.lg`font-size: 14.55px;`};
`

const BarWrapper = styled.div<IProps>`
	color: ${({scroll, path, isDarkTheme}) => (isDarkTheme ? `${palette.light_gray}` : scroll < 680 && path ? `${palette.white}` : `${palette.dark_gray}`)};
	display: flex;
	${media.lg`display:none`};
	cursor: pointer;
`

const BrandName = styled(Text)<any>`
	${media.xs`
	display: ${({path}: any) => (path ? 'none' : 'flex')};
	`};
	${media.sm`
	   display:${({path}: any) => (path ? 'none' : 'flex')};
	`};

	${media.md`
	display: ${({display}: any) => (display ? 'flex' : 'none')};
	`};
	${media.lg`	display: ${({display}: any) => (display ? 'flex' : 'none')};`};
`

export const JoinButton = styled(Button)`
	${media.xs`
	padding:0rem !important;
	`};
	${media.sm`
	padding:0rem !important;
	`};
	${media.md`
	padding:8px 16px  8px 16px;
	`};
	${media.lg`
	padding:8px 16px  8px 16px;
	`};
`

export const Icons = styled.img<any>`
	cursor: ${({pointer}: any) => (pointer ? pointer : 'default')};
	width: ${({width}: any) => (width ? width : '')};
	margin-right: ${({marginRight}: any) => (marginRight ? marginRight : '')};
	@media (min-width: 992px) and (max-width: 1050px) {
		width: ${({isSocialIcon, isCopy}: any) => (isSocialIcon ? '2rem' : isCopy ? '0.7rem' : '')};
	}
	@media (max-width: 991px) {
		margin-right: ${({isSocialIcon}: any) => (isSocialIcon ? '0.5rem' : '')};
	}
	@media (max-width: 576px) {
		width: ${({isBookNetworking}: any) => isBookNetworking && '100%'};
	}
`

const CoinWrapper = styled(Flexed)`
	border-color: ${palette.blue_500};
	border: 1px solid ${palette.blue_500};
	padding: 8px 16px 8px 16px;
`

export const ProfileImg = styled.div<any>`
	position: relative;
	width: ${({width}: any) => (width ? width : '1.9rem')};
	height: ${({height}: any) => (height ? height : '1.9rem')};
	border-radius: 100%;
	overflow: hidden;
	background: ${({backgroud}: any) => (backgroud ? backgroud : palette.purple_1)};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	border: 1px solid ${palette.purple_1};
	& > img {
		width: ${({width}: any) => (width ? width : '1.9rem')};
		height: ${({height}: any) => (height ? height : '1.9rem')};
		border-radius: 3rem;
		object-fit: cover;
	}
`

const CustomFlexed = styled(Flexed)<any>`
	gap: ${({gap}) => `${gap}rem`};

	${media.xs`
gap: 0.5rem;
	`};
	${media.sm`
	gap: 0.5rem;
	`};
	${media.md`
	gap: ${({gap}: any) => `${gap}rem`};
	`};
	${media.lg`
	gap: ${({gap}: any) => `${gap}rem`};
	`};

	@media screen and (min-width: 768px) and (max-width: 1300px) {
		gap: 0.5rem;
	}
`

// Styled component for the notification badge
const NotificationBadge = styled.div`
	background-color: red;
	color: white;
	border-radius: 50%;
	padding: 2px 6px;
	font-size: 0.8rem;
	margin-left: 5px;
`
export const DropSubMenu = styled.div<any>`
	color: ${({isDarkTheme, active}) => (isDarkTheme ? palette.light_gray : active ? palette.blue_500 : palette.text_color)};
	font-size: 0.875rem;
	padding: 0.425rem 0rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	align-items: center;
	&:hover {
		/* background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.silver)}; */
		/* color: ${palette.blue}; */
	}
`

const SubMenu = styled.div<any>`
	display: ${({display}) => (display ? 'block' : 'none')};
	/* position: absolute; */
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	/* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
	z-index: 1;

	${DropSubMenu}:hover & {
		/* display: block; */
	}
`

export default TopNavBar
