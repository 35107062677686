import React, {useEffect, useState} from 'react'
import {Col, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Card} from '../../pages/Dashboard'
import {palette} from '../../styled/colors'
import useRouter from '../../hooks/router'
import {Icons} from '../TopNavBar'
import Button from '../common/Button'
import CheckoutPage from './checkout/CheckoutPage'
import MultiSelect from '../common/MultiSelect'
import {useDispatch} from 'react-redux'
import {api} from '../../helpers/auth-axios'
import {saveUser, selectCoinsValue} from '../../actions/authActions'
import FadeLoader from 'react-spinners/FadeLoader'
import {useTranslation} from 'react-i18next'

const BuyCoinsPayment = ({onBack, coins, onSuccess}: {selectedOptions?: any; coins?: any; onBack?: any; onSuccess?: any}) => {
	const [selectCoinsOption, setSelectedCoinsOption] = useState<any>('')
	const {t} = useTranslation()
	const options = [
		{label: '25', value: '5'},
		{label: '50', value: '10'},
		{label: '100', value: '20'},
		{label: '200', value: '40'},
		{label: '500', value: '100'}
	]
	const router: any = useRouter()
	useEffect(() => {
		if (coins) {
			dispatch(selectCoinsValue({label: coins, value: coins / 5}))
		}
	}, [coins])

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const buyCoins = async (coins?: any) => {
		setLoading(true)
		await api
			.post('/user/update', {coins, isPurchasing: true})
			.then((res: any) => {
				if (res?.data) {
					dispatch(saveUser(res?.data?.data))
					setLoading(false)
					const redirect = sessionStorage.getItem('redirect_url')
					if (redirect) {
						// Navigate to the stored path
						router.navigate(redirect)
					} else {
						onSuccess && onSuccess()
					}
				}
			})
			.catch((er: any) => {
				setLoading(false)
			})
	}

	return (
		<Card background={palette.white} padding={'0rem'} overflow={'visible'}>
			<Flexed direction="column" align={''} gap={'0'} padding={'1rem 0.7rem'}>
				<Flexed direction="row" align={'center'} gap={'0.6'}>
					<Icons
						src={'/images/icon_view.svg'}
						onClick={() => {
							onBack && onBack()
						}}
						pointer={'pointer'}
					/>
					<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'dark_blue'}>
						{t('Buy Coins')}
					</Text>
				</Flexed>
			</Flexed>
			<Row style={{height: '100%'}} justifyContent="center">
				{/* <Col lg={6} md={12} sm={12}>
					<Flexed direction="column" align={''} padding={'1rem'} gap={'0'} height={'100%'}>
						<Flexed direction="column" align={''} gap={'0'} padding={'0rem 0.3rem'}>
							<Flexed direction="row" align={'center'} gap={'0.6'}>
								// <Icons
								// 	src={'/images/icon_view.svg'}
								// 	onClick={() => {
								// 		onBack && onBack()
								// 	}}
								// 	pointer={'pointer'}
								// />
								<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'dark_blue'}>
									Buy Coins
								</Text>
							</Flexed>
						</Flexed>
						<Spacer height={'3'} />
						<Flexed direction="column" height={'100%'} align={'left'} style={{padding: '2rem'}}>
							<HandleFlex direction={'column'} align={'left'} gap={'0.5'} flexWrap={'wrap'} padding={'0rem 1rem'}>
								<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'500'} color={'black'} opacity={'50%'}>
									Pay JustNetwork
								</Text>
								<Text fontSize={'2'} lineHeight={'2.52'} fontWeight={'600'} color={'blac'} opacity={'87%'}>
									US ${selectCoinsOption?.value}
								</Text>
								<Flexed direction="row" justify={'space-between'} align={'center'} width="70%">
									<Flexed margin="2rem 0rem 0rem 0rem" justify="flex-start" direction="row" gap={0.7}>
										<img src={'/images/icon_goldcoin.svg'} width="36px" height="36px" />
										<Flexed direction="col">
											<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'500'} color={'black'} opacity={'80%'}>
												Coins
											</Text>

											<MultiSelect
												selectCoins
												options={options}
												searchMembers={true}
												value={selectCoinsOption}
												onChange={(e: any) => {
													setSelectedCoinsOption(e)
													setSelectedOption(options.find((re: any) => re.value == e?.value))
												}}
											/>
										</Flexed>
									</Flexed>
									<Flexed margin="2rem 0rem 0rem 0rem" justify="flex-start" direction="row" gap={0.7}>
										<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={'black'} opacity={'80%'}>
											US ${selectCoinsOption?.value}
										</Text>
									</Flexed>
								</Flexed>
							</HandleFlex>
						</Flexed>
					</Flexed>
				</Col> */}
				<Col lg={12} md={12} sm={12}>
					<CheckoutPage coins={coins} amount={coins / 5} />
				</Col>
			</Row>
		</Card>
	)
}

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
	text-align: center;
	align-items: center;
	justify-content:center;
	width: 100%;
	 `};
`

export default BuyCoinsPayment
