import React, {useState} from 'react'
import styled from 'styled-components'
import {Text} from '../styled/shared'
import {Flexed, Spacer} from '../styled/shared'
import {palette} from '../styled/colors'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import Button from '../components/common/Button'
import useRouter from '../hooks/router'
import {CustomSmallScreenSpacer} from '../components/netwrokComponents/RescheduleRequest'
import {api} from '../helpers/auth-axios'
import {useTranslation} from 'react-i18next'
import Loader from '../components/common/Loader'
import {useDispatch} from 'react-redux'
import {chatAllUsers, chatUnreadMessages, chatUsers, saveSearchQuery, saveUser} from '../actions/authActions'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import Footer from '../components/Footer'

const Logout = () => {
	const router = useRouter()
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const [loading, setLaoding] = useState(false)

	const initializeChat = async () => {
		try {
			await CometChatUIKit.logout()
			console.log('User logged out successfully')
		} catch (error) {
			console.error('Error during logout:', error)
			// Handle network errors or other issues here (e.g., display an error message)
		}
	}

	const logout = async () => {
		setLaoding(true)
		await api
			.post('/user/update', {is_online: false, is_social_login: false})
			.then(async (res: any) => {
				if (res?.data) {
					setLaoding(false)
					localStorage.clear()
					dispatch(saveUser({}))
					dispatch(chatUsers([]))
					dispatch(chatAllUsers([]))
					dispatch(saveSearchQuery(''))
					dispatch(chatUnreadMessages(0))

					await initializeChat()
					router.navigate('/login')
				}
				setLaoding(false)
			})
			.catch(async (err) => {
				localStorage.clear()
				dispatch(saveUser({}))
				dispatch(chatUsers([]))
				dispatch(chatAllUsers([]))
				dispatch(saveSearchQuery(''))
				dispatch(chatUnreadMessages(0))

				await initializeChat()
				router.navigate('/login')
				setLaoding(false)
			})
	}
	return (
		<Section>
			{/* <Spacer height={4} /> */}
			<CenteredContainer fluid>
				{loading && <Loader />}
				<Main>
					<Container fluid>
						<Row>
							<Col>
								<Flexed justify="center" align="center">
									<LogoutMain margin="2rem 0rem 0rem 0rem" gap={1}>
										<LogoutText margin="2rem 0rem 0rem 0rem" justify="center" align="center" fontsize="1.125">
											{t('Are you sure you want to logout ?')}
										</LogoutText>
										<Logoutimg justify="center" align="center">
											<img src="/images/logout.png" alt="error" height={250} width={415.81} />
										</Logoutimg>
										<hr />
										<Buttons justify="flex-end" align="center" direction="row" gap={2}>
											<Button
												small
												borderRadius={'1rem'}
												hasBorder
												fontSize={'0.8rem'}
												padding={'8px 16px 8px 16px'}
												label={<>{t('CANCEL')}</>}
												background={palette.white}
												color={palette.blue_500}
												lineHeight={'0.89rem'}
												ifClicked={() => {
													// router.navigate(-1)
													router.navigate(-1)
												}}
											/>

											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												padding={'8px 16px 8px 16px'}
												label={<>{t('LOGOUT')}</>}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.89rem'}
												ifClicked={async () => {
													await logout()
												}}
											/>
										</Buttons>
										<CustomSmallScreenSpacer height={1} />
										<Spacer height={1} />
									</LogoutMain>
								</Flexed>
							</Col>
						</Row>
					</Container>
				</Main>
			</CenteredContainer>
			<Footer />
		</Section>
	)
}

const Section = styled.div`
	background-image: url('/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const CenteredContainer = styled(Container)`
	margin-top: 5rem;
	display: flex;
	justify-content: center;
`

const Main = styled.div`
	padding: 1rem;
	border-radius: 20px;
	width: 83rem;
	background-color: white;
	${media.xs`
	width: 100%;
	height: 100%;
	 `};
`
const LogoutMain = styled(Flexed)`
	border-radius: 1.25rem;
	border: 1px solid #80808063;
	width: 40.875rem;
	${media.xs`
	width: 100%;
	height: 100%;
	padding: auto;
	margin:0;
	gap: 0.5rem;
	 `};
`
const Logoutimg = styled(Flexed)`
	cursor: pointer;

	${media.xs`
	width: 100%;
	height: 100%;
	>img{
		width: 100%;
	height: 100%;
	}
	 `};
`
const LogoutText = styled(Text)`
	color: ${palette.dark_blue};
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.361rem;
`
const Buttons = styled(Flexed)`
	margin-right: 1rem;
	@media (max-width: 574px) {
		margin-right: 0.25rem;
	}
`
const CancelButton = styled(Flexed)`
	justify-content: center;
	text-align: center;
	align-items: center;
	border: 1px solid ${palette.blue};
	color: ${palette.blue};
	border-radius: 1rem;
	font-weight: 600;
	width: 5.5rem;
	height: 2rem;
	cursor: pointer;
`
export default Logout
