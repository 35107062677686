import React, {useEffect, useState} from 'react'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Icons, ProfileImg} from '../../components/TopNavBar'
import Button from '../../components/common/Button'
import moment from 'moment'
import {Card} from '../../pages/Dashboard'
import useRouter from '../../hooks/router'
import {api} from '../../helpers/auth-axios'
import ConfirmationPopup from '../modals/ConfirmationModel'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../../actions/authActions'
import {useSelector} from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import Loader from '../common/Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import {subscribeToChannel} from '../../service/pusherService'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import UserProfileLoader from '../common/UserProfileLoader'

const SentRequestCard = ({reqLoading, handleRescheduled, handleDecline, handleAccept, activeTab}: {activeTab?: any; recentNetworks?: any[]; reqLoading: any; handleRescheduled?: any; handleDecline?: any; handleAccept?: any}) => {
	const router = useRouter()
	const [confirmationModel, setConfirmationModel] = useState(false)
	const [loading, setLoading] = useState(false)

	const [hasMore, setHasMore] = useState(true)
	const [page, setPage] = useState(1)

	const [selectedMeeting, setSelectedMeeting] = useState<any>('')
	const [type, setType] = useState<any>('')
	const {t} = useTranslation()
	const [sentMeetings, setSentMeetingsRequest] = useState<any[]>([])
	const dispatch = useDispatch()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)

	const _handleAccept = async (meeting: any) => {
		setLoading(true)
		setConfirmationModel(false)
		await api
			.put(`/meeting/update_meeting_status`, {id: meeting?.id, accept_reschedule: true, status: 'accepted'})
			.then((res: any) => {
				if (res?.data?.status) {
					setSelectedMeeting('')
					setLoading(false)
					toast.success(res?.data?.message)
					router.navigate(`/accept-reschedule-successful?id=${meeting?.id}`)
				} else {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const _handleDecline = async (meeting: any) => {
		setLoading(true)
		setConfirmationModel(false)

		await api
			.put(`/meeting/update_meeting_status`, {id: meeting?.id, status: 'decline'})
			.then((res: any) => {
				if (res?.data?.status) {
					setSelectedMeeting('')
					setLoading(false)
					toast.success(res?.data?.message)
					dispatch(callAutoLogin(true))
					router.navigate(`/decline-request?id=${meeting?.id}`)
				} else {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	useEffect(() => {
		const unsubscribe = subscribeToChannel('MEETING', `ADD_MEETING_${userDetails?.id}`, (data: any) => {
			if (activeTab == 2 && data?.meeting?.u_id == userDetails?.id) {
				setSentMeetingsRequest((prev: any) => [data?.meeting, ...prev])
			}
		})

		const acceptMeeting = subscribeToChannel('MEETING', `ACCEPT_MEETING_${userDetails?.id}`, (data: any) => {
			setSentMeetingsRequest((prev: any) => {
				const index = prev.findIndex((meeting: any) => meeting.id === data?.meeting?.id)
				if (index !== -1) {
					const updatedMeetings = prev.map((meeting: any, i: any) => (i === index ? {...meeting, ...data.meeting} : meeting))
					return updatedMeetings
				} else {
					return [...prev, data.meeting]
				}
			})
		})
		const declinetMeeting = subscribeToChannel('MEETING', `DECLINE_MEETING_${userDetails?.id}`, (data: any) => {
			dispatch(callAutoLogin(true))
			setSentMeetingsRequest((prev: any) => {
				const index = prev.findIndex((meeting: any) => meeting.id === data?.meeting?.id)
				if (index !== -1) {
					const updatedMeetings = prev.map((meeting: any, i: any) => (i === index ? {...meeting, ...data.meeting} : meeting))
					return updatedMeetings
				} else {
					return [...prev, data.meeting]
				}
			})
		})

		return () => {
			if (activeTab == 2) {
				unsubscribe()
				acceptMeeting()
				declinetMeeting()
			}
		}
	}, [userDetails])

	useEffect(() => {
		getSendedMeetings(page)
	}, [page])

	const getSendedMeetings = async (page: number) => {
		setLoading(true)
		await api
			.get(`/meeting/get_sent_meetings?page=${page}`)
			.then((res: any) => {
				setSentMeetingsRequest(res?.data?.data?.sentRequests)
				const newMeetings: any = [...sentMeetings, ...res?.data?.data?.sentRequests]

				setSentMeetingsRequest(newMeetings)
				setHasMore(res?.data?.data?.sentRequests.length !== 0)
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
			})
	}

	const checkMeeting = (user: any) => {
		const currentTime = moment()
		const eventStartTime = moment.utc(user?.confirmation_start_time || user.start_time).tz(user?.google_meeting_id ? userDetails?.timezone : user?.timezone)
		const eventEndTime = moment(user.end_time)
		const isUpcoming = currentTime.isBefore(eventStartTime)
		const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
		const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
		const duration = diffMinutes < 0 ? user.duration + diffMinutes : user.duration - diffMinutes
		const hasRemainingTime = duration > 0
		return {duration, isUpcoming, hasRemainingTime}
	}

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		setSentMeetingsRequest((prevMeetings: any) => {
	// 			return prevMeetings.map((meeting: any) => ({
	// 				...meeting,
	// 				...checkMeeting(meeting)
	// 			}))
	// 		})
	// 	}, 60000)

	// 	return () => clearInterval(interval)
	// }, [])
	const fetchMoreData = () => {
		setPage((prev: any) => prev + 1)
	}

	// if (loading) return <Loader />
	return (
		<Flexed id="sentMeetings" style={{height: '48.5rem', overflow: 'hidden', overflowY: 'scroll'}} direction="column" gap={'1'} padding={'1rem 1.3rem'}>
			{loading && <Loader />}
			<InfiniteScroll scrollableTarget="sentMeetings" scrollThreshold={0.9} dataLength={sentMeetings.length} next={fetchMoreData} hasMore={hasMore} loader={<></>} endMessage={sentMeetings?.length && !hasMore ? <></> : ''}>
				{sentMeetings?.length
					? sentMeetings?.map((user: any, i: any) => (
							<Card marginBottom={'10px'} background={'transparent'} removeShadow borderColor={'#0000001F'} padding={'0'} minHeight={'auto'} height={'max-content !important'}>
								<Flexed direction="column" gap={'0.3'} justify={'flex-between'}>
									<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0.3rem 1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
											<Flexed
												direction="row"
												align={'center'}
												pointer
												onClick={() => {
													router.navigate(`/${user?.meetingTo.user_name}`, {
														state: {route: '/network'}
													})
												}}>
												<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={user?.meetingTo?.image_Url ? user?.meetingTo?.image_Url : '/images/user_default.png'} />
												<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
													<Flexed direction="row" justify={'center'} align={'center'} gap={'0.5'}>
														<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
															{user?.meetingTo?.full_name}
														</Text>

														{user?.meetingTo?.linkedin_profile_verified || user?.meetingTo?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
														{!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming ? (
															<Flexed padding={'4px 8px 4px 8px'} radius="20px" background={palette.light_gray}>
																<Text margin={'auto'} fontSize={'0.8'} color={palette.text_color}>
																	{t('ENDED')}
																</Text>
															</Flexed>
														) : user?.status == 'accepted' ? (
															<Flexed>
																<Icons src={`/images/Tag_accepted.svg`} />
															</Flexed>
														) : user?.status == 'decline' ? (
															<Flexed>
																<Icons src={`/images/Tag_rejected.svg`} />
															</Flexed>
														) : user?.status == 're-scheduled' ? (
															<Flexed>
																<Icons src={`/images/Tag_re-scheduled.svg`} />
															</Flexed>
														) : user?.status == 'pending' ? (
															<Flexed>
																<Icons src={`/images/Tag_Inprogress.svg`} />
															</Flexed>
														) : (
															''
														)}
													</Flexed>
													<Spacer height={0.2} />
													<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
														{user?.meetingTo?.designation}
													</Text>
												</Flexed>
											</Flexed>
											<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
												<Button
													small
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													hasBorder
													padding={'0'}
													label={<>{t('MESSAGE')}</>}
													background={palette.white}
													color={palette.blue_500}
													lineHeight={'0.89rem'}
													ifClicked={() => {
														const handleRequest = async () => {
															if (userDetails) {
																if (!user?.meetingTo?.chat_id) {
																	toast.warning(t('Chat id not exist'))
																} else if (user?.meetingTo?.chat_id) {
																	await CometChat.getUser(user?.meetingTo?.chat_id?.toLowerCase()).then(
																		(res: any) => {
																			if (res?.blockedByMe || res?.hasBlockedMe) {
																				toast.warning(res?.blockedByMe ? t('You blocked this user') : t('User blocked'))
																			} else if (myNetworkUsers?.includes(user?.meetingTo?.chat_id?.toLowerCase())) {
																				router.navigate(`/inbox?id=${user?.meetingTo?.chat_id?.toLowerCase()}&network=${true}`)
																				console.log('User details fetched for user:', res)
																			} else {
																				toast.warning(t('User not in your network'))
																			}
																		},
																		(error) => {
																			toast.warning(t('User not exist in chat'))
																			console.log('User details fetching failed with error:', error)
																		}
																	)
																} else {
																	toast.warning(t('Network request not accepted yet'))
																}
															}
														}
														handleRequest()
													}}
												/>
											</Flexed>
										</CustomFlexedForWrap>
									</Flexed>
									<Flexed direction="column" gap={'0.3'} align={'start'} padding={' 0.3rem 1rem'} style={user?.status == 're-scheduled' ? {borderBottom: `1px solid ${palette.zink_300}`} : {}} flexWrap={'wrap'}>
										<Flexed align={'start'} padding={'1rem'}>
											<Card background={palette.primary_50} minHeight={'5rem'} radius={'8px'} opacity={'50%'}>
												<Flexed align={'center'} padding={'0.5rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
													<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
														{user?.membershipDetails?.name}
													</Text>
													<Dashes></Dashes>
													<Flexed align={'center'} gap={'0.2'} direction={'row'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'600'} color={'text_color'}>
															<Icons src={'/images/icon_goldcoin.svg'} /> {user?.required_coins}
														</Text>
														<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'zink_400'}>
															{t('coins')}
														</Text>
													</Flexed>

													<Dashes></Dashes>
													<Flexed margin="0rem 0rem" align={'center'} gap={'0.2'} direction={'row'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'600'} color={'text_color'}>
															<Icons src={'/images/schedule.svg'} /> {user?.membershipDetails?.time}
														</Text>
														<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'600'} color={'zink_400'}>
															{t('minutes')}
														</Text>
													</Flexed>

													<Flexed margin="0 0 0 auto" align={'center'} gap={'0.2'} justify={'center'} direction={'row'} style={{background: palette.white, borderRadius: '5px'}} width={'5.3rem'} height={'2.5rem'}>
														<Text fontSize={'1'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
															${user?.required_coins / 5}
														</Text>
													</Flexed>
												</Flexed>
											</Card>

											<Spacer height={'0.5'} />
											<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
												{t('Meeting')}
											</Text>
											<Spacer height={'0.5'} />
											<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={1} flexWrap={'wrap'}>
												<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
													<Icons src={'/images/calendar_blue.svg'} />{' '}
													{moment
														.utc(user?.confirmation_start_time ? user?.confirmation_start_time : user?.start_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format('dddd, MMMM Do, YYYY')}
												</Text>

												<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
													<Icons src={'/images/appointment_card_time.svg'} />{' '}
													{`${user?.duration ? user?.duration : user?.membershipDetails?.time} ${t('minutes')} - ${moment
														.utc(user?.confirmation_start_time ? user?.confirmation_start_time : user?.start_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format('hh:mm a')} ${t('to')} ${moment
														.utc(user?.confirmation_end_time ? user?.confirmation_end_time : user?.end_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format('hh:mm a')}`}
												</Text>
											</Flexed>
											<Spacer height={'0.5'} />
											{!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming ? (
												''
											) : (
												<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
													{user?.is_video ? (
														<>
															<Icons src={'/images/video_meeting_icon.svg'} />
															{user?.status !== 'accepted' ? (
																t('Web conferencing details provided upon confirmation')
															) : (
																<JoinLinkText
																	onClick={() => {
																		const handleVideoClick = () => {
																			const currentTime = moment()

																			const eventStartTime = moment(user.confirmation_start_time || user.start_time)
																			const eventEndTime = moment(user.confirmation_end_time || user.end_time)
																			const isUpcoming = currentTime.isBefore(eventStartTime)

																			const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
																			const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
																			const duration = diffMinutes < 0 ? user.duration + diffMinutes : user.duration - diffMinutes
																			const hasRemainingTime = duration > 0

																			if (duration && duration <= 0 && !hasRemainingTime && !isUpcoming) {
																				toast.warn(t('The meeting has been ended.'))
																			} else if (isUpcoming) {
																				toast.warn(t('The meeting will start at the exact time.'))
																			} else if ((isInProgress || hasRemainingTime) && duration && duration >= 1 && user?.status == 'accepted') {
																				router.navigate(`/initialize-video?id=${user?.id}`)
																			} else {
																				if (duration && duration < 0 && user?.status == 'accepted') {
																					toast.warn(t('The meeting has already started.'))
																				} else {
																					if (user?.status == 'accepted') {
																						toast.warn(t('The meeting is scheduled to start in more than 5 minutes.'))
																					} else {
																						toast.warn(t('The meeting is not confirmed.'))
																					}
																				}
																			}
																		}
																		handleVideoClick()
																	}}>
																	{' '}
																	Join Call Here
																</JoinLinkText>
															)}{' '}
														</>
													) : (
														''
													)}
												</Text>
											)}
										</Flexed>
									</Flexed>
									{user?.status == 're-scheduled' && user?.proposed_start_time ? (
										<Flexed direction="column" gap={'0.3'} align={'start'} padding={' 0.3rem 1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}} flexWrap={'wrap'}>
											<Flexed align={'start'} padding={'0 1rem'}>
												<Spacer height={'0.5'} />
												<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
													{t('Proposed Date & Time')}
												</Text>
												<Spacer height={'0.5'} />
												<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={1} flexWrap={'wrap'}>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
														<Icons src={'/images/calendar_blue.svg'} /> {moment(user?.proposed_start_time ? user?.proposed_start_time : user?.start_time).format('dddd, MMMM Do, YYYY')}
													</Text>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
														<Icons src={'/images/appointment_card_time.svg'} />{' '}
														{`${user?.duration ? user?.duration : user?.membershipDetails?.time} ${t('minutes')} - ${moment(user?.proposed_start_time ? user?.proposed_start_time : user?.start_time).format('hh:mm a')} ${t(
															'to'
														)} ${moment(user?.proposed_end_time ? user?.proposed_end_time : user?.end_time).format('hh:mm a')}`}
													</Text>
												</Flexed>
												<Spacer height={'0.5'} />
												{user?.notes ? (
													<>
														<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
															{t('Notes')}
														</Text>
														<Paragraph fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
															{user?.notes}
														</Paragraph>
														<Spacer height={'0.5'} />
													</>
												) : (
													''
												)}
											</Flexed>
										</Flexed>
									) : (
										''
									)}{' '}
									<Spacer height={'0.1'} />
									{user?.status == 're-scheduled' ? (
										<>
											<Flexed align={'center'} direction={'row'} justify={'flex-end'} gap={'0.5'} padding={'0 1rem'} flexWrap={'wrap'}>
												<>
													<Button
														small
														type={'danger'}
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														hasBorder
														padding={'8px 16px 8px 16px'}
														label={<>{t('DECLINE')}</>}
														background={palette.white}
														color={palette.danger_1}
														lineHeight={'0.89rem'}
														disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
														ifClicked={() => {
															setType('decline')
															setConfirmationModel(true)
															setSelectedMeeting(user)
														}}
													/>

													{user?.re_scheduled_from == userDetails?.id ? (
														''
													) : moment().format() <=
													  moment
															.utc(user?.confirmation_end_time ? user?.confirmation_end_time : user?.end_time)
															.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
															.format() ? (
														<Button
															small
															// hasBorder
															type={'success'}
															borderRadius={'1rem'}
															fontSize={'0.8rem'}
															padding={'8px 16px 8px 16px'}
															label={<>{t('ACCEPT')}</>}
															background={palette.success}
															color={palette.white}
															lineHeight={'0.89rem'}
															disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
															ifClicked={() => {
																setType('Accept')
																setConfirmationModel(true)
																setSelectedMeeting(user)
															}}
														/>
													) : (
														''
													)}
												</>
											</Flexed>
											<Spacer height={'0.5'} />
										</>
									) : user?.status == 'pending' ? (
										<>
											<Flexed align={'center'} direction={'row'} justify={'flex-end'} gap={'0.5'} padding={'0 1rem'} flexWrap={'wrap'}>
												<>
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														hasBorder
														padding={'8px 16px 8px 16px'}
														label={<>{t('RE-SCHEDULE')}</>}
														background={palette.white}
														color={palette.blue_500}
														lineHeight={'0.89rem'}
														minWidth={'7rem'}
														disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
														ifClicked={() => {
															setType('re-schedule')
															setConfirmationModel(true)
															setSelectedMeeting(user)
														}}
													/>
													<Button
														small
														type={'danger'}
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														hasBorder
														padding={'8px 16px 8px 16px'}
														label={<>{t('DECLINE')}</>}
														background={palette.white}
														color={palette.danger_1}
														lineHeight={'0.89rem'}
														disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
														ifClicked={() => {
															setType('decline')
															setConfirmationModel(true)
															setSelectedMeeting(user)
														}}
													/>
												</>
											</Flexed>
											<Spacer height={'0.5'} />
										</>
									) : user?.status == 'accepted' ? (
										<>
											<Flexed align={'center'} direction={'row'} justify={'flex-end'} gap={'0.5'} padding={'0 1rem'} flexWrap={'wrap'}>
												<>
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														hasBorder
														padding={'8px 16px 8px 16px'}
														label={<>{t('RE-SCHEDULE')}</>}
														background={palette.white}
														color={palette.blue_500}
														lineHeight={'0.89rem'}
														minWidth={'7rem'}
														disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
														ifClicked={() => {
															setType('re-schedule')
															setConfirmationModel(true)
															setSelectedMeeting(user)
														}}
													/>
													<Button
														small
														type={'danger'}
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														hasBorder
														padding={'8px 16px 8px 16px'}
														label={<>{t('DECLINE')}</>}
														background={palette.white}
														color={palette.danger_1}
														lineHeight={'0.89rem'}
														disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
														ifClicked={() => {
															setType('decline')
															setConfirmationModel(true)
															setSelectedMeeting(user)
														}}
													/>
												</>
											</Flexed>
											<Spacer height={'0.5'} />
										</>
									) : (
										user?.status == 'decline' && <></>
									)}
								</Flexed>
							</Card>
					  ))
					: !loading &&
					  sentMeetings.length == 0 && (
							<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'}>
								<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
									{t('You have not sent any requests yet.')}
								</Text>
							</Flexed>
					  )}
				{confirmationModel && (
					<ConfirmationPopup
						header={type == 're-schedule' ? t('Are you sure you want to re-schedule this meeting?') : type == 'decline' ? t('Are you sure you want to decline this meeting?') : t('Are you sure you want to accept this meeting?')}
						onClose={() => {
							setSelectedMeeting('')
							setConfirmationModel(false)
						}}
						buttonTitle={type == 're-schedule' ? 'Re-Schedule' : type == 'decline' ? t('Decline') : t('Accept')}
						onConfirm={() => {
							if (selectedMeeting) {
								type == 're-schedule' ? router.navigate(`/request-reschedule?id=${selectedMeeting?.id}`) : type == 'decline' ? _handleDecline(selectedMeeting) : _handleAccept(selectedMeeting)
							}
						}}
					/>
				)}
			</InfiniteScroll>
		</Flexed>
	)
}

export const HandleCardPadding = styled(Flexed)<any>`
	padding: ${({padding}) => padding};
	${media.xs`
	padding: 0;
	 `};
`

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

const JoinLinkText = styled(Text)`
	font-size: 12px;
	text-decoration: underline;
	font-weight: 400;
	line-height: 18px;
	cursor: pointer;
	display: inline;
	color: #006fee;
`

const Dashes = styled.div<any>`
	height: 2rem;
	border-left: 1px solid ${palette.blue_500};
	margin: 0 1rem;

	@media (max-width: 574px) {
		visibility: hidden;
	}
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`

export default SentRequestCard
