import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'
import {Container, media} from 'styled-bootstrap-grid'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	width: 600px;
	height: 500px;
	overflow-y: scroll;
	position: relative;
`

const Header = styled(Text)`
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const InfoCategory = styled.h3`
	font-size: 1.2rem;
	font-weight: 700;
	margin-top: 1rem;
`

const InfoDetails = styled.p`
	font-size: 0.9rem;
	line-height: 1.2;
	margin-left: 1rem;
`

interface WithdrawPoliciesProps {
	header?: any
	body?: any
	user?: any
	onClose?: () => void
	buttonTitle?: string
}

const WithdrawPolicies = ({header, onClose, user, body}: WithdrawPoliciesProps) => {
	const {t} = useTranslation()

	return (
		<Overlay>
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>
				<Spacer height={2} />
				<Container fluid>
					<Text isCentered fontSize={'2'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						{t('Withdraw Policies')}
					</Text>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							1. {t('Minimum Balance Requirement')}
						</Text>
						<InfoDetails>
							1.1.{' '}
							{t(
								"Users must maintain a minimum balance of 50 coins (equivalent to $10 USD) in their JustNetwork account at all times. This minimum balance is necessary to cover any potential service fees and ensure the continued availability of JustNetwork's services."
							)}
						</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							2. {t('Withdrawal Methods')}
						</Text>
						<InfoDetails>2.1. {t('Users can withdraw funds from their JustNetwork account to their Stripe or PayPal accounts. All withdrawal transactions are processed securely through these platforms.')}</InfoDetails>
					</Flexed>
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							3. {t('Withdrawal Process')}
						</Text>
						<InfoDetails>3.1. {t('To initiate a withdrawal, users must submit a withdrawal request through their JustNetwork account.')}</InfoDetails>
						<InfoDetails>3.2. {t('Withdrawal requests will be processed within [insert timeframe, e.g., 3-5 business days] from the date of the request.')}</InfoDetails>
						<InfoDetails>3.3. {t('Users must ensure that their Stripe or PayPal account information is accurate and up-to-date to avoid delays or issues with the withdrawal process.')}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							4. {t('Fees and Charges')}
						</Text>
						<InfoDetails>4.1. {t('JustNetwork does not charge any additional fees for withdrawals. However, users may be subject to fees imposed by Stripe or PayPal, depending on their policies.')}</InfoDetails>
						<InfoDetails>4.2. {t('Users are responsible for any fees or charges applied by Stripe or PayPal and should review the terms and conditions of these services for more information')}</InfoDetails>
					</Flexed>
					<Spacer height="1" />

					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							5. {t('Withdrawal Limits')}
						</Text>
						<InfoDetails>5.1. {t('There is no maximum withdrawal limit, provided the user maintains the minimum balance of 50 coins in their account')}</InfoDetails>
						<InfoDetails>5.2. {t('JustNetwork reserves the right to impose withdrawal limits or restrictions if necessary to ensure the security and integrity of its platform.')}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							6. {t('Disputes and Resolution')}
						</Text>

						<InfoDetails>6.1. {t("If a user encounters any issues with their withdrawal, they must contact JustNetwork's support team for assistance.")}</InfoDetails>
						<InfoDetails>6.2. {t('JustNetwork will investigate any disputes related to withdrawals and work with the user to resolve the issue as quickly as possible.')}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							7. {t('Account Suspension and Termination')}
						</Text>
						<InfoDetails>7.1. {t("JustNetwork reserves the right to suspend or terminate a user's account if fraudulent activity is suspected or if the user violates the terms and conditions.")}</InfoDetails>
						<InfoDetails>
							7.2. {t("In the event of account suspension or termination, any remaining balance (excluding the minimum balance requirement) will be refunded to the user's Stripe or PayPal account after a thorough investigation.")}
						</InfoDetails>
					</Flexed>
					<Spacer height="1" />
					<Flexed>
						<Text fontSize={'1'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
							8. {t('Policy Changes')}
						</Text>
						<InfoDetails>8.1. {t('JustNetwork reserves the right to modify its withdrawal policies at any time. Users will be notified of any changes through the platform or via email.')}</InfoDetails>
						<InfoDetails>8.2. {t("Continued use of JustNetwork's services after any changes to the withdrawal policies constitutes acceptance of the new terms.")}</InfoDetails>
					</Flexed>
					<Spacer height="1" />
				</Container>

				{/* Social Media Share Options */}
			</Modal>
		</Overlay>
	)
}

const CustomFlexed = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	flex-direction: column;
	`};
`
export default WithdrawPolicies
