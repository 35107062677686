import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Icons, ProfileImg} from '../../components/TopNavBar'
import Button from '../../components/common/Button'
import {toast} from 'react-toastify'
import useRouter from '../../hooks/router'
import InfiniteScroll from 'react-infinite-scroll-component'
import {api} from '../../helpers/auth-axios'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Loader from '../common/Loader'
import FadeLoader from 'react-spinners/FadeLoader'
import UserProfileLoader from '../common/UserProfileLoader'

const SuggestedNetworkCards = ({searchChatUserQuery, isDashboard, isSearch, setSearch}: {searchChatUserQuery?: any; setSearch: any; isSearch: any; isDashboard?: boolean}) => {
	const router = useRouter()
	const [hasMore, setHasMore] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	const [dataLoading, setDataLoading] = useState(false)
	const [users, setUsers] = useState<any>([])
	const [page, setPage] = useState(1)

	const {t} = useTranslation()

	const fetchMoreData = () => {
		setPage((prevPage) => prevPage + 1)
	}

	const getUserSuggestions = async (page = 1, resetUsers = false) => {
		try {
			setDataLoading(true)
			setIsLoading(true)

			const response = await api.get(`/user/user_suggestions?page=${page}`, {
				params: {page, search: searchChatUserQuery}
			})

			const fetchedUsers = response?.data?.data?.users || []
			const newUsers = resetUsers ? fetchedUsers : [...users, ...fetchedUsers]
			if (resetUsers) {
				setSearch(false)
			}
			setUsers(newUsers)
			setHasMore(response?.data?.data?.users.length !== 0)
			setDataLoading(false)
			setIsLoading(false)
		} catch (error) {
			setDataLoading(false)
			setIsLoading(false)
			console.error('Error fetching user suggestions:', error)
			toast.error(t('Failed to fetch user suggestions.'))
		}
	}

	useEffect(() => {
		if (isSearch) {
			setPage(1) // Reset page to 1 for new search
			getUserSuggestions(1, true) // Pass true to reset users
		}
	}, [isSearch])

	useEffect(() => {
		// Prevent fetching data when resetting page
		getUserSuggestions(page)
	}, [page])
	return (
		<Flexed id="scrollableDiv" direction="column" gap={'0.5'} style={{height: '45.5rem', overflow: 'hidden', overflowY: 'scroll'}}>
			<InfiniteScroll scrollThreshold={0.9} scrollableTarget="scrollableDiv" dataLength={users.length} next={fetchMoreData} hasMore={hasMore} loader={!dataLoading ? <></> : <></>} endMessage={users.length ? <p></p> : ''}>
				{dataLoading && <Loader />}
				{users?.length > 0 && !isDashboard ? (
					users.map((user: any, i: any) => (
						<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0 1rem'} style={i === users.length - 1 ? {} : {borderBottom: `1px solid ${palette.zink_300}`}} key={user.id}>
							<Flexed direction="row" width={'100%'} align={'center'} justify={'space-between'}>
								<Flexed
									direction="row"
									align={'center'}
									pointer
									onClick={() => {
										router.navigate(`/${user.user_name}`, {
											state: {route: '/network'}
										})
									}}>
									<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
										<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={user?.image_Url ? user?.image_Url : '/images/user_default.png'} />
										<AvailabilityIconWrapper src={`/images/${user?.is_online ? 'green_circle' : user?.is_online === 'offline' ? 'pink_circle' : user?.is_online === 'red' ? 'red_circle' : 'gray_circle'}.svg`} alt="upload" />
									</ProfileImgWrapper>
									<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
										<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
											<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
												{user?.first_name + ' ' + user.last_name}
											</Text>
											{user?.linkedin_profile_verified || user?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : null}
										</Flexed>
										<Spacer height={0.2} />
										<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
											{user?.designation}
										</Text>
									</Flexed>
								</Flexed>
								<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
									<Button
										small
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										hasBorder
										padding={'0'}
										// ifClicked={() => addToNetwork(user.id)}
										label={<>{t('ADD NETWORK')}</>}
										background={palette.white}
										color={palette.blue_500}
										minWidth={'8rem'}
										lineHeight={'0.89rem'}
										ifClicked={() => {
											router.navigate(`/${user.user_name}`, {
												state: {route: '/network'}
											})
										}}
									/>
								</Flexed>
							</Flexed>
							<Spacer height={0.2} />
						</Flexed>
					))
				) : !dataLoading && users?.length == 0 ? (
					<Flexed align={'center'} justify={'center'} direction={'row'} height={'100%'}>
						<Text fontSize={'0.9'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
							{t('No network suggested user found!')}
						</Text>
					</Flexed>
				) : (
					<></>
				)}
			</InfiniteScroll>
		</Flexed>
	)
}

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: ${({isDashboard}: any) => (isDashboard ? 'nowrap' : ` wrap`)};
    `};
`

const ProfileImgWrapper = styled(Flexed)`
	position: relative;
	display: flex;
`

const AvailabilityIconWrapper = styled.img`
	position: absolute;
	bottom: 0;
	margin-left: 1.8rem;
`
const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
 min-height:auto;
 /* width:auto; */
 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
 `};
`

export default SuggestedNetworkCards
