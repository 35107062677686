import React, {useState, useEffect} from 'react'
import {api} from '../../helpers/auth-axios'
import {toast} from 'react-toastify'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Spacer} from '../../styled/shared'
import {Card} from '../../pages/BMT'
import {Icons} from '../TopNavBar'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import PaidMembership from '../PaidMembership'
import FreeBMTMemberShip from '../bmt/FreeBMTMemberShip'
import ProSubscriptionCheckout from '../ProSubscriptionCheckout'
import {useTranslation} from 'react-i18next'

const BeforeMemberShipFlowModal: React.FC<{isOpen: boolean; onClose: () => void}> = ({isOpen, onClose}) => {
	const [loading, setLoading] = useState(false)
	const [selectedMembership, setSelectedMembership] = useState<any>([])
	const [clickUpgrade, setClickUpgrade] = useState<any>(false)
	const [cardOptions, setCardOptions] = useState<any>([])
	const {t} = useTranslation()
	const getAllMemberships = async () => {
		setLoading(true)
		try {
			const res = await api.get('/user/memberships')
			if (res?.data) {
				const filteredMemberships = res?.data?.data?.filter((membership: any) => membership.type !== 'pro')
				const groupedMemberships = filteredMemberships.reduce((acc: any, membership: any) => {
					if (!acc[membership.type]) {
						acc[membership.type] = []
					}
					acc[membership.type].push(membership)
					return acc
				}, {})
				setCardOptions(groupedMemberships)
			}
		} catch (error: any) {
			toast.error(error?.message)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (isOpen) {
			getAllMemberships()
		}
	}, [isOpen])

	if (!isOpen) return null

	return (
		<Overlay>
			<ModalContainer>
				{/* <CloseButton onClick={onClose}>×</CloseButton> */}
				<Row>
					<Col lg={12}>
						{selectedMembership?.length ? (
							<FreeBMTMemberShip
								bmtDetails={selectedMembership}
								onClose={() => {
									setSelectedMembership('')
								}}
							/>
						) : clickUpgrade ? (
							<ProSubscriptionCheckout onClose={() => setClickUpgrade(false)} />
						) : (
							<>
								<PaidMembership clickUpgrade={() => setClickUpgrade(true)} />
								<Spacer height={2} />
								<Card background={palette.white} padding={'1.2rem'} overflow={'visible'} height={'auto'} minHeight={'auto'}>
									<HandleFlex direction={'row'} align={'left'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
										<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
											{t('FREE Memberships')}
										</Text>
										<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'300'} color={'black'} align={'left'} fontStyle={'italic'}>
											{t("A range of options designed to cater to every professional's needs.")}
										</Text>
									</HandleFlex>
									<Spacer height={'0.5'} />
									<Row alignItems={'center'}>
										{Object.keys(cardOptions).map((type: string) => (
											<Col md={6} lg={3}>
												<HandleFlex direction={'column'} align={'center'} gap={'0.5'} key={type}>
													<Flexed
														pointer
														gap={'0.3'}
														height={'4rem'}
														onClick={() => {
															// Handle card selection and show details
															setSelectedMembership(cardOptions[type])
														}}>
														<Icons src={`/images/${type}_member_card.svg`} height={'100%'} />
													</Flexed>
													<Spacer height={'0.3'} />
													<ManageCardComponent gap={'0.3'}>
														{cardOptions[type]?.map((card: any, i: number) => (
															<CustomCards key={i} removeShadow radius={'0.6rem'} marginLeft={'0'} background={'transparent'} borderColor={palette.default_200} width={'8rem'} minHeight={'auto'} height={'5rem'}>
																<Flexed align={'center'} padding={'0.5'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																	<Text fontSize={'0.6'} lineHeight={'0.75'} fontWeight={'700'} color={'text_color'}>
																		{card?.name}
																	</Text>
																</Flexed>
																<Spacer height={0.2} />
																<Flexed align={'center'} padding={'0.5'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
																	<Flexed align={'start'} gap={'0.2'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																		<Flexed align={'center'} gap={'0.1'} direction={'row'}>
																			<Text fontSize={'0.6'} lineHeight={'0.7'} fontWeight={'600'} color={'text_color'}>
																				<Icons src={'/images/icon_goldcoin.svg'} style={{height: '0.7rem'}} />
																				&nbsp;{parseInt(card?.coins)}
																			</Text>
																			<Text fontSize={'0.5'} lineHeight={'0.6'} fontWeight={'400'} color={'zink_400'}>
																				{t('coins')}
																			</Text>
																		</Flexed>
																		<Flexed align={'center'} gap={'0.1'} direction={'row'}>
																			<Text fontSize={'0.6'} lineHeight={'0.7'} fontWeight={'600'} color={'text_color'}>
																				<Icons src={'/images/schedule.svg'} style={{height: '0.7rem'}} />
																				&nbsp;{parseInt(card?.time)}
																			</Text>
																			<Text fontSize={'0.5'} lineHeight={'0.6'} fontWeight={'400'} color={'zink_400'}>
																				{t('minutes')}
																			</Text>
																		</Flexed>
																	</Flexed>
																	<Flexed align={'center'} gap={'0.1'} justify={'center'} direction={'row'} style={{background: palette.input_bg, borderRadius: '5px'}} width={'3rem'} height={'2rem'}>
																		<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'700'} color={'text_color'}>
																			${card?.price}
																		</Text>
																	</Flexed>
																</Flexed>
															</CustomCards>
														))}
													</ManageCardComponent>
												</HandleFlex>
											</Col>
										))}
									</Row>
								</Card>
								<Spacer height={3} />
							</>
						)}
					</Col>
				</Row>
			</ModalContainer>
		</Overlay>
	)
}

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`

const ModalContainer = styled.div`
	background: white;
	padding: 2rem;
	border-radius: 10px;
	overflow-y: scroll;
	height: 40rem;
	width: 90%;
	max-width: 1000px;
	position: relative;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`

const HandleFlex = styled(Flexed)<any>`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
	margin-top: 10px;

  `};
`
const ManageCardComponent = styled(Flexed)<any>`
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
        text-align: center;
        align-items: center;
        width: 100%;
    `};
`
interface TextProps {
	fontSize?: any
	lineHeight?: any
	fontWeight?: any | string
	color?: any
	align?: string
	fontStyle?: string
}

const Text = styled.p<TextProps>`
	font-size: ${({fontSize}) => (fontSize ? `${fontSize}rem` : '1rem')};
	line-height: ${({lineHeight}) => (lineHeight ? `${lineHeight}rem` : '1.5rem')};
	font-weight: ${({fontWeight}) => fontWeight || '400'};
	color: ${({color}) => color || palette.text_color};
	text-align: ${({align}) => align || 'left'};
	font-style: ${({fontStyle}) => fontStyle || 'normal'};
	${media.xs`
	  font-size: ${({fontSize}: any) => (fontSize ? `${fontSize * 1.4}rem` : '1.2rem')};
	  line-height: ${({lineHeight}: any) => (lineHeight ? `${lineHeight * 1.5}rem` : '1.8rem')};
	`}
`

const CustomCards = styled(Card)<any>`
	width: 8rem;
	min-height: auto;
	height: 5rem;
	border-radius: 0.6rem;
	background: transparent;
	border-color: ${palette.default_200};
	${media.xs`
    width: 100%;
    height: 6rem;
  `}
`
export default BeforeMemberShipFlowModal
