import {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {Icons, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'

import {Dot} from './SignUp'
import useRouter from '../hooks/router'
import {useTranslation} from 'react-i18next'
import InviteFriends from '../components/InviteFriends'
import {useSelector} from 'react-redux'
import {api} from '../helpers/auth-axios'
import {setNotification} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import NotificationItem from '../components/NotificationItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../components/common/Loader'
import {subscribeToChannel} from '../service/pusherService'
import Footer from '../components/Footer'

const Notifications = () => {
	const router = useRouter()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [loading, setLoading] = useState(false)
	const [notificationLoading, setNotificationLoading] = useState(true)
	const [page, setPage] = useState<any>(1)
	const [hasMore, setHasMore] = useState(true)

	const {t} = useTranslation()
	const dispatch = useDispatch()
	const _isNotification: any = useSelector<any>((state: any) => state.auth.isNotification)

	const [notifications, setNotifications] = useState([])

	useEffect(() => {
		getUserNotifications()
	}, [page])

	useEffect(() => {
		const unsubscribe = subscribeToChannel('NOTIFICATIONS', `NOTIFICATIONS_COUNT_${userDetails.id}`, (data: any) => {
			getUserNotifications()
		})

		return () => {
			unsubscribe()
		}
	}, [userDetails.id])

	const getUserNotifications = async () => {
		setLoading(true)
		await api
			.get(`/user/notifications?page=${page}`)
			.then((response: any) => {
				setLoading(false)
				if (response.data?.status) {
					const newNotifications: any = [...notifications, ...response?.data?.data?.data]

					setNotifications(newNotifications)
					setHasMore(response?.data?.data?.data !== 0)
				}
				setNotificationLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
			})
	}

	const readNotification = async (notify: any) => {
		if (!notify.is_read) {
			// Optimistically update the state
			setNotifications((prevNotifications: any) => prevNotifications.map((notification: any) => (notification.id === notify.id ? {...notification, is_read: true} : notification)))

			try {
				const response = await api.put('/user/notifications', {id: notify.id})
				if (!response.data?.status) {
					setNotifications((prevNotifications: any) => prevNotifications.map((notification: any) => (notification.id === notify.id ? {...notification, is_read: false} : notification)))
				} else {
					dispatch(setNotification(!_isNotification))
				}
			} catch (error) {
				setNotifications((prevNotifications: any) => prevNotifications.map((notification: any) => (notification.id === notify.id ? {...notification, is_read: false} : notification)))
			}
		}
	}

	const updateNotification = async (notify: any) => {
		if (!notify.is_read) {
			setNotifications((prevNotifications: any) => prevNotifications.map((notification: any) => (notification.id === notify.id ? {...notification, is_read: true} : notification)))
			try {
				const response = await api.put('/user/notifications', {id: notify.id})
				if (!response.data?.status) {
					setNotifications((prevNotifications: any) => prevNotifications.map((notification: any) => (notification.id === notify.id ? {...notification, is_read: false} : notification)))
				} else {
					dispatch(setNotification(!_isNotification))
				}
			} catch (error) {
				setNotifications((prevNotifications: any) => prevNotifications.map((notification: any) => (notification.id === notify.id ? {...notification, is_read: false} : notification)))
			}
		}
		if (notify.type === 'ACCEPT_NETWORK_REQUEST' || notify.type === 'REJECT_NETWORK_REQUEST' || notify.type === 'SEND_NETWORK_REQUEST') {
			router.navigate(notify.type === 'ACCEPT_NETWORK_REQUEST' ? '/network' : `/network?isRequest=${true}`)
		} else if (notify.type === 'BMT_COINS_DEDUCTED' || notify.type === 'BMT_COINS_RECEIVED' || notify.type === 'ADD_BMT' || notify.type === 'RESCHEDULE_BMT' || notify.type === 'DECLINE_BMT' || notify.type === 'ACCEPTED_BMT') {
			if (notify?.meta && JSON.parse(notify.meta)?.id) {
				router.navigate(`/calendar?id=${JSON.parse(notify.meta)?.id}&isShare=${true}`)
			}
		} else if (notify.type === 'CANCEL_PRO_MEMBERSHIP') {
			router.navigate(`/buy-my-time`)
		} else if (notify?.type === 'BUY_TIME_NOW_INVITE_ACCEPTED' && notify?.userNotificationTo) {
			router.navigate(`/${notify?.userNotificationTo?.user_name}`)
		}
	}
	const fetchMoreData = () => {
		setPage((prevPage: any) => prevPage + 1)
	}
	return (
		<MainContainer>
			<Spacer height={5} />
			<Container fluid>
				<Row>
					<Col lg={9}>
						{loading && <Loader />}
						<Card background={palette.white} padding={'0rem'} style={{overflowY: 'hidden'}}>
							<Flexed padding={'1.6rem'}>
								<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'700'} color={'text_color'} align={'left'}>
									{t('Notifications')}
								</Text>
							</Flexed>
							<Flexed height={'47.3rem'} id="scrollableDiv" style={{overflow: 'hidden', overflowY: 'scroll'}}>
								<InfiniteScroll scrollableTarget="scrollableDiv" dataLength={notifications.length} next={fetchMoreData} hasMore={hasMore} scrollThreshold={0.9} loader={<></>} endMessage={<></>}>
									{notifications && notifications.length
										? notifications?.map((notification: any, i: any) => {
												return <NotificationItem key={i} notification={notification} readNotification={readNotification} updateNotification={updateNotification} index={i} total={notifications.length} />
										  })
										: !loading && notifications.length == 0 && !notificationLoading && <>There is no notifications to show</>}
								</InfiniteScroll>
							</Flexed>
						</Card>
					</Col>
					<Col lg={3}>
						<Row>
							<Col lg={12}>
								<CustomSmallSpace height={2} />
								<Card background={palette.default_theme}>
									<ProBadgeWrapper>
										<ProBadgeCircle></ProBadgeCircle>
										<Icons height={'90%'} width={'90%'} src={'/images/pro_badge.svg'} />
									</ProBadgeWrapper>
									<Spacer height={2} />

									<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
										{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? t('You are already a') : t('Become a')}
									</Text>
									<Spacer height={0.5} />
									<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
										{t('Pro Member')}
									</Text>
									<Spacer height={1} />
									<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
										{t('Setup your own BMT Values')}
									</Text>

									<Spacer height={0.5} />
									<Flexed direction="row" justify={'center'} align={'center'} gap={'0.4'}>
										<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
											{t('Exclusive Access')}
										</Text>
										<Dot bg={palette.secondary_900} />
										<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
											{t('Premium Visibility')}
										</Text>
									</Flexed>
									<Spacer height={1.5} />
									<Flexed direction="row" justify={'center'} align={'center'} gap={'0.4'}>
										{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? (
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												fontWeight={600}
												icon={'icon.png'}
												padding={'0 1rem'}
												label={<>{t('BUY COINS')}</>}
												background={palette.white}
												color={palette.text_color}
												lineHeight={'0.91rem'}
												ifClicked={() => router.navigate('/buy-coins')}
											/>
										) : (
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												fontWeight={600}
												icon={'icon.png'}
												padding={'0 1rem'}
												label={<>{t('UPGRADE NOW')}</>}
												background={palette.white}
												color={palette.text_color}
												lineHeight={'0.91rem'}
												ifClicked={() => router.navigate('/upgrade-bmt-membership')}
											/>
										)}
									</Flexed>
									<Spacer height={0.5} />
								</Card>
							</Col>

							<Col lg={12}>
								<Spacer height={1} />
								<InviteFriends />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<Footer />
			{/* <Spacer height={3} /> */}
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 0;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	opacity: ${({opacity}) => opacity};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`

const CustomFlexed = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	flex-direction: column;
	 `};
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 70%;
	}
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 94px;
	height: 94px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const CustomSmallSpace = styled(Spacer)`
	display: none;
	${media.xs`
display: block
	 `};
`

export default Notifications
