import {useCallback, useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js'
import {api} from '../../../helpers/auth-axios'
import Loader from '../../common/Loader'
import {AppConstants} from '../../../AppConstants'
import {useSelector} from 'react-redux'

// Load your publishable key from the Stripe dashboard
const stripePromise = loadStripe(AppConstants.stripe_public_key)

const CheckoutForm = ({coins, amount}: {amount: number; coins: any}) => {
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [loading, setLoading] = useState<any>(false)
	const fetchClientSecret = useCallback(() => {
		setLoading(true)
		return api.post('/create-checkout-session', {amount, coins, email: userDetails?.email}).then((res) => {
			setLoading(false)
			return res.data.clientSecret
		})
	}, [])

	const options = {fetchClientSecret}

	return (
		<div id="checkout">
			{loading && <Loader />}
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout />
			</EmbeddedCheckoutProvider>
		</div>
	)
}

export default CheckoutForm
