import React, {useState} from 'react'
import {Icons, ProfileImg} from '../TopNavBar'
import {Flexed} from '../../styled/shared'
import ClipLoader from 'react-spinners/ClipLoader'
import {palette} from '../../styled/colors'

const UserProfileLoader = ({imgUrl, height, width}: {imgUrl?: any; height?: any; width?: any}) => {
	const [imageLoaded, setImageLoaded] = useState(false)
	const [imageError, setImageError] = useState(false)
	const handleImageLoad = () => {
		setImageLoaded(true)
	}

	const handleImageError = () => {
		setImageError(true)
		setImageLoaded(false)
	}
	return (
		<ProfileImg width={width} height={height}>
			{!imageLoaded && !imageError ? (
				<Flexed justify={'center'} align={'center'} direction={'columns'} width={'50%'} style={{position: 'absolute'}}>
					<ClipLoader color={palette.input_text_color} />
				</Flexed>
			) : (
				''
			)}
			<Icons pointer={'pointer'} style={{opacity: imageLoaded ? 1 : 0}} onError={handleImageError} onLoad={handleImageLoad} src={imageError ? '/images/user_default.png' : imgUrl ? imgUrl : '/images/user_default.png'} alt="i" />
		</ProfileImg>
	)
}

export default UserProfileLoader
