export const palette: any = {
	white: '#ffffff',
	black: '#141414',
	light_black: '#232323',
	dark_gray: '#434343',
	light_gray: '#DCDCDC',
	blue: '#1366ff',
	aqua_blue: '#00BCDD',
	sky_blue: '#66aaf921',
	dark_blue: '#002e62',
	golden: '#efb300',
	gray: '#5C6B73',
	silver: '#E1E1E1',
	light_silver: '#434343',
	danger: '#EF402B',
	green: '#17c964',
	opacity: {
		white: '#ffffff2b'
	},
	shadow: '0px 4px 10px rgba(0, 0, 0, 0.10)',
	shadowHover: '0px 4px 10px rgba(0, 0, 0, 0.30)',
	main_cover_overlay_light: 'rgb(0 0 0 / 40%)',
	main_cover_overlay_dark: 'rgb(0 0 0 / 65%)',
	default_theme: 'linear-gradient(134.73deg, #5F6AFC 13.02%, #936AF2 50.84%, #BF67E4 86.65%)',
	text_color: '#002E62',
	blue_500: '#006FEE',
	zink_400: '#A1A1AA',
	input_text_color: '#71717A',
	cards_bg: 'linear-gradient(112.31deg, #FFFFFF 6.93%, rgba(255, 255, 255, 0) 98.02%)',
	foreground_600: '#52525B',
	input_bg: '#F4F4F5',
	cyn_50: '#F0FCFF',
	yellow_100: '#FDEDD3',
	secondary_900: '#180828',
	warning_50: '#FEFCE8',
	default_200: '#E4E4E7',
	chevron_color: '#1C1B1F',
	success: '#17C964',
	danger_1: '#F31260',
	card_border: '#eaeaea',
	zink_300: '#D4D4D8',
	primary_50: '#E6F1FE',
	bronze_light: '#FFECEC',
	days_color: '#61617B', //days name color,
	today_slot_border: '#66AAF9',
	grey_1: '#D9D9D9',
	blue_100: '#CCE3FD',
	blue_400: '#338EF7',
	notes_bg: '#F0F0F0',
	purpole_gradient: 'linear-gradient(199.15deg, rgba(255, 3, 169, 0.12) -0.19%, rgba(11, 138, 255, 0.12) 101.1%)',
	withdraw_coin_box: 'linear-gradient(199.15deg, rgba(3, 210, 255, 0.12) -0.19%, rgba(60, 255, 11, 0.12) 101.1%)',
	default_foreground: '#000000',
	active_inbox_user: '#EFEFEF',
	reply_bg: '#F2F2F2',
	calendar_bg: `linear-gradient(173.74deg, #011B3B -0.53%, #00387C 100.01%)`,
	calendar_header_bg: ' #F4F4F5',
	calendar_header_color: '#18181B',
	red_600: '#DC2626',
	light_blue_700: '#0369A1',
	dark_blue_event: '#0EA5E91A',
	light_blue_500: '#0EA5E9',
	voilet: '#8B5CF61A',
	voilet_700: '#6D28D9',
	voilet_500: '#8B5CF6',
	amber: '#F59E0B1A',
	amber_700: '#B45309',
	amber_500: '#F59E0B',
	emeraled: '#10B9811A',
	emeraled_700: '#047857',
	emeraled_500: '#10B981',
	rose_100: '#FFE4E6',
	rose_700: '#BE123C',
	rose_500: '#F43F5E',
	white_70: '#FFFFFFB2',
	red_500: '#EF4444',
	green_500: '#22C55E',
	purple_500: '#A855F7',
	paypal_card: 'linear-gradient(199.15deg, #FFD5F1 -0.19%, #C6E4FF 101.1%)',
	decline_request: '#F54180',
	diamond_ligh: '#E6FAFE',
	gold_light: '#FDEDD3',
	silver_light: '#E4E4E7',
	overlay: '#00000080',
	aa: `linear-gradient(
		to bottom,
		rgba(136, 0, 200, 1) 0%,
		rgba(0, 68, 144, 1) 100%
	  )`
}

// Follow this light & dark theme setting.
//             Light Mode             Dark Mode
// SiteTheme	white			.	   light_black
// Heading    	black     		.      white
// Text   		dark_gray   	.      light_gray
// Border   	silver      	.      light_silver
// Icons		gray			.	   silver
