import React, {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import TextArea from './common/input/TextArea'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {ButtonFlex} from './AboutUsPage'
import {Flexed, Spacer} from '../styled/shared'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {api} from '../helpers/auth-axios'
import Loader from './common/Loader'

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
	message: Yup.string().required('Message is required')
})

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	message: ''
}

const ContactUsForm = () => {
	const {t} = useTranslation()
	const [loading, setLoading] = useState(false)
	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/contact_us', values)
			.then((res: any) => {
				if (res.data?.status) {
					actions.setSubmitting(false)
					actions.resetForm()
					setLoading(false)
					toast.success(res?.data?.message)
				} else {
					setLoading(false)
					toast.success(res?.data?.message)
				}
			})
			.catch((e) => {
				setLoading(false)
			})
	}

	return (
		<>
			{loading && <Loader />}
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
				{({isSubmitting, errors, setFieldValue, values, touched}) => (
					<Form>
						<NameFieldsFlex direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
							<Field
								component={Input}
								name="firstName"
								type="text"
								label={t('First Name')}
								value={values.firstName ?? null}
								isContact
								onChange={(event: any) => {
									setFieldValue('firstName', event?.value, true)
								}}
								error={touched.firstName && errors.firstName ? {message: errors.firstName ?? ''} : null}
							/>

							<Field
								component={Input}
								name="lastName"
								type="text"
								label={t('Last Name')}
								value={values.lastName ?? undefined}
								isContact
								onChange={(event: any) => {
									setFieldValue('lastName', event?.value, true)
								}}
								error={touched.lastName && errors.lastName ? {message: errors.lastName ?? ''} : null}
							/>
						</NameFieldsFlex>
						<Spacer height="1" />
						<Field
							component={Input}
							name="email"
							type="email"
							label={t('Email')}
							value={values.email ?? undefined}
							isContact
							onChange={(event: any) => {
								setFieldValue('email', event?.value, true)
							}}
							error={touched.email && errors.email ? {message: errors.email ?? ''} : null}
						/>

						<Spacer height="1" />
						<Field
							component={TextArea}
							name="message"
							type="text"
							value={values.message ?? undefined}
							onChange={(event: any) => {
								setFieldValue('message', event?.value, true)
							}}
							error={touched.message && errors.message ? {message: errors.message ?? ''} : null}
							label={t('Type your message here')}
							isContact
						/>

						<Spacer height="1" />
						<ButtonFlex direction={'row'} align={'center'}>
							<Button
								small
								hasBorder
								disabled={isSubmitting}
								type="submit"
								borderRadius={'1rem'}
								fontSize={'0.8rem'}
								padding={'8px 16px 8px 16px'}
								label={t('SUBMIT')}
								height={'32px'}
								width={'85px'}
								background={'transparent'}
								color={palette.blue_500}
								lineHeight={'0.89rem'}
							/>
						</ButtonFlex>
					</Form>
				)}
			</Formik>
		</>
	)
}

const NameFieldsFlex = styled(Flexed)`
	${media.xs`
flex-direction: column;
    `}
	${media.sm`
	flex-direction: column;

    `}
    ${media.md`
	flex-direction: row;

    `}
	${media.lg`
	flex-direction: row;

    `}
`
export default ContactUsForm
