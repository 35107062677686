import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Card} from '../../pages/Dashboard'
import {palette} from '../../styled/colors'
import useRouter from '../../hooks/router'
import {Icons, ProfileImg} from '../TopNavBar'
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import Button from '../common/Button'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Footer from '../Footer'

const ProMemberCongrats = () => {
	const router: any = useRouter()
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const {t} = useTranslation()

	return (
		<MainContainer>
			<Container fluid style={{flex: 1}}>
				<Spacer height={5} />
				<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'visible'}>
					<Flexed direction="column" align={''} gap={'0'} padding={'0rem 0.5rem'}>
						<Flexed direction="row" align={'center'} gap={'0.6'}>
							<Icons
								src={'/images/icon_view.svg'}
								onClick={() => {
									router.navigate('/bmt-membership-payment')
								}}
								pointer={'pointer'}
							/>
							<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
								{t('Set up your Pro Membership')}
							</Text>
						</Flexed>
					</Flexed>
					<Row alignItems="center">
						<Col lg={3} md={12} sm={12}></Col>
						<Col lg={6} md={12} sm={12}>
							<Spacer height={'0'} />
							<CustomSmallScreenSpacer height={1} />
							<Flexed align="center" text-align="center" direction="column" gap={1}>
								<SuccessText fontSize={1.5} fontWeight={600}>
									{t('Congratulations')}
								</SuccessText>

								<SuccessImg src="/images/bmt_success.svg" alt="Success" />

								<Spacer height={'0.5'} />

								<Card background={palette.default_theme} minHeight={'6.56rem'} padding={'1rem'} style={{alignItems: 'center', display: 'flex'}}>
									<Flexed direction={'row'} align={'center'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
										<HandleFlex direction={'row'} align={'center'} gap={'0.5'} flexWrap={'wrap'}>
											<ProBadgeWrapper>
												<ProBadgeCircle></ProBadgeCircle>
												<Icons height={'80%'} width={'80%'} src={'/images/pro_badge.svg'} />
											</ProBadgeWrapper>
											<Spacer height={2} />
											<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
												<Flexed direction={'column'} gap={'0.5'} align={'start'}>
													<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
														{t('You are officially')}
													</Text>

													<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
														{t('Pro Member')}
													</Text>
												</Flexed>
											</HandleFlex>
										</HandleFlex>

										<HandleFlex direction="column" justify={'center'} align={'center'} gap={'1'} padding={'1rem'}>
											<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
												{t('Subscription')}
											</Text>

											<Flexed direction="column" justify={'space-between'} align={'left'} gap={'1'}>
												<Flexed direction="row" justify={'space-between'} align={'center'} gap={'0.8'} width={'16.87rem'} height={'3rem'} padding={'0 1rem'} background={`rgba(228, 228, 231, 0.1)`} radius={'8px'}>
													<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
														{_userDetails.proMembership && _userDetails.proMembership[0]?.duration === 'annually' ? t('Yearly Subscription') : t('Monthly Subscription')}
													</Text>
													<Text fontSize={'2'} lineHeight={'2.5'} fontWeight={'700'} color={'white'}>
														${_userDetails.proMembership && _userDetails.proMembership[0]?.duration === 'annually' ? 86 : 8}
													</Text>
												</Flexed>
											</Flexed>
										</HandleFlex>
									</Flexed>
								</Card>

								<Spacer height={'0.5'} />

								<Button
									small
									hasBorder
									borderRadius={'1rem'}
									fontSize={'0.8rem'}
									padding={'8px 16px 8px 16px'}
									label={
										<>
											<FaArrowLeft />
											&nbsp; {t('BACK TO BMT')}
										</>
									}
									background={palette.white}
									color={palette.blue_500}
									lineHeight={'0.89rem'}
									ifClicked={() => router.navigate('/buy-my-time')}
								/>
							</Flexed>
						</Col>
						<Col lg={3} md={12} sm={12}></Col>
					</Row>
					{/* <Spacer height={'3'} /> */}
				</Card>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
`

export const CustomSmallScreenSpacer = styled(Spacer)<any>`
	${media.xs`
    display: flex;
    `}
	${media.sm`
    display: flex;

    `}
    ${media.md`

    display: none;
    `}
	${media.lg`
   display: none;
    `}
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
	text-align: center;
	align-items: center;
	justify-content:center;
	width: 100%;
	 `};
`

const ManageCardComponent = styled(Flexed)<any>`
	height: ${({height}) => height};
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
	text-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	 `};
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 50px;
		width: 37px;
	}
	${media.xs`
	display: content;
	width: 100%
	 `};
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const Meeting = styled(Flexed)`
	text-align: center;
	background-color: #66aaf921;
	border-radius: 10px;
	border: 1px solid #66aaf921;
	width: 28.25rem;
	height: 3.25rem;
`

const SuccessText = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
export default ProMemberCongrats
