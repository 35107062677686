import React, {useState} from 'react'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa' // Assuming you're using Font Awesome for icons
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../../actions/authActions'
import {toast} from 'react-toastify'
import {api} from '../../helpers/auth-axios'
import {useTranslation} from 'react-i18next'

interface ModalProps {
	closeModal: () => void
	setIsContinue: (value: boolean) => void
	coins: number
	amount: number
}

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ModalContainer = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 600px;
	position: relative;
`

const Header = styled.h2`
	color: #000000;
	margin: 0 0 20px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 20px;
`

const CardEntryContainer = styled.div`
	margin-bottom: 20px;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`

const WithdrawButton = styled.button`
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`

const ErrorText = styled.div`
	color: red;
	margin-top: 5px;
`

const StyledCardElement = styled(CardElement)`
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 15px;

	&:focus {
		border-color: #6772e5;
	}
`

const ModalComponent: React.FC<ModalProps> = ({closeModal, coins, amount, setIsContinue}) => {
	const [error, setError] = useState<string>('')
	const [processing, setProcessing] = useState<boolean>(false)
	const stripe = useStripe()
	const elements = useElements()
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const handleWithdrawClick = async () => {
		if (!stripe || !elements) {
			return
		}
		setProcessing(true)
		try {
			const cardElement = elements.getElement(CardElement)
			if (!cardElement) {
				throw new Error(t('Card Element not found'))
			}
			const {token, error} = await stripe.createToken(cardElement)
			if (error || !token) {
				setError(error?.message || t('Token creation failed'))
				setProcessing(false)
				return
			}

			const response = await api.post('/user/withdraw_coins', {coins, amount, token: token.id})
			if (response.data.status) {
				window.location.href = response.data?.data?.url
				// toast.success(response.data.message)
				// dispatch(callAutoLogin(true))
				// setIsContinue(true)
			} else {
				toast.error(response.data.message)
			}

			setProcessing(false)
			closeModal()
		} catch (err) {
			setError(t('An error occurred during withdrawal.'))
			setProcessing(false)
		}
	}

	const handleCardChange = (event: any) => {
		if (event.error) {
			setError(event.error.message)
		} else {
			setError('')
		}
	}

	return (
		<Overlay>
			<ModalContainer>
				<Header>Complete your withdrawal</Header>
				<CloseButton onClick={closeModal}>
					<FaTimes />
				</CloseButton>
				<CardEntryContainer>
					<StyledCardElement
						id="card"
						onChange={handleCardChange}
						options={{
							style: {
								base: {
									fontSize: '16px',
									color: '#424770',
									'::placeholder': {
										color: '#aab7c4'
									}
								},
								invalid: {
									color: '#9e2146'
								}
							}
						}}
					/>
					{error && <ErrorText>{error}</ErrorText>}
				</CardEntryContainer>
				<ButtonContainer>
					<WithdrawButton onClick={handleWithdrawClick} disabled={processing}>
						{processing ? t('Processing...') : t('Withdraw')}
					</WithdrawButton>
				</ButtonContainer>
			</ModalContainer>
		</Overlay>
	)
}

export default ModalComponent
