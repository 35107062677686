import React, {useEffect, useState} from 'react'
import {Flexed, Spacer, Text} from '../../styled/shared'
import Button from '../common/Button'
import styled from 'styled-components'
import useRouter from '../../hooks/router'
import {FaArrowRight} from 'react-icons/fa'
import {palette} from '../../styled/colors'
import {api} from '../../helpers/auth-axios'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../../actions/authActions'
import Loader from '../common/Loader'
import {useTranslation} from 'react-i18next'
import Footer from '../Footer'
import {Container} from 'styled-bootstrap-grid'

const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const ProMembershipSuccessfull = () => {
	const router = useRouter()
	const [sessionId, setSessionId] = useState<any>(null)
	const [sessionStatus, setSessionStatus] = useState<any>(null)
	const [loading, setLoading] = useState<Boolean>(false)
	const [cardOptions, setCardOptions] = useState<any>('')
	const dispatch = useDispatch()
	const {t} = useTranslation()
	const [inviteFrom, setInviteFrom] = useState<any>({email: '', userId: ''})

	useEffect(() => {
		// Extract encoded query parameter from the URL
		let url: any = localStorage.getItem('invited-details')
		const urlParams = new URLSearchParams(url)
		if (urlParams) {
			const encodeduserId = urlParams.get('data')
			const encodedemail = urlParams.get('email')

			// Decode the data
			if (encodeduserId && encodedemail) {
				const decodedData = atob(encodeduserId)
				const decodedEmail = atob(encodedemail)
				setInviteFrom({email: decodedEmail, userId: decodedData})
			} else {
				console.error('Encoded data is null or undefined')
			}
		}
	}, [])

	useEffect(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const sessionId = urlParams.get('session_id')
		setSessionId(sessionId)
		getAllMemberships()
	}, [])

	const getAllMemberships = async () => {
		setLoading(true)
		console.log(cardOptions, 'These are the card options')
		await api
			.get('/user/memberships')
			.then((res: any) => {
				if (res?.data) {
					setLoading(false)
					const filteredMemberships = res?.data?.data?.filter((membership: any) => membership.type == 'pro')

					const groupedMemberships = filteredMemberships.reduce((acc: any, membership: any) => {
						if (!acc[membership.type]) {
							acc[membership.type] = []
						}
						acc[membership.type].push(membership)
						return acc
					}, {})
					setCardOptions(groupedMemberships)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
				}
			})
	}

	useEffect(() => {
		if (sessionId) {
			api.get(`/session-status?session_id=${sessionId}`).then((res) => {
				if (res.data.status === 'complete') {
					setSessionStatus({status: 'complete', transactionId: res.data.transactionId})
				}
			})
		}
	}, [sessionId])

	useEffect(() => {
		if (cardOptions && sessionStatus?.status === 'complete') {
			purchaseProMembership(sessionStatus.transactionId)
		}
	}, [cardOptions, sessionStatus])
	const purchaseProMembership = async (transactionId: any) => {
		let checkProDuration: any = localStorage.getItem('pro_duration') || 'annually'
		const amount = checkProDuration === 'monthly' ? 8 : 30

		const data = [
			{
				m_id: cardOptions['pro'][0]?.id,
				coins: 150,
				price: 30,
				duration: checkProDuration
			},
			{
				m_id: cardOptions['pro'][1]?.id,
				coins: 250,
				price: 50,
				duration: checkProDuration
			}
		]
		api.post('/user/upgrade_to_pro', {
			amount,
			transactionId,
			membership: data,
			invite_email: inviteFrom?.email,
			invite_user_id: inviteFrom.userId
		})
			.then((response) => {
				if (response.data.status) {
					toast.success(t("You're now Pro Member"))
					if (inviteFrom?.email) {
						localStorage.removeItem('invited-details')
					}
					dispatch(callAutoLogin(true))
				} else {
					toast.success(response?.data?.status)
				}
			})
			.catch((error) => {
				console.log(error.message)
				toast.error(t('Something went wrong'))
			})
	}

	return (
		<>
			{loading && <Loader />}
			<div style={{height: '90vh', display: 'flex', flexDirection: 'column'}}>
				<Container fluid style={{flex: 1}}>
					<Flexed align="center" text-align="center" direction="column" gap={1}>
						<Spacer height={'5'} />

						<SuccessImg src="/images/thankyou.png" alt="Success" />

						<Spacer height={'0.5'} />
						<Text fontWeight="700" fontSize="1.125" lineHeight="1.361" align="center" color={'text_color'}>
							{t('Your payment is Successfully Done')} !
						</Text>

						<Button
							small
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={
								<>
									{t('Manage Membership')} &nbsp; <FaArrowRight />
								</>
							}
							background={palette.blue_500}
							color={palette.white}
							lineHeight={'0.89rem'}
							ifClicked={() => {
								router.navigate('/bmt-membership-payment')
							}}
						/>
					</Flexed>
				</Container>
			</div>
			<Footer />
		</>
	)
}

export default ProMembershipSuccessfull
