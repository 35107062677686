import React from 'react'
import MainCover from '../components/MainCover'
import AboutUsPage from '../components/AboutUsPage'
import styled from 'styled-components'
import Footer from '../components/Footer'

const Home = () => {
	return (
		<MainContainer>
			<MainCover />
			<AboutUsPage />
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/home-bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
`
export default Home
