import React, {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Input from './common/input/Input'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {Col, Row} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import {Card} from './AuthPage'
import {FaArrowLeft} from 'react-icons/fa'
import useRouter from '../hooks/router'
import {CustomSpacer, Dot} from '../pages/SignUp'
import {api} from '../helpers/auth-axios'
import Loader from './common/Loader'
import {useTranslation} from 'react-i18next'
import AuthTerms from './common/AuthTerms'

const initialValues = {
	email: ''
}

const ForgotPasswordForm = () => {
	const router = useRouter()
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation()

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t('Invalid email')).required(t('Email is required'))
	})

	const handleSubmit = async (values: any, actions: any) => {
		setLoading(true)
		await api
			.post('/user/send_verification_code', {...values})
			.then((res: any) => {
				if (res?.data?.status) {
					actions.setSubmitting(false)
					actions.resetForm()
					setLoading(false)
					toast.success(res?.data?.message)
					router.navigate(`/verify-otp?email=${values?.email}&tmpToken=${res?.data?.data}`)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	return (
		<Row justifyContent="center">
			<Col xl={8} lg={8} md={8} sm={12} xs={10}>
				{loading && <Loader />}
				<Spacer height={4} />
				<Card>
					<Flexed justify="center">
						<Flexed justify="center">
							<Text fontSize={'1.5'} lineHeight={'2'} fontWeight={'600'} color={'text_color'}>
								{t('Forgot your password?')}
							</Text>
							<Spacer height={1} />
							<Paragraph fontSize={'0.9'} lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'}>
								{t('Enter your email address to reset your password.')}
							</Paragraph>
						</Flexed>
						<Spacer height={1} />

						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
							{({isSubmitting, errors, setFieldValue, values, touched}) => (
								<Form>
									<Field
										component={Input}
										name="email"
										type="email"
										label={t('Email')}
										value={values.email ?? undefined}
										isSignup
										isContact
										onChange={(event: any) => {
											setFieldValue('email', event?.value, true)
										}}
										error={errors.email ? {message: errors.email ?? ''} : null}
									/>

									<Spacer height="1" />

									<Flexed direction={'row'} align={'center'} justify={'center'}>
										<Button
											small
											hasBorder
											disabled={isSubmitting}
											type="submit"
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={<>{t('RESET PASSWORD')}</>}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
										/>
									</Flexed>
								</Form>
							)}
						</Formik>
						<Spacer height="1" />
						<Flexed direction={'row'} align={'center'} justify={'center'}>
							<Button
								small
								hasBorder
								borderRadius={'1rem'}
								fontSize={'0.8rem'}
								label={
									<>
										<FaArrowLeft />
										&nbsp; BACK TO SIGN IN
									</>
								}
								background={'transparent'}
								color={palette.blue_500}
								lineHeight={'0.89rem'}
								ifClicked={() => router.navigate('/login')}
							/>
						</Flexed>
					</Flexed>
					<Spacer height={2} />
				</Card>
				<Flexed justify="center" align={'center'}>
					<Text fontSize={'0.9'} isCentered lineHeight={'1.25'} fontWeight={'400'} color={'black'}>
						Don’t have an account?
					</Text>
					<Spacer height={1} />
					<Button
						small
						hasBorder
						type="submit"
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						label="CREATE AN ACCOUNT"
						background={'transparent'}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => {
							router.navigate('/signup')
						}}
					/>
				</Flexed>
				<Spacer height={2} />
				<CustomSpacer height={2} />
				<AuthTerms />
			</Col>
		</Row>
	)
}

export default ForgotPasswordForm
