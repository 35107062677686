import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Icons, ProfileImg} from '../../components/TopNavBar'
import Button from '../../components/common/Button'
import {BsClock} from 'react-icons/bs'
import useRouter from '../../hooks/router'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {api} from '../../helpers/auth-axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useTranslation} from 'react-i18next'
import Loader from '../common/Loader'
import {subscribeToChannel} from '../../service/pusherService'
import {useSelector} from 'react-redux'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import UserProfileLoader from '../common/UserProfileLoader'

const MyNetworkCards = ({clickTab, setClickTab, isSearch, setSearch, searchChatUserQuery}: {recentNetworks?: any[]; setClickTab: any; clickTab: any; isSearch: any; setSearch: any; searchChatUserQuery?: any}) => {
	const router = useRouter()
	const [hasMore, setHasMore] = useState(true)
	const [users, setUsers] = useState<any>([])
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)
	const {t} = useTranslation()
	const userAgent = navigator.userAgent.toLowerCase()
	const isMobile = /iphone|ipad|ipod|android/.test(userAgent)

	useEffect(() => {
		const unsubscribe = subscribeToChannel('NETWORK_CHANNEL', `ACCEPT_NETWORK_${userDetails.id}`, (data: any) => {
			// Update state with the received data
			setUsers((prev: any) => [...prev, data])
		})

		const unsubscribe2 = subscribeToChannel('NETWORK_CHANNEL', `REMOVE_NETWORK_${userDetails.id}`, (data: any) => {
			// Update state with the received data
			setUsers((prevUsers: any) => prevUsers.filter((el: any) => el.id !== data.id))
		})

		return () => {
			unsubscribe()
			unsubscribe2()
		}
	}, [])

	const getUserNetworks = async (page = 1, resetUsers = false) => {
		setLoading(true)
		try {
			const response = await api.get(`/user/get_user_networks`, {
				params: {page, search: searchChatUserQuery}
			})

			const fetchedUsers = response?.data?.data?.users || []
			const newUsers = resetUsers ? fetchedUsers : [...users, ...fetchedUsers]
			if (resetUsers) {
				setSearch(false)
			}
			setUsers(newUsers)
			setHasMore(response?.data?.data?.users.length !== 0)
			setClickTab(!clickTab)
			setLoading(false)
		} catch (error: any) {
			toast.error(error.message)
		} finally {
			setLoading(false)
		}
	}

	const fetchMoreData = () => {
		setPage((prevPage) => prevPage + 1)
	}

	useEffect(() => {
		if (isSearch && localStorage.getItem('authorization')) {
			setPage(1) // Reset page to 1 for new search
			getUserNetworks(1, true) // Pass true to reset users
		}
	}, [isSearch])

	useEffect(() => {
		getUserNetworks(page)
	}, [page])

	return (
		<Flexed id="scrollableDiv" direction="column" gap={'0.5'} style={{height: '45.5rem', overflow: 'hidden', overflowY: 'scroll'}}>
			{loading && <Loader />}
			<InfiniteScroll scrollThreshold={0.9} scrollableTarget="scrollableDiv" dataLength={users.length} next={fetchMoreData} hasMore={hasMore} loader={!loading ? <></> : <></>} endMessage={users.length && !hasMore ? <></> : ''}>
				{users?.length ? (
					users.map((user: any, i: any) => (
						<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0 1rem'} style={i == users?.length - 1 ? {} : {borderBottom: `1px solid ${palette.zink_300}`}}>
							<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
								<Flexed direction="row" align={'center'} pointer onClick={() => router.navigate(`/${user.user_name}`, {state: {route: '/network'}})}>
									<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'} style={{cursor: 'pointer'}}>
										<UserProfileLoader width={'2.5rem'} height={'2.5rem'} imgUrl={user?.image_Url} />
										<AvailabilityIconWrapper src={`/images/${user?.is_online ? 'green_circle' : user?.is_online == 'offline' ? 'pink_circle' : user?.is_online == 'red' ? 'red_circle' : 'gray_circle'}.svg`} alt="upload" />
									</ProfileImgWrapper>

									<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
										<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
											<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
												{user?.full_name}
											</Text>
											{user?.linkedin_profile_verified || user?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
										</Flexed>
										<Spacer height={0.2} />
										<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'}>
											{user?.designation}
										</Text>
									</Flexed>
								</Flexed>
								<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
									<Button
										small
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										hasBorder
										padding={'0'}
										label={<>{t('MESSAGE')}</>}
										background={palette.white}
										color={palette.blue_500}
										lineHeight={'0.89rem'}
										ifClicked={() => {
											const handleRequest = async () => {
												if (userDetails) {
													if (!user?.chat_id) {
														toast.warning(t('Chat id not exist'))
													} else if (user?.chat_id) {
														await CometChat.getUser(user?.chat_id?.toLowerCase()).then(
															(res: any) => {
																if (res?.blockedByMe || res?.hasBlockedMe) {
																	toast.warning(res?.blockedByMe ? t('You blocked this user') : t('User blocked'))
																} else if (myNetworkUsers?.includes(user?.chat_id?.toLowerCase())) {
																	router.navigate(`/inbox?id=${user?.chat_id?.toLowerCase()}&network=${true}`)
																	console.log('User details fetched for user:', res)
																} else {
																	toast.warning(t('User not in your network'))
																}
															},
															(error) => {
																toast.warning(t('User not exist in chat'))
																console.log('User details fetching failed with error:', error)
															}
														)
													} else {
														toast.warning(t('Network request not accepted yet'))
													}
												}
											}
											handleRequest()
										}}
									/>
									<Button
										small
										borderRadius={'1rem'}
										fontSize={'0.8rem'}
										padding={'0'}
										label={
											<>
												<BsClock /> &nbsp; {t('Buy My Time')}
											</>
										}
										background={palette.default_theme}
										color={palette.white}
										minWidth={'10rem'}
										lineHeight={'0.89rem'}
										ifClicked={() => {
											router.navigate(`/${user.user_name}`, {state: {route: '/network'}})
										}}
									/>
								</Flexed>
							</CustomFlexedForWrap>
							<Spacer height={0.2} />
						</Flexed>
					))
				) : users?.length == 0 && !loading ? (
					<Flexed padding={isMobile ? '2rem' : '6rem'} align={'center'} justify="center" height={'100%'}>
						{/* <Spacer height={1} /> */}
						<Flexed padding={'1rem'} align={'center'}>
							<Icons src={'/images/undraw_add_friends_re_3xte.svg'} />
						</Flexed>
						<Spacer height={1} />
						<Text fontSize={'1.25'} lineHeight={'1.49'} fontWeight={'700'} color={'text_color'} isCentered>
							{t('Join JustNetwork Today and Turn Your Networking into Net Worth!')}
						</Text>
						<Spacer height={0.8} />
						<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
							{t('Start adding people to your Network')}
						</Text>

						<Spacer height={1} />
						<Button
							small
							hasBorder
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={<>{t('Add')}</>}
							background={palette.white}
							color={palette.blue_500}
							lineHeight={'0.89rem'}
							ifClicked={() => router.navigate(`/network`, {state: 'suggestion'})}
						/>
					</Flexed>
				) : users?.filter((e: any) => e?.name?.toLowerCase().includes(searchChatUserQuery.toLowerCase()) || e?.designation?.toLowerCase().includes(searchChatUserQuery.toLowerCase())).length == 0 && !loading ? (
					<Flexed align={'center'} justify={'center'} direction={'row'} height={'100%'}>
						<Text fontSize={'0.9'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
							{t('No network user found!')}
						</Text>
					</Flexed>
				) : (
					''
				)}
			</InfiniteScroll>
		</Flexed>
	)
}

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`
const AvailabilityIconWrapper = styled(Icons)<any>`
	position: absolute;
	bottom: 0;
	margin-left: 1.8rem;
`

export default MyNetworkCards
