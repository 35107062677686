import styled from 'styled-components'
import React, {useRef, useState} from 'react'

type InputProps = {
	length?: number
	onComplete: (pin: any) => void
	onChange: (val: any) => void
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Input = styled.input`
	background-color: #f6f5fa;
	text-align: center;
	border: 1px solid transparent;
	border-radius: 8px;
	width: 54px;
	height: 54px;
	margin: 0 2px;
	font-size: 24px;
	color: #000;
	outline: none;

	@media (max-width: 1000px) {
		width: 40px;
		height: 40px;
		font-size: 18px;
		margin: 0 1px;
	}

	&:focus {
		border-color: #a0a0a0;
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
	}
`

const OTPInput = ({length = 4, onComplete, onChange}: InputProps) => {
	const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null))
	const [OTP, setOTP] = useState<string[]>(Array(length).fill(''))

	const handleTextChange = (input: string, index: number) => {
		// Check if input is numeric, only allow numbers
		if (!/^\d*$/.test(input)) {
			return // Exit early if input is not numeric
		}

		const newPin = [...OTP]
		newPin[index] = input
		setOTP(newPin)
		onChange({verification_code: newPin.join('')})

		// Move focus to the next input if available
		if (index < length - 1 && input.length === 1) {
			inputRef.current[index + 1]?.focus()
		}

		// Check if all fields are filled and trigger onComplete
		if (newPin.every((digit) => digit !== '')) {
			onComplete({verification_code: newPin.join('')})
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'ArrowLeft' && index > 0) {
			inputRef.current[index - 1]?.focus()
		}
		if (e.key === 'ArrowRight' && index < length - 1) {
			inputRef.current[index + 1]?.focus()
		}
		if (e.key === 'Backspace') {
			if (OTP[index] === '' && index > 0) {
				inputRef.current[index - 1]?.focus()
			} else {
				const newPin = [...OTP]
				newPin[index] = ''
				setOTP(newPin)
			}
		}
		if (e.key === 'Delete') {
			if (OTP[index] === '' && index > 0) {
				inputRef.current[index - 1]?.focus()
			} else {
				const newPin = [...OTP]
				newPin[index] = ''
				setOTP(newPin)
			}
		}
	}

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault()
		const pasteData = e.clipboardData.getData('text/plain')
		const pasteDigits = pasteData.replace(/\D/g, '').slice(0, length).split('')

		const newPin = [...OTP]
		pasteDigits.forEach((digit, idx) => {
			if (idx < length) {
				newPin[idx] = digit
			}
		})

		setOTP(newPin)
		onChange({verification_code: newPin.join('')})

		// Move focus to the last input filled by paste
		const lastFilledIndex = Math.min(pasteDigits.length - 1, length - 1)
		inputRef.current[lastFilledIndex]?.focus()

		// Check if all fields are filled and trigger onComplete
		if (newPin.every((digit) => digit !== '')) {
			onComplete({verification_code: newPin.join('')})
		}
	}

	return (
		<Container>
			{Array.from({length}, (_, index) => (
				<Input
					key={index}
					type="text"
					maxLength={1}
					placeholder="."
					value={OTP[index]}
					onChange={(e) => handleTextChange(e.target.value, index)}
					onKeyDown={(e) => handleKeyDown(e, index)}
					onPaste={handlePaste}
					ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
				/>
			))}
		</Container>
	)
}

export default OTPInput
