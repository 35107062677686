export const SWITCH_THEME = 'SWITCH_THEME'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export const SEARCH_QUERY = 'SEARCH_QUERY'
export const INVITED_USERS = 'INVITED_USERS'
export const SAVE_USERS = 'SAVE_USERS'
export const CALL_AUTO_LOGIN = 'CALL_AUTO_LOGIN'
export const CHAT_USERS = 'CHAT_USERS'
export const CHAT_ALL_USERS = 'CHAT_ALL_USERS'
export const SELECT_COINS_VALUE = 'SELECT_COINS_VALUE'
export const NETWORK_USERS = 'NETWORK_USERS'
export const CHAT_UNREAD_MESSAGES = 'CHAT_UNREAD_MESSAGES'
export const CHAT_USER_MESSAGES = 'CHAT_USER_MESSAGES'
export const CHAT_USER_MESSAGES_BOTTOM = 'CHAT_USER_MESSAGES_BOTTOM'
export const CHAT_USER_DELETE_MESSAGES = 'CHAT_USER_DELETE_MESSAGES'
export const CHAT_USER_DELETE_MESSAGES_BOTTOM = 'CHAT_USER_DELETE_MESSAGES_BOTTOM'
