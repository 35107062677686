import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Text} from '../../styled/shared'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Icons} from '../TopNavBar'
import {useTranslation} from 'react-i18next'

const InputPhoneNo = ({label, required, placeholder, disabled, error, errorMsg, textTransform, onChange, name, value, isPhoneVerified, verifyPhone, isVerify}: any) => {
	const [userCountry, setUserCountry] = useState<any>('')
	const {t} = useTranslation()
	useEffect(() => {
		fetch('https://ipinfo.io/json')
			.then((response) => response.json())
			.then((data) => setUserCountry(data.country.toLowerCase()))
			.catch((err) => console.log('=====err', err))
	}, [])

	return (
		<>
			{label && (
				<Flexed direction={'row'} justify={'space-between'} align={'center'} gap={'0.2'}>
					<Label type="normal" margin="0rem 0rem 0.4rem 0rem" color={'black'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'600'}>
						{label} {required ? <Mandatory>*</Mandatory> : ''}
					</Label>
					{isPhoneVerified ? (
						<Text color={'success'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'}>
							<Icons src={'/images/icon_tick.svg'} /> {t('Verified')}
						</Text>
					) : isVerify ? (
						<Text color={'danger_1'} fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} pointer onClick={() => verifyPhone && verifyPhone()}>
							<Icons src={'/images/not_verified.svg'} /> {t('Not Verified')} ({t('click to verify')})
						</Text>
					) : (
						''
					)}
				</Flexed>
			)}
			<PhoneInput
				inputClass="phoneInputFields"
				country={value ? undefined : userCountry}
				enableAreaCodes={true}
				placeholder={placeholder}
				value={value}
				inputProps={{
					name: name,
					value: value,
					country: value ? undefined : userCountry,
					required: true
					// autoFocus: true
				}}
				onChange={(phone: any) => onChange && onChange({value: phone, name: name})}
			/>
			{error && (
				<ErrorText fontSize={0.625} type="small" color="danger">
					{error}
				</ErrorText>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: ${({textTransform}) => (textTransform ? 'capitalize' : ' uppercase')};
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const ErrorText = styled(Text)`
	text-transform: capitalize;
	font-weight: 400;
`

export default InputPhoneNo
