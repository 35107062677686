import React, {useState} from 'react'
import styled from 'styled-components'
import {FaChevronRight, FaTimes} from 'react-icons/fa'
import {useTranslation} from 'react-i18next'

interface ModalProps {
	user: string
	onClose: () => void
	onReport: (reason: any) => void
	onBlock: () => void
}

const ModalComponent: React.FC<ModalProps> = ({user, onClose, onReport, onBlock}) => {
	const [selectedOption, setSelectedOption] = useState<string | null>(null)
	const [selectedReason, setSelectedReason] = useState<string | null>(null)
	const {t} = useTranslation()

	const handleOptionClick = (option: string) => {
		setSelectedOption(option)
	}

	const handleBackClick = () => {
		setSelectedOption(null)
		setSelectedReason(null)
	}

	const handleReasonChange = (reason: string) => {
		setSelectedReason(reason)
	}

	return (
		<Overlay>
			<Modal>
				<CloseButton onClick={onClose}>
					<FaTimes />
				</CloseButton>
				{selectedOption ? (
					<Content>
						{selectedOption === 'Block' ? (
							<>
								<Header>Block</Header>
								<Body>{`${t("You're about to block")} ${user}`}</Body>
								<Text>{t(`You'll no longer be connected and will lose any endorsement or recommendations from this person.`)}</Text>
								<ButtonContainer>
									<ActionButton onClick={handleBackClick}>{t('Back')}</ActionButton>
									<ActionButton onClick={onBlock} primary>
										{t('Block')}
									</ActionButton>
								</ButtonContainer>
							</>
						) : (
							<>
								<Header>{selectedOption}</Header>
								<Body>{t('Select a reason')}:</Body>
								<RadioGroup>
									<RadioItem>
										<input style={{cursor: 'pointer'}} type="radio" id="impersonating" name="reason" value="impersonating" onChange={() => handleReasonChange('This person is impersonating someone')} />
										<label htmlFor="impersonating">{t('This person is impersonating someone')}</label>
									</RadioItem>
									<RadioItem>
										<input style={{cursor: 'pointer'}} type="radio" id="hacked" name="reason" value="hacked" onChange={() => handleReasonChange('This account has been hacked')} />
										<label htmlFor="hacked">{t('This account has been hacked')}</label>
									</RadioItem>
									<RadioItem>
										<input style={{cursor: 'pointer'}} type="radio" id="fake" name="reason" value="fake" onChange={() => handleReasonChange('This account is not a real person')} />
										<label htmlFor="fake">{t('This account is not a real person')}</label>
									</RadioItem>
								</RadioGroup>
								<ButtonContainer>
									<ActionButton onClick={handleBackClick}>{t('Back')}</ActionButton>
									<ActionButton onClick={() => onReport(selectedReason)} primary disabled={!selectedReason}>
										{t('Report')}
									</ActionButton>
								</ButtonContainer>
							</>
						)}
					</Content>
				) : (
					<Content>
						<Header>Select an option</Header>
						<Accordion>
							<AccordionItem onClick={() => handleOptionClick('Block')}>
								<span>
									{t('Block')} {user}
								</span>
								<FaChevronRight />
							</AccordionItem>
							{/* <AccordionItem onClick={() => handleOptionClick('Report')}>
								<span>Report {user} or entire Profile</span>
								<FaChevronRight />
							</AccordionItem> */}
							<AccordionItem onClick={() => handleOptionClick('Report Profile element')}>
								<span>{t('Report Profile element')}</span>
								<FaChevronRight />
							</AccordionItem>
						</Accordion>
					</Content>
				)}
			</Modal>
		</Overlay>
	)
}

export default ModalComponent

// Styled Components
const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Header = styled.h2`
	margin: 0 0 10px;
	font-size: 18px;
	font-weight: 700;
	color: black;
	border-bottom: 2px solid #ccc;
	width: 100%;
	padding-bottom: 5px;
	text-align: center;
`

const Body = styled.p`
	margin: 0 0 20px;
	font-size: 16px;
	font-weight: 600;
	color: black;
`

const Text = styled.p`
	margin: 20px 0;
	font-size: 14px;
	font-weight: 500;
	color: black;
`

const Accordion = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const AccordionItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	&:hover {
		background-color: #f0f0f0;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
`

const ActionButton = styled.button<{primary?: boolean}>`
	padding: 10px 20px;
	border: none;
	border-radius: 25px; // Rounded buttons
	background-color: ${({primary}) => (primary ? '#007bff' : '#ccc')};
	color: white;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		background-color: ${({primary}) => (primary ? '#0056b3' : '#aaa')};
	}
	&:disabled {
		background-color: #ccc;
		cursor: not-allowed;
	}
`

const RadioGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 20px 0;
`

const RadioItem = styled.div`
	margin-bottom: 10px;
	cursor: pointer;

	label {
		margin-left: 10px;
		font-size: 14px;
		font-weight: 500;
	}
`
