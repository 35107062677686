import React, {useEffect, useRef, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, HorizontalRow, Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {DropContent, DropMenu, Icons, MenuList} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {Dot} from './SignUp'
import ReactPlayer from 'react-player'
import {mockUsers} from '../utils'
import FreeBMTMemberShip from '../components/bmt/FreeBMTMemberShip'
import useRouter from '../hooks/router'
import {api} from '../helpers/auth-axios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Loader from '../components/common/Loader'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {callAutoLogin} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import {FaArrowRight, FaChevronRight} from 'react-icons/fa'
import Footer from '../components/Footer'
import {BsClock} from 'react-icons/bs'
import BuyTimeNowForm from '../components/BuyTimeNowForm'

interface IProps {
	isDarkTheme?: boolean
}

const BMT = ({isSignup}: any) => {
	const [selectedBMTOption, setSelectedBMTOption] = useState('')
	const isBtmPro = localStorage.getItem('isPro')
	const {t} = useTranslation()
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [userMembership, setUserMembership] = useState<any>(_userDetails?.userMembership?.length ? _userDetails?.userMembership[0]?.membershipId?.type : '')
	const [isBMTtrue, setIsBMTtrue] = useState<any>(false)
	const [cardOptions, setCardOptions] = useState<any>({})
	const [userSelected, setUserSelected] = useState<any>()
	const [isProMember, setIsProMember] = useState<any>(false)
	const [loading, setLoading] = useState(false)
	const [remainingEmails, setRemainingEmails] = useState(0)
	const router = useRouter()

	const menuRef: any = useRef(null)
	const [openMenu, setOpenMenu] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		if (_userDetails.proMembership && _userDetails.proMembership.length && !_userDetails.proMembership[0].is_deleted) {
			setIsProMember(true)
		} else {
			setUserMembership(_userDetails?.userMembership?.length ? _userDetails?.userMembership[0]?.membershipId?.type : '')
		}
	}, [isBtmPro, _userDetails])

	useEffect(() => {
		getAllMemberships()
	}, [])
	useEffect(() => {
		if (_userDetails?.id) getEmailsCout()
	}, [_userDetails])

	const getEmailsCout = async () => {
		try {
			await api.get('/user/get_buy_now_emails_count').then((res: any) => {
				if (res.data?.status) {
					setRemainingEmails(res?.data?.data?.count)
				}
			})
		} catch (error) {}
	}

	const proMembershipFeatures = [t('Pro Badge'), t('Setup your own BMT Value'), t('Early access to new features'), t('Premium Visibility'), t('Send & Receive unlimited coins')]

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenMenu(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [menuRef])

	const handleCancelMembership = async () => {
		setLoading(true)
		await api.get('/user/cancel_pro_membership').then((response: any) => {
			if (response.data?.status) {
				setLoading(false)
				toast.success(response.data?.message)
				dispatch(callAutoLogin(true))
				router.navigate('/buy-my-time')
			} else {
				toast.error(response.data?.message)
			}
		})
	}

	const getAllMemberships = async () => {
		setLoading(true)
		try {
			const res = await api.get('/user/memberships')
			if (res?.data) {
				const filteredMemberships = res.data.data.filter((membership: any) => membership.type !== 'pro')

				// Group memberships by type
				const groupedMemberships = filteredMemberships.reduce((acc: any, membership: any) => {
					if (!acc[membership.type]) {
						acc[membership.type] = []
					}
					acc[membership.type].push(membership)
					return acc
				}, {})

				// Define the order of membership types
				const order = {
					diamond: 1,
					gold: 2,
					silver: 3,
					bronze: 4
				}

				// Sort grouped memberships based on defined order
				const sortedGroupedMemberships: any = {}
				Object.keys(order).forEach((key) => {
					if (groupedMemberships[key]) {
						sortedGroupedMemberships[key] = groupedMemberships[key]
					}
				})

				setCardOptions(sortedGroupedMemberships)
			}
		} catch (error: any) {
			toast.error(error?.message)
		} finally {
			setLoading(false)
		}
	}

	const getDetails = (type: string) => {
		const membershipDetails: {[key: string]: string} = {
			diamond: `"${t('Exclusive for C-Level executives, Managing Partners, Chairmen, Presidents, and entrepreneurs who lead organizations and drive strategic vision.')}"`,
			gold: `"${t('Designed for senior executives, including directors, vice presidents, and managing directors.')}"`,
			silver: `"${t('Ideal for middle management professionals such as managers, senior managers, and heads of departments.')}"`,
			bronze: `"${t('Perfect for entry-level professionals, freelancers, specialists, individual contributors, and junior managers looking to grow.')}"`
		}

		return membershipDetails[type]
	}
	if (loading) return <Loader />
	return (
		<MainContainer height={isProMember}>
			{isSignup ? '' : <Spacer height={4} />}
			{/* {loading && <Loader />} */}
			<Container fluid style={{flex: 1}}>
				{userSelected ? (
					<FreeBMTMemberShip bmtDetails={userSelected} onClose={() => setUserSelected('')} isSignup={isSignup} />
				) : (
					<Row>
						{!isSignup && (
							<Col lg={12}>
								<Spacer height={'2'} />

								<Flexed direction="column" align={'center'} gap={'0.6'}>
									<Card background={palette.white} padding={'1.2rem'} overflow={'visible'}>
										<>
											<ManageCardComponent direction="column" align={'left'} gap={'0.6'}>
												<Flexed direction="row" align={'center'} gap={'0.6'}>
													<Text fontSize={'1.12'} lineHeight={'1.34'} fontWeight={'600'} color={'text_color'}>
														{t('Active membership')}
													</Text>
												</Flexed>
											</ManageCardComponent>
											<Spacer height={1} />
											<Flexed direction="row" align={'center'} justify={'space-between'} gap={'1.5'} flexWrap={'wrap'}>
												<ManageDimondCardComponent gap={'0.6'} height={'7.75rem'}>
													{userMembership && userMembership?.toLowerCase().includes('diamond') && !isProMember ? (
														<Icons src={'/images/daimond_member_card.svg'} height={'100%'} />
													) : userMembership && userMembership?.toLowerCase().includes('gold') && !isProMember ? (
														<Icons src={'/images/gold_member_card.svg'} height={'100%'} />
													) : userMembership && userMembership?.toLowerCase().includes('silver') && !isProMember ? (
														<Icons src={'/images/silver_member_card.svg'} height={'100%'} />
													) : userMembership && userMembership?.toLowerCase().includes('bronze') && !isProMember ? (
														<Icons src={'/images/bronze_member_card.svg'} height={'100%'} />
													) : isProMember ? (
														<Icons src={isProMember ? '/images/pro_member_card.svg' : '/images/daimond_member_card.svg'} height={'100%'} />
													) : (
														<Flexed justify={'flex-end'} align={'center'} direction={'row'} width={'100%'} height={'100%'}>
															<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'dark_blue'} isCentered>
																{t('No Active membeship found!')}
															</Text>
														</Flexed>
													)}
												</ManageDimondCardComponent>

												<ManageCardComponent gap={'0.6'} height={'7.75rem'}>
													<CustomTopCards marginLeft={'0'} removeShadow radius={'0.6rem'} background={palette.purpole_gradient} minHeight={'auto'} height={'7.75rem'}>
														<NumberCircle left={'4rem'} fontSize={'1'} lineHeight={'1.21'} fontWeight={'800'} color={'blue_500'} isCentered>
															<Button
																small
																borderRadius={'1rem'}
																fontSize={'0.8rem'}
																padding={'8px 16px 8px 16px'}
																label={
																	<>
																		<Icons src={'/images/icon_goldcoin.svg'} /> &nbsp;{t('BUY COINS')}
																	</>
																}
																background={palette.blue_500}
																color={palette.white}
																lineHeight={'0.89rem'}
																ifClicked={() => router.navigate(`/buy-coins?membership='gold'`)}
															/>
														</NumberCircle>
														<Flexed align={'center'} padding={'0 1rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
															<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}></Text>
														</Flexed>
														<Spacer height={1} />

														<Flexed align={'center'} padding={'0.2rem 0.9rem'} gap={0.5} justify={'space-around'} direction={'row'} flexWrap={'wrap'}>
															<Flexed height={'4.25rem'} width={'4.25rem'}>
																<Icons src={'/images/icon_goldcoin.svg'} height={'100%'} />
															</Flexed>
															<Flexed align={'start'} gap={'0.4'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																<Text fontSize={'0.8'} lineHeight={'0.90'} fontWeight={'300'} color={'text_color'} fontStyle={'italic'}>
																	{t('Your Coin Balance')}
																</Text>
																<Text fontSize={'2'} lineHeight={'2.52'} fontWeight={'800'} color={'text_color'}>
																	{_userDetails?.coins}
																</Text>
															</Flexed>
														</Flexed>
													</CustomTopCards>
												</ManageCardComponent>

												<ManageCardComponent gap={'0.6'} height={'7.75rem'}>
													<CustomTopCards marginLeft={'0'} removeShadow radius={'0.6rem'} background={palette.withdraw_coin_box} minHeight={'auto'} height={'7.75rem'}>
														<NumberCircle fontSize={'1'} lineHeight={'1.21'} fontWeight={'800'} color={'blue_500'} isCentered left={'10rem'}>
															<Button
																small
																borderRadius={'1rem'}
																fontSize={'0.8rem'}
																padding={'8px 16px 8px 16px'}
																label={
																	<>
																		<Icons src={'/images/icon_withdraw.svg'} /> &nbsp;{t('WITHDRAW COINS')}
																	</>
																}
																background={palette.blue_500}
																color={palette.white}
																lineHeight={'0.89rem'}
																ifClicked={() => router.navigate('/withdraw-coins')}
															/>
														</NumberCircle>
														<Flexed align={'center'} padding={'0 1rem'} justify={'space-between'} direction={'row'} flexWrap={'wrap'}>
															<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}></Text>
														</Flexed>
														<Spacer height={0.4} />

														<Flexed align={'center'} padding={'0.2rem 0.9rem'} gap={1} justify={'space-around'} direction={'row'} flexWrap={'wrap'}>
															<Flexed height={'72px'} width={'114px'}>
																<div style={{borderRadius: '1rem', overflow: 'hidden'}}>
																	<CustomPlayer
																		url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
																		controls
																		width="100%"
																		height="100%"
																		style={{borderRadius: '1rem'}}
																		config={{
																			file: {
																				attributes: {
																					controlsList: 'nodownload' // Remove download button
																				}
																			}
																		}}
																	/>
																</div>
															</Flexed>
															<Flexed align={'start'} gap={'0.4'} justify={'center'} direction={'column'} flexWrap={'wrap'}>
																<Text fontSize={'0.8'} lineHeight={'0.90'} fontWeight={'300'} color={'text_color'} fontStyle={'italic'}>
																	{t('Withdraw your coins anytime')}
																</Text>
																<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'700'} color={'text_color'}>
																	{t('Watch How')}
																</Text>
																<Text fontSize={'0.8'} lineHeight={'0.90'} fontWeight={'500'} color={'text_color'}>
																	<a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" target="_blank" style={{textDecoration: 'underline'}}>
																		{t('How to Cash-out JustNetwork Coins')}
																	</a>
																</Text>
																<CustomSmallSpace height={0.4} />
															</Flexed>
														</Flexed>
													</CustomTopCards>
												</ManageCardComponent>
											</Flexed>
										</>
										<Spacer height={1} />
									</Card>
									<Spacer height={1} />

									<Card background={palette.white} padding={'1.2rem'} overflow={'visible'}>
										<Col>
											<Row>
												<Col lg={6}>
													<Customflexed width={'100%'} padding={'0 3rem'} smallPadding={'0 1rem'}>
														<Flexed width={'100%'} hight={'100%'}>
															<GradientText fontSize={'4'} whiteSpace="normal" align="start" width="fit-content" style={{lineHeight: '110%'}}>
																Connect & <br />
																Prospect Now
															</GradientText>
														</Flexed>
														<Spacer height={'0.5'} />
														<CustomParagrapht fontSize={'2'} fontWeight={'bold'} lineHeight={'2.5'} color={'input_text_color'} smallFontSize={'1.5rem'}>
															Quick Connect & Unlock the Power of Personalized Prospecting
														</CustomParagrapht>
														<Spacer height={'0.5'} />
														<CustomIcon width={'21.43rem'} smallWidth={'12.16rem'} src={'/images/Marketing-Marketing-target-01.svg'} />
													</Customflexed>
												</Col>
												<Col lg={6}>
													<Customflexed padding={'1rem 2rem'} smallPadding={'1rem'}>
														<Flexed>
															<Paragraph fontSize={'1.25'} lineHeight={'1.5'} color={'input_text_color'}>
																Send an invite to your prospect with an offer to buy their time. We will notify you when they join & set their availability.
															</Paragraph>
															<Spacer height={'0.5'} />
															<Text fontSize={'1'} color={'input_text_color'} lineHeight="1.2" width="fit-content">
																{remainingEmails} mails left today − <span style={{textDecoration: 'underline', cursor: 'pointer'}}>learn more</span>
															</Text>
															<Spacer height={'0.5'} />
														</Flexed>
														<BuyTimeNowForm setRemainingEmails={setRemainingEmails} />
													</Customflexed>
												</Col>
											</Row>
										</Col>
									</Card>
								</Flexed>
								{/* <Spacer height={1} /> */}
							</Col>
						)}
						{!isProMember && (
							<Col lg={12}>
								<Spacer height={2} />
								<Card background={palette.white} padding={'1.2rem'} overflow={'visible'} height={'auto'} minHeight={'auto'}>
									<HandleFlex direction={'row'} align={'left'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
										<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
											{t('FREE Memberships')}
										</Text>
										<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'300'} color={'black'} align={'left'} fontStyle={'italic'}>
											{t("A range of options designed to cater to every professional's needs.")}
										</Text>
									</HandleFlex>
									<Spacer height={'0.5'} />
									<Row alignItems={'center'}>
										{Object.keys(cardOptions).map((type: string) => (
											<Col md={6} lg={3} key={type}>
												<Card padding="0.7rem" background="transparent" removeShadow borderColor={palette.zink_300}>
													<HandleFlex direction={'column'} align={'center'} gap={'0.5'}>
														<Icons width="100%" src={`/images/${type}_member_card.svg`} height={'100%'} />
														<Spacer height={'0.3'} />
														<ManageCardComponent gap={'0.2'} justify="space-around" align="center">
															<Text height="85px" color={'input_text_color'} fontSize={'0.8'} fontWeight="400" lineHeight="14.52px" isCentered>
																{getDetails(type)}
															</Text>
															<Button
																small
																borderRadius={'1rem'}
																hasBorder
																fontSize={'0.8rem'}
																padding={'8px 16px'}
																label={
																	<>
																		{t('View Details')}
																		<FaChevronRight />
																	</>
																}
																background={palette.white}
																color={palette.blue_500}
																lineHeight={'0.89rem'}
																ifClicked={() => {
																	setUserSelected(cardOptions[type])
																}}
															/>
														</ManageCardComponent>
													</HandleFlex>
												</Card>
											</Col>
										))}
									</Row>
								</Card>
								<Spacer height={3} />
							</Col>
						)}
						<Col lg={12}>
							{/* <Spacer height={'1'} /> */}
							<Card background={palette.default_theme} minHeight={'8.81rem'} padding={'1rem'} style={{alignItems: 'center', display: 'flex'}}>
								{isProMember ? (
									<HandleProPlanFlex direction={'row'} justify={'space-between'} width={'100%'}>
										<HandleFlex direction="row" justify={'flex-start'} align={'center'} gap={'0.4'} flexWrap={'wrap'} width={'50%'}>
											<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'white'}>
												{t('Pro Membership Plan Advantages')}
											</Text>
											<Flexed direction={'row'} gap={'0.9'} flexWrap={'wrap'}>
												{proMembershipFeatures?.map((benifits) => (
													<Flexed direction={'row'} padding={'0 0.2rem'} align={'center'} gap={'0.3'}>
														<Icons src={'/images/icon_tick.svg'} />
														<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={'white'}>
															{benifits}
														</Text>
													</Flexed>
												))}

												<Button
													small
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													fontWeight={600}
													padding={'0 1rem'}
													label={
														<>
															<Icons src={'/images/icon_send.svg'} /> &nbsp; {t('SEND COINS')}
														</>
													}
													background={palette.white}
													color={palette.text_color}
													lineHeight={'0.91rem'}
													ifClicked={() => router.navigate(`/send-coins`)}
												/>
											</Flexed>
										</HandleFlex>
										<CustomFlexedForWrap src={'/images/verticalLine.svg'} />

										<HandleFlex direction="column" width={'50%'}>
											<Flexed direction={'row'} justify={'space-between'} padding={'0 0 0rem  2rem'}>
												<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'white'}>
													{t('Active Plan')}
												</Text>
												{!_userDetails?.proMembership[0]?.is_cancelled && (
													<Flexed direction="row" justify={'flex-end'} margin={'0.4rem'} flexWrap={'wrap'}>
														<MenuList type="normal" onSmall>
															<Text onClick={() => setOpenMenu(true)}>
																<Icons pointer={'pointer'} src={'/images/icon_options.svg'} />
															</Text>
															<DropContent ref={menuRef} top={'92%'} display={openMenu ? true : false}>
																{_userDetails.proMembership[0]?.duration !== 'annually' && (
																	<DropMenu onClick={() => router.navigate('/upgrade-bmt-membership')}>
																		<Icons src={'/images/icon_upgrade.svg'} /> {t('Upgrade to Annual')}
																	</DropMenu>
																)}
																{_userDetails.proMembership[0]?.is_cancelled ? (
																	<DropMenu href="#">
																		<Icons src={'/images/icon_cancel.svg'} /> {t('Cancelled')}
																	</DropMenu>
																) : (
																	<DropMenu href="#" onClick={handleCancelMembership}>
																		<Icons src={'/images/icon_cancel.svg'} /> {t('Cancel Membership')}
																	</DropMenu>
																)}
															</DropContent>
														</MenuList>
													</Flexed>
												)}
											</Flexed>

											<HandleFlex direction="row" justify={'start'} align={'end'} gap={'1'} padding={'0 2rem'} width={'100%'} flexWrap={'wrap'}>
												<Flexed direction="column" justify={'space-between'} align={'left'} gap={'1'}>
													<Flexed direction="row" justify={'space-between'} align={'center'} gap={'0.8'} width={'16.87rem'} height={'3rem'} padding={'0 1rem'} background={`rgba(228, 228, 231, 0.1)`} radius={'8px'}>
														<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
															{_userDetails.proMembership[0]?.duration === 'annually' ? t('Yearly Subscription') : t('Monthly Subscription')}
														</Text>
														<Text fontSize={'2'} lineHeight={'2.5'} fontWeight={'700'} color={'white'}>
															${_userDetails.proMembership[0]?.duration === 'annually' ? 86 : 8}
														</Text>
													</Flexed>
												</Flexed>
												<Flexed direction="column" justify={'space-between'} align={'left'} gap={'0.6'}>
													<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'white'} fontStyle={'italic'}>
														{t('Billing Started on')}
													</Text>
													<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'white'}>
														{moment(_userDetails.proMembership[0]?.createdAt).format('DD MMMM, YYYY')}
													</Text>
												</Flexed>
												<Flexed direction="column" justify={'space-between'} align={'left'} gap={'0.6'}>
													<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'white'} fontStyle={'italic'}>
														{_userDetails.proMembership[0].is_cancelled ? t('Expiry Date') : t('Next Billing Date')}
													</Text>
													<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'white'}>
														{_userDetails.proMembership[0]?.duration === 'annually'
															? moment(_userDetails.proMembership[0]?.createdAt).add(1, 'year').format('DD MMMM, YYYY')
															: moment(_userDetails.proMembership[0]?.createdAt).add(1, 'month').format('DD MMMM, YYYY')}
													</Text>
												</Flexed>
											</HandleFlex>
										</HandleFlex>
									</HandleProPlanFlex>
								) : (
									<Flexed direction={'row'} align={'center'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
										<Row>
											<Col sm={12} md={6} lg={9}>
												<HandleFlex banner direction={'row'} align={'center'} gap={'0.3'}>
													<ProBadgeWrapper>
														<ProBadgeCircle></ProBadgeCircle>
														<Icons height={'100%'} width={'100%'} src={'/images/pro_badge.svg'} />
													</ProBadgeWrapper>
													<Spacer height={1} />
													<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
														<Text fontSize={'1.12'} lineHeight={'1.2'} fontWeight={'700'} color={'text_color'}>
															{t('Paid Memberships')}
														</Text>
														<Spacer height={0.5} />
														<Flexed direction={'row'} gap={'0.4'} align={'center'} flexWrap={'wrap'}>
															<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
																{t('Become a')}
															</Text>

															<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'}>
																{t('Pro Member')}
															</Text>
														</Flexed>
														<Spacer height={0.5} />
														<Text banner fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'white'} fontStyle={'italic'}>
															{`${t('Tailored for top entrepreneurs, VCs, influencers, celebrities, Public Figures and similar high-profile individuals, whose time is exceptionally valuable.')}`}
														</Text>
													</HandleFlex>
												</HandleFlex>
											</Col>
											<Col lg={3} md={6} sm={12}>
												<HandleFlex direction="column" justify={'center'} align={'center'} gap={'1'} padding={'1rem'}>
													<Flexed direction="row" justify={'space-between'} align={'center'} gap={'0.8'} width={'16.87rem'} height={'3rem'} padding={'0 1rem'} background={`rgba(228, 228, 231, 0.1)`} radius={'8px'}>
														<Text fontSize={'0.85'} lineHeight={'0.95'} fontWeight={'500'} color={'white'} fontStyle={'italic'} opacity={'1'}>
															{_userDetails?.proMembership && _userDetails?.proMembership[0]?.duration === 'annually' ? t('Yearly Subscription') : t('Monthly Subscription')}
														</Text>
														<Text fontSize={'2'} lineHeight={'2.5'} fontWeight={'700'} color={'white'}>
															${_userDetails?.proMembership && _userDetails?.proMembership[0]?.duration === 'annually' ? 86 : 8}
														</Text>
													</Flexed>
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														fontWeight={600}
														padding={'0 1rem'}
														label={<>{t('UPGRADE NOW')}</>}
														background={palette.white}
														color={palette.text_color}
														lineHeight={'0.91rem'}
														ifClicked={() => {
															router.navigate('/upgrade-bmt-membership')
															localStorage.setItem('upgrade_to_pro', router.pathname + `?id=${router.query?.id}`)
															setIsProMember(true)
														}}
													/>
												</HandleFlex>
											</Col>
										</Row>
										{/* <HandleFlex direction={'row'} align={'center'} gap={'0.5'} flexWrap={'wrap'}>
											<ProBadgeWrapper>
												<ProBadgeCircle></ProBadgeCircle>
												<Icons height={'100%'} width={'100%'} src={'/images/pro_badge.svg'} />
											</ProBadgeWrapper>
											<Spacer height={2} />
											<HandleFlex direction={'column'} align={'left'} justify={'space-between'} flexWrap={'wrap'}>
												<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
													{t('Paid Memberships')}
												</Text>
												<Spacer height={0.5} />
												<Flexed direction={'row'} gap={'0.6'} align={'center'}>
													<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
														{t('Become a')}
													</Text>

													<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
														{t('Pro Member')}
													</Text>
												</Flexed>
												<Spacer height={0.5} />
												<HandleFlex direction="row" justify={'center'} align={'center'} gap={'0.4'} flexWrap={'wrap'}>
													<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'white'} fontStyle={'italic'}>
														{t('"Tailored for top entrepreneurs, VCs, influencers, celebrities, Public Figures and similar high-profile individuals, whose time is exceptionally valuable."')}
													</Text>
												</HandleFlex>
											</HandleFlex>
										</HandleFlex> */}
									</Flexed>
								)}
							</Card>
						</Col>
					</Row>
				)}
			</Container>
			{/* <Spacer height={5} /> */}
			<div style={{alignSelf: '', justifySelf: 'flex-end'}}>
				<Footer />
			</div>
		</MainContainer>
	)
}

const MainContainer = styled.div<any>`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: ${({height}) => (height ? '100vh' : `100%`)}; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;

	${media.xs`
	 height:${({height}: any) => (height ? '100%' : `100%`)};

	 `};
	${media.sm`
	 height:${({height}: any) => (height ? '100vh' : `100%`)};

	 `};
	${media.md`
	 height:${({height}: any) => (height ? '100vh' : `100%`)};

	 `};
	${media.lg`
	 height:${({height}: any) => (height ? '100vh' : `100%`)};

	 `};
	${media.xl`
	 height:${({height}: any) => `100%`};

	 `};
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 70%;
	}
	${media.xs`
	display: content;
	width: 100%
	 `};
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 94px;
	height: 94px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const EditProfileToolTip = styled(Flexed)`
	display: none;
	position: absolute;
	margin-left: 1.3rem;
	top: -28%;
	z-index: 999;
	align-items: center;
	cursor: pointer;
`

const CustomSmallSpace = styled(Spacer)`
	display: none;
	${media.xs`
display: block
	 `};
`

const CustomFlexedForWrap = styled(Icons)`
	display: flex;
	${media.xs`
	display: none;
	 `};
`
export const CustomCards = styled(Card)<any>`
	position: relative;
	bottom: 0;
	margin-left: ${({marginLeft}) => (marginLeft ? marginLeft : `1.8rem`)};
	overflow: visible;
	background: linear-gradient(199.15deg, rgba(255, 3, 169, 0.18) -0.19%, rgba(11, 138, 255, 0.18) 101.1%);
	width: ${({width}) => width};
	padding: ${({padding}) => padding};
	height: ${({height}) => (height ? height : 'auto')};
	${media.xs`
		flex-direction: column;
	 `};
	@media (max-width: 576px) {
		width: 16rem;
	}
`

const CustomTopCards = styled(Card)<any>`
	position: relative;
	bottom: 0;
	margin-left: ${({marginLeft}) => (marginLeft ? marginLeft : `1.8rem`)};
	overflow: visible;
	z-index: 0;

	width: ${({width}) => width};
	height: ${({height}) => (height ? height : 'auto')};
	${media.xs`
	width: 100%;
	 height: 100%;
	 `};
`
const NumberCircle = styled(Text)<any>`
	position: absolute;
	bottom: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	left: ${({left}) => (left ? left : `6rem`)};
	transform: translateY(50%);
	z-index: 1;

	${media.xs`
	left: ${({left}: any) => (left ? '30%' : `6rem`)};
	 `};
`

const CustomPlayer = styled(ReactPlayer)`
	border-radius: 1rem !important;
	> video {
		border-radius: 1rem !important;
	}
`

const HandleFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
	text-align: center;
	align-items: center;
	justify-content:center;
	flex-direction: ${({banner}: any) => banner && 'column'};
	width: 100%;
	`};
`

const HandleProPlanFlex = styled(Flexed)`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	justify-content: ${({justify}) => justify};
	flex-wrap: nowrap;

	${media.xs`
	text-align: center;
	align-items: center;
	justify-content:center;
	flex-wrap: wrap;
	width: 100%;
	flex-direction: column-reverse;
	>div{
		width: 100%;
		>div{
		width: 100%;
	}
	}
	 `};
`

const ManageCardComponent = styled(Flexed)<any>`
	height: ${({height}) => height};
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
	text-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	 `};
`

const ManageDimondCardComponent = styled(Flexed)<any>`
	height: ${({height}) => height};
	width: ${({width}) => (width ? width : 'auto')};
	${media.xs`
	text-align: center;
	align-items: center;
	width: 100%;
	height: 5.5rem;
	 `};
`

const GradientText = styled(Text)`
	color: transparent; /* Set the actual text color to transparent */
	background-image: linear-gradient(to bottom, rgba(136, 0, 200, 1) 0%, rgba(0, 68, 144, 1) 100%);
	-webkit-background-clip: text; /* For Safari and Chrome */
	-webkit-text-fill-color: transparent; /* For Safari and Chrome */
	background-clip: text;
	text-fill-color: transparent;
	font-size: ${({fontSize}) => fontSize};
	line-height: 110%;
	font-weight: bolder;
	${media.xs`
	font-size: 2rem;
	/* line-height: 2.5rem; */
	 `};
	${media.md`
	font-size: 3rem;
	/* line-height: 3.5rem; */
	 `};
`

const CustomParagrapht = styled(Paragraph)<any>`
	font-size: ${({fontSize}) => fontSize};
	${media.xs`
	font-size: 1rem;
	line-height: 1.5rem;
	 `};
	${media.md`
	font-size: ${({smallFontSize}: any) => smallFontSize};
	line-height: auto;
	 `};
`
const CustomIcon = styled(Icons)<any>`
	width: ${({width}) => width};
	${media.xs`
	width: ${({smallWidth}: any) => smallWidth};
	 `};
`

const Customflexed = styled(Flexed)<any>`
	padding: ${({padding}) => padding};
	${media.xs`
padding: ${({smallPadding}: any) => smallPadding};
	 `};
`
export default BMT
