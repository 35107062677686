import React, {useEffect, useRef, useState} from 'react'
import {Flexed, Spacer, Text} from '../styled/shared'
import Loader from './common/Loader'
import {Col, media} from 'styled-bootstrap-grid'
import useRouter from '../hooks/router'
import {Icons, ProfileImg} from './TopNavBar'
import {Card} from '../pages/Dashboard'
import {palette} from '../styled/colors'
import styled from 'styled-components'
import Button from './common/Button'
import {CustomFlexedForMessages, CustomMessageFlex, TrashMesssageIcons} from '../pages/Inbox'
import Input from './common/input/Input'
import moment from 'moment'
import {BiTrash} from 'react-icons/bi'
import {ChatMessageWrapper} from './BottomChatCardPopup'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import EmojiPicker from 'emoji-picker-react'
import {useTranslation} from 'react-i18next'
import UserProfileLoader from './common/UserProfileLoader'
import {useDispatch} from 'react-redux'
import {chatUnreadMessages, chatUserDeleteMessages, chatUserDeleteMessagesBottom, chatUserMessages, chatUserMessagesBottom, chatUsers} from '../actions/authActions'
import {useSelector} from 'react-redux'

const BottomChatModel = ({
	selectedNewChatUsers,
	newMessageUsers,
	setSelectedNewChatUsers,
	selectedUser,
	setSelectedUser,
	index,
	chatFetchedUsers,
	setChatUsers,
	openChatIndex,
	setOpenChatIndex
}: {
	openChatIndex: any
	setOpenChatIndex: any
	selectedNewChatUsers?: any
	newMessageUsers?: any
	setSelectedNewChatUsers?: any
	selectedUser?: any
	setSelectedUser?: any
	index?: any
	setChatUsers?: any
	chatFetchedUsers?: any
}) => {
	const [openChatPopupModel, setOpenChatPopupModel] = useState(false)
	const router = useRouter()
	const chatRef: any = useRef()
	const {t} = useTranslation()
	const [messageLaoding, setMessageLoading] = useState(false)
	const [conversationMessages, setConversationMessages] = useState<any[]>([])
	const [handleComment, setHandleComment] = useState<any>('')
	const [submitting, setSubmitting] = useState(false)
	const [openEmoji, setOpenEmoji] = useState(false)
	const emojiPickerRef: any = useRef(null)
	const menuRef: any = useRef(null)
	const [messagePage, setMessagePage] = useState(1)
	const dispatch = useDispatch()
	const [messageLastId, setMessagesLastId] = useState<any>(1)
	const userMessageBottom: any = useSelector<any>((state: any) => state.auth.userMessageBottom)
	const userMessageDeleteBottom: any = useSelector<any>((state: any) => state.auth.userMessageDeleteBottom)
	useEffect(() => {
		if (selectedUser) getUserConversation(selectedUser?.uid)
		if (index > 1) {
			if (index < 2) {
				setOpenChatPopupModel(true)
			}
		}
		if (index >= 2) {
			setOpenChatIndex(2)
		}
	}, [selectedUser, index])

	useEffect(() => {
		if (userMessageBottom?.id) {
			if (selectedUser?.uid == userMessageBottom?.receiverId) {
				setConversationMessages((prevMessages: any) => [...prevMessages, userMessageBottom])
				let userss = chatFetchedUsers.map((e: any) =>
					e?.conversationWith?.uid == userMessageBottom?.receiverId || e?.conversationWith?.uid == userMessageBottom?.receiver?.uid ? {...e, unreadMessageCount: 0, lastMessage: userMessageBottom} : e
				)
				setChatUsers(userss)
				dispatch(chatUsers(userss))

				scrollToBottom()
			} else {
				let userss = chatFetchedUsers.map((e: any) =>
					e?.conversationWith?.uid == userMessageBottom?.sender?.uid || e?.conversationWith?.uid == userMessageBottom?.receiver?.uid ? {...e, unreadMessageCount: 0, lastMessage: userMessageBottom} : e
				)
				setChatUsers(userss)
				dispatch(chatUsers(userss))
			}
			setTimeout(() => {
				dispatch(chatUserMessagesBottom({}))
			}, 3000)
		}
	}, [userMessageBottom?.id])

	useEffect(() => {
		if (userMessageDeleteBottom?.id) {
			let messages = conversationMessages.filter((e: any) => e.id != userMessageDeleteBottom?.id)
			setConversationMessages(messages)
			dispatch(chatUserDeleteMessagesBottom({}))
		}
	}, [userMessageDeleteBottom?.id])

	const MessageComponent = (message: any) => {
		if (message.hasOwnProperty('url')) {
			// Check for download URL
			const filename = message?.attachments.length ? message?.attachments[0].name : ''
			const downloadUrl = message.url

			return (
				<div className="pdf-message">
					<a href={downloadUrl} target="_blank" download={filename}>
						{filename} (Download)
					</a>
				</div>
			)
		}

		return null
	}

	const groupedMessages = (chatMessages?: any) => {
		return chatMessages?.reduce((grouped: any, message: any) => {
			const date = moment.unix(message.sentAt).format('YYYY-MM-DD')
			if (grouped[date]) {
				grouped[date].push(message)
			} else {
				grouped[date] = [message]
			}

			return grouped
		}, {})
	}

	const sendMessage = async () => {
		try {
			setSubmitting(true)
			let receiverID: any = selectedUser?.uid,
				messageText: string = handleComment,
				receiverType: string = CometChat.RECEIVER_TYPE.USER,
				textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, messageText, receiverType)

			CometChat.sendMessage(textMessage).then(
				(message: any) => {
					setHandleComment('')
					dispatch(chatUserMessages(message))
					let userss = chatFetchedUsers && chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == selectedUser?.uid ? {...e, lastMessage: message} : e))
					setChatUsers && setChatUsers(userss)

					setConversationMessages((prevMessages: any) => [...prevMessages, message])
					scrollToBottom()
					setSubmitting(false)
					setOpenEmoji(false)
				},
				(error: CometChat.CometChatException) => {
					setSubmitting(false)
					console.log('Message sending failed with error:', error)
				}
			)
		} catch (error) {
			setSubmitting(false)
		}
	}

	const scrollToBottom = () => {
		if (chatRef.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}
	}

	const deleteMessage = (messageId?: any) => {
		try {
			CometChat.deleteMessage(messageId).then(
				(message: CometChat.BaseMessage) => {
					let messages = conversationMessages.filter((e: any) => e.id != messageId)
					let _message = conversationMessages.find((e: any) => e.id == messageId)
					dispatch(chatUserDeleteMessages(_message))
					setConversationMessages(messages)

					// getUserConversation(selectedUser?.uid, false)
				},
				(error: CometChat.CometChatException) => {
					console.log('Message delete failed with error:', error)
				}
			)
		} catch (error) {}
	}
	useEffect(() => {
		if (conversationMessages?.length == messagePage * 50 && messageLastId > 1) {
			getUserConversation(selectedUser?.uid, false, messagePage + 1, messageLastId)
		}
	}, [conversationMessages?.length, messagePage, messageLastId])

	const getUserConversation = async (uid?: any, loading = true, messagePage = 1, messageLastId = 1) => {
		try {
			if (uid) {
				setMessageLoading(loading)
				let messagesRequest = new CometChat.MessagesRequestBuilder().setUID(uid).setMessageId(messageLastId).hideDeletedMessages(true).setLimit(50).build()
				messagesRequest.fetchNext().then(
					(messages: any[]) => {
						setMessageLoading(false)
						let lastIndexId = messages[messages.length - 1]?.id ?? 1
						setMessagesLastId(lastIndexId)
						dispatch(chatUserMessages({}))
						if (messagePage > 1) {
							setConversationMessages([...conversationMessages, ...messages])
						} else {
							setConversationMessages(messages)
						}

						scrollToBottom()
					},
					(error) => {
						setMessageLoading(false)
						console.log('Conversations list fetching failed with error:', error)
					}
				)
				setMessagePage(messagePage)
			}
		} catch (error) {
			console.log('Initialization failed with error:', error)
		}
	}

	const handleFileInputChange = (event: any) => {
		const file = event.target.files[0]
		let receiverID: string = selectedUser?.uid,
			messageType: string = file?.type?.startsWith('image') ? CometChat.MESSAGE_TYPE.IMAGE : CometChat.MESSAGE_TYPE.FILE,
			receiverType: string = CometChat.RECEIVER_TYPE.USER,
			mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType)

		CometChat.sendMediaMessage(mediaMessage).then(
			(message: any) => {
				setConversationMessages((prevMessages: any) => [...prevMessages, message])
				dispatch(chatUserMessages(message))
				scrollToBottom()
			},
			(error: CometChat.CometChatException) => {
				console.log('Media message sending failed with error', error)
			}
		)
	}

	const modelStyle = {
		position: 'absolute',
		bottom: 0,
		zIndex: '9999',
		transition: 'bottom 0.3s ease-in-out',
		boxShadow: '0px 0px 10px 0px #0000001a',
		width: openChatPopupModel ? '20rem' : '25rem',
		right: `${(index + 1) * (index > 1 && openChatPopupModel ? 330 : 360)}px` // Adjust the offset value (340px) as per your card width + margin
	}

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenEmoji(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		CometChat.addMessageListener(
			`CHAT_MODEL_MESSAGE_LISTENER`,
			new CometChat.MessageListener({
				onTextMessageReceived: (textMessage: any) => {
					console.log('==========textMessage  bottom chat', textMessage)

					if (selectedUser?.uid == textMessage?.sender?.uid) {
						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == selectedUser?.uid ? {...e, lastMessage: textMessage} : e))
						setChatUsers(userss)

						setConversationMessages((prevMessages: any) => [...prevMessages, textMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length ? totalUnread?.length + 1 : 0))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 1, lastMessage: textMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onMediaMessageReceived: (mediaMessage: any) => {
					if (selectedUser?.uid == mediaMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, mediaMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == mediaMessage?.sender?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length + 1))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == mediaMessage?.sender?.uid ? {...e, unreadMessageCount: 1, lastMessage: mediaMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onCustomMessageReceived: (customMessage: any) => {
					if (selectedUser?.uid == customMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, customMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == customMessage?.sender?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length + 1))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == customMessage?.sender?.uid ? {...e, unreadMessageCount: 1, lastMessage: customMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onMessagesDelivered: (messageReceipt: any) => {
					// if (selectedUser?.uid == messageReceipt?.sender?.uid) {
					// 	setConversationMessages((prevMessages: any) => [...prevMessages, messageReceipt])
					// 	scrollToBottom()
					// }
				},
				onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {},
				onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {},
				onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {},
				onMessageDeleted: (message: any) => {
					if (selectedUser?.uid == message?.sender?.uid) {
						let messages = conversationMessages.filter((e: any) => e.id != message?.id)
						setConversationMessages(messages)
						scrollToBottom()
					}
				},
				onMessagesEdited: (message: CometChat.BaseMessage) => {},
				onInteractiveMessageReceived: (message: any) => {
					console.log('interactive Message received', message)
				},
				onInteractionGoalCompleted: (message: any) => {
					console.log('Message interaction goal completed', message)
				}
			})
		)
	})
	console.log('=====openChatIndex', openChatIndex, index)

	return (
		<div>
			<Col lg={6}>
				<Flexed direction="row" justify={'flex-end'} margin={'0'} style={{borderBottom: 'none'}}>
					<Flexed radius={'1.25rem 1.25rem  0 0'} height={openChatPopupModel ? '4rem' : 'auto'} background={palette.white} padding={'0'} style={modelStyle}>
						<Flexed direction="row" margin={'0'} justify={'space-between'} padding={'1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}}>
							<Flexed
								direction="row"
								align={'center'}
								gap={'1'}
								pointer
								onClick={(e: any) => {
									if (openChatPopupModel) {
										setOpenChatPopupModel(!openChatPopupModel)
									} else {
										router.navigate(`/${selectedUser?.metadata?.user_name}`, {
											// state: {id: selectedUser?.metadata?.id}
										})
									}
								}}>
								<ProfileImgWrapper
									justify="center"
									direction={'row'}
									align={'center'}
									gap={'0.2'}
									onClick={(e: any) => {
										e?.stopPropagation()
										router.navigate(`/${selectedUser?.metadata?.user_name}`, {
											// state: {id: selectedUser?.metadata?.id}
										})
									}}>
									<UserProfileLoader width={'40px'} height={'40px'} imgUrl={selectedUser?.metadata?.image_Url} />
									{selectedUser?.status == 'online' ? <AvailabilityIconWrapper src={'/images/green_circle.svg'} alt="upload" /> : <AvailabilityIconWrapper src={'/images/gray_circle.svg'} alt="upload" />}
								</ProfileImgWrapper>

								<Text
									fontSize={'1.2'}
									lineHeight={'1.26'}
									fontWeight={'600'}
									color={'text_color'}
									pointer
									onClick={() => {
										if (openChatPopupModel) {
											setOpenChatPopupModel(!openChatPopupModel)
										} else {
											router.navigate(`/${selectedUser?.metadata?.user_name}`, {
												// state: {id: selectedUser?.metadata?.id}
											})
										}
									}}>
									{selectedUser?.name}
								</Text>
							</Flexed>
							<Flexed direction="row" align={'center'} gap={'0.5'}>
								{openChatPopupModel ? (
									<i className="fa fa-expand" style={{cursor: 'pointer'}} onClick={() => setOpenChatPopupModel(!openChatPopupModel)} />
								) : (
									<i className="fa fa-compress" style={{cursor: 'pointer'}} onClick={() => setOpenChatPopupModel(!openChatPopupModel)} />
								)}

								<Icons
									src={'/images/icon_close.svg'}
									alt="i"
									pointer={'pointer'}
									onClick={() => {
										setSelectedUser('')
									}}
								/>
							</Flexed>
						</Flexed>

						<Spacer height={1} />

						<Card background={'transparent'} removeShadow minHeight={messageLaoding ? '10rem' : 'auto'} height={'100%'} overflow={'hidden'} display={!selectedUser?.uid && 'flex'} style={{position: 'relative', zIndex: '9999'}}>
							{selectedUser ? (
								messageLaoding ? (
									<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'}>
										<Loader isComponent={true} isBottomChat={true} />
									</Flexed>
								) : (
									<ChatMessageWrapper height={'20vw'} style={{overflow: 'hidden', overflowY: 'scroll'}} gap={'1'} ref={chatRef}>
										{Object.entries(groupedMessages(conversationMessages?.filter((e: any) => e?.action != 'deleted'))).map(([date, messages]: any) => (
											<Flexed key={date} direction="column" padding={'10px 12px'}>
												<Flexed direction={'row'} align={'center'} justify={'center'} width={'auto'}>
													<Flexed background={palette.primary_50} direction={'row'} padding={'10px 16px 10px 16px'} radius={'19px'} align={'center'} justify={'center'} width={'auto'}>
														<Text fontSize={'0.7'} lineHeight={'0.82'} fontWeight={'400'} color={'black'} isCentered>
															{moment(date).format('dddd, MMMM Do')}
														</Text>
													</Flexed>
												</Flexed>
												<Spacer height={1} />
												{messages.map((message: any, index: any) => (
													<Flexed key={index + 'a'} direction="row" align="center" justify={message?.receiver?.uid == selectedUser?.uid ? 'flex-end' : 'flex-start'} padding={'10px 0'} radius={'0.8rem'} pointer>
														<Spacer height={0.5} />
														<Flexed direction="row" align="start" gap={0.5}>
															{message?.sender?.uid == selectedUser?.uid ? (
																<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
																	<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={message?.sender?.avatar} />
																</ProfileImgWrapper>
															) : (
																''
															)}

															<CustomMessageFlex direction="row" gap={'0.2'}>
																<Flexed direction="column">
																	<Flexed
																		direction="column"
																		align="start"
																		width={'auto'}
																		padding={message?.receiver?.uid == selectedUser?.uid ? '10px 15px 10px 20px' : '10px 15px 10px 20px'}
																		radius={message?.receiver?.uid == selectedUser?.uid ? '20px 20px 20px 0px' : '20px 20px 0px 20px'}
																		onHover={true}
																		background={message?.data?.type == 'file' || message?.data?.url ? '' : message?.receiver?.uid == selectedUser?.uid ? palette.reply_bg : palette.blue_500}>
																		<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={message?.receiver?.uid == selectedUser?.uid ? 'black' : 'white'} wordBreak>
																			{message?.data?.type == 'file' || (message?.data?.attachments?.length && message?.data?.attachments[0]?.extension == 'pdf') ? (
																				MessageComponent(message?.data)
																			) : message?.data?.url && !message?.data?.url?.endsWith('.pdf') ? (
																				<Flexed direction={'row'}>
																					<img width={'100%'} src={message?.data?.url} />
																				</Flexed>
																			) : (
																				message.text
																			)}
																		</Text>
																	</Flexed>
																	<Spacer height={0.2} />

																	<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} align={message.type === 'reply' ? 'left' : 'right'}>
																		{/* {moment(message.createdAt).format('hh:mm A')} */}
																		{moment.unix(message.sentAt).format('hh:mm A')}
																	</Text>
																</Flexed>

																<Flexed>
																	<TrashMesssageIcons>{message?.receiver?.uid == selectedUser?.uid && <BiTrash onClick={() => message?.id && deleteMessage(message?.id)} />}</TrashMesssageIcons>
																</Flexed>
															</CustomMessageFlex>
														</Flexed>
													</Flexed>
												))}
											</Flexed>
										))}
									</ChatMessageWrapper>
								)
							) : (
								''
							)}

							{selectedNewChatUsers?.length || selectedUser ? (
								<form
									onSubmit={(e) => {
										e.preventDefault()
										e.stopPropagation()
										if (selectedUser || selectedNewChatUsers?.length) {
											handleComment?.trim()?.length && sendMessage()
										}
									}}>
									<CustomFlexedForMessages padding={'0.5rem 2rem 1rem 0rem '} gap={'1'}>
										<Input label={t('Message')} isSignup isContact height={'70.4px'} value={handleComment} onChange={(e: any) => setHandleComment(e?.value)} />
										<Flexed direction={'row'} align={'center'} justify={'space-between'}>
											<Flexed direction={'row'} align={'center'} gap={'0.5'}>
												<label htmlFor="imageInputBottom" style={{marginBottom: '0rem'}}>
													<Icons src={'/images/icon_image.svg'} pointer={'pointer'} />
												</label>
												<label htmlFor="attachmentInputBottom" style={{marginBottom: '0rem'}}>
													<Icons src={'/images/icon_link.svg'} pointer={'pointer'} />
												</label>

												<EmojiPickerWrapper>
													<EmojiButton
														onClick={(e) => {
															setOpenEmoji(!openEmoji)
														}}
														ref={menuRef}>
														<Icons src={'/images/icon_smile.svg'} alt="i" pointer />
													</EmojiButton>
													{openEmoji && (
														<PickerContainer ref={emojiPickerRef}>
															<EmojiPicker
																onEmojiClick={(e) => {
																	setHandleComment(handleComment + e?.emoji)
																}}
															/>
														</PickerContainer>
													)}
												</EmojiPickerWrapper>
												<input id="imageInputBottom" type="file" accept="image/*" style={{display: 'none'}} onChange={handleFileInputChange} />

												<input id="attachmentInputBottom" type="file" style={{display: 'none'}} onChange={handleFileInputChange} />
											</Flexed>
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												fontWeight={600}
												padding={'0 1rem'}
												label={<>{t('SEND')}</>}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.91rem'}
												disabled={submitting}
												// ifClicked={() => handleComment?.trim()?.length && sendMessage()}
											/>
										</Flexed>
									</CustomFlexedForMessages>
								</form>
							) : (
								''
							)}
						</Card>
					</Flexed>
				</Flexed>
			</Col>
		</div>
	)
}

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`

const AvailabilityIconWrapper = styled(Icons)<any>`
	position: absolute;
	bottom: 0;
	margin-left: 1.8rem;
`

const EmojiPickerWrapper = styled.div`
	position: relative;
`

const EmojiButton = styled.div`
	background: transparent;
	border: none;
	cursor: pointer;
`

const PickerContainer = styled.div`
	position: absolute;
	bottom: 5rem;
	right: 0;
	z-index: 9999;
	${media.xs`
	left: -5rem;
bottom: 6rem;
	 `};
	${media.sm`
	left: -5rem;
bottom: 6rem;
	 `};
	${media.md`
	left:50%;
bottom: 6rem;
	 `};
	${media.lg`
    left:-100%;
bottom: 6rem;
	 `};
`
export default BottomChatModel
