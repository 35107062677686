import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Flexed, Spacer, Text} from '../styled/shared'
import useRouter from '../hooks/router'
import {useTranslation} from 'react-i18next'
import {Card} from '../pages/Dashboard'
import Button from './common/Button'
import {FaArrowLeft} from 'react-icons/fa'

import {useEffect, useState} from 'react'
import {api} from '../helpers/auth-axios'
import {useDispatch} from 'react-redux'
import {callAutoLogin} from '../actions/authActions'
import Footer from './Footer'

const SuccessPaymentMessage = () => {
	const router = useRouter()
	const {t} = useTranslation()
	const [status, setStatus] = useState('')
	const [amount, setAmount] = useState<any>(0)
	const redirect = sessionStorage.getItem('redirect_url')
	const dispatch = useDispatch()

	useEffect(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const sessionId = urlParams.get('session_id')
		const amount = urlParams.get('amount')
		const coins = urlParams.get('coins')

		setAmount(amount)
		api.get(`/session-status?session_id=${sessionId}`).then((res: any) => {
			if (res.data.status === 'complete') {
				buyCoins(coins, res.data.transactionId, amount)
				setStatus(res.data.status)
			}
		})
	}, [])

	const buyCoins = async (coins: any, transactionId: any, amount: any) => {
		try {
			const response = await api.post('/user/purchase_coins', {
				coins,
				transactionId,
				amount
			})
			if (response?.data) {
				dispatch(callAutoLogin(true))
				const redirect = sessionStorage.getItem('redirect_url')
				if (redirect) {
					// Navigate to the stored path
					router.navigate(redirect)
				} else {
				}
			}
		} catch (error: any) {
			console.log(error.message)
		}
	}

	if (status === 'complete') {
		return (
			<section id="success">
				<Spacer height="6" />
				<Flexed align="center" text-align="center" direction="column" gap={1}>
					<SuccessTextPayment fontSize={1.5} lineHeight={'2.12'} fontWeight={600} fontStyle={'italic'}>
						{t('Thank You')}
					</SuccessTextPayment>

					<SuccessImg src="/images/thankyou.png" alt="Success" />
					<>
						<DimondText>{t('For your purchase')}</DimondText>

						<Card marginLeft={'0'} removeShadow radius={'0.6rem'} background={palette.purpole_gradient} width={'16.62rem'} minHeight={'auto'} height={'6.25rem'}>
							<Flexed justify={'center'} align={'center'} direction="row" height={'100%'}>
								<Text fontSize={'2'} lineHeight={'2.54'} fontWeight={'800'} color={'dark_blue'} isCentered>
									${amount}
								</Text>
							</Flexed>
						</Card>
					</>

					<Button
						small
						hasBorder
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={
							<>
								<FaArrowLeft />
								&nbsp; {t('BACK TO BMT')}
							</>
						}
						background={palette.white}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => router.navigate('/buy-my-time')}
					/>
					{redirect ? (
						<Button
							small
							hasBorder
							borderRadius={'1rem'}
							fontSize={'0.8rem'}
							padding={'8px 16px 8px 16px'}
							label={
								<>
									<FaArrowLeft />
									&nbsp; {t('Go to Meeting')}
								</>
							}
							background={palette.white}
							color={palette.blue_500}
							lineHeight={'0.89rem'}
							ifClicked={() => router.navigate(redirect)}
						/>
					) : (
						<></>
					)}
				</Flexed>
				<Footer />
			</section>
		)
	}

	return <></>
}

export default SuccessPaymentMessage

const SuccessTextPayment = styled(Text)`
	color: ${palette.green};
`
const SuccessImg = styled.img`
	width: 13.063rem;
	height: 15.625rem;
`
const DimondText = styled(Text)`
	color: ${palette.dark_blue};
	font-weight: 700;
	font-size: 1.125;
	line-height: 1.361rem;
`
