import React, {useEffect, useRef, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {Icons} from '../components/TopNavBar'
import Button from '../components/common/Button'
import BeforeMemberShipFlowModal from '../components/modals/BeforeMemberShipFlow'
import useRouter from '../hooks/router'
import {api} from '../helpers/auth-axios'
import Loader from '../components/common/Loader'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {FaChevronRight} from 'react-icons/fa'
import {Card} from './BMT'
import ProSubscriptionCheckout from '../components/ProSubscriptionCheckout'
import PaidMembership from '../components/PaidMembership'
import FreeBMTMemberShip from '../components/bmt/FreeBMTMemberShip'
import Footer from '../components/Footer'

const UserProfileAfterSignup: React.FC = () => {
	const router = useRouter()
	const menuRef = useRef<HTMLDivElement | null>(null)
	const [openMenu, setOpenMenu] = useState(false)
	const [userData, setUserData] = useState<any>()

	const [btmlSuccess, setBtmSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation()
	const [selectedMembership, setSelectedMembership] = useState<any>([])
	const [clickUpgrade, setClickUpgrade] = useState(false)
	const [cardOptions, setCardOptions] = useState<any>({})
	const contactFormRef: any = useRef(null)

	useEffect(() => {
		if (contactFormRef?.current) {
			contactFormRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [router.location.state, selectedMembership?.length])

	const getAllMemberships = async () => {
		setLoading(true)
		try {
			const res = await api.get('/user/memberships')
			if (res?.data) {
				const filteredMemberships = res.data.data.filter((membership: any) => membership.type !== 'pro')
				const groupedMemberships = filteredMemberships.reduce((acc: any, membership: any) => {
					if (!acc[membership.type]) {
						acc[membership.type] = []
					}
					acc[membership.type].push(membership)
					return acc
				}, {})

				const order = {
					diamond: 1,
					gold: 2,
					silver: 3,
					bronze: 4
				}
				const sortedGroupedMemberships: any = {}
				Object.keys(order).forEach((key) => {
					if (groupedMemberships[key]) {
						sortedGroupedMemberships[key] = groupedMemberships[key]
					}
				})

				setCardOptions(sortedGroupedMemberships)
			}
		} catch (error: any) {
			toast.error(error?.message)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getAllMemberships()
	}, [])

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setOpenMenu(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [menuRef])

	useEffect(() => {
		if (userData?.is_membership) {
			router.navigate('/dashboard')
		}
	}, [userData])

	const getDetails = (type: string) => {
		const membershipDetails: {[key: string]: string} = {
			diamond: `"${t('Exclusive for C-Level executives, Managing Partners, Chairmen, Presidents, and entrepreneurs who lead organizations and drive strategic vision.')}"`,
			gold: `"${t('Designed for senior executives, including directors, vice presidents, and managing directors.')}"`,
			silver: `"${t('Ideal for middle management professionals such as managers, senior managers, and heads of departments.')}"`,
			bronze: `"${t('Perfect for entry-level professionals, freelancers, specialists, individual contributors, and junior managers looking to grow.')}"`
		}

		return membershipDetails[type]
	}

	return (
		<MainContainer ref={contactFormRef}>
			{loading && <Loader />}

			<Spacer height={btmlSuccess ? 3 : 4} />
			<Container fluid>
				{selectedMembership?.length ? (
					<FreeBMTMemberShip
						bmtDetails={selectedMembership}
						onClose={() => {
							setSelectedMembership('')
						}}
					/>
				) : clickUpgrade ? (
					<ProSubscriptionCheckout onClose={() => setClickUpgrade(false)} />
				) : (
					<>
						<Row justifyContent="center">
							<Col md={7} sm={12}>
								<HandleFlex justify="center" align="center">
									<Icons src={`/images/beforeSignup.svg`} height={'100%'} />
									<Text fontSize="1.5" lineHeight={'1.4'} fontWeight="700" isCentered>
										{t("Transform your professional networking into profitable venture with JustNetwork's unique BMT feature.")}
									</Text>
									<Spacer height={0.3} />

									<Paragraph fontSize="1">{t('Choose one of the Free membership according to your requirement to unlock the BMT features')}</Paragraph>
									<Spacer height={0.3} />
								</HandleFlex>
							</Col>
						</Row>
						<Spacer height={2} />

						<Card background={palette.white} padding={'1.2rem'} overflow={'visible'} height={'auto'} minHeight={'auto'}>
							<HandleFlex direction={'row'} align={'left'} justify={'space-between'} width={'100%'} flexWrap={'wrap'}>
								<Text fontSize={'1.12'} lineHeight={'1.30'} fontWeight={'700'} color={'text_color'}>
									{t('FREE Memberships')}
								</Text>
								<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'300'} color={'black'} align={'left'} fontStyle={'italic'}>
									{t("A range of options designed to cater to every professional's needs.")}
								</Text>
							</HandleFlex>
							<Spacer height={'0.5'} />
							<Row alignItems={'center'}>
								{Object.keys(cardOptions).map((type: string) => (
									<Col md={6} lg={3} key={type}>
										<Card padding="0.7rem" background="transparent" removeShadow borderColor={palette.zink_300}>
											<HandleFlex direction={'column'} align={'center'} gap={'0.5'}>
												<Icons width="100%" src={`/images/${type}_member_card.svg`} height={'100%'} />
												<Spacer height={'0.3'} />
												<ManageCardComponent gap={'0.2'} justify="space-around" align="center">
													<Text height="85px" color={'input_text_color'} fontSize={'0.8'} fontWeight="400" lineHeight="14.52px" isCentered>
														{getDetails(type)}
													</Text>
													<Button
														small
														borderRadius={'1rem'}
														hasBorder
														fontSize={'0.8rem'}
														padding={'8px 16px'}
														label={
															<>
																{t('View Details')}
																<FaChevronRight />
															</>
														}
														background={palette.white}
														color={palette.blue_500}
														lineHeight={'0.89rem'}
														ifClicked={() => {
															setSelectedMembership(cardOptions[type])
														}}
													/>
												</ManageCardComponent>
											</HandleFlex>
										</Card>
									</Col>
								))}
							</Row>
						</Card>
						<Spacer height={2} />

						<PaidMembership clickUpgrade={() => setClickUpgrade(true)} />
					</>
				)}
			</Container>
			<BeforeMemberShipFlowModal isOpen={false} onClose={() => {}} />

			{/* <Spacer height={4} /> */}
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 0;
`

const HandleFlex = styled(Flexed)<{align?: string; width?: string; height?: string; justify?: string}>`
	text-align: ${({align}) => align};
	width: ${({width}) => (width ? width : 'auto')};
	height: ${({height}) => (height ? height : 'auto')};
	justify-content: ${({justify}) => justify};
	${media.xs`
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
	margin-top: 10px;
  `};
`

const ManageCardComponent = styled(Flexed)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	${media.xs`
        text-align: center;
        align-items: center;
        width: 100%;
    `};
`

export default UserProfileAfterSignup
