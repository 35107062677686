import React from 'react'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {CustomSmallScreenSpacer} from './netwrokComponents/RescheduleRequest'
import useRouter from '../hooks/router'
import {useTranslation} from 'react-i18next'
import {Dot} from '../pages/SignUp'

const Footer = () => {
	const router: any = useRouter()
	let check = router.pathname != '/'
	const {t} = useTranslation()
	const handleNavigateNewTab = (url: any) => {
		window.open(url, '_blank') // Opens in a new tab
	}
	return (
		<Container fluid>
			<Row>
				<Col lg={12}>
					{check ? <Spacer height={2} /> : ''}
					<Flexed direction={'row'} align={'center'} flexWrap={'wrap'}>
						<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'500'} align={'left !important'} color={palette.input_text_color}>
							JustNetwork LLC. @{new Date().getFullYear()} - Delaware USA
						</Text>
						{router.pathname == '/' ? (
							<>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<Flexed direction={'row'} align={'center'} gap={'0.3'}>
									<Text fontSize={'0.72'} pointer lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'} title={t('Terms & Conditions')} onClick={() => handleNavigateNewTab('/terms-conditions')}>
										{t('Terms & Conditions')}
									</Text>
									<Dot bg={palette.input_text_color} width={'4px'} height={'4px'} />
									<Text fontSize={'0.72'} pointer lineHeight={'1.25'} fontWeight={'400'} color={'input_text_color'} title={t('Privacy Policy')} onClick={() => handleNavigateNewTab('/privacy-policy')}>
										{t('Privacy Policy')}
									</Text>
								</Flexed>
							</>
						) : (
							''
						)}
					</Flexed>
					<Spacer height={1} />
					<CustomSmallScreenSpacer height={4} />
				</Col>
			</Row>
		</Container>
	)
}

export default Footer
