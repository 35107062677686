//new ui
import React from 'react'
import styled, {createGlobalStyle, css, keyframes} from 'styled-components'
import {palette} from '../../styled/colors'
import {Text} from '../../styled/shared'
// import { useSelector } from 'react-redux';

const Button = ({
	ifClicked,
	disabled,
	textTransformation,
	label,
	icon,
	type,
	stretchMobile,
	stretchWeb,
	hasShadow,
	hasBorder,
	medium,
	small,
	margin,
	width,
	tooltip,
	color,
	fontWeight,
	borderRadius,
	fontSize,
	lineHeight,
	padding,
	background,
	height,
	minWidth,
	border,
	isBMT,
	backgroundImage
}: any) => {
	// const isDarkTheme = useSelector((state) => state.ui.isDarkTheme);
	return (
		<>
			<GlobalStyle />
			<Btn
				type={type}
				backgroundImage={backgroundImage}
				color={color}
				background={background}
				hasLabel={label}
				minWidth={minWidth}
				borderRadius={borderRadius}
				disabled={disabled}
				onClick={ifClicked}
				isBMT={isBMT}
				medium={medium}
				height={height}
				small={small}
				hasBorder={hasBorder}
				margin={margin}
				width={width}
				stretchMobile={stretchMobile}
				stretchWeb={stretchWeb}
				textTransformation={textTransformation}
				fontWeight={fontWeight}
				fontSize={fontSize}
				lineHeight={lineHeight}
				padding={padding}
				border={border}
				hasShadow={hasShadow}>
				{icon && <Icon color={color} hasLabel={label} src={`/images/icons/${icon}`} />}
				{isBMT ? (
					<CustomText
						type={type}
						color={color}
						background={background}
						hasLabel={label}
						minWidth={minWidth}
						borderRadius={borderRadius}
						disabled={disabled}
						onClick={ifClicked}
						isBMT={isBMT}
						medium={medium}
						height={height}
						small={small}
						hasBorder={hasBorder}
						margin={margin}
						width={width}
						stretchMobile={stretchMobile}
						stretchWeb={stretchWeb}
						textTransformation={textTransformation}
						fontWeight={fontWeight}
						fontSize={fontSize}
						lineHeight={lineHeight}
						padding={padding}
						border={border}
						hasShadow={hasShadow}>
						{label}
					</CustomText>
				) : (
					label
				)}
			</Btn>
		</>
	)
}

const handleColorType = (type: any) => {
	switch (type) {
		case 'clear':
			return palette.text_color
		default:
			return palette.white
	}
}

const handleBackgroundType = (type: any, index: any) => {
	switch (type) {
		case 'clear':
			return palette.white
		default:
			return palette.blue
	}
}

const handleBorderColor = (type: any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'dark_grey_border':
			return palette.silver

		case 'danger':
			return palette.danger_1

		case 'success':
			return palette.success

		default:
			return palette.blue
	}
}

const handleHoverBackgroundType = (type: any) => {
	switch (type) {
		case 'clear':
			return palette.white
		default:
			return palette.blue
	}
}
// Button Size
//             fontSize              		Hight
// Large	14px || 0.875rem	.		50px || 3.125rem
// Medium	14px || 0.875rem	.		40px || 2.5rem
// Small	12px || 0.75rem		.		32px || 2rem

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

const GlobalStyle = createGlobalStyle`
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`

const CustomText = styled(Text)<any>`
	transition: 'transform 0.3s';
	display: inline-block;
	font-size: ${({small, fontSize}) => (fontSize ? fontSize : small ? '0.75rem' : '0.875rem')};
	color: ${({hasBorder, type, color}) => (color ? color : hasBorder ? palette[type] : handleColorType(type))};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
	cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
	line-height: ${({lineHeight}) => (lineHeight ? lineHeight : '32px')};
	opacity: ${({type}) => (type === 'disable' ? '50%' : '')};
	white-space: nowrap;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	${({isBMT}) =>
		isBMT &&
		css`
			animation: ${scaleAnimation} 2s infinite;
		`}
`

const Btn = styled.button<any>`
	display: inline-block;
	opacity: ${({disabled}) => (disabled ? '0.3' : '1')};
	border: ${({hasBorder, type, border}) => (border ? border : hasBorder ? `0.063rem solid ${handleBorderColor(type)}` : 'none')};
	border-radius: ${({borderRadius, type}) => (borderRadius ? borderRadius : '0.25rem')};
	padding: ${({padding}) => (padding ? padding : '0.65rem 1rem')};
	font-size: ${({small, fontSize}) => (fontSize ? fontSize : small ? '0.75rem' : '0.875rem')};
	height: ${({small, medium, height}) => (height ? height : small ? '2rem' : medium ? '2.5rem' : '3.125rem')};
	color: ${({hasBorder, type, color}) => (color ? color : hasBorder ? palette[type] : handleColorType(type))};
	background: ${({type, hasBorder, background, backgroundImage}) =>
		backgroundImage ? '' : background ? background : hasBorder ? `transparent` : `linear-gradient(180deg,${handleBackgroundType(type, 1)} 0%, ${handleBackgroundType(type, 2)} 100%)`};
	margin: ${({margin}) => `${margin}`};
	background-image: ${({backgroundImage}) => (backgroundImage ? `url(${backgroundImage})` : '')};
	background-position: center;
	background-repeat: no-repeat;
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
	opacity: ${({type}) => (type === 'disable' ? '50%' : '')};
	cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
	min-width: ${({minWidth}) => (minWidth ? minWidth : '6rem')};
	width: ${({width}) => (width ? width : 'min-content')};
	line-height: ${({lineHeight}) => (lineHeight ? lineHeight : '32px')};
	white-space: nowrap;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all ease 0.25s;
	// &:hover {
	// 	background: ${({type, disabled}) => (disabled ? '' : `linear-gradient(180deg,${handleHoverBackgroundType(type)} 40%, ${handleHoverBackgroundType(type)} 100%)`)};
	// }
	// &:hover > div {
	// 	visibility: visible !important;
	// }
`

const Icon = styled.img<any>`
	margin-right: ${({hasLabel}) => (hasLabel ? ` 0.5rem` : 'none')};
`

Btn.defaultProps = {
	type: 'primary',
	ifClicked: () => null
}

export default Button
