import {Container} from 'styled-bootstrap-grid'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import styled from 'styled-components'
import useRouter from '../hooks/router'
import {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
	const router = useRouter()
	const contactFormRef: any = useRef(null)
	const {t} = useTranslation()

	useEffect(() => {
		if (router.location.state == 'top' && contactFormRef?.current) {
			contactFormRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [router.location.state])
	return (
		<MainContainer ref={contactFormRef}>
			<Spacer height="4" />

			<Container fluid>
				<Card>
					<Text isCentered fontSize={'2'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						{'JustNetwork Privacy Policy'}
					</Text>
				</Card>

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						{'1. Introduction'}
					</Text>
					<InfoDetails>
						{t('Welcome to')} <b>JustNetwork</b>. {t('We are committed to protecting your privacy and ensuring the security of your personal data. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website and use our services.')}
					</InfoDetails>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						2. {t('Information We Collect')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('Personal Information')}:</InfoCategory>
							<InfoDetails>{t('Name, email address, phone number, job title, company name, and other details provided during registration or use of our services.')}</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Payment Information')}:</InfoCategory>
							<InfoDetails>{t('Billing details, transaction history, and payment method')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Usage Data')}:</InfoCategory>
							<InfoDetails>{t('Information about how you interact with our website, such as IP address, browser type, pages visited, and time spent on the site')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Cookies and Tracking Technologies')}:</InfoCategory>
							<InfoDetails>{t('Information collected through cookies, web beacons, and other tracking technologies to enhance user experience')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						3. {t('How We Use Your Information')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('To Provide Services')}:</InfoCategory>
							<InfoDetails>{t('To facilitate your use of our marketplace, including account management, transaction processing, and customer support')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Communication')}:</InfoCategory>
							<InfoDetails>{t('To send you updates, promotional materials, and other information related to our services')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Improvement')}:</InfoCategory>
							<InfoDetails>{t('To analyze usage patterns and improve our website and services')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Compliance')}:</InfoCategory>
							<InfoDetails>{t('To comply with legal obligations and protect our rights and interests')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						4. {t('Sharing Your Information')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('With Service Providers')}:</InfoCategory>
							<InfoDetails>{t('We may share your information with third-party service providers who assist us in operating our website and delivering our services')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Business Partners')}:</InfoCategory>
							<InfoDetails>{t('With your consent, we may share your information with business partners to offer you additional services')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Legal Requirements')}:</InfoCategory>
							<InfoDetails>{t('We may disclose your information if required by law or in response to valid requests by public authorities')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						5. {t('Data Security')}
					</Text>

					<InfoDetails>{t('We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security')}.</InfoDetails>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						6. {t('Your Data Protection Rights')}
					</Text>
					<ul>
						<li>
							<InfoCategory>{t('Access')}:</InfoCategory>
							<InfoDetails>{t('You have the right to request copies of your personal data')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Rectification')}:</InfoCategory>
							<InfoDetails>{t('You have the right to request correction of inaccurate or incomplete data')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Erasure')}:</InfoCategory>
							<InfoDetails>{t('You have the right to request deletion of your personal data under certain conditions')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Restriction')}:</InfoCategory>
							<InfoDetails>{t('You have the right to request restriction of processing your data under certain conditions')}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>{t('Objection')}:</InfoCategory>
							<InfoDetails>{t('You have the right to object to our processing of your personal data under certain conditions')}.</InfoDetails>
						</li>

						<li>
							<InfoCategory>{t('Portability')}:</InfoCategory>
							<InfoDetails>{t('You have the right to request transfer of your data to another organization or directly to you under certain conditions')}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						7. {t('Data Usage and Privacy')}
					</Text>
					<ul>
						<li>
							<InfoCategory>*{t('Personal Information')}:*</InfoCategory>
							<InfoDetails>{t(`JustNetwork collects and processes personal information in accordance with our Privacy Policy. Users' data is used solely to facilitate the services provided by JustNetwork`)}.</InfoDetails>
						</li>
						<li>
							<InfoCategory>*{t('Data Security')}:*</InfoCategory>
							<InfoDetails>{t(` JustNetwork implements appropriate security measures to protect users' data from unauthorized access, disclosure, or misuse`)}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						8. {t('User Accountability')}
					</Text>
					<ul>
						<li>
							<InfoCategory>*{t('User Conduct')}:*</InfoCategory>
							<InfoDetails>
								{t(`Users are responsible for their conduct on JustNetwork and must ensure their actions are legal and ethical. JustNetwork disclaims any responsibility for users' actions that violate laws or regulations`)}.
							</InfoDetails>
						</li>
						<li>
							<InfoCategory>*{t('Reporting Violations')}:*</InfoCategory>
							<InfoDetails>{t(`Users are encouraged to report any suspected misuse or violations of these terms to JustNetwork for investigation.`)}.</InfoDetails>
						</li>
					</ul>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						9. {t('Misuse Disclaimer')}:
					</Text>
					<InfoDetails>
						{t(
							`"Users are solely responsible for their interactions and transactions on JustNetwork. JustNetwork does not endorse or take responsibility for any improper use of the platform, including but not limited to activities that may violate procurement policies or anti-bribery laws. Users agree to indemnify and hold JustNetwork harmless from any claims arising from their misuse of the platform."`
						)}
					</InfoDetails>
				</Flexed>
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						10. {t('Cookies and Tracking Technologies')}
					</Text>
					<InfoDetails>{t('We use cookies to enhance your experience on our site. You can control the use of cookies through your browser settings. For more details, please refer to our Cookie Policy.')}</InfoDetails>
				</Flexed>
				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						11. {t('Third-Party links')}
					</Text>
					<InfoDetails>{t('Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these sites. We encourage you to read the privacy policies of any third-party sites you visit.')}</InfoDetails>
				</Flexed>

				<Spacer height="1" />

				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						12. {t('Changes to This Privacy Policy')}
					</Text>
					<InfoDetails>{t('We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.')}</InfoDetails>
				</Flexed>
				<Spacer height="1" />
				<Flexed>
					<Text fontSize={'1.5'} lineHeight={'1.7'} fontWeight={'700'} color={'text_color'}>
						13. {t('Contact us')}
					</Text>
					<InfoDetails>
						{t('If you have any questions about this Privacy Policy, please contact us at:')}
						<br />
						<b>{t('Email')}: support@justnetwork.tech</b>
					</InfoDetails>
				</Flexed>
			</Container>
			<Spacer height={1} />
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	/* background-image: url('/images/auth-bg.png'); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	padding: 1rem;
`

const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 10rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background-image: url('/images/auth-bg.png');
`

const InfoCategory = styled.h3`
	font-size: 1.2rem;
	font-weight: 700;
	margin-top: 1rem;
`

const InfoDetails = styled.p`
	font-size: 1rem;
	line-height: 1.6;
	margin-left: 1rem;
`

export default PrivacyPolicy
