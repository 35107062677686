import React from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {CustomSpacer} from './SignUp'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import UpdatePasswordForm from '../components/UpdatePasswordForm'
import Footer from '../components/Footer'

interface IProps {
	isDarkTheme?: boolean
}

const UpdateUserPassword = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()

	return (
		<MainContainer>
			<Container fluid style={{flex: 1}}>
				<Row justifyContent="center">
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<Spacer height={2} />
						<CustomSpacer height={2} />
						<UpdatePasswordForm />
					</Col>
				</Row>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100vh;
	padding: 1rem;
	display: flex;
	flex-direction: column;
`
export const BuyMyTime = styled(Text)`
	background-clip: text;
	background-image: ${palette.default_theme};
	color: transparent;
`

export default UpdateUserPassword
