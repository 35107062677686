import React, {useEffect, useState} from 'react'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Icons, ProfileImg} from '../../components/TopNavBar'
import Button from '../../components/common/Button'
import moment from 'moment'
import {Card} from '../../pages/Dashboard'
import {HandleCardPadding} from './SentRequestCard'
import useRouter from '../../hooks/router'
import {api} from '../../helpers/auth-axios'
import ConfirmationPopup from '../modals/ConfirmationModel'
import {toast} from 'react-toastify'
import Loader from '../common/Loader'
import {useTranslation} from 'react-i18next'
import {callAutoLogin} from '../../actions/authActions'
import {useDispatch} from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import {useSelector} from 'react-redux'
import {subscribeToChannel} from '../../service/pusherService'
import InfiniteScroll from 'react-infinite-scroll-component'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import UserProfileLoader from '../common/UserProfileLoader'

const RequestCard = ({recentNetworks, reqLoading, meetingTab, activeTab}: {activeTab?: any; meetingTab?: any; reqLoading: any; recentNetworks?: any[]; handleRescheduled?: any; handleDecline?: any; handleAccept?: any}) => {
	const router = useRouter()
	const [confirmationModel, setConfirmationModel] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedMeeting, setSelectedMeeting] = useState<any>('')
	const [receivedMeetingsRequest, setReceivedMeetingsRequest] = useState<any>([])
	const [hasMore, setHasMore] = useState(true)
	const [page, setPage] = useState(1)

	const [type, setType] = useState<any>('')
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)

	useEffect(() => {
		getSendedMeetings(page)
	}, [page])

	const getSendedMeetings = async (page: number, loading = meetingTab ? meetingTab : false) => {
		setLoading(meetingTab)
		await api
			.get(`/meeting/get_sent_meetings?page=${page}`)
			.then((res: any) => {
				const meetings = [...receivedMeetingsRequest, ...res?.data?.data?.receivedRequests]
				setReceivedMeetingsRequest(meetings)
				setHasMore(res?.data?.data?.receivedRequests.length !== 0)
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
			})
	}
	useEffect(() => {
		const unsubscribe = subscribeToChannel('MEETING', `ADD_MEETING_${userDetails.id}`, (data: any) => {
			// Update state with the received data
			if (activeTab == 1 && data?.meeting?.f_id == userDetails?.id) {
				setReceivedMeetingsRequest((prev: any) => [data?.meeting, ...prev])
			}

			// getSendedMeetings(1)
		})
		const acceptMeeting = subscribeToChannel('MEETING', `ACCEPT_MEETING_${userDetails?.id}`, (data: any) => {
			setReceivedMeetingsRequest((prev: any) => {
				const index = prev.findIndex((meeting: any) => meeting.id === data?.meeting?.id)
				if (index !== -1) {
					const updatedMeetings = prev.map((meeting: any, i: any) => (i === index ? {...meeting, ...data.meeting} : meeting))
					return updatedMeetings
				} else {
					return [...prev, data.meeting]
				}
			})
		})
		const declinetMeeting = subscribeToChannel('MEETING', `DECLINE_MEETING_${userDetails?.id}`, (data: any) => {
			dispatch(callAutoLogin(true))
			setReceivedMeetingsRequest((prev: any) => {
				const index = prev.findIndex((meeting: any) => meeting.id === data?.meeting?.id)
				if (index !== -1) {
					const updatedMeetings = prev.map((meeting: any, i: any) => (i === index ? {...meeting, ...data.meeting} : meeting))
					return updatedMeetings
				} else {
					return [...prev, data.meeting]
				}
			})
		})

		return () => {
			if (activeTab == 1) {
				unsubscribe()
				declinetMeeting()
				acceptMeeting()
			}
		}
	}, [userDetails])

	const _handleAccept = async (meeting: any) => {
		setLoading(true)
		setConfirmationModel(false)

		await api
			.put(`/meeting/update_meeting_status`, {id: meeting?.id, status: 'accepted'})
			.then((res: any) => {
				if (res?.data?.status) {
					setSelectedMeeting('')
					setLoading(false)
					dispatch(callAutoLogin(true))
					toast.success(res?.data?.message)
					router.navigate(`/accept-reschedule-successful?id=${meeting?.id}`)
				} else {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const _handleDecline = async (meeting: any) => {
		setLoading(true)
		setConfirmationModel(false)
		await api
			.put(`/meeting/update_meeting_status`, {id: meeting?.id, status: 'decline'})
			.then((res: any) => {
				if (res?.data?.status) {
					setSelectedMeeting('')
					setLoading(false)
					toast.success(res?.data?.message)
					dispatch(callAutoLogin(true))
					router.navigate(`/decline-request?id=${meeting?.id}`)
				} else {
					toast.error(res?.data?.message)
					setLoading(false)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	const checkMeeting = (user: any) => {
		const currentTime = moment()
		const eventStartTime = moment(user.confirmation_start_time || user.start_time)
		const eventEndTime = moment(user.end_time)
		const isUpcoming = currentTime.isBefore(eventStartTime)

		const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
		const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
		const duration = diffMinutes < 0 ? user.duration + diffMinutes : user.duration - diffMinutes
		const hasRemainingTime = duration > 0

		return {duration, isUpcoming, hasRemainingTime, isInProgress}
	}

	const fetchMoreData = () => {
		setPage((prev) => prev + 1)
	}
	return (
		<Flexed id="requestMeetings" direction="column" gap={'1'} style={{height: '48.5rem', overflow: 'hidden', overflowY: 'scroll'}} padding={'1rem 1.3rem'}>
			{loading && <Loader />}
			<InfiniteScroll
				scrollableTarget="requestMeetings"
				scrollThreshold={0.9}
				dataLength={receivedMeetingsRequest.length}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={
					!loading ? (
						<></>
					) : (
						<Flexed justify={'center'} align={'center'}>
							{' '}
							{/* <FadeLoader /> */}
							<Loader isComponent={true} />
						</Flexed>
					)
				}
				endMessage={receivedMeetingsRequest?.length && !hasMore ? <></> : ''}>
				{receivedMeetingsRequest?.length
					? receivedMeetingsRequest?.map((user: any, i: any) => (
							<Card marginBottom={'10px'} background={'transparent'} removeShadow borderColor={'#0000001F'} padding={'0'} minHeight={'auto'} height={'max-content !important'}>
								<Flexed direction="column" gap={'0.3'} justify={'flex-between'}>
									<Flexed direction="column" gap={'0.3'} align={'center'} padding={' 0.3rem 1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<CustomFlexedForWrap direction="row" width={'100%'} align={'center'} justify={'space-between'}>
											<Flexed
												direction="row"
												align={'center'}
												pointer
												onClick={() => {
													router.navigate(`/${user?.meetingFrom?.user_name}`, {
														state: {route: '/network'}
													})
												}}>
												<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={user?.meetingFrom?.image_Url ? user?.meetingFrom?.image_Url : '/images/user_default.png'} />
												<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
													<Flexed direction="row" justify={'center'} align={'center'} gap={'0.5'}>
														<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
															{user?.meetingFrom?.full_name}
														</Text>
														{user?.meetingFrom?.linkedin_id || user?.meetingFrom?.linkedin_profile_verified ? <Icons src={`/images/linkedin.svg`} /> : ''}
														{!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming ? (
															<Flexed padding={'4px 8px 4px 8px'} radius="20px" background={palette.light_gray}>
																<Text margin={'auto'} fontSize={'0.6'} color={palette.text_color}>
																	{t('ENDED')}
																</Text>
															</Flexed>
														) : user?.status == 'accepted' ? (
															<Flexed>
																<Icons src={`/images/Tag_accepted.svg`} />
															</Flexed>
														) : user?.status == 'decline' ? (
															<Flexed>
																<Icons src={`/images/Tag_rejected.svg`} />
															</Flexed>
														) : user?.status == 're-scheduled' ? (
															<Flexed>
																<Icons src={`/images/Tag_re-scheduled.svg`} />
															</Flexed>
														) : user?.status == 'pending' ? (
															<Flexed>
																<Icons src={`/images/Tag_Inprogress.svg`} />
															</Flexed>
														) : (
															''
														)}
													</Flexed>
													<Spacer height={0.2} />
													<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
														{user?.meetingFrom?.designation}
													</Text>
												</Flexed>
											</Flexed>

											<Flexed align={'center'} direction={'row'} justify={'space-between'} gap={'1.3'}>
												<Button
													small
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													hasBorder
													padding={'0'}
													label={<>{t('MESSAGE')}</>}
													background={palette.white}
													color={palette.blue_500}
													lineHeight={'0.89rem'}
													ifClicked={() => {
														const handleRequest = async () => {
															if (userDetails) {
																if (!user?.meetingFrom?.chat_id) {
																	toast.warning(t('Chat id not exist'))
																} else if (user?.meetingFrom?.chat_id) {
																	await CometChat.getUser(user?.meetingFrom?.chat_id?.toLowerCase()).then(
																		(res: any) => {
																			if (res?.blockedByMe || res?.hasBlockedMe) {
																				toast.warning(res?.blockedByMe ? t('You blocked this user') : t('User blocked'))
																			} else if (myNetworkUsers?.includes(user?.meetingFrom?.chat_id?.toLowerCase())) {
																				router.navigate(`/inbox?id=${user?.meetingFrom?.chat_id?.toLowerCase()}&network=${true}`)
																				console.log('User details fetched for user:', res)
																			} else {
																				toast.warning(t('User not in your network'))
																			}
																		},
																		(error) => {
																			toast.warning(t('User not exist in chat'))
																			console.log('User details fetching failed with error:', error)
																		}
																	)
																} else {
																	toast.warning(t('Network request not accepted yet'))
																}
															}
														}
														handleRequest()
													}}
												/>
											</Flexed>
										</CustomFlexedForWrap>
									</Flexed>
									<Flexed direction="column" gap={'0.3'} align={'start'} padding={' 0.3rem 1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}} flexWrap={'wrap'}>
										<Flexed align={'start'} padding={'1rem'}>
											<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
												{t('Meeting')}
											</Text>
											<Spacer height={'0.5'} />
											<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={1} flexWrap={'wrap'}>
												<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
													<Icons src={'/images/calendar_blue.svg'} />{' '}
													{moment
														.utc(user?.confirmation_start_time ? user?.confirmation_start_time : user?.start_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format('dddd, MMMM Do, YYYY')}
												</Text>

												<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
													<Icons src={'/images/appointment_card_time.svg'} />{' '}
													{`${user?.duration ? user?.duration : user?.membershipDetails?.time} ${t('minutes')} - ${moment
														.utc(user?.confirmation_start_time ? user?.confirmation_start_time : user?.start_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format('hh:mm a')} ${t('to')} ${moment
														.utc(user?.confirmation_end_time ? user?.confirmation_end_time : user?.end_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format('hh:mm a')}`}
												</Text>
											</Flexed>
											<Spacer height={'0.5'} />
											{!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming ? (
												''
											) : (
												<Text fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
													{user?.is_video ? (
														<>
															<Icons src={'/images/video_meeting_icon.svg'} />{' '}
															{user?.status !== 'accepted' ? (
																t('Web conferencing details provided upon confirmation')
															) : (
																<JoinLinkText
																	onClick={() => {
																		const handleVideoClick = () => {
																			const currentTime = moment()
																			const eventStartTime = moment(user.confirmation_start_time || user.start_time)
																			const eventEndTime = moment(user.end_time)
																			const isUpcoming = currentTime.isBefore(eventStartTime)

																			const isInProgress = currentTime.isBetween(eventStartTime, eventEndTime)
																			const diffMinutes = eventStartTime.diff(currentTime, 'minutes')
																			const duration = diffMinutes < 0 ? user.duration + diffMinutes : user.duration - diffMinutes
																			const hasRemainingTime = duration > 0

																			if (duration && duration <= 0 && !hasRemainingTime && !isUpcoming) {
																				toast.warn(t('The meeting has been ended.'))
																			} else if (isUpcoming) {
																				toast.warn(t('The meeting will start at the exact time.'))
																			} else if ((isInProgress || hasRemainingTime) && duration && duration >= 1 && user?.status == 'accepted') {
																				router.navigate(`/initialize-video?id=${user?.id}`)
																			} else {
																				if (duration && duration < 0 && user?.status == 'accepted') {
																					toast.warn(t('The meeting has already started.'))
																				} else {
																					if (user?.status == 'accepted') {
																						toast.warn(t('The meeting is scheduled to start in more than 5 minutes.'))
																					} else {
																						toast.warn(t('The meeting is not confirmed.'))
																					}
																				}
																			}
																		}
																		handleVideoClick()
																	}}>
																	{' '}
																	Join Call Here{' '}
																</JoinLinkText>
															)}{' '}
														</>
													) : (
														''
													)}
												</Text>
											)}
										</Flexed>
									</Flexed>
									{user?.status == 're-scheduled' && user?.proposed_start_time ? (
										<Flexed direction="column" gap={'0.3'} align={'start'} padding={' 0.3rem 1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}} flexWrap={'wrap'}>
											<Flexed align={'start'} padding={'0 1rem'}>
												<Spacer height={'0.5'} />
												<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
													{t('Proposed Date & Time')}
												</Text>
												<Spacer height={'0.5'} />
												<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={1} flexWrap={'wrap'}>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
														<Icons src={'/images/calendar_blue.svg'} /> {moment(user?.proposed_start_time ? user?.proposed_start_time : user?.start_time).format('dddd, MMMM Do, YYYY')}
													</Text>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
														<Icons src={'/images/appointment_card_time.svg'} />{' '}
														{`${user?.duration ? user?.duration : user?.membershipDetails?.time} ${t('minutes')} - ${moment(user?.proposed_start_time ? user?.proposed_start_time : user?.start_time).format('hh:mm a')} ${t(
															'to'
														)} ${moment(user?.proposed_end_time ? user?.proposed_end_time : user?.end_time).format('hh:mm a')}`}
													</Text>
												</Flexed>
												<Spacer height={'0.5'} />
												{user?.notes ? (
													<>
														<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
															{t('Notes')}
														</Text>
														<Paragraph fontSize={'0.8'} lineHeight={'1.12'} fontWeight={'400'} color={'black'}>
															{user?.notes}
														</Paragraph>
														<Spacer height={'0.5'} />
													</>
												) : (
													''
												)}
											</Flexed>
										</Flexed>
									) : (
										''
									)}{' '}
									{/* <Spacer height={'0.5'} /> */}
									<Spacer height={'0.3'} />
									<Flexed align={'center'} direction={'row'} justify={'flex-end'} gap={'0.5'} padding={'0 1rem'} flexWrap={'wrap'}>
										<>
											{user.status !== 'decline' && (
												<Button
													small
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													hasBorder
													padding={'8px 16px 8px 16px'}
													label={<>{t('RE-SCHEDULE')}</>}
													background={palette.white}
													color={palette.blue_500}
													lineHeight={'0.89rem'}
													minWidth={'7rem'}
													disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
													ifClicked={() => router.navigate(`/request-reschedule?id=${user?.id}`)}
												/>
											)}

											{user?.status == 're-scheduled' || user?.status == 'pending' || user?.status == 'accepted' ? (
												<Button
													small
													type={'danger'}
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													hasBorder
													padding={'8px 16px 8px 16px'}
													label={<>{t('DECLINE')}</>}
													background={palette.white}
													color={palette.danger_1}
													disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
													lineHeight={'0.89rem'}
													ifClicked={() => {
														setType('decline')
														setConfirmationModel(true)
														setSelectedMeeting(user)
													}}
												/>
											) : (
												''
											)}

											{user?.status == 'pending' &&
											moment().format() <=
												moment
													.utc(user?.confirmation_end_time ? user?.confirmation_end_time : user?.end_time)
													.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
													.format() ? (
												<Button
													small
													hasBorder
													type={'success'}
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={<>{t('ACCEPT')}</>}
													background={palette.success}
													color={palette.white}
													lineHeight={'0.89rem'}
													disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
													ifClicked={() => {
														setType('accept')
														setConfirmationModel(true)
														setSelectedMeeting(user)
													}}
												/>
											) : user?.status == 're-scheduled' &&
											  moment().format() <=
													moment
														.utc(user?.confirmation_end_time ? user?.confirmation_end_time : user?.end_time)
														.tz(user?.google_meeting_id ? userDetails?.timezone : user?.u_id == userDetails?.id ? user.timezone : user?.receiver_timezone)
														.format() ? (
												<Button
													small
													hasBorder
													type={'success'}
													borderRadius={'1rem'}
													fontSize={'0.8rem'}
													padding={'8px 16px 8px 16px'}
													label={<>{t('ACCEPT')}</>}
													background={palette.success}
													color={palette.white}
													lineHeight={'0.89rem'}
													disabled={(!checkMeeting(user).hasRemainingTime && !checkMeeting(user).isUpcoming) || user?.user1_joind || user?.user2_joind}
													ifClicked={() => {
														setType('accept')
														setConfirmationModel(true)
														setSelectedMeeting(user)
													}}
												/>
											) : (
												''
											)}
										</>
									</Flexed>
									<Spacer height={'0.5'} />
								</Flexed>
							</Card>
					  ))
					: !loading &&
					  receivedMeetingsRequest.length == 0 && (
							<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'}>
								<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
									{t('You have not received any requests yet.')}
								</Text>
							</Flexed>
					  )}

				{confirmationModel && (
					<ConfirmationPopup
						header={type == 'decline' ? 'Are you sure you want to decline this meeting?' : 'Are you sure you want to accept this meeting?'}
						onClose={() => {
							setSelectedMeeting('')
							setConfirmationModel(false)
						}}
						buttonTitle={type == 'decline' ? 'Decline' : 'Accept'}
						onConfirm={() => {
							if (selectedMeeting) {
								type == 'decline' ? _handleDecline(selectedMeeting) : _handleAccept(selectedMeeting)
							}
						}}
					/>
				)}
			</InfiniteScroll>
		</Flexed>
	)
}

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

const JoinLinkText = styled(Text)`
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	line-height: 18px;
	color: #006fee;
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`
export default RequestCard
