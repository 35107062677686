import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Card} from '../../pages/Dashboard'
import {palette} from '../../styled/colors'
import MultiSelect from '../common/MultiSelect'
import moment from 'moment-timezone'
import Button from '../common/Button'
import CustomCalendar from '../common/DatePicker'
import {Dot} from '../../pages/SignUp'
import useRouter from '../../hooks/router'
import {Icons, ProfileImg} from '../TopNavBar'
import {mockUsers} from '../../utils/index'
import InputField, {Label} from '../common/InputField'
import {FaArrowRight} from 'react-icons/fa'
import {api} from '../../helpers/auth-axios'
import Loader from '../common/Loader'
import {toast} from 'react-toastify'
import Input from '../common/input/Input'
import {useSelector} from 'react-redux'
import {callAutoLogin} from '../../actions/authActions'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import UserProfileLoader from '../common/UserProfileLoader'
import Footer from '../Footer'

const RescheduleRequest = () => {
	const [selectedDate, setSelectedDate] = useState<any>('')
	const [availableSlots, setAvailableSlots] = useState<any[]>([])
	const [selectedSlot, setSelectedSlot] = useState<any>('')
	const [newSelectedSlot, setNewSelectedSlot] = useState<any>('')
	const [notes, setNotes] = useState<any>('')
	const [loading, setLoading] = useState(false)
	const router: any = useRouter()
	const [userMeeting, setUserMeeting] = useState<any>('')
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [selectedTimezone, setSelectedTimezone] = useState<any>('')
	const userTimeZone = userDetails?.timezone ? userDetails?.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
	const [meetingTitle, setMeetingTitle] = useState<any>('')
	const [meetingTitleError, setMeetingTitleError] = useState<any>('')
	const [availableDays, setAvailableDays] = useState<any[]>([])
	const dispatch = useDispatch()
	const {t} = useTranslation()

	useEffect(() => {
		if (router?.query?.id) {
			getMeetingById(router?.query?.id)
		}
	}, [router?.query?.id])

	useEffect(() => {
		if (selectedDate) {
			getAvailableSlots(selectedDate, selectedTimezone?.value ? selectedTimezone?.value : userTimeZone, userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time)
		}
	}, [selectedDate])

	useEffect(() => {
		if (userMeeting) {
			getUserAvailableDays(userMeeting?.f_id, selectedDate ? selectedDate : moment().format(), selectedTimezone ? selectedTimezone : userTimeZone)
		}
	}, [userMeeting])

	const getMeetingById = async (id?: any) => {
		setLoading(true)
		await api.get(`/meeting/get_single_meeting?id=${id}`).then((res: any) => {
			if (res?.data?.status) {
				setUserMeeting(res?.data?.data)
				setMeetingTitle(res?.data?.data?.title)
				setSelectedDate(
					moment
						.utc(res?.data?.data?.confirmation_start_time || res?.data?.data?.start_time)
						.tz(res?.data?.data?.u_id == userDetails?.id ? res?.data?.data.timezone : res?.data?.data?.receiver_timezone)
						.startOf('day')
						.format()
				)
				setSelectedSlot(moment.utc(res?.data?.data?.confirmation_start_time || res?.data?.data?.start_time).format('HH:mm'))
				setLoading(false)
			}
			setLoading(false)
		})
	}

	const getUserAvailableDays = async (user_id?: any, date = moment().format(), timezone?: any) => {
		await api.post(`/meeting/user_availability`, {user_id, date, timezone}).then((res: any) => {
			if (res?.data?.status) {
				setAvailableDays(res?.data?.data)
			}
		})
	}

	const getAvailableSlots = async (selectedDate?: any, timezone?: any, duration?: any) => {
		if (meetingTitle) {
			setLoading(true)
			await api
				.post(`/meeting/get_available_stols`, {user_id: userMeeting?.f_id, date: selectedDate, timezone: timezone, duration})
				.then((res: any) => {
					if (res?.data?.status) {
						setAvailableSlots(res?.data?.data?.slots)
					}
					setLoading(false)
				})
				.catch((error: any) => {
					setLoading(false)
				})
		} else {
			setMeetingTitleError(t('Meeting title is required'))
		}
	}

	const dateTimeString = moment(selectedDate).format('YYYY-MM-DD') + 'T' + selectedSlot + ':00'
	const start = moment(dateTimeString)
	const formattedStart = start.format()
	// console.log('======', start, formattedStart)

	//reschedule meeting
	const rescheduleBuyMyTime = async (timezone?: any, duration?: any) => {
		if (newSelectedSlot) {
			let end = moment(formattedStart).add(duration, 'minute').format()
			setLoading(true)
			await api
				.post(`/meeting/reschedule_meeting`, {
					proposed_start_time: userMeeting?.u_id != userDetails?.id ? formattedStart : undefined,
					proposed_end_time: userMeeting?.u_id != userDetails?.id ? end : undefined,
					start_time: userMeeting?.u_id == userDetails?.id ? formattedStart : undefined,
					end_time: userMeeting?.u_id == userDetails?.id ? end : undefined,
					id: router?.query?.id,
					notes: notes,
					timezone: userMeeting?.timezone
					// receiver_timezone: timezone
				})
				.then((res: any) => {
					if (res?.data?.status) {
						setLoading(false)
						toast.success(res?.data?.message)
						dispatch(callAutoLogin(true))
						if (router?.location?.state?.router) {
							router.navigate(router?.location?.state?.router)
							router.navigate(`/reschedule-successful?id=${router?.query?.id}`, {state: router?.location?.state})
						} else {
							router.navigate(`/reschedule-successful?id=${router?.query?.id}`)
						}
					} else {
						toast.error(res?.data?.message)
					}

					setLoading(false)
				})
				.catch((error: any) => {
					setLoading(false)
				})
		} else {
			toast.warn(t('Please choose slot to re-schedule meeting'))
		}
	}

	const currentMonthDays = moment(selectedDate ? selectedDate : moment()).daysInMonth()
	const daysArray = Array.from({length: currentMonthDays}, (_, index) => index + 1)

	const startTime = userMeeting?.confirmation_start_time ? userMeeting?.confirmation_start_time : userMeeting?.start_time
	const endTime = userMeeting?.confirmation_end_time ? userMeeting?.confirmation_end_time : userMeeting?.end_time

	return (
		<MainContainer>
			<Spacer height={4} />
			{userMeeting && loading && <Loader />}
			{userMeeting ? (
				<Container fluid>
					<Card background={palette.white} padding={'1rem 1.2rem'} overflow={'hidden'}>
						<Row>
							<Col lg={3} md={12} sm={12}>
								<Card background={'transparent'} padding={''} removeShadow overflow={'hidden'}>
									<>
										<Flexed direction="column" align={''} gap={'0'}>
											<Flexed direction="row" align={'center'} gap={'0.6'}>
												<Icons
													src={'/images/icon_view.svg'}
													onClick={() => {
														if (router?.location?.state?.router) {
															router.navigate(router?.location?.state?.router)
														} else {
															router.navigate(-1)
														}

														setSelectedSlot('')
														setNewSelectedSlot('')
													}}
													pointer={'pointer'}
												/>
												<Text fontSize={'1.12'} lineHeight={'1.31'} fontWeight={'700'} color={'text_color'} align={'left'}>
													{t('Re-Schedule')}
												</Text>
											</Flexed>
										</Flexed>
										<Spacer height={'3'} />
										<Flexed direction="row" width={'100%'} align={'center'}>
											<UserProfileLoader
												height={'2.8rem'}
												width={'2.8rem'}
												imgUrl={userMeeting?.meetingFrom?.image_Url ? userMeeting?.meetingFrom?.image_Url : userMeeting?.meetingFrom?.image_Url ? userMeeting?.meetingFrom?.image_Url : '/images/user_default.png'}
											/>
											<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
												<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
													<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'text_color'}>
														{userMeeting?.meetingFrom?.full_name ? userMeeting?.meetingFrom?.full_name : userMeeting?.meetingTo?.full_name}
													</Text>
													{(
														userMeeting?.meetingFrom?.linkedin_id || userMeeting?.meetingFrom?.linkedin_profile_verified
															? userMeeting?.meetingFrom?.linkedin_id || userMeeting?.meetingFrom?.linkedin_profile_verified
															: userMeeting?.meetingTo?.linkedin_id || userMeeting?.meetingTo?.linkedin_profile_verified
													) ? (
														<Icons src={`/images/linkedin.svg`} />
													) : (
														''
													)}
												</Flexed>
												<Spacer height={0.2} />
												<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
													{userMeeting?.meetingFrom?.designation ? userMeeting?.meetingFrom?.designation : userMeeting?.meetingTo?.designation}
												</Text>
											</Flexed>
										</Flexed>
										<Spacer height={3} />
										<Flexed>
											<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'} align={'left'}>
												{t('Requested Date & Time')}
											</Text>
										</Flexed>

										<Spacer height={'1'} />
										<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={1} flexWrap={'wrap'}>
											<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
												<Icons src={'/images/calendar_blue.svg'} />{' '}
												{moment
													.utc(userMeeting?.confirmation_start_time ? userMeeting?.confirmation_start_time : userMeeting?.start_time)
													.tz(userMeeting?.u_id == userDetails?.id ? userMeeting.timezone : userMeeting?.receiver_timezone)
													.format('dddd, MMMM Do, YYYY')}
											</Text>
											<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
												<Icons src={'/images/appointment_card_time.svg'} />{' '}
												{`${userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time} ${t('minutes')} - ${moment
													.utc(startTime)
													.tz(userMeeting?.google_meeting_id ? userDetails?.timezone : userMeeting?.u_id == userDetails?.id ? userMeeting?.timezone : userMeeting?.receiver_timezone)
													.format('hh:mm a')} ${t('to')} ${moment
													.utc(endTime)
													.tz(userMeeting?.google_meeting_id ? userDetails?.timezone : userMeeting?.u_id == userDetails?.id ? userMeeting.timezone : userMeeting?.receiver_timezone)
													.format('hh:mm a')}`}
											</Text>
										</Flexed>
										<Spacer height={'3'} />

										{selectedDate && selectedSlot && userMeeting?.u_id != userDetails?.id && userMeeting?.re_scheduled_from ? (
											<>
												<Flexed>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'} align={'left'}>
														{t('Proposed Date & Time')}
													</Text>
												</Flexed>

												<Spacer height={'1'} />
												<Flexed align={'center'} direction={'row'} justify={'space-between'} width={'100%'} gap={1} flexWrap={'wrap'}>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
														<Icons src={'/images/calendar_blue.svg'} /> {moment(selectedDate).format('dddd, MMMM Do, YYYY')}
													</Text>
													<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'600'} color={'text_color'}>
														<Icons src={'/images/appointment_card_time.svg'} />{' '}
														{`${userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time} ${t('minutes')} - ${moment(moment().format() + ' ' + selectedSlot, 'YYYY-MM-DD HH:mm:ss').format(
															'hh:mm a'
														)} ${t('to')} ${moment(moment().format() + ' ' + selectedSlot, 'YYYY-MM-DD HH:mm:ss')
															.add(userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time, 'minute')
															.format('hh:mm a')}`}
													</Text>
												</Flexed>
												<Spacer height={'0.5'} />
											</>
										) : (
											''
										)}
									</>
								</Card>
							</Col>
							<Col lg={6} md={12} sm={12}>
								<Spacer height={1} />
								<Flexed width={'100%'}>
									<Input label={t('Enter Title')} value={meetingTitle} error={meetingTitle?.trim()?.length == 0 && meetingTitleError ? {message: meetingTitleError} : undefined} onChange={(e: any) => setMeetingTitle(e?.value)} />
								</Flexed>
								<Spacer height={0.5} />
								<Card background={'transparent'} padding={'1rem 1.2rem'} height={'auto'} removeShadow overflow={'hidden'} borderColor={palette.default_200}>
									<CustomCalendar
										title={t('Propose Date')}
										availableDays={daysArray}
										selecteddate={selectedDate}
										handleSelection={(date: any) => {
											setSelectedDate(date)
										}}
									/>
									<Flexed direction={'row'} align={'center'} justify={'space-between'} padding={'0.1rem 1rem'} flexWrap={'wrap'} gap={'0.5'}>
										<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
											<Dot height={'1rem'} width={'1rem'} bg={palette.grey_1} />
											<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
												{t('Unavailable Dates')}
											</Text>
										</Flexed>
										<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
											<Dot height={'1rem'} width={'1rem'} bg={palette.blue_100} />
											<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
												{t('Available Dates')}
											</Text>
										</Flexed>
										<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
											<Dot height={'1rem'} width={'1rem'} bg={palette.blue_100} border={`1px solid ${palette.blue_400}`} />
											<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
												{t('Today’s Date')}
											</Text>
										</Flexed>
										<Flexed direction={'row'} align={'center'} justify={'space-between'} gap={'0.5'}>
											<Dot height={'1rem'} width={'1rem'} bg={palette.blue_500} />
											<Text fontSize={'0.8'} lineHeight={'0.95'} fontWeight={'400'} color={'black'}>
												{t('Selected Date')}
											</Text>
										</Flexed>
									</Flexed>
								</Card>
							</Col>
							<Col lg={3} md={12} sm={12}>
								<Spacer height={1} />
								<CustomSmallScreenSpacer height={1} />
								<Card background={'transparent'} padding={'1rem 1.2rem'} height={'98%'} removeShadow overflow={'hidden'} borderColor={palette.default_200}>
									<>
										<Flexed direction="row" align={'center'} gap={'0.6'}>
											<Text fontSize={'1.12'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'} align={'left'}>
												{t('Propose Time Slot')}
											</Text>
										</Flexed>
										<Spacer height={'1'} />
										<Flexed direction={'row'} justify={'space-between'} align={'center'}>
											<Flexed width={'30%'}>
												<Text fontSize={'0.81'} lineHeight={'0.95'} fontWeight={'600'} color={'text_color'} align={'left'}>
													{t('Time Zone')}
												</Text>
											</Flexed>
											<Flexed width={'70%'}>
												<MultiSelect
													placeholder={t('Choose timezone')}
													padding={'0'}
													radius={'5px'}
													value={selectedTimezone ? selectedTimezone : {label: userTimeZone, value: userTimeZone}}
													options={moment.tz.names().map((timezone: any) => {
														return {label: timezone, value: timezone}
													})}
													onChange={(e: any) => {
														setSelectedTimezone(e)
														getAvailableSlots(selectedDate, e?.value, userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time)
													}}
													noOptionsMessage={t('no option available')}
												/>
											</Flexed>
										</Flexed>
										<Spacer height={'1'} />
										{selectedDate ? (
											<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'600'} color={'text_color'} align={'left'}>
												{moment(selectedDate).format('dddd, MMMM Do, YYYY')}
											</Text>
										) : (
											''
										)}
										<Spacer height={'1'} />
										<Flexed direction="column" align={'center'} gap={'0.6'} height={'29rem'} style={{overflow: 'hidden', overflowY: 'scroll'}}>
											{availableSlots?.map((slot: any, index: any) =>
												moment().format('DD-MM') == moment(selectedDate).format('DD-MM') ? (
													moment().format('HH:mm') <= slot ? (
														<Flexed
															pointer
															direction="row"
															align={'center'}
															gap={'0.6'}
															padding={'0.8rem'}
															justify={'center'}
															border={`1px solid ${palette.blue_500}`}
															width={'100%'}
															radius={'5px'}
															background={selectedSlot == slot ? palette.blue_500 : 'transparent'}
															onClick={() => {
																setSelectedSlot(slot)
																setNewSelectedSlot(slot)
															}}>
															<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'600'} color={selectedSlot == slot ? 'white' : 'blue_500'} isCentered>
																{moment(moment().format() + ' ' + slot, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
															</Text>
														</Flexed>
													) : (
														<>
															{index == availableSlots?.length - 1 ? (
																<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'}>
																	<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'zink_400'} isCentered>
																		{t('No slot available!')}
																	</Text>
																</Flexed>
															) : (
																''
															)}
														</>
													)
												) : (
													<Flexed
														pointer
														direction="row"
														align={'center'}
														gap={'0.6'}
														padding={'0.8rem'}
														justify={'center'}
														border={`1px solid ${palette.blue_500}`}
														width={'100%'}
														radius={'5px'}
														background={selectedSlot == slot ? palette.blue_500 : 'transparent'}
														onClick={() => {
															setSelectedSlot(slot)
															setNewSelectedSlot(slot)
														}}>
														<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'600'} color={selectedSlot == slot ? 'white' : 'blue_500'} isCentered>
															{moment(moment().format() + ' ' + slot, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
														</Text>
													</Flexed>
												)
											)}
										</Flexed>
									</>
								</Card>
							</Col>
						</Row>
						<Spacer height={'3'} />
						<Row>
							<Col lg={12}>
								<Card background={'transparent'} padding={'2rem 1.2rem'} removeShadow overflow={'visible'} borderColor={palette.default_200} minHeight={'9rem'}>
									<Flexed align={'left'} direction={'column'} justify={'center'} width={'100%'}>
										<Flexed>
											<Label type="normal" margin="0rem 0rem 0.4rem 0rem" color={'text_color'} fontSize={'1.12'} lineHeight={'1.3'} fontWeight={'600'}>
												Notes
											</Label>
											<InputField placeholder={'Add notes'} padding={'0.8rem'} background={palette.notes_bg} onChange={(e: any) => setNotes(e?.value)} />
										</Flexed>
									</Flexed>
								</Card>
							</Col>
						</Row>

						<Spacer height={'2'} />
						<Row>
							<Col lg={12}>
								<Flexed justify="space-between" gap={1} direction="row" align="end" padding={'0 0.8rem'}>
									<Flexed></Flexed>
									<Flexed justify="space-between" gap={1} direction="row" align="center">
										<Button
											small
											borderRadius={'1rem'}
											hasBorder
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={<>CANCEL</>}
											background={palette.white}
											color={palette.blue_500}
											lineHeight={'0.89rem'}
											ifClicked={() => router.navigate(-1)}
										/>
										<Button
											small
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={
												<>
													PROCEED &nbsp;
													<FaArrowRight />
												</>
											}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
											ifClicked={() => rescheduleBuyMyTime(selectedTimezone?.value ? selectedTimezone?.value : userTimeZone, userMeeting?.duration ? userMeeting?.duration : userMeeting?.membershipDetails?.time)}
										/>
									</Flexed>
								</Flexed>
							</Col>
						</Row>
						{/* <Spacer height={'3'} /> */}
					</Card>
				</Container>
			) : (
				loading && <Loader />
			)}
			{userMeeting && !loading ? <Footer /> : ''}
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
`

export const CustomSmallScreenSpacer = styled(Spacer)<any>`
	${media.xs`
    display: flex;
    `}
	${media.sm`
    display: flex;

    `}
    ${media.md`

    display: none;
    `}
	${media.lg`
   display: none;
    `}
`

export default RescheduleRequest
