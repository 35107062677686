import React from 'react'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from '../../styled/colors'

const Loader = ({visible, isPopup, isComponent, isBottomChat, isLazy}: any) => {
	return (
		<Wrapper visible={visible} isComponent={isComponent} isBottomChat={isBottomChat} isLazy={isLazy}>
			<Img className="loading_logo_animation" src={'/logo.png'} alt="logo.svg" right={isPopup} />
		</Wrapper>
	)
}

const Wrapper = styled.div<any>`
	display: ${({visible}) => (visible === true ? 'none ' : 'flex')};
	position: ${({isComponent}) => (isComponent ? 'relative' : 'fixed')};
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background-color: ${({isComponent, isBottomChat, isLazy}) => (isLazy ? 'black' : isComponent ? 'none' : palette.overlay)};
	opacity: 0.92;
	z-index: 15;
	${media.xs`
	width: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : '100%')};
	height: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : isComponent ? '100vh' : '100%')};
	`}
	${media.sm`
	width: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : '100%')};
	height: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : isComponent ? '100vh' : '100%')};
	`}
	${media.md`
	width: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : '100%')};
	height: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : isComponent ? '100vh' : '100%')};
	`}
	${media.lg`
	width: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : '100vw')};
	`}
	${media.xl`
	width: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : '100vw')};
	`}
	${media.xxl`
	width: ${({isComponent, isBottomChat}: any) => (isBottomChat ? 'auto' : '100vw')};

	`}
`

const Img = styled.img<any>`
	width: 70px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: ${({right}) => (right ? '42rem !important' : '0')};
	left: 0;
	margin: auto;
	${media.xs`
	position: relative;
	`}
	${media.sm`
	position: relative;
	`}
	${media.md`
	position: absolute;
	`}
	${media.lg`
	position: absolute;
	`}
	${media.xl`
	position: absolute;
	`}
	${media.xxl`
	position: absolute;
	`}
`
export default Loader
