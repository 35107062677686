import React, { useContext, useEffect, useState } from 'react'
import AgoraUIKit, { MaxVideoView, MinVideoView, layout } from 'agora-react-uikit'
import { useSelector } from 'react-redux'
import useRouter from '../hooks/router'
import { api } from '../helpers/auth-axios'
import { Flexed, Spacer, Text } from '../styled/shared'
import { palette } from '../styled/colors'
import { BiChat, } from 'react-icons/bi'
import Loader from '../components/common/Loader'
import { toast } from 'react-toastify'
import Timer from '../components/common/Timer'
import moment from 'moment'
import { UIKitProvider, Chat as MyChat, ConversationList, useClient, rootStore } from 'agora-chat-uikit'
import 'agora-chat-uikit/style.css'
import AgoraChat from '../components/video/Chat'
import { useDispatch } from 'react-redux'
import { callAutoLogin } from '../actions/authActions'
import ConfirmationPopup from '../components/modals/ConfirmationModel'
import { useTranslation } from 'react-i18next'
import MeetingFeedbackModel from '../components/modals/MeetingFeedbackModel'
import { subscribeToChannel } from '../service/pusherService'
import AgoraRTC from 'agora-rtc-sdk-ng'

const App = () => {
    const [videoCall, setVideoCall] = useState(true)
    const Router = useRouter()
    const userDetails = useSelector((state) => state.auth.userDetails)
    const [agoraOptions, setAgoraOptions] = useState('')
    const [isFetching, setIsFetching] = useState(false)
    const [appointmentInfo, setAppointmentInfo] = useState()
    const [meetingComplete, setMeetingComplete] = useState(false)
    const dispatch = useDispatch()
    const [openModel, setOpenModel] = useState(false)
    const [customDuration, setCustomDuration] = useState(0)
    const [tokenData, setTokenData] = useState('');
    const [screenShare, setScreenShare] = useState(false);
    const [meetingEndMessage, setMeetingEndMessage] = useState(false)
    const [remoteUsers, setRemoteUsers] = useState([]);
    const [isScreenShare, setIsScreenShare] = useState(false);
    const [screenShareTrack, setScreenShareTrack] = useState('');
    const { t } = useTranslation()
    const [fullScreen, setFullScreen] = useState(false)

    // Event handler for screen sharing
    const handleScreenSharing = (state) => {
        setIsScreenShare(state)

        if (state) {
            console.log('Screen sharing started');
        } else {
            console.log('Screen sharing stopped');
        }
    };

    const generateToken = async (userId) => {
        setIsFetching(true)
        await api
            .post('/meeting/generate_call_token', { userId, meetingId: Router?.query?.id })
            .then((res) => {
                if (res?.data?.status) {
                    setVideoCall(true)
                    setTokenData(res?.data?.data)
                    setScreenShare(true)
                    setAgoraOptions({
                        appId: res?.data?.data?.appId,
                        token: res?.data?.data?.token,
                        channel: res?.data?.data?.app_name,
                        uid: userDetails?.id,
                        chatToken: res?.data?.data?.chatToken,
                        chatUserId: res?.data?.data?.chatUserId,
                        chatWithUserId: res?.data?.data?.chatWithUserId,
                        chatUser: res?.data?.data?.chatUser,
                        shareScreeToken: res?.data?.data?.shareScreeToken,
                        systemTime: res?.data?.data?.systemTime
                    })
                    setIsFetching(false)
                    // setOpenCallDialog(false);
                    setTimeout(() => {
                        window.close()
                    }, 3000)
                } else if (res?.data?.status == false) {
                    if (res?.data?.message == 'The meeting has been ended.') {
                        setMeetingEndMessage(true)
                    } else {
                        toast.error(t(res?.data?.message))
                    }

                    setIsFetching(false)
                    setVideoCall(false)
                }
            })
            .catch((err) => {
                toast.error('Failed to start meeting')
                setIsFetching(false)
                setVideoCall(false)
                console.log('err', err)
            })
    }

    useEffect(() => {
        getAppointmentData(Router.query?.id)
        if (userDetails?.id && Router.query?.id && !videoCall && !meetingEndMessage) {
            generateToken(userDetails?.id)
        }

    }, [Router.query?.id, userDetails?.id, meetingEndMessage])

    useEffect(() => {
        const unsubscribe = subscribeToChannel('SHARE_SCREEN', `SHARE_SCREEN_TRACK`, (data) => {
            setIsScreenShare(true)
        })

        return () => {
            unsubscribe()
        }
    }, [userDetails])

    const updateTrack = async (user) => {
        setIsFetching(true)
        // if (!user?._videoTrack) {
        //     setIsScreenShare(true)
        // }
        console.log('======user before', user)
        setTimeout(async () => {
            console.log('======user', user?._videoTrack
            )
            if (!user?._videoTrack) {
                setIsFetching(true)
                setScreenShareTrack(user)
                await api.post('/user/auto_login', { isShare: true }).then(res => {
                    setIsFetching(false)
                })
                setIsScreenShare(true)

            } else if (user?._videoTrack) {
                setIsScreenShare(false)
            }
        }, 2000)
        setIsFetching(false)
    }

    const callbacks = {
        Screensharing: (state) => alert(state),
        EndCall: () => { setVideoCall(false); rootStore.setLoginState(false); setOpenModel(false) },
        ['user-published']: (user, mediaType) => {
            // console.log('=======', user?._videoTrack, user?._videoTrack?._ID, user?.uid, user, 'mediaType', mediaType)
            setRemoteUsers([...remoteUsers, user])
            updateTrack(user)


        },
        ['user-unpublished']: (user, mediaType) => {
            // console.log('=======unpublish', user, 'mediaType', mediaType)
            setIsScreenShare(false)
            let filterUser = remoteUsers.filter(e => e.uid != user.uid)
            setRemoteUsers(filterUser)
        },
        ['user-joined']: (user) => {
            setRemoteUsers([...remoteUsers, user])
            // console.log('=======joined', user,)
            // setIsScreenShare(false)
        },
        ['user-left']: (user) => {
            let filterUser = remoteUsers.filter(e => e.uid != user.uid)
            setRemoteUsers(filterUser)
            // console.log('=======joined', user,)
            // setIsScreenShare(false)
        },
        ['stream-inject-status']: (status, uid, url) => {
            console.log('=======status', status, uid, url)

        }

    }
    useEffect(() => {
        if (remoteUsers.some((e) => e?._videoTrack?._ID)) {
            // setIsScreenShare(true)
        } else {
            // setIsScreenShare(false)
        }
    }, [remoteUsers])

    const getAppointmentData = async (id) => {
        await api
            .get(`/meeting/get_single_meeting?id=${id}&isShare=${true}`)
            .then((response) => {
                setAppointmentInfo(response?.data?.data)
            })
            .catch((e) => {
                if (e?.response) {
                    // toast.error(e?.response?.data?.message);
                }
            })
    }

    const handleClose = async () => {
        if (customDuration <= 0) {
            if (Router.query.id) {
                await transferCoins(Router.query.id)
                let userFrom = appointmentInfo?.f_id == userDetails?.id
                if (userFrom) {
                    setTimeout(async () => {
                        await transferCoins(Router.query.id)
                        dispatch(callAutoLogin(true))
                    }, 3000)
                }


            }
            setVideoCall(false)
            window.close()
            setMeetingComplete(true)
            toast.success(t('This meeting is marked as completed'))
        }

    }

    const transferCoins = async (id) => {
        await api
            .post(`/meeting/transfer_coins`, { meeting_id: id })
            .then((response) => {
                console.log('===', response)
            })
            .catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                }
            })
    }

    useEffect(() => {
        if (!appointmentInfo && !agoraOptions) return;

        const currentTime = moment();
        const eventStartTime = moment(appointmentInfo?.confirmation_start_time || appointmentInfo?.start_time);
        const eventEndTime = moment(appointmentInfo?.confirmation_end_time || appointmentInfo?.end_time);

        const initialDuration = eventEndTime.diff(currentTime, 'seconds');
        setCustomDuration(initialDuration);

        const timer = setInterval(() => {
            const newCurrentTime = moment();
            const newDuration = eventEndTime.diff(newCurrentTime, 'seconds');
            if (newDuration >= 0) {
                setCustomDuration(newDuration);
            } else {
                setCustomDuration(0); // Meeting has ended
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [appointmentInfo, agoraOptions]);


    return (
        <>
            {isFetching && <Loader />}
            {videoCall && agoraOptions?.appId ? (
                <>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh' }}>
                            <Flexed justify={'flex-end'} direction="column" align={'left'} padding={'0'} flexWrap={'wrap'} style={{ position: 'absolute', bottom: '1rem', zIndex: '99999', left: '1rem', color: 'white' }}>
                                <Flexed justify={'space-between'} direction="row" padding={'0.5rem 0 0'} flexWrap={'wrap'} gap={'0.1'} color={'white'}>
                                    <Text fontWeight={'600'} color={'white'}>{t("Call Duration")}:</Text>
                                    <Text fontWeight={'600'} color={'white'}>{appointmentInfo?.duration} {t("min")}</Text>
                                </Flexed>
                                <Flexed justify={'space-between'} direction="row" padding={'0rem'} flexWrap={'wrap'} align={'center'} gap={'0.1'} color={'white'}>
                                    <Text fontWeight={'600'} color={'white'}>{t("Call Remain")}:</Text>
                                    <Timer duration={customDuration && customDuration >= 0 ? customDuration : 0} onComplete={handleClose} /> {t("min")}
                                </Flexed>

                            </Flexed>

                            <AgoraUIKit
                                styleProps={{
                                    remoteBtnContainer: {
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        position: 'absolute'
                                    },
                                    localBtnStyles: {
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        position: 'absolute'
                                    },
                                    BtnTemplateStyles: {
                                        justifyContent: 'center',
                                        gap: '1rem',
                                    },
                                    localBtnContainer: {
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        background: palette.default_theme,
                                        boxShadow: '0px 0px 0px 5px #0000001A',
                                    },
                                    UIKitContainer: {
                                        width: '100%',
                                        background: palette.black,
                                    },
                                    minViewStyles: {
                                    },
                                    maxViewContainer: {
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        margin: '0 auto',
                                        justifyContent: 'center'

                                    },
                                    scrollViewContainer: {
                                        position: fullScreen ? 'relative' : '',
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        margin: '0 auto',
                                    },
                                }}

                                rtcProps={{
                                    token: agoraOptions?.token,
                                    appId: agoraOptions?.appId,
                                    channel: agoraOptions?.channel,
                                    disableRtm: true,
                                    callActive: true,
                                    enableScreensharing: true,
                                    // CustomVideoPlaceholder: VideoCallComponent,
                                    screenshareToken: agoraOptions?.token,
                                    layout: isScreenShare ? layout.pin : layout.grid,
                                }}

                                rtmProps={{ showPopUpBeforeRemoteMute: true, token: agoraOptions?.chatToken, displayUsername: true, username: userDetails?.full_name, }}
                                callbacks={callbacks}
                            >

                            </AgoraUIKit>

                        </div>

                        <Flexed direction="row" margin={'0'} padding={'0rem'} style={{ position: 'absolute', right: openModel ? '50%' : '39%', bottom: '1rem', zIndex: '9999' }}>
                            <Flexed direction="column" align={'center'} gap={'0'} padding={'0.23rem'} pointer onClick={() => {
                                setOpenModel(!openModel)
                            }} border={'2px solid white'} style={{ borderRadius: '50%' }}>
                                <BiChat color="white" fontSize={'1.5rem'} />

                            </Flexed>


                        </Flexed>
                        <div style={{ position: openModel ? 'relative' : 'absolute', right: '0', bottom: openModel ? '0rem' : '1rem', zIndex: '0' }}>

                            {openModel ? <AgoraChat appointmentInfo={appointmentInfo} tokenDetails={tokenData} tokenData={tokenData} openModel={openModel} setOpenModel={setOpenModel} token={agoraOptions?.chatToken} user={userDetails} meeting={appointmentInfo} userId={agoraOptions?.chatUserId} chatWith={agoraOptions?.chatWithUserId} chatUser={agoraOptions?.chatUser} /> : ""}
                        </div>
                    </div>
                </>
            ) : (
                !isFetching && (
                    <>

                        {!meetingComplete ? <ConfirmationPopup header={screenShare ? t('Leave Meeting') : t('Join Meeting')}
                            body={screenShare ? t('Are you sure you want to leave this meeting ?') : t('Are you sure you want to join this meeting ?')}
                            onClose={async () => {

                                Router.navigate(`/calendar?id=${Router.query?.id}&isShare=${true}`)
                            }}
                            buttonTitle={screenShare ? t('Resume the call') : t('Join Call')}
                            onConfirm={() => { if (!meetingEndMessage) generateToken(userDetails?.id, 'JustNetwork') }}
                            cancelButtonTitle={<>
                                {screenShare ? t('End the Call') : t('No')}
                            </>} onCancel={async () => {
                                Router.navigate(`/calendar?id=${Router.query?.id}&isShare=${true}`)
                            }} /> : ""}
                        <Spacer height={8} />

                    </>
                )
            )}
            {meetingComplete ? <MeetingFeedbackModel onClose={() => {
                Router.navigate(`/calendar?id=${Router.query?.id}&isShare=${true}`)
            }} eventDetails={appointmentInfo} /> : ''
            }
        </>
    )
}

export default App
