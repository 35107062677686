import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import english from './en.json'
import spanish from './es.json'
import french from './fr.json'
let userLang = localStorage.getItem('user_lang') || 'en'

const resources = {
	en: {
		translation: english
	},
	es: {
		translation: spanish
	},
	fr: {
		translation: french
	}
}

i18n.use(initReactI18next).init({
	resources,
	lng: userLang,
	fallbackLng: 'en',
	debug: false,
	interpolation: {
		escapeValue: false
	}
})

export default i18n
