import React from 'react'
import styled from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'
import {palette} from '../../styled/colors'
import Button from '../common/Button'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {useTranslation} from 'react-i18next'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 400px;
	position: relative;
`

const Header = styled(Text)`
	margin: 0 0 10px;
`

const Body = styled.p`
	margin: 0 0 20px;
`

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px;
	background: none;
	border: none;
	cursor: pointer;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

interface ConfirmationPopupProps {
	header?: any
	body?: any
	onCancel?: () => void
	onConfirm?: () => void
	onClose?: () => void
	buttonTitle?: any
	cancelButtonTitle?: any
}
const ConfirmationPopup = ({header, body, onConfirm, onClose, buttonTitle, onCancel, cancelButtonTitle}: ConfirmationPopupProps) => {
	const {t} = useTranslation()
	return (
		<Overlay>
			<Modal>
				<Flexed direction={'row'} align={'center'}>
					<Header fontSize={'1.2'} lineHeight={'2'} fontWeight={'600'} color={'dark_blue'}>
						{t(header)}
					</Header>{' '}
					&nbsp;&nbsp;&nbsp;
					<CloseButton onClick={onClose}>
						<AiOutlineClose />
					</CloseButton>
				</Flexed>

				<Spacer height={1} />
				<Body>{t(body)}</Body>
				<ButtonContainer>
					<Button
						small
						hasBorder
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={<>{cancelButtonTitle ? cancelButtonTitle : t(cancelButtonTitle ? cancelButtonTitle : t('Cancel'))}</>}
						background={palette.white}
						color={palette.blue_500}
						lineHeight={'0.89rem'}
						ifClicked={() => (onCancel ? onCancel() : onClose && onClose())}
					/>
					<Button
						small
						hasBorder
						borderRadius={'1rem'}
						fontSize={'0.8rem'}
						padding={'8px 16px 8px 16px'}
						label={<>{t(buttonTitle)}</>}
						background={palette.blue_500}
						color={palette.white}
						lineHeight={'0.89rem'}
						ifClicked={() => onConfirm && onConfirm()}
					/>
				</ButtonContainer>
			</Modal>
		</Overlay>
	)
}

export default ConfirmationPopup
