import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

interface CheckboxProps {
	label: string
	defaultChecked?: boolean
	onChange?: (checked: boolean) => void
}

const CheckboxContainer = styled.label`
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	margin: 0;
`

const CheckboxInput = styled.input`
	/* position: absolute;
	opacity: 0; */
	cursor: pointer;
`

const CheckboxCheckmark = styled.span`
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-right: 8px;

	&:after {
		content: '';
		position: absolute;
		display: none;
		left: 6px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
`

const CheckboxLabel = styled.span`
	vertical-align: middle;
`

const Checkbox: React.FC<CheckboxProps> = ({label, defaultChecked = false, onChange}) => {
	const [checked, setChecked] = useState(defaultChecked)

	useEffect(() => {
		setChecked(defaultChecked)
	}, [defaultChecked])

	const handleChange = () => {
		const newChecked = !checked
		setChecked(newChecked)
		if (onChange) {
			onChange(newChecked)
		}
	}

	return (
		<CheckboxContainer>
			<CheckboxInput type="checkbox" checked={checked} onChange={handleChange} />
			{/* <CheckboxCheckmark /> */}
			{label ? <CheckboxLabel>{label}</CheckboxLabel> : ''}
		</CheckboxContainer>
	)
}

export default Checkbox
