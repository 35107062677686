import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {AiOutlineClose} from 'react-icons/ai'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 80%;
	width: 600px;
	position: relative;
`
const CloseButton = styled.button`
	position: absolute;
	top: 8px;
	right: 10px;
	padding: 2px;
	background: none;
	border: none;
	cursor: pointer;
`
interface BeforeSignInModals {
	children?: any
	onClose?: () => void
	signUp?: any
}
const BeforeSignInModals = ({children, signUp, onClose}: BeforeSignInModals) => {
	const {t} = useTranslation()
	console.log(signUp)
	return (
		<Overlay>
			<Modal>
				<CloseButton onClick={onClose}>
					<AiOutlineClose />
				</CloseButton>
				{children}
			</Modal>
		</Overlay>
	)
}

export default BeforeSignInModals
