import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import {Text} from '../../styled/shared'
import {Mandatory} from './InputField'

interface Option {
	label: string
	value?: string | number
	render?: string
}

export interface InputProps {
	name?: string
	label?: string | undefined
	options: Option[]
	onChange?(selectedOptions: Option[]): void
	value?: any
	isMulti?: any
	noOptionsMessage?: any
	defalutValue?: any
	required?: any
	disabled?: any
	error?: any
	searchMembers?: any
	placeholder?: any
	padding?: any
	radius?: any
	isChat?: boolean
	inputChange?: any
	defaultInputValue?: any
	selectCoins?: any
	isSearchable?: any
	hideSelectedOptions?: any
}

const MultiSelect = ({
	name,
	label,
	options,
	onChange,
	error,
	value,
	isMulti,
	noOptionsMessage,
	defalutValue,
	required,
	disabled,
	searchMembers,
	placeholder,
	padding,
	radius,
	isChat,
	inputChange,
	defaultInputValue,
	selectCoins,
	isSearchable,
	hideSelectedOptions,
	...props
}: InputProps) => {
	const isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [selectedOptions, setSelectedOptions] = useState<any[]>([])
	const [isFocused, setFocused] = useState(false)
	const [inputValue, setInputValue] = useState('')

	useEffect(() => {
		setSelectedOptions(options)
	}, [options])

	useEffect(() => {
		setInputValue(defaultInputValue)
	}, [defaultInputValue])

	const colourStyles = {
		control: (provided: any, state: any) => {
			setFocused(state.isFocused)
			return {
				...provided,

				border: selectCoins ? 'none' : '1px solid #0000001F',
				borderColor: 'none',
				color: isDarkTheme ? palette.light_gray : `${palette.text_color} !important`,
				width: '100% !important',
				padding: searchMembers ? '0' : padding ? padding : '0.45rem 0.5rem',
				backgroundColor: selectCoins ? '#F5F5F5' : 'white',
				boxShadow: 'none !important',
				textAlign: 'start',
				minHeight: selectCoins ? '1rem' : '',
				borderRadius: searchMembers ? '0.15rem' : radius ? radius : '0.8rem',
				'&:hover': {
					border: selectCoins ? 'none' : `${searchMembers ? '2px' : '1px'} solid ${state.isFocused ? palette.purple_1 : isDarkTheme ? palette.light_silver : palette.silver}`,
					boxShadow: 'none !important'
				}
			}
		},
		input: (provided: any, state: any) => ({
			...provided,
			color: isDarkTheme ? palette.light_gray : `${palette.text_color} !important`,
			fontSize: selectCoins ? '0.8rem' : '',
			padding: selectCoins ? '0rem' : '',
			margin: selectCoins ? '0rem' : ''
		}),
		ValueContainer: (provided: any, state: any) => ({
			...provided,
			color: isDarkTheme ? palette.light_gray : `${palette.text_color} !important`,
			fontSize: selectCoins ? '0.8rem' : '',
			padding: selectCoins ? '0.8rem' : ''
		}),
		singleValue: (provided: any, state: any) => ({
			...provided,
			color: isDarkTheme ? palette.light_gray : `${palette.text_color} !important`,
			fontSize: selectCoins ? '0.7rem' : '',
			padding: selectCoins ? '0rem' : ''
		}),
		indicatorSeparator: (provided: any, state: any) => ({
			...provided,
			display: 'none',
			fontSize: selectCoins ? '0.7rem' : '',
			padding: selectCoins ? '0rem' : ''
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			color: '#1C1B1F',
			padding: selectCoins ? '0rem' : '',
			fontSize: selectCoins ? '0.7rem' : '',
			width: selectCoins ? '0.8rem' : ''
		}),
		multiValue: (provided: any, state: any) => ({
			...provided,
			background: isChat ? palette.primary_50 : '',
			borderRadius: isChat ? '20px' : ''
		}),
		placeholder: (provided: any, state: any) => ({
			...provided,
			fontSize: '0.9rem',
			lineHeight: '1.2rem',
			fontWeight: '500',
			color: 'black',
			opacity: '50%'
		}),

		option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
			return {
				...styles,
				width: '100% !important',
				textAlign: 'left',
				zIndex: 5,
				cursor: isDisabled ? 'not-allowed' : 'default',
				color: isDarkTheme ? palette.light_gray : `${palette.text_color} !important`,
				':hover': {
					backgroundColor: '#f3f6f9',
					color: isDisabled ? null : palette.black
				},
				':focus': {
					backgroundColor: isSelected ? palette.secondary : null,
					color: isDisabled ? null : palette.white
				},
				':active': {
					backgroundColor: isSelected ? palette.secondary : null,
					color: isDisabled ? null : palette.white
				}
			}
		}
	}
	const _noOptionsMessage = () => (noOptionsMessage ? noOptionsMessage : '')

	const handleInputChange = (input: any, {action}: any) => {
		setInputValue(input)
		inputChange && inputChange(input)
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			event.preventDefault() // Prevent default form submission
			const inputValue = event.target.value.trim()

			if (inputValue !== '') {
				// Check if the input value already exists in the selected options
				const existingOption = selectedOptions.find((option) => option.label === inputValue)

				// If the input value doesn't exist, add it as a custom value
				if (!existingOption) {
					const customOption = {value: inputValue, label: inputValue}
					setSelectedOptions([...selectedOptions, customOption])
					if (value) {
						onChange && onChange([...value, customOption])
						setInputValue('')
					}
				}
			}
		}
	}
	return (
		<>
			{label &&
				(searchMembers ? (
					''
				) : (
					<Label type="small" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={isDarkTheme}>
						{label}
						{required ? <Mandatory> *</Mandatory> : ''}
					</Label>
				))}
			<Select
				placeholder={placeholder || label}
				styles={colourStyles}
				name={name}
				value={value}
				onChange={(e) => onChange && onChange(e)}
				options={selectedOptions}
				isMulti={isMulti}
				isDisabled={disabled}
				required={required}
				loadingMessage={noOptionsMessage}
				noOptionsMessage={_noOptionsMessage}
				onInputChange={handleInputChange}
				// onKeyDown={handleKeyDown}
				inputValue={inputValue}
				hideSelectedOptions={hideSelectedOptions}
				isClearable={false}
				isSearchable={isSearchable ?? true}
				defaultInputValue={inputValue}
			/>
			{error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error?.message ? error?.message : error}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`
const SubLable = styled.span`
	font-size: 0.875rem;
	color: ${palette.text_color};
	font-family: 'Roboto';
	text-transform: capitalize;
	margin-left: 0.5rem;
`
export default MultiSelect
