import React, {useEffect, useRef, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {BiBlock, BiPencil, BiTrash} from 'react-icons/bi'
import {DropContent, Icons, MenuList, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'
import {toast} from 'react-toastify'
import Input from '../components/common/input/Input'
import moment from 'moment'
import {Card} from '../pages/Dashboard'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import useRouter from '../hooks/router'
import {CustomFlexedForMessages, CustomMessageFlex, DropDownList, NetwrokUsers, TrashMesssageIcons} from '../pages/Inbox'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {useDispatch} from 'react-redux'
import {Effects} from '../pages/effects'
import {chatAllUsers, chatUnreadMessages, chatUsers, networkUsers} from '../actions/authActions'
import EmojiPicker from 'emoji-picker-react'
import {FaTrash} from 'react-icons/fa'
import {getFirstWords} from '../utils'
import ConfirmationPopup from './modals/ConfirmationModel'
import MultiSelect from './common/MultiSelect'
import {api} from '../helpers/auth-axios'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import Loader from './common/Loader'
import BottomChatModel from './BottomChatModel'
import UserProfileLoader from './common/UserProfileLoader'

const BottomChatCardPopup = () => {
	const [openChatModel, setOpenChatModel] = useState(false)
	const [openChatPopupModel, setOpenChatPopupModel] = useState(false)
	const [myNetwrokTab, setMyNetworkTab] = useState(true)
	const [searchChatUserQuery, setSearchChatUserQuery] = useState('')
	const {t} = useTranslation()
	const router = useRouter()
	const [selectedUser, setSelectedUser] = useState<any>('')
	let _chatUsers: any = useSelector<any>((state: any) => state.auth.chatUsers)
	const _chatAllUsers: any = useSelector<any>((state: any) => state.auth.chatAllUsers)

	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [openEmoji, setOpenEmoji] = useState(false)
	const [newMessage, setNewMessage] = useState(false)
	const [selectedUserIndex, setSelectedUserIndex] = useState<any>('')
	const getUserStatus: any = localStorage.getItem('isNewUser')
	const menuRef: any = useRef(null)
	const dispatch = useDispatch()
	const [handleComment, setHandleComment] = useState<any>('')
	const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null | undefined>()
	const [isMobileView, setIsMobileView] = useState(false)
	const [fileInputVisible, setFileInputVisible] = useState(false)
	const [selectedNewChatUsers, setSelectedNewChatUsers] = useState<any[]>([])
	const chatRef: any = useRef()
	const [conversationMessages, setConversationMessages] = useState<any[]>([])
	const [messageLaoding, setMessageLoading] = useState(false)
	const [openMenu, setOpenMenu] = useState(false)
	const _menuRef: any = useRef(null)
	const [openMenuIndex, setOpenMenuIndex] = useState(null)
	const [confirmationModel, setConfirmationModel] = useState({type: '', open: false})
	const [userId, setUserId] = useState<any>('')
	const [chatFetchedUsers, setChatUsers] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [networkUser, setNetworkUsers] = useState<any>([])
	const [submitting, setSubmitting] = useState(false)
	const [allUsers, setAllUsers] = useState<any>([])
	const [chatLoading, setChatLoading] = useState(false)
	const [selectedUsers, setSelectedUsers] = useState<any[]>([])
	const [openChatIndex, setOpenChatIndex] = useState(null)
	const [page, setPage] = useState(1)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)

	useEffect(() => {
		let usersss: any = _chatUsers?.length ? _chatAllUsers.filter((user: any) => !_chatUsers.some((e: any) => e?.conversationWith?.uid === user?.uid)) : _chatAllUsers
		usersss = usersss?.filter((e: any) => (networkUser?.length ? networkUser?.includes(e?.uid) : myNetworkUsers?.includes(e?.uid)))
		setAllUsers(usersss)
	}, [myNetworkUsers?.length, userDetails, _chatAllUsers?.length, _chatUsers?.length, networkUser])

	useEffect(() => {
		if (router.pathname == '/logout') {
			setOpenChatModel(false)
			setNewMessage(false)
		}
	}, [router.pathname])
	useEffect(() => {
		setNetworkUsers(myNetworkUsers)
		getNetworkUsers()
	}, [userDetails?.chat_id, newMessage])

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_menuRef.current && !_menuRef.current.contains(event.target)) {
				setOpenMenu(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [_menuRef])

	useEffect(() => {
		CometChat.addMessageListener(
			'CHAT_BOTTOM_MESSAGE_LISTENER',
			new CometChat.MessageListener({
				onTextMessageReceived: (textMessage: any) => {
					if (ids?.length) {
						getUsers()
					}

					if (!_chatUsers?.some((e: any) => e?.conversationWith?.uid == textMessage?.sender?.uid)) {
						getUsers()
					}

					if (selectedUser?.uid == textMessage?.sender?.uid) {
						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == selectedUser?.uid ? {...e, lastMessage: textMessage} : e))
						setChatUsers(userss)

						setConversationMessages((prevMessages: any) => [...prevMessages, textMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length + 1))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 1, lastMessage: textMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onMediaMessageReceived: (mediaMessage: any) => {
					if (ids.length) {
						getUsers()
					}
					if (selectedUser?.uid == mediaMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, mediaMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == mediaMessage?.sender?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length + 1))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == mediaMessage?.sender?.uid ? {...e, unreadMessageCount: 1, lastMessage: mediaMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onCustomMessageReceived: (customMessage: any) => {
					if (ids.length) {
						getUsers()
					}
					console.log('==========customMessage', customMessage)
					// alert(customMessage)
					if (selectedUser?.uid == customMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, customMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == customMessage?.sender?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length + 1))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == customMessage?.sender?.uid ? {...e, unreadMessageCount: 1, lastMessage: customMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onMessagesDelivered: (messageReceipt: any) => {
					console.log('==========messageReceipt', messageReceipt)
					if (ids.length) {
						getUsers()
					}
					if (selectedUser?.uid == messageReceipt?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, messageReceipt])
						scrollToBottom()
					}
				},
				onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
					console.log('==========messageReceipt', messageReceipt)
				},
				onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
					console.log('==========typingIndicator', typingIndicator)
				},
				onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {
					console.log('==========typingIndicator', typingIndicator)
				},
				onMessagesDeleted: (message: any) => {
					if (ids.length) {
						getUsers()
					}
					if (!_chatUsers?.some((e: any) => e?.conversationWith?.uid == message?.sender?.uid)) {
						getUsers()
					}
					if (selectedUser?.uid == message?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, message])
						scrollToBottom()
					}
				},
				onMessagesEdited: (message: CometChat.BaseMessage) => {},
				onInteractiveMessageReceived: (message: any) => {
					console.log('interactive Message received', message)
				},
				onInteractionGoalCompleted: (message: any) => {
					console.log('Message interaction goal completed', message)
				}
			})
		)
	})

	const blockUser = async (uid?: any) => {
		let usersList: String[] = [uid]

		CometChat.blockUsers(usersList).then(
			(list: any) => {
				getUsers()
				setConfirmationModel({open: false, type: ''})
				toast.success(list[uid]?.message)
			},
			(error: CometChat.CometChatException) => {
				console.log('Blocking user fails with error', error)
			}
		)
	}

	const getNetworkUsers = async () => {
		setLoading(true)
		await api
			.get('/user/network_users')
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data) {
						setNetworkUsers(res?.data?.data)
						dispatch(networkUsers(res?.data?.data))
					}
				}
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
				console.log('====error', error)
			})
	}

	const emojiPickerRef: any = useRef(null)

	useEffect(() => {
		setChatUsers(_chatUsers)

		if (router?.query?.id) {
			let user = _chatUsers?.find((e: any) => e?.conversationWith?.uid == router?.query?.id)
			if (user) {
				setSelectedUser(user?.conversationWith)
			}
		}
	}, [_chatUsers, router?.query?.id])

	const handleImageIconClick = () => {
		setFileInputVisible(true)
	}

	const firstMessageEmail = async (email: any, name: any, chatId: any, userDetails: any) => {
		try {
			await api.post('/user/first_message_email', {email, name, chatId, userDetails}).then((res: any) => {
				console.log('======message email sent', res?.data)
			})
		} catch (error) {}
	}

	const handleAttachmentIconClick = () => {
		// Show the file input for file attachment
		setFileInputVisible(true)
	}
	let ids = newMessage && selectedNewChatUsers?.length ? selectedNewChatUsers?.map((e: any) => e?.value?.uid) : []

	const handleFileInputChange = async (event: any) => {
		const file = event.target.files[0]
		if (ids?.length) {
			setLoading(true)
			let messageAdded: any[] = []
			for (let i = 0; i < ids.length; i++) {
				let receiverID: string = ids[i],
					messageType: string = file?.type?.startsWith('image') ? CometChat.MESSAGE_TYPE.IMAGE : CometChat.MESSAGE_TYPE.FILE,
					receiverType: string = CometChat.RECEIVER_TYPE.USER,
					mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType)

				await CometChat.sendMediaMessage(mediaMessage).then(
					async (message: any) => {
						if (message?.receiver?.metadata?.email) {
							await firstMessageEmail(
								message?.receiver?.metadata?.email,
								message?.receiver?.metadata?.full_name ? message?.receiver?.metadata?.full_name : message?.receiver?.metdata?.first_name,
								message?.sender?.uid,
								message?.receiver?.metadata
							)
						}
						messageAdded.push(message)
						scrollToBottom()
					},
					(error: CometChat.CometChatException) => {
						console.log('Media message sending failed with error', error)
					}
				)

				setFileInputVisible(false)
				if (i == ids.length - 1 && messageAdded?.length) {
					getUsers()
				}
			}
			setLoading(false)
			setSubmitting(false)
			setSelectedNewChatUsers([])
			setNewMessage(false)
		} else {
			let receiverID: string = selectedUser?.uid,
				messageType: string = file?.type?.startsWith('image') ? CometChat.MESSAGE_TYPE.IMAGE : CometChat.MESSAGE_TYPE.FILE,
				receiverType: string = CometChat.RECEIVER_TYPE.USER,
				mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType)

			await CometChat.sendMediaMessage(mediaMessage).then(
				(message: any) => {
					setConversationMessages((prevMessages: any) => [...prevMessages, message])
					scrollToBottom()
				},
				(error: CometChat.CometChatException) => {
					console.log('Media message sending failed with error', error)
				}
			)
			setFileInputVisible(false)
		}
	}

	useEffect(() => {
		const loginUser = async () => {
			let user = await CometChatUIKit.getLoggedinUser()
				.then((res) => {
					return res
				})
				.catch((err) => {
					return null
				})
			if (user?.getUid()) {
				await getUsers()
			} else {
				await getUsers()
			}
		}

		loginUser()
	}, [userDetails?.chat_id, networkUser?.length])

	useEffect(() => {
		if (selectedUser?.uid) {
			getUserConversation(selectedUser?.uid)
		}
	}, [selectedUser?.uid])

	Effects({
		setLoggedInUser,
		setIsMobileView
	})

	const deleteUserChat = async (uid?: any) => {
		try {
			let UID: string = uid
			let type: string = 'user'
			await CometChat.deleteConversation(UID, type).then(
				async (deletedConversation: string) => {
					if (UID == selectedUser?.id) {
						setConversationMessages([])
						setSelectedUser('')
					}
					setSelectedUsers(selectedUsers?.filter((re: any) => re?.uid != uid))

					setConfirmationModel({open: false, type: ''})
					await saveDetails()
				},
				(error: CometChat.CometChatException) => {
					console.log('error while deleting a conversation', error)
				}
			)
		} catch (error) {}
	}

	const getUserConversation = async (uid?: any) => {
		try {
			if (uid) {
				setMessageLoading(true)
				let messagesRequest = new CometChat.MessagesRequestBuilder().setUID(uid).setMessageId(1).hideDeletedMessages(true).setLimit(50).build()
				messagesRequest.fetchNext().then(
					async (messages) => {
						setMessageLoading(false)
						setConversationMessages(messages)
						scrollToBottom()
						if (messages?.length) {
							await CometChat.markAsRead(messages[0])
							let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == uid ? {...e, unreadMessageCount: 0} : e))

							let totalUnread = data.filter((e: any) => e.unreadMessageCount)
							CometChat.getUnreadMessageCount().then((res: any) => {
								dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
								return res
							})

							// dispatch(chatUnreadMessages(totalUnread?.length))
							setChatUsers(data)
							dispatch(chatUsers(data))
						}
					},
					(error) => {
						setMessageLoading(false)
						console.log('Conversations list fetching failed with error:', error)
					}
				)
			}
		} catch (error) {
			console.log('Initialization failed with error:', error)
		}
	}

	const chatLogin = async () => {
		return await CometChatUIKit.login(userDetails?.chat_id)
			?.then(async (loggedInUser: any) => {
				console.log('loggedInUser at bottom chat model', loggedInUser)
				const result: any = await CometChat.getUnreadMessageCount()
				dispatch(chatUnreadMessages(Object.keys(result?.users)?.length))
				return loggedInUser
			})
			.catch((err) => {
				console.log('error', err?.message)
				return null
			})
	}
	const getUsers = async (limit: number = 30, page: number = 1) => {
		try {
			setChatLoading(true)
			if (userDetails?.chat_id) {
				let userLogin = await CometChatUIKit.login(userDetails?.chat_id)
					?.then((loggedInUser: any) => {
						return loggedInUser
					})
					.catch((err) => {
						console.log('error', err?.message)
						return null
					})

				if (!userLogin) {
					setTimeout(() => {
						userLogin = chatLogin()
						saveDetails(limit, page)
					}, 5000)
				}

				if (userLogin) {
					saveDetails(limit, page)
				}
			}
		} catch (error) {
			setChatLoading(false)
			console.error('Error fetching users:', error)
		}
	}

	useEffect(() => {
		if (page > 1) {
			saveDetails(30 * page, page)
		}
	}, [page])

	const saveDetails = async (limit: number = 30, page: number = 1) => {
		const usersRequest = new CometChat.UsersRequestBuilder()
			.hideBlockedUsers(true)
			.setUIDs(networkUser?.length ? networkUser : myNetworkUsers)
			.setLimit(limit)
			.build()

		let conversationsRequest = new CometChat.ConversationsRequestBuilder().setLimit(50).withUserAndGroupTags(true).build()

		await conversationsRequest.fetchNext().then(
			(conversationList) => {
				let conversation = conversationList.filter((res: any) => !res?.conversationWith?.deactivatedAt)
				dispatch(chatUsers(conversation))
				setChatLoading(false)
				if (conversationList.length === limit) {
					setPage(page + 1)
				}
			},
			(error) => {
				setChatLoading(false)
				console.log('Conversations list fetching failed with error:', error)
			}
		)
		try {
			const userList = await usersRequest.fetchNext()
			dispatch(chatAllUsers(userList))
			setChatLoading(false)
			if (userList.length === limit) {
				setPage(page + 1)
			}
		} catch (error) {
			setChatLoading(false)
			console.error('Error fetching users:', error)
		}
	}

	const scrollToBottom = () => {
		if (chatRef.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}
	}

	const sendMessage = async () => {
		try {
			setSubmitting(true)
			if (ids?.length) {
				let messageAdded: any[] = []
				for (let i = 0; i < ids.length; i++) {
					let receiverID: any = ids[i],
						messageText: string = handleComment,
						receiverType: string = CometChat.RECEIVER_TYPE.USER,
						textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, messageText, receiverType)

					await CometChat.sendMessage(textMessage).then(
						async (message: any) => {
							if (message?.receiver?.metadata?.email) {
								await firstMessageEmail(
									message?.receiver?.metadata?.email,
									message?.receiver?.metadata?.full_name ? message?.receiver?.metadata?.full_name : message?.receiver?.metdata?.first_name,
									message?.sender?.uid,
									message?.receiver?.metadata
								)
							}
							messageAdded.push(message)
							setHandleComment('')
						},
						(error: CometChat.CometChatException) => {
							setSubmitting(false)
							console.log('Message sending failed with error:', error)
						}
					)
					if (i == ids.length - 1 && messageAdded?.length) {
						setSubmitting(false)
						getUsers()
					}
				}
				setSubmitting(false)
				setSelectedNewChatUsers([])
			} else {
				let receiverID: any = selectedUser?.uid,
					messageText: string = handleComment,
					receiverType: string = CometChat.RECEIVER_TYPE.USER,
					textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, messageText, receiverType)

				CometChat.sendMessage(textMessage).then(
					(message: any) => {
						setHandleComment('')

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == selectedUser?.uid ? {...e, lastMessage: message} : e))
						setChatUsers(userss)

						setConversationMessages((prevMessages: any) => [...prevMessages, message])
						scrollToBottom()
						setSubmitting(false)
					},
					(error: CometChat.CometChatException) => {
						setSubmitting(false)
						console.log('Message sending failed with error:', error)
					}
				)
			}
		} catch (error) {
			setSubmitting(false)
		}
	}

	const newMessageUsers: any[] = allUsers?.map((user: any) => ({
		label: (
			<Flexed direction={'row'} align={'center'} gap={'0.5'}>
				<UserProfileLoader width={'2.8rem'} height={'2.8rem'} imgUrl={user?.metadata?.image_Url ? user?.metadata?.image_Url : user?.avatar} />
				<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'500'} color={'dark_blue'}>
					{user?.name}
				</Text>
				{user?.metadata?.linkedin_profile_verified || user?.metadata?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
			</Flexed>
		),
		value: user
	}))

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenEmoji(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<Container style={{overflow: 'hidden', position: 'relative', zIndex: '0', overflowX: 'scroll', width: '40rem'}}>
			{loading && (
				<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'}>
					<Loader isComponent={true} />
				</Flexed>
			)}
			<Row justifyContent="end">
				<Flexed direction={'row'} gap={'1'} overflow={'scroll'}>
					{selectedUsers?.length ? (
						<div
							style={{
								position: 'fixed',
								bottom: 0,
								right: 0,
								display: 'flex',
								// overflow: 'hidden',
								flexDirection: 'row-reverse',
								width: '80%' // Add width
								// height: '100%' // Add height
							}}>
							{selectedUsers?.map((res: any, index: any) => (
								<div key={index} style={{marginLeft: index === selectedUsers.length - 1 ? 0 : '2rem'}}>
									<BottomChatModel
										key={index}
										index={index}
										selectedUser={res}
										setSelectedUser={(e: any) => {
											setSelectedUsers((prev: any) => {
												const updatedMeetings = prev.filter((meeting: any) => meeting.uid !== res.uid)
												return updatedMeetings
											})
										}}
										setChatUsers={setChatUsers}
										chatFetchedUsers={chatFetchedUsers}
										openChatIndex={openChatIndex}
										setOpenChatIndex={setOpenChatIndex}
									/>
								</div>
							))}
						</div>
					) : (
						''
					)}
				</Flexed>
				{openChatPopupModel && newMessage ? (
					<Col lg={6}>
						<Flexed direction="row" justify={'flex-end'} margin={'0'} style={{borderBottom: 'none'}}>
							<CustomCardFlex radius={'1.25rem 1.25rem  0 0'} minHeight={openChatPopupModel ? '20rem' : '4rem'} background={palette.white} padding={'0'}>
								{newMessage && chatLoading && (
									<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'}>
										<Loader isComponent={true} />
									</Flexed>
								)}

								{newMessage ? (
									<Flexed direction="row" margin={'0'} gap={'1'} justify={'space-between'} padding={'1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<Flexed gap={'0.5'} width="100%">
											<MultiSelect
												hideSelectedOptions={true}
												isSearchable={false}
												isChat={true}
												isMulti={true}
												noOptionsMessage={'No user found'}
												value={selectedNewChatUsers}
												options={newMessageUsers}
												onChange={(e: any) => setSelectedNewChatUsers(e)}
											/>
										</Flexed>
										<Flexed direction="row" align={'center'} gap={'0.5'}>
											<Icons
												src={'/images/icon_close.svg'}
												alt="i"
												pointer={'pointer'}
												onClick={() => {
													setSelectedNewChatUsers([])
													setOpenChatPopupModel(!openChatPopupModel)
													setSelectedUser('')
												}}
											/>
										</Flexed>
									</Flexed>
								) : (
									<></>
								)}

								<Spacer height={1} />
								{openEmoji && selectedNewChatUsers?.length && <Spacer height={16} />}
								<Card background={'transparent'} removeShadow minHeight={'auto'} height={'100%'} overflow={'visible'} display={!selectedUser?.uid && 'flex'}>
									{selectedNewChatUsers?.length ? (
										<form
											onSubmit={(e) => {
												e.preventDefault()
												if (selectedUser || selectedNewChatUsers?.length) {
													handleComment?.trim()?.length && sendMessage()
												}
											}}>
											<CustomFlexedForMessages padding={'0.5rem 2rem 1rem 0rem '} gap={'1'}>
												<Input label={t('Message')} isSignup isContact height={'70.4px'} value={handleComment} onChange={(e: any) => setHandleComment(e?.value)} />
												<Flexed direction={'row'} align={'center'} justify={'space-between'}>
													<Flexed direction={'row'} align={'center'} gap={'0.5'}>
														<label htmlFor="imageInput" style={{marginBottom: '0rem'}}>
															<Icons src={'/images/icon_image.svg'} pointer={'pointer'} onClick={handleImageIconClick} />
														</label>
														<label htmlFor="attachmentInput" style={{marginBottom: '0rem'}}>
															<Icons src={'/images/icon_link.svg'} pointer={'pointer'} onClick={handleAttachmentIconClick} />
														</label>

														<EmojiPickerWrapper>
															<EmojiButton
																onClick={(e) => {
																	setOpenEmoji(!openEmoji)
																}}
																ref={menuRef}>
																<Icons src={'/images/icon_smile.svg'} alt="i" pointer />
															</EmojiButton>
															{openEmoji && (
																<PickerContainer ref={emojiPickerRef}>
																	<EmojiPicker
																		onEmojiClick={(e) => {
																			setHandleComment(handleComment + e?.emoji)
																		}}
																	/>
																</PickerContainer>
															)}
														</EmojiPickerWrapper>

														<input id="imageInput" type="file" accept="image/*" style={{display: 'none', position: 'relative', zIndex: '9999'}} onChange={(e) => handleFileInputChange(e)} />

														<input id="attachmentInput" type="file" style={{display: 'none'}} onChange={(e) => handleFileInputChange(e)} />
													</Flexed>
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														fontWeight={600}
														padding={'0 1rem'}
														label={<>{t('SEND')}</>}
														background={palette.blue_500}
														color={palette.white}
														lineHeight={'0.91rem'}
														disabled={submitting}
														// ifClicked={() => handleComment?.trim()?.length && sendMessage()}
													/>
												</Flexed>
											</CustomFlexedForMessages>
										</form>
									) : (
										''
									)}
								</Card>
							</CustomCardFlex>
						</Flexed>
					</Col>
				) : (
					''
				)}
				<Col lg={3}>
					<Flexed direction="row" justify={'flex-end'} margin={'0'} style={{borderBottom: 'none'}}>
						<Card
							radius={'1.25rem 1.25rem  0 0'}
							minHeight={openChatModel ? '20rem' : '4rem'}
							background={palette.white}
							padding={'0'}
							width={'20rem'}
							style={{
								position: 'fixed',
								top: openChatModel ? '20%' : '91%',
								right: '2rem',
								zIndex: 9999,
								transition: 'bottom 0.3s ease-in-out',
								boxShadow: `0px 0px 10px 0px #0000001A`,
								oveflow: 'hidden'
							}}>
							<Flexed direction="row" margin={'0'} justify={'space-between'} padding={'1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}}>
								<Flexed
									direction="row"
									align={'center'}
									gap={'1'}
									pointer
									onClick={() => {
										setSelectedUser('')
										setNewMessage(true)
										setOpenChatPopupModel(true)
									}}>
									<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'} onClick={() => router.navigate('/dashboard')}>
										<UserProfileLoader width={'2.5rem'} height={'2.5rem'} imgUrl={userDetails?.image_Url} />
										<AvailabilityIconWrapper src={'/images/green_circle.svg'} alt="upload" />
									</ProfileImgWrapper>

									<Text fontSize={'1.2'} lineHeight={'1.26'} fontWeight={'600'} color={'text_color'}>
										{t('Messaging')}
									</Text>
								</Flexed>
								<Flexed direction="row" align={'center'} gap={'0.5'}>
									<Icons
										src={'/images/icon_new message.svg'}
										alt="i"
										pointer={'pointer'}
										onClick={() => {
											setSelectedUser('')
											setSelectedUsers([])
											setNewMessage(true)
											setOpenChatPopupModel(true)
										}}
									/>
									<Icons
										src={openChatModel ? '/images/icon_view_down.svg' : '/images/icon_view_top.svg'}
										alt="i"
										onClick={() => {
											setSelectedUser('')
											setOpenChatModel(!openChatModel)
										}}
										pointer={'pointer'}
									/>
								</Flexed>
							</Flexed>
							<Spacer height={1} />
							<Flexed direction="row" align={'center'} gap={'0.5'} padding={' 0 1.2rem '}>
								<Input
									isSearch
									isSignup
									label={'Search'}
									onChange={(e: any) => {
										setSearchChatUserQuery(e.value)
									}}
								/>
							</Flexed>
							<Flexed direction="row" align={'center'} gap={'0.5'}></Flexed>
							<Spacer height={0.5} />
							{chatLoading && (
								<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'}>
									<Loader isComponent={true} />
								</Flexed>
							)}
							{myNetwrokTab ? (
								<Flexed isBottomChat direction="column" gap={'0.5'} height="23rem">
									<Flexed height={'44.3rem'} style={{overflow: 'hidden', overflowY: 'scroll'}}>
										{chatFetchedUsers?.length ? (
											chatFetchedUsers
												.filter((e: any) => e?.conversationWith?.name?.toLowerCase().includes(searchChatUserQuery.toLowerCase()))
												?.map((user: any, i: any) => {
													return (
														!(user?.conversationWith?.hasBlockedMe || user?.conversationWith?.blockedByMe) && (
															<NetwrokUsers direction="column" align={'center'} padding={'10px 12px 10px 12px'} background={selectedUser && selectedUserIndex == i ? palette.active_inbox_user : ''} radius={'0.8rem'}>
																<CustomFlexedForWrap direction="column" width={'100%'} align={'left'} justify={'space-between'}>
																	<Flexed
																		direction="row"
																		justify={'space-between'}
																		align={'start'}
																		pointer
																		onClick={() => {
																			setHandleComment('')
																			setSelectedUserIndex(i)
																			setNewMessage(false)
																			setSelectedUser(user?.conversationWith)
																			setOpenChatPopupModel(true)

																			if (!selectedUsers?.some((e: any) => e?.uid === user?.conversationWith?.uid)) {
																				if (selectedUsers?.length == 2) {
																					setSelectedUsers(selectedUsers.slice(1))
																				}
																				setSelectedUsers((pre: any) => [...pre, user?.conversationWith])
																			}
																		}}>
																		<Flexed direction="row" align={'start'}>
																			<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
																				<UserProfileLoader
																					width={'2.5rem'}
																					height={'2.5rem'}
																					imgUrl={
																						user?.conversationWith?.avatar
																							? user?.conversationWith?.avatar
																							: user?.conversationWith?.metadata?.image_Url
																							? user?.conversationWith?.metadata?.image_Url
																							: `/images/user_default.png`
																					}
																				/>
																				<AvailabilityIconWrapper
																					src={`/images/${
																						user?.conversationWith?.status == 'online'
																							? 'green_circle'
																							: user?.conversationWith?.status == 'offline'
																							? 'gray_circle'
																							: user?.status == 'red'
																							? 'red_circle'
																							: 'pink_circle'
																					}.svg`}
																					alt="upload"
																				/>
																			</ProfileImgWrapper>

																			<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
																				<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
																					<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
																						{user?.conversationWith?.name}
																					</Text>
																					{user?.conversationWith?.metadata?.linkedin_id || user?.conversationWith?.metadata?.linkedin_profile_verified ? <Icons src={`/images/linkedin.svg`} /> : ''}
																				</Flexed>
																				<Spacer height={0.2} />
																				<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
																					{user?.conversationWith?.designation}
																				</Text>
																				<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
																					{user?.lastMessage?.text ? (
																						<Text
																							fontSize={'0.8'}
																							lineHeight={'1'}
																							fontWeight={user?.unreadMessageCount ? '600' : '400'}
																							color={'black'}
																							opacity={user?.unreadMessageCount ? '1' : '57%'}
																							wordBreak>
																							{userDetails?.chat_id != user?.lastMessage?.receiverId
																								? t('You:') + getFirstWords(user?.lastMessage?.text, 20)
																								: t('Replied:') + getFirstWords(user?.lastMessage?.text, 20)}
																						</Text>
																					) : (
																						''
																					)}
																				</Flexed>
																			</Flexed>
																		</Flexed>
																		<MenuList type="normal" onSmall>
																			<Text
																				onClick={(e: any) => {
																					e?.stopPropagation()
																					setOpenMenu(true)
																					if (openMenuIndex === i) {
																						setOpenMenuIndex(null)
																					} else {
																						setOpenMenuIndex(i)
																					}
																				}}>
																				<Icons pointer={'pointer'} src={'/images/icon_options.svg'} />
																			</Text>
																			{openMenuIndex == i ? (
																				<DropContent ref={_menuRef} top={'92%'} display={openMenu ? true : false}>
																					<DropDownList
																						padding={'0.5rem'}
																						direction={'row'}
																						gap="0.3"
																						style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}
																						onClick={(e: any) => {
																							e?.stopPropagation()
																							setUserId(user?.conversationWith?.uid)
																							setConfirmationModel({type: 'block', open: true})
																						}}>
																						<BiBlock /> {t('Block User')}
																					</DropDownList>
																					<DropDownList
																						padding={'0.5rem'}
																						direction={'row'}
																						gap="0.3"
																						style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}
																						onClick={(e: any) => {
																							e?.stopPropagation()
																							setUserId(user?.conversationWith?.uid)
																							setConfirmationModel({type: 'delete', open: true})
																						}}>
																						<FaTrash /> {t('Delete Conversation')}
																					</DropDownList>
																				</DropContent>
																			) : (
																				''
																			)}
																		</MenuList>
																	</Flexed>
																</CustomFlexedForWrap>
																<Spacer height={0.2} />
															</NetwrokUsers>
														)
													)
												})
										) : (
											<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'} margin={'auto'}>
												<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
													{t('No user available')}
												</Text>
											</Flexed>
										)}
									</Flexed>
								</Flexed>
							) : (
								<></>
							)}
						</Card>
					</Flexed>
				</Col>
			</Row>

			{confirmationModel.open && (
				<ConfirmationPopup
					header={confirmationModel.type == 'delete' ? 'Are you sure you want to delete this user chat?' : 'Are you sure you want to block this user?'}
					onClose={() => {
						setUserId('')
						setConfirmationModel({open: false, type: ''})
					}}
					buttonTitle={confirmationModel.type == 'delete' ? 'Delete' : 'Block'}
					onConfirm={() => {
						if (userId) {
							confirmationModel.type == 'delete' ? deleteUserChat(userId) : blockUser(userId)
						}
					}}
				/>
			)}
		</Container>
	)
}

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`

const AvailabilityIconWrapper = styled(Icons)<any>`
	position: absolute;
	bottom: 0;
	margin-left: 1.8rem;
`

const OuterDiv = styled.div`
	width: 100px;
	border-radius: 1rem;
	overflow: hidden;
`

const InnerDiv = styled.div`
	width: 100%;
	height: 100%;
	border-width: 2px;
	border-style: solid;
	/* border-image: ${palette.blue_500} 1; */
	border-color: ${palette.blue_500};
`

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

const MessageContent = styled(Flexed)`
	/* width: 19.81rem;
	${media.xs`
	width: auto;
	 `};
	${media.sm`
	width: auto
	 `};
	${media.md`
	width: 19.81rem;
	 `};
	${media.lg`
	width: 19.81rem;
	 `};
	${media.xl`
	width: 19.81rem;
	 `}; */
`

const CustomCardFlex = styled(Card)<any>`
	width: 40rem;
	position: fixed;
	top: 20%;
	right: 27%;
	z-index: 0;
	transition: bottom 0.3s ease-in-out;
	box-shadow: 0px 0px 10px 0px #0000001a;
	${media.xs`
	right: 1%;
	width: auto;
	 `};
	${media.sm`
	right: 10%;
	width: auto;
	 `};
	${media.md`
	width: 40rem;
	right: 35%;
	 `};
	${media.lg`
	width: 40rem;
	right: 30%;
	 `};
	${media.xl`
	width: 40rem;
	right: 27%;
	 `};

	@media (min-width: 1200px) and (max-width: 1350px) {
		right: 30%;
		width: 30rem;
	}
	@media (min-width: 768px) and (max-width: 1200px) {
		right: 40%;
		width: 30rem;
	}
	@media (min-width: 500px) and (max-width: 768px) {
		right: 15%;
		width: 25rem;
	}
	@media (min-width: 250px) and (max-width: 500px) {
		right: 1%;
		width: 20rem;
	}
`

export const ChatMessageWrapper = styled(Flexed)`
	height: 18rem;
	${media.xs`
	height: 20rem;
	 `};
	${media.sm`
	height: 20rem;
	 `};
	${media.md`
	height: 18rem;
	 `};
	${media.lg`
	height: 18rem;
	 `};
	${media.xl`
	height: 18rem;
	 `};

	@media (min-width: 1200px) and (max-width: 1350px) {
		height: 18rem;
	}
	@media (min-width: 768px) and (max-width: 1200px) {
		height: 18rem;
	}
	@media (min-width: 500px) and (max-width: 768px) {
		height: 18rem;
	}
	@media (min-width: 250px) and (max-width: 500px) {
		height: 15rem;
	}
`

const EmojiPickerWrapper = styled.div`
	position: relative;
`

const EmojiButton = styled.div`
	background: transparent;
	border: none;
	cursor: pointer;
`

const PickerContainer = styled.div`
	position: absolute;
	bottom: 5rem;
	right: 0;
	z-index: 9999;
	${media.xs`
	left: -5rem;
bottom: 4rem;
	 `};
	${media.sm`
	left: -5rem;
bottom: 4rem;
	 `};
	${media.md`
	left:50%;
bottom: 4rem;
	 `};
	${media.lg`
    left:-100%;
bottom: 4rem;
	 `};
`

export default BottomChatCardPopup
