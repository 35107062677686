import React from 'react'
import {Helmet} from 'react-helmet-async'

const DynamicMetaTags = ({title, description, imageUrl, url}: any) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" itemProp="image" content={imageUrl} />
			<meta property="og:image:type" content="image/png/jpg/jpeg" />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />
			<meta property="og:url" content={url} />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="JustNetwork" />
			<meta property="og:image:secure_url" content={imageUrl} />
			<meta property="og:image:alt" content="A descriptive alt text for your image" />
			<link href={imageUrl} rel="shortcut icon" type="image/x-icon" />
			<link href={imageUrl} />
			{/* Twitter meta tags (optional) */}
			{/*
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:url" content={url} />
      */}
		</Helmet>
	)
}

export default DynamicMetaTags
