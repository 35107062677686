import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Paragraph, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import Input from '../components/common/input/Input'
import RequestCard from '../components/netwrokComponents/RequestCard'
import SentRequestCard from '../components/netwrokComponents/SentRequestCard'
import MyNetworkCards from '../components/netwrokComponents/MyNetworkCards'
import NetworkRequestCards from '../components/netwrokComponents/NetworkRequestCards'
import SuggestedNetworkCards from '../components/netwrokComponents/SuggestedNetworkCards'
import {api} from '../helpers/auth-axios'

import Loader from '../components/common/Loader'
import {useTranslation} from 'react-i18next'
import useRouter from '../hooks/router'
import Footer from '../components/Footer'

interface IProps {
	isDarkTheme?: boolean
}

const Network = () => {
	const [openChatModel, setOpenChatModel] = useState(false)
	const [fetchAgain, setFetchAgain] = useState(false)
	const [isSearch, setIsSearch] = useState(false)
	const [activeTab, setActiveTab] = useState<any>(1)
	const [requestActiveTab, setRequestActiveTab] = useState<any>(1)
	const [meetingTab, setMeetingTab] = useState<any>(false)

	const getUserStatus: any = localStorage.getItem('isNewUser')

	const [searchChatUserQuery, setSearchChatUserQuery] = useState('')
	const [users, setUsers] = useState([])
	const [clickTab, setClickTab] = useState<any>(false)
	const [sentMeetingsRequest, setSentMeetingsRequest] = useState<any>([])
	const [receivedMeetingsRequest, setReceivedMeetingsRequest] = useState<any>([])
	const [loading, setLoading] = useState(false)
	const [recentNetworks, setRecentNetworks] = useState<any[]>([])
	const {t} = useTranslation()
	const router = useRouter()

	useEffect(() => {
		if (router.query?.isRequest) {
			setActiveTab(2)
		}
		if (router.location.state == 'suggestion') {
			setActiveTab(3)
		}
	}, [router.query])
	// useEffect(() => {
	// 	getData()
	// }, [fetchAgain])

	const getData = async () => {
		await Promise.all([getSendedMeetings()])
	}
	const handleAccept = async (index: number) => {
		const updatedNetworks = [...recentNetworks]
		updatedNetworks[index].requestStatus = 'accepted'
		setRecentNetworks(updatedNetworks)
	}

	const handleIgnore = (index: number) => {
		const updatedNetworks = [...recentNetworks]
		updatedNetworks[index].requestStatus = 'ignored'
		setRecentNetworks(updatedNetworks)
	}

	const handleReschedule = (index: number) => {
		const updatedNetworks = [...recentNetworks]
		updatedNetworks[index].requestStatus = 'rescheduled'
		setRecentNetworks(updatedNetworks)
	}

	const getAllUsers = async () => {
		setLoading(true)
		await api
			.get('/user/get_user_networks')
			.then((res: any) => {
				setUsers(res?.data?.data.users)
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
			})
	}

	const getSendedMeetings = async (loading = true) => {
		setLoading(true)
		await api
			.get(`/meeting/get_sent_meetings?page=${1}`)
			.then((res: any) => {
				setReceivedMeetingsRequest(res?.data?.data?.receivedRequests)
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
			})
	}

	return (
		<MainContainer>
			<Spacer height={5} />
			{/* {loading && <Loader />} */}
			<Container fluid>
				<Row>
					<Col lg={6}>
						<Flexed direction="row" justify={'flex-end'} margin={'0'} style={{borderBottom: 'none'}}>
							<Card pointer minHeight={openChatModel ? '20rem' : '4rem'} background={palette.white} padding={'0'}>
								<Spacer cursor height={0.2} />
								<Flexed direction="row" margin={'0'} justify={'space-between'} padding={'1rem'} style={{borderBottom: `1px solid ${palette.zink_300}`}}>
									<Flexed direction="row" align={'center'} gap={'1'}>
										<Text fontSize={'1.2'} lineHeight={'1.3'} fontWeight={'700'} color={'text_color'}>
											{t('Network')}
										</Text>
									</Flexed>
									<Flexed direction="row" align={'center'} gap={'0.5'}>
										<Input isSearch isSignup label={t('Search')} onKeyPress={() => setIsSearch(true)} onChange={(e: any) => setSearchChatUserQuery(e.value)} />
									</Flexed>
								</Flexed>
								<Spacer pointer height={1} />

								<Flexed pointer direction="row" align={'center'}>
									<Flexed
										direction="column"
										align={'center'}
										justify={'space-between'}
										margin={'auto'}
										height="4rem"
										pointer
										width={'50%'}
										gap={'1'}
										padding={'1.2rem 0 0'}
										onClick={() => {
											setActiveTab(1)
											setClickTab(true)
										}}
										style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={activeTab == 1 ? '600' : 400} color={activeTab == 1 ? 'blue_500' : 'foreground_600'} pointer>
											{t('My Network')}
										</TabsText>
										{activeTab == 1 ? (
											<OuterDiv style={{width: '100%', borderRadius: '0'}}>
												<InnerDiv style={{borderWidth: '2px'}} />
											</OuterDiv>
										) : (
											<></>
										)}
									</Flexed>
									<Flexed
										direction="column"
										align={'center'}
										width={'50%'}
										justify={'space-between'}
										margin={'auto'}
										height="4rem"
										gap={'1'}
										pointer
										padding={'1.2rem 0 0'}
										onClick={() => setActiveTab(2)}
										style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={activeTab == 2 ? '600' : 400} color={activeTab == 2 ? 'blue_500' : 'foreground_600'} pointer>
											{t('Network Requests')}
										</TabsText>
										{activeTab == 2 ? (
											<OuterDiv style={{width: '100%', borderRadius: '0'}}>
												<InnerDiv style={{borderWidth: '2px'}} />
											</OuterDiv>
										) : (
											<></>
										)}
									</Flexed>
									<Flexed
										direction="column"
										align={'center'}
										width={'50%'}
										justify={'space-between'}
										gap={'1'}
										margin={'auto'}
										height="4rem"
										pointer
										padding={'1.2rem 0 0'}
										onClick={() => setActiveTab(3)}
										style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={activeTab == 3 ? '600' : 400} color={activeTab == 3 ? 'blue_500' : 'foreground_600'} pointer>
											{t('Suggestion')}
										</TabsText>
										{activeTab == 3 ? (
											<OuterDiv style={{width: '100%', borderRadius: '0'}}>
												<InnerDiv style={{borderWidth: '2px'}} />
											</OuterDiv>
										) : (
											<></>
										)}
									</Flexed>
								</Flexed>
								<Spacer height={0.5} />
								{activeTab == 1 ? (
									<MyNetworkCards setClickTab={setClickTab} clickTab={clickTab} recentNetworks={users} isSearch={isSearch} setSearch={setIsSearch} searchChatUserQuery={searchChatUserQuery} />
								) : activeTab == 2 ? (
									<NetworkRequestCards searchChatUserQuery={searchChatUserQuery} isSearch={isSearch} setSearch={setIsSearch} handleLoad={getAllUsers} />
								) : activeTab == 3 ? (
									<SuggestedNetworkCards isSearch={isSearch} setSearch={setIsSearch} searchChatUserQuery={searchChatUserQuery} />
								) : (
									''
								)}
							</Card>
						</Flexed>
						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<Flexed direction="row" justify={'flex-end'} margin={'0'} style={{borderBottom: 'none'}}>
							<Card background={palette.white} padding={'0'}>
								<Spacer height={2} />
								<Flexed direction="row" margin={'0'} justify={'space-between'} padding={'0 2.7rem'}>
									<Flexed direction="row" align={'center'} gap={'1'}>
										<Text fontSize={'1.2'} lineHeight={'1.3'} fontWeight={'700'} color={'text_color'}>
											{t('BMT Requests')}
										</Text>
									</Flexed>
								</Flexed>

								<Flexed pointer direction="row" align={'center'}>
									<Flexed
										direction="column"
										align={'center'}
										justify={'space-between'}
										width={'50%'}
										pointer
										gap={'1'}
										padding={'1.2rem 0 0'}
										margin={'auto'}
										height="4rem"
										onClick={() => {
											setMeetingTab(true)
											setRequestActiveTab(1)
										}}
										style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={requestActiveTab == 1 ? '700' : 400} color={requestActiveTab == 1 ? 'blue_500' : 'foreground_600'} pointer>
											{t('Received')}
										</TabsText>
										{requestActiveTab == 1 ? (
											<OuterDiv style={{width: '100%', borderRadius: '0'}}>
												<InnerDiv style={{borderWidth: '2px'}} />
											</OuterDiv>
										) : (
											''
										)}
									</Flexed>
									<Flexed
										direction="column"
										align={'center'}
										width={'50%'}
										justify={'space-between'}
										gap={'1'}
										pointer
										padding={'1.2rem 0 0'}
										margin={'auto'}
										height="4rem"
										onClick={() => {
											setMeetingTab(true)
											setRequestActiveTab(2)
										}}
										style={{borderBottom: `1px solid ${palette.zink_300}`}}>
										<TabsText fontSize={'1'} lineHeight={'1.20'} fontWeight={requestActiveTab == 2 ? '700' : 400} color={requestActiveTab == 2 ? 'blue_500' : 'foreground_600'} pointer>
											{t('Sent')}
										</TabsText>
										{requestActiveTab == 2 ? (
											<OuterDiv style={{width: '100%', borderRadius: '0'}}>
												<InnerDiv style={{borderWidth: '2px'}} />
											</OuterDiv>
										) : (
											''
										)}
									</Flexed>
								</Flexed>

								{requestActiveTab == 1 ? (
									<RequestCard
										reqLoading={loading}
										activeTab={requestActiveTab}
										meetingTab={meetingTab}
										recentNetworks={receivedMeetingsRequest}
										handleRescheduled={handleReschedule}
										handleDecline={handleIgnore}
										handleAccept={handleAccept}
									/>
								) : requestActiveTab == 2 ? (
									<SentRequestCard reqLoading={loading} activeTab={requestActiveTab} recentNetworks={sentMeetingsRequest} handleRescheduled={handleReschedule} handleDecline={handleIgnore} handleAccept={handleAccept} />
								) : (
									''
								)}
							</Card>
						</Flexed>
					</Col>
				</Row>
			</Container>
			<Footer />
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
`

export const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? padding : `padding`)};
	 `};
`

export const OuterDiv = styled.div`
	width: 100px;
	border-radius: 1rem;
	overflow: hidden;
`

export const InnerDiv = styled.div`
	width: 100%;
	height: 100%;
	border-width: 2px;
	border-style: solid;
	/* border-image: ${palette.blue_500} 1; */
	border-color: ${palette.blue_500};
`

export const TabsText = styled(Text)`
	font-size: 1rem;
	${media.xs`
	font-size: 0.6rem;
	 `};
`
export default Network
