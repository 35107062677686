import React from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {Heading, Text, Flexed, Spacer} from '../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import LocationSearch from './common/LocationSearch'
import {useSelector} from 'react-redux'
import Button from './common/Button'
import useRouter from '../hooks/router'
import {useTranslation} from 'react-i18next'

interface IProps {
	isDarkTheme: boolean
}

const MainCover = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()
	const {t} = useTranslation()
	return (
		<MainContent>
			<OverLay isDarkTheme={_isDarkTheme} />
			<Content>
				<LeftContent>
					<CustomRow justifyContent="start">
						<Col lg={12}>
							<Flexed margin="0rem 0rem 5rem 0rem" align={'start'}>
								<MainHeading level={2} margin="0rem 0rem 1rem 0rem" textTransform="normal" isCentered fontSize={'2.5'} lineHeight={'3.63'} fontWeight={'700'} color={'text_color'}>
									{t('WELCOME TO JUSTNETWORK')}
								</MainHeading>
								<MainHeading level={2} margin="0rem 0rem 2rem 0rem" fontSize={'1.75'} lineHeight={'2.11'} fontWeight={'700'} color={'blue_500'} textTransform="normal" isCentered>
									{t('Turn Your Networking into NetWorth!')}
								</MainHeading>
								<Text fontSize={'1.2'} lineHeight={'1.7'} fontWeight={'400'} color={'text_color'}>
									{t(
										'​JustNetwork is a B2B prospecting platform, designed to optimize and monetize the time of busy executives. By facilitating meaningful and compensated calls between executives and potential clients, JustNetwork transforms the way businesses connect, ensuring that every interaction is valuable and efficient.'
									)}
								</Text>
								<Spacer height={3} />
								<Button
									small
									// type={'clear'}
									borderRadius={'2rem'}
									fontSize={'1.25rem'}
									// padding={'8px 16px 8px 16px'}
									label={t('Join Now - It’s Free')}
									height={'4rem'}
									width={'13rem'}
									background={palette.default_theme}
									lineHeight={'24.2px'}
									ifClicked={() => {
										router.navigate('/signup')
									}}
								/>
							</Flexed>
						</Col>
					</CustomRow>
				</LeftContent>
				<RightContent>
					<Row>
						<Column lg={12} md={12} sm={12} xs={12}>
							<Flexed direction={'column'} justify={'center'} align={'center'}>
								<Img width={'100%'} src="/images/Group_2821__1_-removebg.png" />
								{/* <BoardImg src="/images/user_1.png" /> */}
							</Flexed>
						</Column>
					</Row>
				</RightContent>
			</Content>
		</MainContent>
	)
}

const MainContent = styled.div``

const LeftContent = styled(Container)`
	background-image: url('/images/heza_bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	padding: 1rem;
`
const CustomRow = styled(Row)`
	padding: 2rem;
	margin-top: 8rem;

	${media.xs`
   margin-top: 3rem;
    `}
	${media.sm`
   margin-top: 3rem;
    `}
    ${media.md`
    margin-top: 8rem;
    `}
	${media.lg`
    margin-top: 8rem;
    `}
`

const RightContent = styled(Container)`
	height: 80vh;
`
const ImagesGroup = styled(Flexed)`
	background-image: url('/images/users-group.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
`

const Content = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	/* height: 100vh; */
	position: relative;
	z-index: 2;
	${media.xs`
    flex-direction: column;
    `}
	${media.sm`
    flex-direction: column;
    `}
    ${media.md`
    flex-direction: row;
    `}
	${media.lg`
    flex-direction: row;
    `}
`

const OverLay = styled.div<IProps>`
	/* background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.main_cover_overlay_dark}` : `${palette.main_cover_overlay_light}`)}; */
	position: absolute;
	width: 100%;
	height: 100vh;
`

const MainHeading = styled(Heading)`
	@media screen and (min-width: 0px) and (max-width: 1300px) {
		// margin-right:3rem;
		// margin-left:3rem;
		text-align: center;
	}
`

const Column = styled(Col)`
	text-align: center;
	margin-top: 2rem;
`

const Img = styled.img`
	width: ${({width}) => width};
	/* height: 2rem;
	${media.md`height:3rem;`}
	${media.lg`height:4rem;`}
margin-bottom:0.5rem; */
`

const BoardImg = styled.img`
	position: relative;
	top: -40%;
	width: 40%;
	margin-top: -12rem;
	/* width: 10rem; */
`

export default MainCover
